import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { globalSignupAction, clearErrorAction } from '../../store/actions/authActions'
import Loader1 from '../loader/Loader1';
import PhoneInput from 'react-phone-input-2';
import Breadcrumb from "../common/Breadcrumb";
import ErrorMessage from "../errorMessage/ErrorMessage"
import ConfirmationMessage from "../confirmationMessage/ConfirmationMessage"
import * as ga from '../util/googleAnalytics'

class GlobalSignUp extends Component {

  constructor(props) {
    super(props)
    this.state = this.initialState()
    this.handleChange = this.handleChange.bind(this)
    this.handleHomeAddressChange = this.handleHomeAddressChange.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.handleOfficePhoneChange = this.handleOfficePhoneChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.autocompleteHomeAddress = null
  }

  initialState() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      officePhoneNumber: '',
      companyName: '',
      numberOfFleet: '',
      country: '',
      homeAddress: '',
      homeAddressComponents: null,
      validHomeAddress: false,
      profileDescription: '',
      loading: false,
    }
  }

  componentDidMount() {
    this.props.clearError();
    ga.pageViewsTracking(window.location.pathname);

    const google = window.google;
    var options = {
      // componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] }
    };
    this.autocompleteHomeAddress = new google.maps.places.Autocomplete(document.getElementById('homeAddress'), options)
    this.autocompleteHomeAddress.addListener("place_changed", this.handleHomeAddressChange);
  }

  handleChange = (e) => {
    e.preventDefault();
    const { id, type, value } = e.target
    if (type === 'email') {
      this.setState({
        [id]: value,
      })
    } else if (id === 'numberOfFleet') {
      this.setState({
        [id]: e.target.value.replace(/\D/g, ''),
      })
    } else if (id === 'homeAddress') {
      this.setState({
        homeAddress: e.target.value,
      })
    } else if (id === 'country') {
      this.setState({
        country: e.target.value,
        homeAddress: '',
        homeAddressComponents: null,
        validHomeAddress: false,
      })
    } else {
      this.setState({
        [id]: value
      })
    }
  }

  handleHomeAddressChange() {
    let addressObject = this.autocompleteHomeAddress.getPlace();
    if (addressObject) {
      this.setState({
        homeAddress: addressObject.formatted_address,
        homeAddressComponents: addressObject.address_components || null,
        validHomeAddress: true,
      })
    }
  }

  handlePhoneChange = (value) => {
    this.setState({
      phoneNumber: value.replace(/[^0-9]+/g, '')
    })
  }

  handleOfficePhoneChange = (value) => {
    this.setState({
      officePhoneNumber: value.replace(/[^0-9]+/g, '')
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.clearError();
    this.setState({
      loading: true
    }, () => {
      this.props.globalSignupAction(this.state)
        .then((result) => {
          window.scrollTo(0, 0);
          if (result === 1) {
            this.setState({
              firstName: '',
              lastName: '',
              email: '',
              phoneNumber: '',
              officePhoneNumber: '',
              companyName: '',
              numberOfFleet: '',
              country: '',
              homeAddress: '',
              homeAddressComponents: null,
              validHomeAddress: false,
              profileDescription: '',
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.setState({
            loading: false
          });
        })
    })
  }

  render() {
    const { auth, error, profile, globalSignUpSuccess } = this.props;
    if (auth.uid) {
      if (profile && !profile.isEmpty) {
        return <Redirect to='/home' />
      }
    } else {
      let loader = null;
      if (this.state.loading === true) {
        loader = <Loader1 />
      }
      return (
        <>
          {loader}
          <Breadcrumb title={'Global Operators'} title1={'Global Operators'} />
          <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
            <div className="container">
              <div className="mb--40">
                {
                  error
                    ? <ErrorMessage errorMessageText={error}></ErrorMessage>
                    : globalSignUpSuccess === true
                      ? <ConfirmationMessage message="Congratulations! You have been successfully been added as a FINDANIO Global Operator." />
                      : null
                }
              </div>
              <div className="section-title text-left mb--30">
                <h3 className="title mt--30">Join FINDANIO Global Operators</h3>
              </div>
              <div>
                <div className="row">
                  <div className="col-lg-6  col-md-6 col-sm-6 order-1 order-lg-1">
                    <div>
                      <label htmlFor="firstName">First Name<span className="star">*</span></label>
                      <input type="text" id='firstName' placeholder="Enter your first name" onChange={this.handleChange}
                        value={this.state.firstName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                    <div>
                      <label htmlFor="lastName">Last Name<span className="star">*</span></label>
                      <input type="text" id='lastName' placeholder="Enter your last name" onChange={this.handleChange}
                        value={this.state.lastName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                    <div>
                      <label htmlFor="phoneNumber">Cell Phone<span className="star">*</span></label>
                      <PhoneInput
                        specialLabel=""
                        id='phoneNumber'
                        placeholder={'Enter cell phone'}
                        value={this.state.phoneNumber}
                        onChange={this.handlePhoneChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                    <div>
                      <label htmlFor="officePhoneNumber">Office Phone</label>
                      <PhoneInput
                        specialLabel=""
                        id='officePhoneNumber'
                        placeholder={'Enter office phone'}
                        value={this.state.officePhoneNumber}
                        onChange={this.handleOfficePhoneChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                    <div>
                      <label htmlFor="email">Email<span className="star">*</span></label>
                      <input type="email" id='email' placeholder="yours@example.com" onChange={this.handleChange}
                        value={this.state.email}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                    <div>
                      <label htmlFor="companyName">Company Name<span className="star">*</span></label>
                      <input type="text" id='companyName' placeholder="Enter your company name" onChange={this.handleChange}
                        value={this.state.companyName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                    <label htmlFor="country">Country<span className="star">*</span></label>
                    <select name="country" id="country"
                      value={this.state.country} onChange={this.handleChange} >
                      <option value="">Select country</option>
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Åland Islands">Åland Islands</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antarctica">Antarctica</option>
                      <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                      <option value="Botswana">Botswana</option>
                      <option value="Bouvet Island">Bouvet Island</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                      <option value="Brunei Darussalam">Brunei Darussalam</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">Central African Republic</option>
                      <option value="Chad">Chad</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote D'ivoire">Cote D'ivoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">Dominican Republic</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">Equatorial Guinea</option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Territories">French Southern Territories</option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guernsey">Guernsey</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guinea-bissau">Guinea-bissau</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                      <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="India">India</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jersey">Jersey</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                      <option value="Korea, Republic of">Korea, Republic of</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macao">Macao</option>
                      <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                      <option value="Moldova, Republic of">Moldova, Republic of</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montenegro">Montenegro</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Namibia">Namibia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherlands">Netherlands</option>
                      <option value="Netherlands Antilles">Netherlands Antilles</option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau">Palau</option>
                      <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Philippines">Philippines</option>
                      <option value="Pitcairn">Pitcairn</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russian Federation">Russian Federation</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="Saint Helena">Saint Helena</option>
                      <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                      <option value="Saint Lucia">Saint Lucia</option>
                      <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                      <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                      <option value="Samoa">Samoa</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Serbia">Serbia</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Timor-leste">Timor-leste</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">United Arab Emirates</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                      <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                      <option value="Uruguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Viet Nam">Viet Nam</option>
                      <option value="Virgin Islands, British">Virgin Islands, British</option>
                      <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                      <option value="Wallis and Futuna">Wallis and Futuna</option>
                      <option value="Western Sahara">Western Sahara</option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                    <label htmlFor="homeAddress">Address<span className="star">*</span></label>
                    <input type="text" id='homeAddress' placeholder="Enter your address" onChange={this.handleChange}
                      value={this.state.homeAddress}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 order-1 order-lg-1">
                    <label htmlFor="numberOfFleet">Number of Fleet<span className="star">*</span></label>
                    <input type="text" id='numberOfFleet' placeholder="Enter your fleet number" onChange={this.handleChange}
                      value={this.state.numberOfFleet}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="profileDescription">About you</label>
                    <textarea id='profileDescription' className="materialize-textarea has-character-counter updatable"
                      placeholder="Enter something about yourself which would be seen by the requestors."
                      value={this.state.profileDescription}
                      onChange={this.handleChange}
                      data-length="500" maxLength="500"></textarea>
                  </div>
                </div>
                <div className="row ptb--10">
                  <div className="col-lg-12">
                    By clicking the button below, you confirm that you've read and accepted our <a href="/tnc" className="anchor">Terms and Conditions of Service</a> and <a href="/privacy" className="anchor">Privacy Policy</a>
                  </div>
                </div>
                <div className="row ptb--10">
                  <div className="col-lg-12">
                    <button className="rn-button-style--2 btn-solid align-center" onClick={this.handleSubmit}>JOIN</button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )
    }
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    error: state.auth.globalSignUpError,
    globalSignUpSuccess: state.auth.globalSignUpSuccess,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    globalSignupAction: (obj) => dispatch(globalSignupAction(obj)),
    clearError: () => dispatch(clearErrorAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSignUp)
