import React from 'react'
import ErrorIcon from '@material-ui/icons/Error';

class DisclaimerMessages extends React.Component {
    render() {
        const { disclaimerMessagesArr } = this.props;
        if (disclaimerMessagesArr.length > 0) {
            return (
                <div className="gray-box">
                    <p className="gray-text"><b>Disclaimer:</b></p>
                    {
                        disclaimerMessagesArr.map((disclaimerMessage, index) => {
                            return (
                                <div className="row mt--10" key={`disclaimerMsg${index}`}>
                                    <div className="col-12">
                                        <span className="icon">
                                            <ErrorIcon className="gray-text" />
                                        </span> {disclaimerMessage}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        } else {
            return (<></>)
        }
    }
}

export default DisclaimerMessages
