import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader1 from '../loader/Loader1';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, Tooltip } from '@material-ui/core';
import { updateProfile, updateImage, getCarMakeListAction, clearErrorAction } from '../../store/actions/authActions'
import M from "materialize-css";
import PhoneInput from 'react-phone-input-2';
import Breadcrumb from "../common/Breadcrumb";
import { StorageBaseURL, altMedia } from "../constants/CommonConstants"
import firebase from "../../config/fbConfig";
import Delete from '@material-ui/icons/DeleteRounded';
import CheckBox from '../common/Checkbox';
import ErrorMessage from "../errorMessage/ErrorMessage"
import ConfirmationMessage from "../confirmationMessage/ConfirmationMessage"
import { getVehicleTypeName } from '../util/rideUtils';
import parse from 'html-react-parser'
import InfoIcon from '@material-ui/icons/Info';

class ViewProfile extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.editPersonalDetailsBtnClicked = this.editPersonalDetailsBtnClicked.bind(this);
        this.saveProfileBtnClicked = this.saveProfileBtnClicked.bind(this);
        this.editImageBtnClicked = this.editImageBtnClicked.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.removeFileHandler = this.removeFileHandler.bind(this);
        this.updateImageBtnClicked = this.updateImageBtnClicked.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    initialState() {
        return {
            loading: false,
            airportList: [],
            carModelList: [],
            carModelTypeMapping: {},
            profile: {
                uid: '',
                email: '',
                password: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                officePhoneNumber: '',
                carMake: '',
                carModel: '',
                carYear: '',
                vehicleVIN: '',
                carPlate: '',
                carType: '',
                carColor: '',
                state: '',
                airports: [],
                petFriendly: '',
                receiveEmails: '',
                receiveTexts: '',
                companyName: '',
                profileImageUrl: '',
                licenseImageUrl: '',
                insuranceImageUrl: '',
                vehicleImageUrl: '',
                vehicleRegistrationImageURL: '',
                q1: '',
                q2: '',
                q3: '',
                q4: '',
                requestorCategory: '',
                liabilityInsuranceAmt: '',
                cancellationPolicy: '',
                profileDescription: ''
            },
            editMode: false,
        }
    }

    handleOfficePhoneChange = (value, data, event) => {
        this.setState({
            profile: { ...this.state.profile, officePhoneNumber: value.replace(/[^0-9]+/g, '') }
        })
    }

    fileChangedHandler = (event) => {
        const file = event.target.files[0];
        if (file.type && file.type.toLowerCase().includes('image')) {
            switch (event.target.id) {
                case 'newProfileImageFile':
                    this.setState({
                        newProfileImageFile: file,
                        newProfileImageFileURL: URL.createObjectURL(event.target.files[0])
                    });
                    break;
                case 'newLicenseImageFile':
                    this.setState({
                        newLicenseImageFile: file,
                        newLicenseImageFileURL: URL.createObjectURL(event.target.files[0]),
                    });
                    break;
                case 'newInsuranceImageFile':
                    this.setState({
                        newInsuranceImageFile: file,
                        newInsuranceImageFileURL: URL.createObjectURL(event.target.files[0]),
                    });
                    break;
                case 'newVehicleImageFile':
                    this.setState({
                        newVehicleImageFile: file,
                        newVehicleImageFileURL: URL.createObjectURL(event.target.files[0]),
                    });
                    break;
                case 'newVehicleRegistrationImageFile':
                    this.setState({
                        newVehicleRegistrationImageFile: file,
                        newVehicleRegistrationImageFileURL: URL.createObjectURL(event.target.files[0]),
                    });
                    break;
                default:
                    break;
            }
            document.getElementById(`${event.target.id}Err`).innerHTML = '';
        } else {
            // invalid image file uploaded, so clear it
            document.getElementById(event.target.id).value = '';
            document.getElementById(`${event.target.id}Err`).innerHTML = 'Please upload an image file only.';
        }
    }

    removeFileHandler = (id) => {
        document.getElementById(id).value = '';
        switch (id) {
            case 'newProfileImageFile':
                this.setState({
                    newProfileImageFile: null,
                    newProfileImageFileURL: ''
                });
                break;
            case 'newLicenseImageFile':
                this.setState({
                    newLicenseImageFile: null,
                    newLicenseImageFileURL: '',
                });
                break;
            case 'newInsuranceImageFile':
                this.setState({
                    newInsuranceImageFile: null,
                    newInsuranceImageFileURL: '',
                });
                break;
            case 'newVehicleImageFile':
                this.setState({
                    newVehicleImageFile: null,
                    newVehicleImageFileURL: '',
                });
                break;
            case 'newVehicleRegistrationImageFile':
                this.setState({
                    newVehicleRegistrationImageFile: null,
                    newVehicleRegistrationImageFileURL: '',
                });
                break;
            default:
                break;
        }
    }

    editImageBtnClicked = (category) => {
        let modalId = `update${category}ImageModal`
        var elems = document.getElementById(modalId);
        M.Modal.init(elems, {});
        M.Modal.getInstance(elems).open();
    }

    updateImageBtnClicked = (type) => {
        let imageFile = '';
        let category = '';
        let target = ''
        switch (type) {
            case 'Profile':
                imageFile = this.state.newProfileImageFile;
                category = 'profile';
                target = 'newProfileImageFileErr';
                break;
            case 'License':
                imageFile = this.state.newLicenseImageFile;
                category = 'license';
                target = 'newLicenseImageFileErr';
                break;
            case 'Insurance':
                imageFile = this.state.newInsuranceImageFile;
                category = 'insurance';
                target = 'newInsuranceImageFileErr';
                break;
            case 'Vehicle':
                imageFile = this.state.newVehicleImageFile;
                category = 'vehicle';
                target = 'newVehicleImageFileErr';
                break;
            case 'VehicleRegistration':
                imageFile = this.state.newVehicleRegistrationImageFile;
                category = 'vehicleRegistration';
                target = 'newVehicleRegistrationImageFileErr';
                break;
            default:
                break;
        }

        if (imageFile) {
            document.getElementById(target).innerHTML = 'Uploading image, please wait.';
            this.setState({
                loading: true
            }, (() => {
                this.props.updateImage({
                    uid: this.state.profile.uid,
                    accountType: this.state.profile.accountType,
                    category,
                    imageFile
                })
                    .then(() => {
                        let modalId = `update${type}ImageModal`
                        var elems = document.getElementById(modalId);
                        M.Modal.getInstance(elems).close();
                        this.setState({
                            loading: false
                        });
                        window.scrollTo(0, 0)
                    })
            })
            );
        } else {
            document.getElementById(target).innerHTML = 'Please upload an image file.';
        }

    }

    editPersonalDetailsBtnClicked = () => {
        let currentEditMode = this.state.editMode;
        if (currentEditMode === false) {
            this.setState({
                editMode: true,
            }, () => {
                const x = document.querySelectorAll(".updatable");
                for (let v of x) {
                    v.disabled = false;
                }
                if (this.state.profile.carColor === 'OtherColor') {
                    document.getElementById('otherColor').disabled = false;
                }
            })
        } else {
            this.setState({
                editMode: false,
            }, () => {
                const x = document.querySelectorAll(".updatable");
                for (let v of x) {
                    v.disabled = true;
                }
                if (this.state.profile.carColor === 'OtherColor') {
                    document.getElementById('otherColor').disabled = true;
                }
            })
        }
    }

    saveProfileBtnClicked = async () => {
        this.setState({
            loading: true
        }, (() => {
            this.props.updateProfile(this.state.profile)
                .then(() => {
                    if (!this.props.errorMsg) {
                        this.setState({
                            editMode: false,
                            loading: false
                        }, () => {
                            const x = document.querySelectorAll(".updatable");
                            for (let v of x) {
                                v.disabled = true
                            }
                            window.scrollTo(0, 0);
                        })
                    } else {
                        // error occurred
                        this.setState({
                            editMode: true,
                            loading: false
                        });
                        window.scrollTo(0, 0)
                    }
                });
        })
        )
    }

    handleCheckInputChanged = (event) => {
        let airportList = this.state.airportList;
        let selectedAirports = [];
        airportList.forEach(airport => {
            if (airport.value === event.target.value) {
                airport.isChecked = event.target.checked;
            }
            if (airport.isChecked) {
                selectedAirports.push(airport.value);
            }
        })
        this.setState({
            airportList,
            profile: {
                ...this.state.profile,
                airports: selectedAirports
            }
        })
    }

    handleChange = (e) => {
        const { id, name, value } = e.target
        if (name === 'petFriendly' || name === 'receiveEmails' || name === 'receiveTexts'
            || name === 'q1' || name === 'q2' || name === 'q3' || name === 'q4' || name === 'otherColor') {
            this.setState({
                profile: { ...this.state.profile, [name]: value }
            })
        } else if (name === 'carMake') {
            let carModelDropdown = document.getElementById("carModel");
            if (value === "") {
                if (carModelDropdown) {
                    carModelDropdown.setAttribute("disabled", "disabled");
                }
            } else {
                firebase.firestore().collection('cars').doc(value).get().then((doc) => {
                    let data = [];
                    if (doc.exists) {
                        data = doc.data();
                    }
                    this.setState({
                        profile: {
                            ...this.state.profile,
                            [id]: value,
                            carModel: '',
                            carType: ''
                        },
                        carModelList: Object.keys(data.modelType),
                        carModelTypeMapping: data.modelType
                    });
                });
            }
        } else if (name === 'carModel') {
            this.setState({
                profile: {
                    ...this.state.profile,
                    carModel: value,
                    carType: this.state.carModelTypeMapping[value]
                },
            })
        } else if (name === 'state') {
            firebase.firestore().collection('airports').doc(value).get().then((doc) => {
                let airports = [];
                if (doc.exists) {
                    let data = doc.data();
                    for (var p in data.airports) {
                        if (data.airports.hasOwnProperty(p)) {
                            airports.push({ value: p, label: data.airports[p] + " (" + p + ")", isChecked: false });
                        }
                    }
                    this.setState({
                        airportList: airports,
                        profile: {
                            ...this.state.profile,
                            [id]: value,
                            airports: [],
                        },
                    });
                }
            });
            // } else if (name === 'airports') {
            //     const selected = document.querySelectorAll('#airports option:checked');
            //     const values = Array.from(selected).map(el => el.value);
            //     this.setState({
            //         profile: {
            //             ...this.state.profile,
            //             [id]: values
            //         },
            //     })
        } else if (name === 'carColor') {
            if (value === 'OtherColor') {
                document.getElementById('otherColor').disabled = false;
                this.setState({
                    profile: { ...this.state.profile, [id]: value }
                })
            } else {
                document.getElementById('otherColor').disabled = true;
                this.setState({
                    profile: { ...this.state.profile, [id]: value, otherColor: '' }
                })
            }
        } else {
            this.setState({
                profile: { ...this.state.profile, [id]: value }
            })
        }
    }

    componentDidMount() {
        this.props.clearError();
        const { auth, profile } = this.props;
        if (auth && auth.uid && profile) {
            if (!this.state.profile.uid && profile.isEmpty === false) {
                var updatedProfileImageUrl = profile.profileImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/profile/' + profile.profileImageUrl) + altMedia : null;
                var updatedLicenseUrl = profile.licenseImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/license/' + profile.licenseImageUrl) + altMedia : null;
                var updatedInsuranceUrl = profile.insuranceImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/insurance/' + profile.insuranceImageUrl) + altMedia : null;
                var updatedVehicleImageUrl = profile.vehicleImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/vehicle/' + profile.vehicleImageUrl) + altMedia : null;
                var updatedVehicleRegistrationImageUrl = profile.vehicleRegistrationImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/vehicleRegistration/' + profile.vehicleRegistrationImageUrl) + altMedia : null;
                let p = {
                    ...profile,
                    airports: profile.airports || [],
                    uid: auth.uid,
                    profileImageUrl: updatedProfileImageUrl,
                    licenseImageUrl: updatedLicenseUrl,
                    insuranceImageUrl: updatedInsuranceUrl,
                    vehicleImageUrl: updatedVehicleImageUrl,
                    vehicleRegistrationImageUrl: updatedVehicleRegistrationImageUrl,
                }
                if (profile.accountType === 'Operator') {

                    if (!this.props.carMakeList || this.props.carMakeList.length === 0) {
                        this.props.getCarMakeList();
                    }

                    let promiseList = [];
                    const emptyObj = {};
                    profile.carMake ? promiseList.push(firebase.firestore().collection('cars').doc(profile.carMake).get()) : promiseList.push(emptyObj);
                    profile.state ? promiseList.push(firebase.firestore().collection('airports').doc(profile.state).get()) : promiseList.push(emptyObj);
                    Promise.all(promiseList).then((results) => {
                        let [cars, airportDocs] = results;
                        let carMakeData = {};
                        let airports = [];
                        if (cars && cars.exists) {
                            carMakeData = cars.data();
                        } else {
                            carMakeData.modelType = [];
                        }
                        if (airportDocs.exists) {
                            let data = airportDocs.data();
                            for (var ap in data.airports) {
                                if (data.airports.hasOwnProperty(ap)) {
                                    if (profile.airports.includes(ap)) {
                                        airports.push({ value: ap, label: data.airports[ap] + " (" + ap + ")", isChecked: true });
                                    } else {
                                        airports.push({ value: ap, label: data.airports[ap] + " (" + ap + ")", isChecked: false });
                                    }
                                }
                            }
                        }
                        this.setState({
                            profile: p,
                            carModelList: Object.keys(carMakeData.modelType),
                            carModelTypeMapping: carMakeData.modelType,
                            airportList: airports
                        })
                    })
                }
                else {
                    this.setState({
                        profile: p
                    })
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { auth, profile } = this.props;
        if (auth && auth.uid && prevProps !== this.props && profile !== prevProps.profile) {
            if ((!this.state.profile.uid && profile.isEmpty === false) || prevProps.refresh !== this.props.refresh || profile !== prevProps.profile) {
                var updatedProfileImageUrl = profile.profileImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/profile/' + profile.profileImageUrl) + altMedia : null;
                var updatedLicenseUrl = profile.licenseImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/license/' + profile.licenseImageUrl) + altMedia : null;
                var updatedInsuranceUrl = profile.insuranceImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/insurance/' + profile.insuranceImageUrl) + altMedia : null;
                var updatedVehicleImageUrl = profile.vehicleImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/vehicle/' + profile.vehicleImageUrl) + altMedia : null;
                var updatedVehicleRegistrationImageUrl = profile.vehicleRegistrationImageUrl ? StorageBaseURL + encodeURIComponent(auth.uid + '/vehicleRegistration/' + profile.vehicleRegistrationImageUrl) + altMedia : null;

                let p = {
                    ...profile,
                    airports: profile.airports || [],
                    uid: auth.uid,
                    profileImageUrl: updatedProfileImageUrl,
                    licenseImageUrl: updatedLicenseUrl,
                    insuranceImageUrl: updatedInsuranceUrl,
                    vehicleImageUrl: updatedVehicleImageUrl,
                    vehicleRegistrationImageUrl: updatedVehicleRegistrationImageUrl,
                }

                if (profile.accountType === 'Operator') {

                    if (!this.props.carMakeList || this.props.carMakeList.length === 0) {
                        this.props.getCarMakeList();
                    }

                    let promiseList = [];
                    const emptyObj = {};
                    profile.carMake ? promiseList.push(firebase.firestore().collection('cars').doc(profile.carMake).get()) : promiseList.push(emptyObj);
                    profile.state ? promiseList.push(firebase.firestore().collection('airports').doc(profile.state).get()) : promiseList.push(emptyObj);
                    Promise.all(promiseList).then((results) => {
                        let [cars, airportDocs] = results;
                        let carMakeData = {};
                        let airports = [];
                        if (cars.exists) {
                            carMakeData = cars.data();
                        }
                        if (airportDocs.exists) {
                            let data = airportDocs.data();
                            for (var ap in data.airports) {
                                if (data.airports.hasOwnProperty(ap)) {
                                    if (profile.airports.includes(ap)) {
                                        airports.push({ value: ap, label: data.airports[ap] + " (" + ap + ")", isChecked: true });
                                    } else {
                                        airports.push({ value: ap, label: data.airports[ap] + " (" + ap + ")", isChecked: false });
                                    }
                                }
                            }
                        }
                        this.setState({
                            profile: p,
                            carModelList: carMakeData.modelType ? Object.keys(carMakeData.modelType) : [],
                            carModelTypeMapping: carMakeData.modelType,
                            airportList: airports
                        })
                    })
                }
                else {
                    this.setState({
                        profile: p
                    })
                }
            }
        }
    }

    render() {
        const { auth, errorMsg, successMsg } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        let carModelOptions = [];
        for (let m in this.state.carModelList) {
            carModelOptions.push(<option key={`${this.state.carModelList[m]}-${m}`} value={this.state.carModelList[m]}>{this.state.carModelList[m]}</option>);
        }

        let carYearList = [];
        const currentYear = new Date().getFullYear();
        for (let x = currentYear; x > 2015; x--) {
            carYearList.push(x);
        }

        let loader = null;
        if (this.state.loading === true) {
            loader = <Loader1 />
        }

        let profile = this.state.profile;
        if (!profile || profile.uid === '') {
            return (
                <>
                    <Breadcrumb title={'Profile'} parent='Account Settings' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mb--50">
                                        <p className="description">Loading</p>
                                        <Loader1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else {
            const operatorProfile = profile.accountType === 'Operator' ? (
                <>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="companyName">Company Name<span className="star">*</span></label>
                            <input type="text" className="updatable" disabled
                                onChange={this.handleChange}
                                id='companyName' value={profile.companyName} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="profileDescription">About you</label>
                            <textarea id='profileDescription' className="materialize-textarea has-character-counter updatable"
                                disabled
                                placeholder="Enter something about yourself which would be seen by the requestors."
                                value={profile.profileDescription}
                                onChange={this.handleChange}
                                data-length="500"
                                maxLength="500"></textarea>

                        </div>
                    </div>

                    <h3 className="title underline">Preferences</h3>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you want to receive text messages?<span className="star">*</span>
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="receiveTexts" className="updatable" disabled type="radio" value="Yes"
                                    checked={profile.receiveTexts === "Yes"}
                                    onChange={this.handleChange} />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="receiveTexts" className="updatable" disabled type="radio" value="No"
                                    checked={profile.receiveTexts === "No"}
                                    onChange={this.handleChange} />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you want to receive email communications?<span className="star">*</span>
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="receiveEmails" className="updatable" disabled type="radio" value="Yes"
                                    checked={profile.receiveEmails === "Yes"}
                                    onChange={this.handleChange} />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="receiveEmails" className="updatable" disabled type="radio" value="No"
                                    checked={profile.receiveEmails === "No"}
                                    onChange={this.handleChange} />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Pet Friendly?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="petFriendly" className="updatable" disabled type="radio" value="Yes"
                                    checked={profile.petFriendly === "Yes"}
                                    onChange={this.handleChange} />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="petFriendly" className="updatable" disabled type="radio" value="No"
                                    checked={profile.petFriendly === "No"}
                                    onChange={this.handleChange} />
                                <span>No</span>
                            </span>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you sanitize vehicle after each passenger?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="q1" className="updatable" disabled type="radio" value="Yes"
                                    checked={profile.q1 === "Yes"}
                                    onChange={this.handleChange} />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="q1" className="updatable" disabled type="radio" value="No"
                                    checked={profile.q1 === "No"}
                                    onChange={this.handleChange} />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you provide complimentary water for passengers?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="q2" className="updatable" disabled type="radio" value="Yes"
                                    checked={profile.q2 === "Yes"}
                                    onChange={this.handleChange} />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="q2" className="updatable" disabled type="radio" value="No"
                                    checked={profile.q2 === "No"}
                                    onChange={this.handleChange} />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you provide hand sanitizer for passengers?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="q3" className="updatable" disabled type="radio" value="Yes"
                                    checked={profile.q3 === "Yes"}
                                    onChange={this.handleChange} />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="q3" className="updatable" disabled type="radio" value="No"
                                    checked={profile.q3 === "No"}
                                    onChange={this.handleChange} />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            Do you have a sneeze guards divider shield in your car?
                        </div>
                        <div className="col-lg-8 col-sm-6 col-md-6">
                            <span className="mr--20">
                                <input name="q4" className="updatable" disabled type="radio" value="Yes"
                                    checked={profile.q4 === "Yes"}
                                    onChange={this.handleChange} />
                                <span>Yes</span>
                            </span>
                            <span>
                                <input name="q4" className="updatable" disabled type="radio" value="No"
                                    checked={profile.q4 === "No"}
                                    onChange={this.handleChange} />
                                <span>No</span>
                            </span>
                        </div>
                    </div>

                    <br />
                    <h3 className="title underline">Vehicle details</h3>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carPlate">License Plate<span className="star">*</span></label>
                            <input type="text" id='carPlate' className="updatable" disabled
                                value={profile.carPlate} onChange={this.handleChange} />
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="vehicleVIN">Vehicle VIN<span className="star">*</span></label>
                            <input type="text" id='vehicleVIN' className="updatable" disabled
                                value={profile.vehicleVIN} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carYear">Year<span className="star">*</span></label>
                            <select name="carYear" id="carYear"
                                value={profile.carYear}
                                className="updatable" disabled
                                onChange={this.handleChange} >
                                <option value="">Select year</option>
                                {
                                    carYearList && carYearList.length > 0 && carYearList.map((value) => {
                                        if (value === profile.carYear) {
                                            return <option key={`carYearId${value}`} value={value} defaultValue={true}>{value}</option>
                                        } else {
                                            return <option key={`carYearId${value}`} value={value} defaultValue={false}>{value}</option>
                                        }
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="carMake">Make<span className="star">*</span></label>
                            <select id="carMake" name="carMake" value={profile.carMake}
                                className="updatable" disabled onChange={this.handleChange} >
                                <option value="">Select a car make</option>
                                {
                                    this.props.carMakeList && this.props.carMakeList.length > 0 && this.props.carMakeList.map((value) => {
                                        if (value === profile.carMake) {
                                            return <option key={`carMakeId${value}`} value={value} defaultValue={true}>{value}</option>
                                        } else {
                                            return <option key={`carMakeId${value}`} value={value} defaultValue={false}>{value}</option>
                                        }
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carModel">Model<span className="star">*</span></label>
                            <select name="carModel" id="carModel" value={profile.carModel}
                                className="updatable"
                                disabled
                                onChange={this.handleChange}>
                                <option value="">Select a car model</option>
                                {carModelOptions}
                            </select>
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="carType">Vehicle Type</label>
                            <input type="text" id='carType' placeholder="Select car make and model" value={getVehicleTypeName(profile.carType)} disabled />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="carColor">Color<span className="star">*</span></label>
                            <select id="carColor" name="carColor" value={profile.carColor}
                                className="updatable" disabled onChange={this.handleChange} >
                                <option value="">Select color</option>
                                <option value="Black">Black</option>
                                <option value="OtherColor">Other</option>
                            </select>
                        </div>
                        <div className="col-lg-6">
                            <label>Other color</label>
                            <input type="text" name="otherColor" id='otherColor' disabled
                                value={profile.otherColor ? profile.otherColor : ''}
                                onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="state">State<span className="star">*</span></label>
                            <select name="state" id="state" value={profile.state} className="updatable" disabled
                                onChange={this.handleChange}>
                                <option value="">Select a State</option>
                                <option value="AL">Alabama (AL)</option>
                                <option value="AK">Alaska (AK)</option>
                                <option value="AZ">Arizona (AZ)</option>
                                <option value="AR">Arkansas (AR)</option>
                                <option value="CA">California (CA)</option>
                                <option value="CO">Colorado (CO)</option>
                                <option value="CT">Connecticut (CT)</option>
                                <option value="DE">Delaware (DE)</option>
                                <option value="DC">District Of Columbia (DC)</option>
                                <option value="FL">Florida (FL)</option>
                                <option value="GA">Georgia (GA)</option>
                                <option value="HI">Hawaii (HI)</option>
                                <option value="ID">Idaho (ID)</option>
                                <option value="IL">Illinois (IL)</option>
                                <option value="IN">Indiana (IN)</option>
                                <option value="IA">Iowa (IA)</option>
                                <option value="KS">Kansas (KS)</option>
                                <option value="KY">Kentucky (KY)</option>
                                <option value="LA">Louisiana (LA)</option>
                                <option value="ME">Maine (ME)</option>
                                <option value="MD">Maryland (MD)</option>
                                <option value="MA">Massachusetts (MA)</option>
                                <option value="MI">Michigan (MI)</option>
                                <option value="MN">Minnesota (MN)</option>
                                <option value="MS">Mississippi (MS)</option>
                                <option value="MO">Missouri (MO)</option>
                                <option value="MT">Montana (MT)</option>
                                <option value="NE">Nebraska (NE)</option>
                                <option value="NV">Nevada (NV)</option>
                                <option value="NH">New Hampshire (NH)</option>
                                <option value="NJ">New Jersey (NJ)</option>
                                <option value="NM">New Mexico (NM)</option>
                                <option value="NY">New York (NY)</option>
                                <option value="NC">North Carolina (NC)</option>
                                <option value="ND">North Dakota (ND)</option>
                                <option value="OH">Ohio (OH)</option>
                                <option value="OK">Oklahoma (OK)</option>
                                <option value="OR">Oregon (OR)</option>
                                <option value="PA">Pennsylvania (PA)</option>
                                <option value="RI">Rhode Island (RI)</option>
                                <option value="SC">South Carolina (SC)</option>
                                <option value="SD">South Dakota (SD)</option>
                                <option value="TN">Tennessee (TN)</option>
                                <option value="TX">Texas (TX)</option>
                                <option value="UT">Utah (UT)</option>
                                <option value="VT">Vermont (VT)</option>
                                <option value="VA">Virginia (VA)</option>
                                <option value="WA">Washington (WA)</option>
                                <option value="WV">West Virginia (WV)</option>
                                <option value="WI">Wisconsin (WI)</option>
                                <option value="WY">Wyoming (WY)</option>
                            </select>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <label>Airports you service<span className="star">*</span></label>
                            <ul>
                                {
                                    this.state.airportList.length > 0 && this.state.airportList.map((airport) => {
                                        return (<CheckBox className={this.state.editMode ? 'updatable' : ''} disabled={!this.state.editMode} key={airport.value} handleCheckInputChanged={this.handleCheckInputChanged}  {...airport} />)
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="liabilityInsuranceAmt">Liability Insurance Amount<span className="star">*</span></label>
                            <select name="liabilityInsuranceAmt" id="liabilityInsuranceAmt"
                                value={profile.liabilityInsuranceAmt}
                                className="updatable" disabled
                                onChange={this.handleChange} >
                                <option value="">Select amount</option>
                                <option value="250K">250K</option>
                                <option value="300K">300K</option>
                                <option value="350K">350K</option>
                                <option value="500K">500K</option>
                                <option value="750K">750K</option>
                                <option value="1Million">1 Million</option>
                                <option value="1.5Million">1.5 Million</option>
                                <option value="2Million">2 Million</option>
                                <option value="5Million">5 Million</option>
                            </select>
                        </div>
                        <div className="col-lg-6">
                            <label htmlFor="cancellationPolicy">Cancellation Policy<span className="star">*</span> <span>
                                <Tooltip title='Select your cancellation policy prior to ride pick up time'>
                                    <InfoIcon fontSize='small' />
                                </Tooltip>
                                </span>
                            </label>
                            <select id="cancellationPolicy" name="cancellationPolicy" value={profile.cancellationPolicy}
                                className="updatable" disabled onChange={this.handleChange} >
                                <option value="1">1 hour (recommended)</option>
                                <option value="2">2 hours</option>
                                <option value="3">3 hours</option>
                                <option value="4">4 hours</option>
                                <option value="5">5 hours</option>
                                <option value="6">6 hours</option>
                                <option value="7">7 hours</option>
                                <option value="8">8 hours</option>
                                <option value="9">9 hours</option>
                                <option value="10">10 hours</option>
                                <option value="11">11 hours</option>
                                <option value="12">12 hours</option>
                                <option value="24">24 hours</option>
                            </select>
                        </div>
                    </div>
                </>
            ) : null;

            const requestorProfile = (profile.accountType === 'Traveler' && profile.requestorCategory) ?
                (
                    <div className="row">
                        <div className="col-lg-6">
                            <label htmlFor="requestorCategory">Category <span className="star">*</span></label>
                            <input type="text" id='requestorCategory' disabled value={profile.requestorCategory} />
                        </div>
                    </div>
                ) : null;

            const ImageContent = [
                {
                    images: profile.profileImageUrl || 'http://via.placeholder.com/500x500',
                    title: 'Profile',
                    category: 'Profile',
                    categoryName: 'Profile',
                    instructions: 'The profile image must be your own clear, well dressed upper body image. ' +
                        '<br/>Company images, selfies and poor quality images are NOT recommended. ' +
                        '<br/>Please do NOT upload your business card or company logo as your profile image.',
                },
                {
                    images: profile.vehicleImageUrl || 'http://via.placeholder.com/500x500',
                    title: 'Vehicle image with plate',
                    category: 'Vehicle',
                    categoryName: 'Vehicle image with plate',
                    instructions: 'The Image of your vehicle with license plate should be facing forward and be clearly visible.'
                },

                {
                    images: profile.licenseImageUrl || 'http://via.placeholder.com/500x500',
                    title: 'Drivers License',
                    category: 'License',
                    categoryName: 'Drivers License',
                    instructions: 'Your driving license image must be scanned and clearly readable'
                },
                {
                    images: profile.insuranceImageUrl || 'http://via.placeholder.com/500x500',
                    title: 'Vehicle proof of insurance',
                    category: 'Insurance',
                    categoryName: 'Vehicle proof of insurance',
                    instructions: 'Proof of insurance image must be scanned and clearly readable. ' +
                        '<br/>Please add the following as additional insured - ' +
                        '<br/><b>FINDANIO LLC, 1915 N CHESTNUT AVE, ARLINGTON HEIGHTS, IL 60004</b>'
                },
                {
                    images: profile.vehicleRegistrationImageUrl || 'http://via.placeholder.com/500x500',
                    title: 'Vehicle Registration',
                    category: 'VehicleRegistration',
                    categoryName: 'Vehicle Registration',
                    instructions: 'Vehicle registration image must be scanned and clearly readable'
                },
            ];

            const ImagesList = profile.accountType === 'Traveler' ? ImageContent.slice(0, 1) : ImageContent.slice(0, 5)

            let docs = ImagesList.map((value, i) => (
                <div className="row mt--10" key={i}>
                    <div className="col-sm-12">
                        <h3 className="title"><span className="underline">{value.categoryName}</span><span className="star">*</span>
                            <Tooltip title="Edit" >
                                <IconButton
                                    className="tooltip-btn"
                                    aria-label="Edit"
                                    onClick={() => { this.editImageBtnClicked(value.category); }}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt--5" >
                        <img width="500" height="500" src={value.images} alt={value.title} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt--5" >
                        <h4 className="error-text underline">Instructions</h4>
                        <p className="font14">{parse(value.instructions)}</p>
                    </div>
                    <div className="col-sm-12 ptb--20">
                        <hr />
                    </div>
                </div>
            ))

            return (
                <>
                    {loader}
                    <Breadcrumb title={'Profile'} parent='Account Settings' parentLink='/account' />
                    <main className="contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                        <div className="container">
                            {
                                profile.accountType === 'Operator' && !profile.approved
                                    ?
                                    <div className="row mb--10">
                                        <div className="col-12">
                                            <div className="notifyText"><span className="star">*</span> Your account is on hold.</div>
                                        </div>
                                    </div>
                                    : <></>
                            }
                            {
                                !profile.approved && profile.reasonForDeny
                                    ?
                                    <div className="row mb--10">
                                        <div className="col-12">
                                            <div className="notifyText"><span className="star">*</span> Reason for account hold: {profile.reasonForDeny}</div>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="row">
                                <div className="col-12">
                                    {successMsg ? <ConfirmationMessage message={successMsg}></ConfirmationMessage> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {errorMsg ? <ErrorMessage errorMessageText={errorMsg}></ErrorMessage> : null}
                                </div>
                            </div>
                            <div className="row ptb--40">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="title underline">Personal details
                                                <span style={{ float: 'right' }}>
                                                    <Tooltip title="Edit Profile">
                                                        <IconButton
                                                            className="tooltip-btn"
                                                            aria-label="edit"
                                                            onClick={this.editPersonalDetailsBtnClicked}>
                                                            <EditIcon />
                                                            Edit
                                                        </IconButton>
                                                    </Tooltip>
                                                </span>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label htmlFor="firstName">First Name<span className="star">*</span></label>
                                            <input type="text" className="updatable" disabled
                                                onChange={this.handleChange}
                                                id='firstName' value={profile.firstName} />
                                        </div>
                                        <div className="col-lg-6">
                                            <label htmlFor="lastName">Last Name<span className="star">*</span></label>
                                            <input type="text" className="updatable" disabled
                                                onChange={this.handleChange}
                                                id='lastName' value={profile.lastName} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label htmlFor="email">Email<span className="star">*</span></label>
                                            <input type="email" disabled id='email' value={profile.email} />
                                        </div>
                                        <div className="col-lg-6">
                                            <PhoneInput
                                                specialLabel="Cell Phone"
                                                id='phoneNumber'
                                                disabled={true}
                                                onlyCountries={['us']}
                                                country={'us'}
                                                disableCountryCode={true}
                                                disableSearchIcon={true}
                                                disableDropdown={true}
                                                placeholder={'(xxx) xxx-xxxx'}
                                                value={profile.phoneNumber}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <PhoneInput
                                                specialLabel="Office Phone"
                                                id='officePhoneNumber'
                                                className="updatable"
                                                disabled={this.state.editMode ? false : true}
                                                onlyCountries={['us']}
                                                country={'us'}
                                                disableCountryCode={true}
                                                disableSearchIcon={true}
                                                disableDropdown={true}
                                                placeholder={'(xxx) xxx-xxxx'}
                                                value={profile.officePhoneNumber}
                                                onChange={this.handleOfficePhoneChange}
                                            />
                                        </div>
                                    </div>
                                    {requestorProfile ? requestorProfile : null}
                                    {operatorProfile ? operatorProfile : null}
                                    <div className="row">
                                        <div className="col-6 mt--20">
                                            <button
                                                style={{
                                                    visibility: this.state.editMode ? 'visible' : 'hidden',
                                                    disabled: this.state.editMode ? false : true,
                                                    marginRight: '20px'
                                                }}
                                                className="rn-button-style--2 btn-solid"
                                                type="button" onClick={this.saveProfileBtnClicked}>Save</button>
                                        </div>
                                        <div className="col-6 mt--20">
                                            <button
                                                style={{
                                                    visibility: this.state.editMode ? 'visible' : 'hidden',
                                                    disabled: this.state.editMode ? false : true
                                                }}
                                                className="rn-button-style--2 rn-btn-dark"
                                                type="button" onClick={this.editPersonalDetailsBtnClicked}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            {docs ? docs : null}

                            <div id="updateProfileImageModal" className="modal">
                                <div className="modal-content">
                                    <div>
                                        <i className="material-icons modal-close float-right">close</i>
                                        <h5 className="center underline">Update profile image</h5>
                                        <br />
                                        <input
                                            id="newProfileImageFile"
                                            type="file"
                                            onChange={this.fileChangedHandler}
                                        />
                                        <label className="error-text" id="newProfileImageFileErr"></label>
                                        <br />
                                        <button className="rn-button-style--2 btn-solid"
                                            onClick={() => { this.updateImageBtnClicked("Profile") }}>Update Image</button>
                                    </div>
                                    <div className="mt--20">
                                        <img src={this.state.newProfileImageFileURL} height={400} width={400} alt='profile'
                                            style={{ visibility: this.state.newProfileImageFileURL ? 'visible' : 'hidden' }} />
                                        <div
                                            onClick={() => this.removeFileHandler('newProfileImageFile')}
                                            style={{ visibility: this.state.newProfileImageFileURL ? 'visible' : 'hidden' }}>
                                            <label>
                                                <span>Remove File</span>
                                                <Delete color="secondary" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="updateLicenseImageModal" className="modal">
                                <div className="modal-content">
                                    <div>
                                        <i className="material-icons modal-close float-right">close</i>
                                        <h5 className="center underline">Update driver's license image</h5>
                                        <br />
                                        <input
                                            id="newLicenseImageFile"
                                            type="file"
                                            onChange={this.fileChangedHandler}
                                        />
                                        <label className="error-text" id="newLicenseImageFileErr"></label>
                                        <br />
                                        <button className="rn-button-style--2 btn-solid"
                                            onClick={() => { this.updateImageBtnClicked("License") }}>Update Image</button>
                                    </div>
                                    <div className="mt--20">
                                        <img src={this.state.newLicenseImageFileURL} height={400} width={400} alt='license'
                                            style={{ visibility: this.state.newLicenseImageFileURL ? 'visible' : 'hidden' }} />
                                        <div
                                            onClick={() => this.removeFileHandler('newLicenseImageFile')}
                                            style={{ visibility: this.state.newLicenseImageFileURL ? 'visible' : 'hidden' }}>
                                            <label>
                                                <span>Remove File</span>
                                                <Delete color="secondary" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="updateInsuranceImageModal" className="modal">
                                <div className="modal-content">
                                    <div>
                                        <i className="material-icons modal-close float-right">close</i>
                                        <h5 className="center underline">Update Vehicle Insurance image</h5>
                                        <br />
                                        <input
                                            id="newInsuranceImageFile"
                                            type="file"
                                            onChange={this.fileChangedHandler}
                                        />
                                        <label className="error-text" id="newInsuranceImageFileErr"></label>
                                        <br />
                                        <button className="rn-button-style--2 btn-solid"
                                            onClick={() => { this.updateImageBtnClicked("Insurance") }}>Update Image</button>
                                    </div>
                                    <div className="mt--20">
                                        <img src={this.state.newInsuranceImageFileURL} height={400} width={400} alt='insurance'
                                            style={{ visibility: this.state.newInsuranceImageFileURL ? 'visible' : 'hidden' }} />
                                        <div
                                            onClick={() => this.removeFileHandler('newInsuranceImageFile')}
                                            style={{ visibility: this.state.newInsuranceImageFileURL ? 'visible' : 'hidden' }}>
                                            <label>
                                                <span>Remove File</span>
                                                <Delete color="secondary" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="updateVehicleImageModal" className="modal">
                                <div className="modal-content">
                                    <div>
                                        <i className="material-icons modal-close float-right">close</i>
                                        <h5 className="center underline"> Update vehicle with plate image</h5>
                                        <br />
                                        <input
                                            id="newVehicleImageFile"
                                            type="file"
                                            onChange={this.fileChangedHandler}
                                        />
                                        <label className="error-text" id="newVehicleImageFileErr"></label>
                                        <br />
                                        <button className="rn-button-style--2 btn-solid"
                                            onClick={() => { this.updateImageBtnClicked("Vehicle") }}>Update Image</button>
                                    </div>
                                    <div className="mt--20">
                                        <img src={this.state.newVehicleImageFileURL} height={400} width={400} alt='vehicle'
                                            style={{ visibility: this.state.newVehicleImageFileURL ? 'visible' : 'hidden' }} />
                                        <div
                                            onClick={() => this.removeFileHandler('newVehicleImageFile')}
                                            style={{ visibility: this.state.newVehicleImageFileURL ? 'visible' : 'hidden' }}>
                                            <label>
                                                <span>Remove File</span>
                                                <Delete color="secondary" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="updateVehicleRegistrationImageModal" className="modal">
                                <div className="modal-content">
                                    <div>
                                        <i className="material-icons modal-close float-right">close</i>
                                        <h5 className="center underline">Update vehicle registration</h5>
                                        <br />
                                        <input
                                            id="newVehicleRegistrationImageFile"
                                            type="file"
                                            onChange={this.fileChangedHandler}
                                        />
                                        <label className="error-text" id="newVehicleRegistrationImageFileErr"></label>
                                        <br />
                                        <button className="rn-button-style--2 btn-solid"
                                            onClick={() => { this.updateImageBtnClicked("VehicleRegistration") }}>Update Image</button>
                                    </div>
                                    <div className="mt--20">
                                        <img src={this.state.newVehicleRegistrationImageFileURL} height={400} width={400} alt='vehicle'
                                            style={{ visibility: this.state.newVehicleRegistrationImageFileURL ? 'visible' : 'hidden' }} />
                                        <div
                                            onClick={() => this.removeFileHandler('newVehicleRegistrationImageFile')}
                                            style={{ visibility: this.state.newVehicleRegistrationImageFileURL ? 'visible' : 'hidden' }}>
                                            <label>
                                                <span>Remove File</span>
                                                <Delete color="secondary" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        errorMsg: state.auth.errorMsg,
        successMsg: state.auth.successMsg,
        refresh: state.auth.refresh,
        carMakeList: state.auth.carMakeList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCarMakeList: (creds) => dispatch(getCarMakeListAction()),
        updateProfile: (profile) => dispatch(updateProfile(profile)),
        updateImage: (obj) => dispatch(updateImage(obj)),
        clearError: () => dispatch(clearErrorAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewProfile)