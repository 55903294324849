import React from 'react'
import Breadcrumb from "../common/Breadcrumb";
import * as ga from '../util/googleAnalytics'

const OperatorQuickGuide = () => {
    ga.pageViewsTracking(window.location.pathname);

    return (
        <>
            <Breadcrumb title={'Operator'} title1={'Operator Quick Guide'} parent='Quick Guide' parentLink='/quickGuide' />

            <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                <div className="row row--35 align-items-start ml--30 ">
                    <div className="col-lg-12 order-2 order-lg-1">
                        <div className="about-inner inner">
                            <div className="section-title">
                                <div className="mt--30 justify-content-space-evenly">
                                    <p><b>Hello Operators</b><br /><br />
                                        <b>Welcome to FINDANIO (Find An Independent Operator).</b><br /><br />
                                        Here are a few tips to help you navigate through our portal.</p>
                                    <p>1. FINDANIO provides alerts of newly posted rides via text and email notifications.</p>
                                    <p>2. Select the rides that best fit your schedule.</p>
                                    <p>3. Please do not accept rides for any other operator than the one registered on the account. If the operator, vehicle, and plate number do not match the information the traveler received, it is the traveler’s right to cancel at the last minute without payment. The incident will be reported and displayed on your profile.</p>
                                    <p>4. If you view a ride that you have the ability to cover, simply accept the recommended FINDANIO rate or submit your own.</p>
                                    <p>5. Your rates may include additional costs such as tolls, airport fees, wait times, redirecting fees, and parking. You are not obligated to accept our recommended rate. If choosing to submit a different rate, be sure to do so when prompted, as travelers will see it when choosing between operators.</p>
                                    <p>6. After submitting the request, await the traveler’s selection of an operator. FINDANIO does not dictate the operator selection, it is all up to the traveler. </p>
                                    <p>7. If selected by a traveler, you will receive an alert that will ask you to confirm whether you are still able to accommodate. If you confirm, you will be able to view each other’s contact information.</p>
                                    <p>8. FINDANIO is not an auction platform for the lowest bidder. Operators cannot lower the recommended rate, they can only increase it. The same applies to the traveler. Travelers are provided the opportunity to increase the rate to attract more operators in high-demand cities or during big events.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default OperatorQuickGuide;
