const ServiceAgreementMD = `
**USER OF INDEPENDENT OPERATOR SERVICES AGREEMENT**

Last updated: June 21, 2021

Findanio LLC (&quot;Findanio&quot;) is a provider of Software As A Service by means of a website and internet based application for independent transportation providers and the users of transportation services to connect and engage for such transportation services (the &quot;Services&quot;). This Client Agreement (&quot;Agreement&quot;) explains Findanio&#39;s obligations to you (&quot;Client&quot;) and your agreements with Findanio.

**Findanio&#39;s Obligations**

1. Findanio has established a proprietary software as a service program accessed via a website and an internet based application whereby a Client can connect with an independent operator of transportation services (an &quot;IO&quot;), communicate with such IO and arrange and make payment for agreed upon transportation services to be provided by the IO (collectively, the &quot;App&quot;). Findanio shall make available the ability of the Client to register as a user of the App and for a connection for the Client to an independent credit card service processor for Client to make payments for services received. By Client&#39;s use of the APP and the provision of Client information on the registration form, Client consents to the services and the use by Findanio of the information provided by Client. A link for Client registration is here: https://findanio.com/signup

2. Client may use the App to request services and thereafter engage, in Client&#39;s sole discretion, an IO for Services. Fees for the Services are agreed to by the Client and the Independent Operator and Findanio has no obligations or liability for establishing, maintaining or monitoring such fees. The App will provide a facility to compare IO fees for the Services, but the fees to be paid by the Client will be those finally agreed to by Client and the IO.

3. Findanio reserves the right to amend and modify the App at any time and from time to time, Continued use by the Client shall indicate Client acceptance of such amendments or modifications. As provided below, Findanio does not provide any guarantee or warranty of the actual transportation services or that any individual IO will continue to be available to Client via the App.

4. Client has the right to terminate its use of the App at any time and upon notice of termination by the Client, Findanio shall delete Client information retained through the Client registration.

**Client&#39;s Obligations**

1. The Client will carefully read this Agreement and the Term of Use for the App which are incorporated into this Agreement by reference as if fully set forth herein. Registration by Client and use of the App constitute Client agreement to the terms herein and the Terms of Use for the App

2. Client warrants that all information provided for registration and to make payments for Services are complete, true and accurate and that the credit card information provided by the Client is for a credit card owned by Client.

3. Client is not charged a fee for the use of the App; provided, however, if Client schedules an IO through the App and then cancels the scheduled services, Client hereby agrees that Findanio will charge the Client&#39;s credit card the amount of $5 for each such cancellation.

**Disclaimers; Limitation of Liability; Indemnity**.

**Disclaimer.**

THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND &quot;AS AVAILABLE.&quot; FINDANIO DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, FINDANIO MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.

FINDANIO DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THE IO. CLIENT AGREES THAT THE ENTIRE RISK ARISING OUT OF USE OF THE SERVICES REMAINS SOLELY THAT OF CLIENT TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.

FINDANIO DOES NOT CONTROL, MANAGE OR DIRECT THE IOS WHO ARE NOT ACTUAL AGENTS, APPARENT AGENTS, OSTENSIBLE AGENTS, OR EMPLOYEES OF FINDANIO.

FINDANIO DOES NOT REPRESENT OR WARRANT THAT THE APP, THE WEBSITE, THE SERVICES OR SERVERS ARE ERROR FREE OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.

**Limitation of Liability**.

FINDANIO SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE SERVICES, REGARDLESS OF THE NEGLIGENCE (EITHER ACTIVE, AFFIRMATIVE, SOLE, OR CONCURRENT) OF FINDANIO, EVEN IF FINDANIO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

FINDANIO SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) CLIENT USE OF OR RELIANCE ON THE APP OR THE SERVICES OR CLIENT INABILITY TO ACCESS OR USE THE APP OR THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY IO, EVEN IF FINDANIO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. FINDANIO SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND FINDANIO&#39;S REASONABLE CONTROL. CLIENT ACKNOWLEDGES THAT FINDANIO DOES NOT CONFIRM OR INDEPENDENTLY VERIFY THAT THE IO PROVIDING TRANSPORTATION SERVICES IS PROFESSIONALLY LICENSED, QUALIFIED OR PERMITTED. CLIENT ACKNOWLEDGES THAT IOS ARE NOT OSTENSIBLE AGENTS, APPARENT AGENTS, ACTUAL AGENTS, OR EMPLOYEES OF FINDANIO.

THE SERVICES AND CONNECTIONS AVAILABLE TO YOU VIA THE APP OR WEBSITE MAY BE USED BY YOU TO REQUEST AND SCHEDULE TRANSPORTATION,SERVICES WITH THE IO, BUT CLIENT AGREES THAT FINDANIO HAS NO RESPONSIBILITY OR LIABILITY TO CLIENT RELATED TO ANY TRANSPORTATION SERVICES PROVIDED TO YOU BY ANY IO OTHER THAN AS EXPRESSLY SET FORTH IN THIS AGREEMENT.

FINDANIO SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF LACK OF OR IMPROPER INSTALLATION OR USE OF CHILD RESTRAINT SYSTEMS FOR GUESTS ON RIDES REQUESTED THROUGH THE SERVICES FOR WHOM A CHILD RESTRAINT SYSTEM IS LEGALLY REQUIRED.

THE LIMITATIONS AND DISCLAIMERS IN THIS SECTION DO NOT PURPORT TO LIMIT LIABILITY OR ALTER CLIENT&#39;S RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS, FINDANIO&#39;S LIABILITY SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW. THIS PROVISION SHALL HAVE NO EFFECT ON FINDANIO&#39;S CHOICE OF LAW PROVISION SET FORTH BELOW.

**Indemnity.**

You agree to indemnify and hold Findanio and its affiliates and their officers, directors, employees, and agents harmless from and against any and all actions, claims, demands, losses, liabilities, costs, damages, and expenses (including attorneys&#39; fees), arising out of or in connection with: (i) your use of the Services obtained through your use of the APP (ii) your breach or violation of any of the terms of this Agreement; (iii) Findanio&#39;s use of the information you have provided to Findanio as part of registration of use of the Services; or (iv) your violation of the rights of any third party, including and IO.

**Limitations**

1. Findanio shall have no liability in the event of:

    a. personal injury or property damage incurred by Client that arises out of or results from the receipt of the IO Services;

    b. Failure of the IO to perform the Services as scheduled or requested;

    c. Any negligent or intentional act or omission by or the willful misconduct of the IO in providing the Services;

    d. Any event or occurrence that was beyond the reasonable control of Findanio, such as, fire, explosions,pandemics, power outages, storms, floods, terrorism, wars, labor disputes, governmental action, imposition of statutes, laws or regulations that materially alter the program for collection, processing, testing and storage of the Specimens;

2. Findanio&#39;s liability to the Client for any claims, loss, harm, damages, expenses, costs (including attorneys&#39; fees) shall be limited to a return of the fees paid by Client for the specific Services finally determined to have created such liability under this Agreement.

3. Findanio shall have no liability for, and Client hereby releases and discharges Findanio from any obligations with respect to, any disclosure of information in accordance with the Privacy Policy that is on an attachment to this Agreement and/or provided on Findanio&#39;s website or as part of the App.

**Miscellaneous**

1. Confidentiality - Findanio acknowledges the confidential nature of the Client&#39;s information provided to Findanio and will use its reasonable best efforts to maintain the confidentiality of such information, except as may be required by applicable law.

2. Termination by Client - This Agreement may be terminated by the Client at any time upon at least 5 business days&#39; prior notice. All fees paid through the date of termination are non-refundable.

3. Termination by Findanio - This Agreement may be terminated by Findanio (a) if Findanio has determined that Client has breached any of its obligations hereunder, including the timely payment of any fees due to Findanio or any IO. (b) at any time after giving Client at least 5 business days prior notice of such termination.

4. Consent to Communications - Client understands and agrees that Findanio and Client communications may occur in several different ways and means of communication. Client agrees that Findanio may communicate with Client by means of any of email, text messaging, telephone or written communication. Client has the right to opt out of such communications by using that means of communication you wish to stop at any time: (i) an email to Findanio at info@findanio.com; (ii) a text &quot;STOP&quot; in response to a Findanio text; (iii) a call to Findanio customer services at 800-664-5630 or (iv) by letter to Findanio Client Services at 1915 N Chestnut Ave, Arlington Heights, Illinois 60004.

5. Notices - Any notice either required or permitted under this Agreement may be given (a) personally to the other party, (b) by electronic mail to the address by the party hereunder; (c) by private delivery services (e.g., Federal Express or United Parcel Service) with a return receipt requested, or (d) by United States registered or certified mail return receipt requested. Notice shall be sent to the other party at their last known address.

6. Dispute Resolution - In the event of any dispute between the Client and Findanio with respect to this Agreement or related in any way to the App or the Services, such dispute will be submitted to binding arbitration under the rules of the American Arbitration Association (the &quot;AAA Rules&quot;). The arbitration will be held in Chicago, Illinois before a single arbitrator. The fees of the arbitrator will be shared equally by the parties. Other than such arbitrator fees, the prevailing party in such arbitration, as determined by the arbitrator will be entitled to recover its costs and expenses of the arbitration. The arbitrator will issue a written opinion of his/her decision and such decision will be binding, final and enforceable in any court of competent jurisdiction.

7. Governing Law - This Agreement and the relationship between the Client and Findanio shall be governed by and interpreted in accordance with the laws of the State of Illinois without regard to its choice of laws rules.

8. Assignment – Client may not assign or transfer this Agreement to any third party. Findanio may assign its rights and obligations under this Agreement to any successor in interest Except for the foregoing circumstances, neither the Client nor Findanio shall assign its rights and obligations under this Agreement without the prior written consent of the other party. All of the terms and conditions shall be binding on any successor or assign of a party.

9. Waiver - No waiver by any party with respect to any breach or default or of any right or remedy, and no course of dealing, shall be deemed to constitute a continuing waiver of any other breach or default or of any other right or remedy, unless such waiver is expressed in writing signed by the party to be bound by such waiver. Failure of a party to exercise any right, under this Agreement or at law or in equity, shall not be deemed a waiver of such right or rights in the future.

10. Severability - If any of these terms and conditions are held to be invalid or unenforceable, such invalidity or un-enforceability will not affect the other terms and conditions, which will remain in full force and effect.

11. Entire Agreement - This Agreement, the website Terms of Use and the Privacy Policy represent the entire agreement between the Client and Findanio and supersede any oral or written prior agreements, representations or understandings.
`;

export default ServiceAgreementMD;