import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'
import * as ga from './components/util/googleAnalytics'

class ProtectedRoute extends Component {


  componentDidMount = () => {
    ga.pageViewsTracking(this.props.path);
    if (this.props.auth && this.props.auth.uid) {
      ga.updateGoogleAnalyticsUser(this.props.auth.uid);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.path !== prevProps.path) {
      ga.pageViewsTracking(this.props.path);
    }
  }

  render() {
    const { auth } = this.props;
    if (auth.uid) {
      return (
        <Route exact path={this.props.path} component={this.props.component} {...this.props} />
      )
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { redirectAfterLoginTo: this.props.location.pathname }
          }}
        />
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default withRouter(connect(mapStateToProps)(ProtectedRoute));