import React, { Component, Fragment } from "react";
import ModalVideo from 'react-modal-video';
import CallAction from "../common/CallAction";
import { IoAirplaneOutline, IoLocationOutline, IoHourglassOutline } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import { FiCheck } from "react-icons/fi";
import { Redirect } from 'react-router-dom';
import Loader1 from '../loader/Loader1';
import { connect } from 'react-redux'
import { BsListUl } from 'react-icons/bs';
import Slider from "react-slick";
import { slideSlick } from "./script";
import LocationsLanding from "../locations/LocationsLanding";
import * as ga from '../util/googleAnalytics'

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'FINDANIO',
        description: 'Find An Independent Operator',
        description1: 'Intelligent Platform for Intelligent Travelers',
        buttonText: 'Instant Quote',
        buttonLink: '/instant-quote'
    }
]

const namesItemOne = [
    'Single-car independent operators',
    'Small, mid-size, and large transportation companies looking for work',
    'Affiliates',
    'Service providers',
];

const namesItemTwo = [
    'Hotels & airlines',
    'Corporations & corporate travelers',
    'Event planners & travel agents',
    'Admin & executive assistants',
    'Transportation companies looking to get rides covered',
];

const namesItemThree = [
    'Book rides in seconds',
    'Get instant quotes',
    'Choose your preferred chauffeur',
    'Easy to use booking platform',
];

const vehicleList = [
    {
        image: 'sedan',
        title: 'Sedan',
        path: '/img/sedan.png',
        category: 'Cadillac XTS or similar'
    },
    {
        image: 'luxurySedan',
        title: 'Luxury Sedan',
        path: 'img/luxurysedan.png',
        category: 'BMW 7 series, S-Class Mercedes or similar'
    },
    {
        image: 'suv',
        title: 'SUV',
        path: '/img/SUV.png',
        category: 'GMC Yukon, Cadillac Escalade or similar'
    },
    {
        image: 'passenger van',
        title: 'Passenger Van',
        path: 'img/sprinter.png',
        category: 'Mercedes-Benz Sprinter or similar'
    },
    {
        image: 'corporate sprinter',
        title: 'Corporate Sprinter',
        path: 'img/sprinter.png',
        category: 'Mercedes-Benz Sprinter or similar'
    },
    {
        image: 'limo sprinter',
        title: 'Limo Sprinter',
        path: 'img/sprinter.png',
        category: 'Mercedes-Benz Sprinter or similar'
    },
    {
        image: '24 passenger bus',
        title: '24 Passenger Bus',
        path: 'img/34passengerbus.png',
        category: ''
    },
    {
        image: '34 passenger bus',
        title: '34 Passenger Bus',
        path: 'img/34passengerbus.png',
        category: ''
    },
    {
        image: '55 Passenger Coach Bus',
        title: '55 Passenger Coach Bus',
        path: 'img/bus.png',
        category: ''
    },
    {
        image: 'stretchLimo',
        title: 'Stretch Limo',
        path: 'img/stretchlimo.png',
        category: ''
    },
    {
        image: 'stretchSUV',
        title: 'Stretch SUV',
        path: 'img/stretchsuv.png',
        category: 'Stretch Hummer, Stretch Escalade or similar'
    }
]

const ServiceListTwo = [
    {
        icon: <IoLocationOutline />,
        title: 'Point-to-Point',
        description: 'Need an ASAP service or a future planned transfer from point A to point B, find a vehicle and choose your operator within minutes.',
    },
    {
        icon: <IoHourglassOutline />,
        title: 'Hourly Service',
        description: 'Need the vehicle for a few hours? Make use of the hourly service as needed.',
    },
    {
        icon: <IoAirplaneOutline />,
        title: 'Airport Service',
        description: 'Airport Arrivals & Departures: Communicate with your chauffeur to set a meeting place (curbside pick-up or baggage claim).',
    },
    {
        icon: <BsListUl />,
        title: 'Multiple Bookings',
        description: 'Book as many rides as needed and choose your preferred operators.',
    },

]


class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen1: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false
        }
        this.openModal1 = this.openModal1.bind(this)
        this.openModal2 = this.openModal2.bind(this)
        this.openModal3 = this.openModal3.bind(this)
        this.openModal4 = this.openModal4.bind(this)
        this.linkScrollToTop = this.linkScrollToTop.bind(this)
    }

    linkScrollToTop() {
        window.scrollTo(0, 0);
    }

    openModal1() {
        this.setState({ isOpen1: true })
    }

    openModal2() {
        this.setState({ isOpen2: true })
    }

    openModal3() {
        this.setState({ isOpen3: true })
    }

    openModal4() {
        this.setState({ isOpen4: true })
    }

    componentDidMount = () => {
        ga.pageViewsTracking(window.location.pathname);
    } 

    render() {

        const { auth, profile } = this.props;
        if (auth.uid) {
            if (profile && !profile.isEmpty) {
                if (profile.accountType === 'Traveler') {
                    return <Redirect to='/home' />
                } else {
                    return <Redirect to='/home' />
                }
            } else {
                return (
                    <div className="container center">
                        <div>Loading</div>
                        <Loader1 />
                    </div>
                )
            }
        }

        return (
            <Fragment>
                {/* <div className="slider-activation slider-creative-agency" id="home">
                    <div className="bg_image bg_image--34" data-black-overlay="6">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}<sup className="theme-gradient">&reg;</sup></h1> : ''}
                                                {value.description ? <p className="description bold">{value.description}</p> : ''}
                                                {value.description1 ? <p className="description">{value.description1}<sup>&reg;</sup></p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><NavLink className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</NavLink></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}


                <div className="slider-wrapper">
                    <div className="slider-activation slider-creative-agency">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                // Start Single Slider 
                                <div className={"slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--34"} key={index} data-black-overlay="6">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title theme-gradient">{value.title}<sup className="theme-gradient">&reg;</sup></h1> : ''}
                                                    {value.description ? <p className="description bold">{value.description}</p> : ''}
                                                    {value.description1 ? <p className="description">{value.description1}<sup>&reg;</sup></p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><NavLink className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</NavLink></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                // End Single Slider 
                            ))}
                        </Slider>
                    </div>
                </div>

                <div className="rn-service-details ptb--10">
                    <div className="service-details-inner bg_color--1">
                        <div className="inner container">
                            <div className="row sercice-details-content pb--50 align-items-center">
                                <div className="col-lg-6 col-12 order-lg-1 order-1">
                                    <div className="details">
                                        <h2 >What is FINDANIO?</h2>
                                        <p>FINDANIO is a subscription based online platform for independent operators, providing best-in-class transportation service.</p>
                                        <p> Need a ride? Just Submit a request and select your preferred chauffeur from a list of available operators based on your preferences.<br /><br />
                                            <b>Think of us as your chauffeur matchmaker!</b></p><br />
                                        <br />
                                    </div>
                                </div>

                                <div className="col-lg-6 order-1 order-lg-2 mt--10">
                                    <div className="thumbnail position-relative">
                                        <img className="w-100" src="/assets/images/about/aboutlogo.png" alt="About Images" />
                                        <ModalVideo channel='custom' isOpen={this.state.isOpen1} url="/video/vid1.mp4" onClose={() => this.setState({ isOpen1: false })} />
                                        <button className="video-popup position-top-center theme-color" onClick={this.openModal1}><span className="play-icon"></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-details-inner ptb--30 bg_color--5">
                        <div className="inner container">
                            <div className="row sercice-details-content pb--80 align-items-center">
                                <div className="col-lg-6 col-12 order-lg-1 order-sm-2 order-2">
                                    <div className="thumb position-relative">
                                        <img className="w-100" src="/img/travelerCollage.jpg" alt="Travelers" />
                                        <ModalVideo channel='custom' isOpen={this.state.isOpen2} url="/video/vid5.mp4" onClose={() => this.setState({ isOpen2: false })} />
                                        <button className="video-popup theme-color" onClick={this.openModal2}><span className="play-icon"></span></button>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 order-lg-2 order-sm-1 order-1">
                                    <div className="details">
                                        <h4 className="title">Travelers</h4>
                                        <p>Get an instant quote, choose your operator, and save on corporate travel.</p>
                                    </div>
                                    <div className="mt--30 mb--10">
                                        <ul className="list-style--1">
                                            {namesItemTwo.map((name, index) => {
                                                return <li key={index}><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                        <NavLink className="mt--20 rn-button-style--2 btn-solid" onClick={this.linkScrollToTop} to="/signup/traveler"><span>Join now</span></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="service-details-inner ptb--30 bg_color--1">
                        <div className="inner container">
                            <div className="row sercice-details-content pb--80 align-items-center">
                                <div className="col-lg-6 col-12 order-lg-1 order-sm-2 order-1">
                                    <div className="details">
                                        <h4 className="title">Operators</h4>
                                        <p className="strong">Book rides, build your clientele, and grow your business.</p>
                                    </div>
                                    <div className="mt--30 mb--10">
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={index}><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                        <NavLink className="mt--20 rn-button-style--2 btn-solid" onClick={this.linkScrollToTop} to="/signup/operator"><span>Join now</span></NavLink>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 order-2 order-lg-2 order-2">
                                    <div className="thumb position-relative">
                                        <img className="w-100" src="/img/operatorCollage.jpg" alt="Operators" />
                                        <ModalVideo channel='custom' isOpen={this.state.isOpen3} url="/video/vid3.mp4" onClose={() => this.setState({ isOpen3: false })} />
                                        <button className="video-popup theme-color" onClick={this.openModal3}><span className="play-icon"></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-details-inner ptb--30 bg_color--5">
                        <div className="inner container">
                            <div className="row sercice-details-content align-items-center">
                                <div className="col-lg-6 col-12 order-2 order-lg-1">
                                    <div className="thumb position-relative">
                                        <img className="w-100" src="/img/transportCompany.jpg" alt="Limo Company" />
                                        <ModalVideo channel='custom' isOpen={this.state.isOpen4} url="/video/vid4.mp4" onClose={() => this.setState({ isOpen4: false })} />
                                        <button className="video-popup theme-color" onClick={this.openModal4}><span className="play-icon"></span></button>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 order-1 order-lg-2">
                                    <div className="details">
                                        <h4 className="title">Transportation Companies</h4>
                                        <p>Manage and dispatch rides easily.</p>
                                    </div>
                                    <div className="mt--30 mb--10">
                                        <ul className="list-style--1">
                                            {namesItemThree.map((name, index) => {
                                                return <li key={index}><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                        <NavLink className="mt--20 rn-button-style--2 btn-solid" onClick={this.linkScrollToTop} to="/signup/operator"><span>Join now</span></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                                    <h2 className="title text-center">Services</h2>
                                </div>
                            </div>
                            <div className="col-lg-12 col-12 mt_md--50">
                                <div className="row service-one-wrapper">
                                    {ServiceListTwo.map((val, i) => (
                                        <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                                            <div className="service service__style--2">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p><br /> <br />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="portfolio-area ptb--20 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3">
                                        <h2 className="title">Vehicles Available</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {vehicleList.map((value, i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="blog blog-style--3">
                                            <div className="thumbnail">
                                                <div>
                                                    <img className="w-100" src={`${value.path}`} alt={value.images} />
                                                </div>
                                            </div>
                                            <div className="content mb--50">
                                                {/* <h4 className="title"><span>{value.title} {value.price}</span></h4>
                                                <p>{value.category}</p> */}
                                                <h5 className="title mt--20 ml--10 text-center"><span><b>{value.title}</b><br /><p className="microText">{value.category}<u> <br /><b className=" microText1 black-text">{value.price}</b></u></p></span></h5>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>


                <LocationsLanding />

                <CallAction />


            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Landing)
