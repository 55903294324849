import React from 'react'
import RequestedRidesData from './RequestedRidesData'
import Breadcrumb from "../../common/Breadcrumb";

export default function RequestedRides() {
  return (
    <>
      <Breadcrumb title={'Requested Rides'} />
      <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-12">
              <div className="section-title text-left mb--50">
                <p className="description">View your requested ride details</p>
              </div>
              <RequestedRidesData />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}