import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { forgotPassword, clearErrorAction } from '../../store/actions/authActions'
import Loader1 from '../loader/Loader1'
import Breadcrumb from "../common/Breadcrumb";
import ErrorMessage from "../errorMessage/ErrorMessage"
import ConfirmationMessage from "../confirmationMessage/ConfirmationMessage"
import * as ga from '../util/googleAnalytics'

class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    this.props.forgotPassword(this.state)
      .then(() => {
        this.setState({
          email: '',
          loading: false
        });
      });
  }

  componentDidMount() {
    this.props.clearError();
    ga.pageViewsTracking(window.location.pathname);
    if (this.props.sessionEmail) {
      this.setState({
        email: this.props.sessionEmail
      })
    }
  }

  render() {
    const { auth, authError, authSuccess, profile } = this.props;
    let loader = null;
    if (this.state.loading === true) {
      loader = <Loader1 />
    }
    if (auth.uid) {
      if (profile && !profile.isEmpty) {
        if (profile.accountType === 'Traveler') {
          return <Redirect to='/home' />
        } else {
          return <Redirect to='/home' />
        }
      } else {
        return (
          <div className="container center">
            <div>Loading</div>
            <Loader1 />
          </div>
        )
      }
    }
    return (
      <>
        {loader}
        <Breadcrumb title={'Forgot Password'} />
        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--20 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-12">
                <div className="mb--40">
                  {authError ? <ErrorMessage errorMessageText={authError}></ErrorMessage> : null}
                  {authSuccess ? <ConfirmationMessage message={authSuccess} /> : null}
                </div>
                <div className="section-title center">
                  <h4>Locked out? Can't access your account?</h4>
                  <p className="description">Enter the email address associated with your FINDANIO account.</p>
                </div>
                <div className="mt--30 mb--30">
                  <form onSubmit={this.handleSubmit}>
                    <label htmlFor="email"><b>Email address</b><span className="star">*</span></label>
                    <input type="email" id='email' placeholder="yours@example.com" value={this.state.email} onChange={this.handleChange} />
                    <div className="text-center">
                      <button className="rn-button-style--2 btn-solid">Submit</button>
                    </div>
                    <br />
                    <br />

                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    authSuccess: state.auth.authSuccess,
    profile: state.firebase.profile,
    sessionEmail: state.auth.sessionEmail
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (creds) => dispatch(forgotPassword(creds)),
    clearError: () => dispatch(clearErrorAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
