import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getTravelerPendingApprovalRides } from '../../../store/actions/projectActions'
import Loader1 from '../../loader/Loader1'
import moment from 'moment';
import RideTableData from '../RideTableData4'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import { createDataForTraveler } from '../../util/rideUtils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as ga from '../../util/googleAnalytics'

class PendingApprovalRidesData extends Component {

  state = {
    fetchedRequestedProjectDetails: false
  }

  componentDidMount() {
    const { auth } = this.props;
    ga.pageViewsTracking(window.location.pathname);
    if (!this.state.fetchedRequestedProjectDetails && auth && auth.uid) {
      this.props.getPendingApprovalRides(auth.uid);
      this.setState({
        fetchedRequestedProjectDetails: true
      })
    }
  }

  componentDidUpdate() {
    const { auth } = this.props;
    if (!this.state.fetchedRequestedProjectDetails && auth && auth.uid) {
      this.props.getPendingApprovalRides(auth.uid);
      this.setState({
        fetchedRequestedProjectDetails: true
      })
    }
  }

  render() {
    const { projects, auth, errorMsg } = this.props;
    if (!auth.uid) return <Redirect to='/login' />

    // let totalCancelledRides = 0;
    // if (profile && profile.cancelledRides) {
    //   totalCancelledRides = profile.cancelledRides.length
    // }

    if (this.state.fetchedRequestedProjectDetails && projects && !errorMsg) {
      if (projects.length > 0) {
        let finalData = [];
        projects && projects.forEach(project => {
          let viewDetailsBtn = (
            <Link to={'/ride/requested/' + project.id}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
          )
          let actionLink = '/ride/requested/' + project.id;
          let passengerName = project.passengerFirstName + ' ' + project.passengerLastName;
          finalData.push(
            createDataForTraveler(
              project.id,
              // moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY, YYYY"),
              // moment(project.serviceDate.toDate()).format("LT"),
              project.serviceDateStr || moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY hh:mm A"),
              `${project.serviceFrom} ${project.serviceFromName ? `(${project.serviceFromName})` : ''}`,
              `${project.serviceTo} ${project.serviceToName ? `(${project.serviceToName})` : ''}`,
              project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : 'N/A',
              project.status ? project.status.toUpperCase() : null,
              viewDetailsBtn,
              project.serviceVehicle,
              project.serviceType,
              project.calculatedDistance.toFixed(2) + ' miles',
              project.serviceType === 'HourlyCharter' ? `${project.serviceHours} hours` : project.calculatedRideDuration,
              project.serviceDate.toDate(),
              actionLink,
              project.rideConfirmationCode,
              project.confirmedOperatorName,
              project.confirmedOperatorPhoneNumber,
              passengerName,
              project.confirmedOperatorProfileImageUrl,
              project.specialNotes,
              project.confirmedOperatorRate
            )
          )
        })
        return (
          <React.Fragment>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <RideTableData sortAsc={true} rows={finalData} />
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      } else {
        return (
          <Card variant="outlined">
            <CardContent>
              <p className="center error-text">No rides found</p>
            </CardContent>
          </Card>
        )
      }
    } else if (errorMsg) {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">{errorMsg}</p>
          </CardContent>
        </Card>
      )
    } else {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">Loading</p>
            <Loader1 />
          </CardContent>
        </Card>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    projects: state.project.travelerPendingApprovalRides,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPendingApprovalRides: (uid) => dispatch(getTravelerPendingApprovalRides(uid))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PendingApprovalRidesData)
