import React, { Component } from 'react'
import RidePrices from './RidePrices';
import Loader1 from '../loader/Loader1';
import MapWithADirectionsRenderer from "../maps/MapWithADirectionsRenderer";

const activeBtn = 'rn-button-style--2 btn-solid';
const inactiveBtn = 'rn-button-style--2 btn-black-color';
const google = window.google;
var options = {
  componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] }
};
class RideQuote extends Component {

  constructor(props) {
    super(props)
    this.state = this.initialState()
    this.handlePlaceSelectFrom = this.handlePlaceSelectFrom.bind(this)
    this.handlePlaceSelectTo = this.handlePlaceSelectTo.bind(this)
    this.handleCalculateDistance = this.handleCalculateDistance.bind(this)
    this.handleUpdateRates = this.handleUpdateRates.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.autocompleteServiceFrom = null
    this.autocompleteServiceTo = null
  }

  initialState() {
    return {
      serviceType: 'Point-to-Point',
      serviceFrom: '',
      serviceTo: '',
      fromlat: "",
      fromlng: "",
      tolat: "",
      tolng: "",
      calculatedDistance: -1,
      calculatedSedanPrice: 0.00,
      calculatedSUVPrice: 0.00,
      calculatedLuxurySedanPrice: 0.00,
      calculatedPassengerVanPrice: 0.0,
      calculatedCorporateSprinterPrice: 0.00,
      calculatedLimoSprinterPrice: 0.0,
      calculated24PassengerBusPrice: 0.0,
      calculated34PassengerBusPrice: 0.0,
      calculatedPassenger55CoachBusPrice: 0.0,
      calculatedStretchLimoPrice: 0.0,
      calculatedStretchSUVPrice: 0.0,
      loading: false,
      validServiceTo: false,
      validServiceFrom: false,
      rateCalculated: false,
      error: false,
      serviceFromState: ''
    }
  }

  componentDidMount() {
    this.autocompleteServiceFrom = new google.maps.places.Autocomplete(document.getElementById('serviceFrom'), options)
    this.autocompleteServiceFrom.addListener("place_changed", this.handlePlaceSelectFrom);
    this.autocompleteServiceTo = new google.maps.places.Autocomplete(document.getElementById('serviceTo'), options)
    this.autocompleteServiceTo.addListener("place_changed", this.handlePlaceSelectTo);
  }

  handleChange = (e) => {
    if (e.target.id === 'serviceFrom') {
      this.setState({
        serviceFrom: e.target.value,
        fromlat: '',
        fromlng: '',
        calculatedDistance: -1,
        calculatedSedanPrice: 0.00,
        calculatedSUVPrice: 0.00,
        calculatedLuxurySedanPrice: 0.00,
        calculatedPassengerVanPrice: 0.0,
        calculatedCorporateSprinterPrice: 0.00,
        calculatedLimoSprinterPrice: 0.0,
        calculated24PassengerBusPrice: 0.0,
        calculated34PassengerBusPrice: 0.0,
        calculated55PassengerCoachBusPrice: 0.0,
        calculatedStretchLimoPrice: 0.0,
        calculatedStretchSUVPrice: 0.0,
        validServiceFrom: false,
        rateCalculated: false,
        error: false,
        serviceFromState: ''
      })
    }

    if (e.target.id === 'serviceTo') {
      this.setState({
        serviceTo: e.target.value,
        tolat: '',
        tolng: '',
        calculatedDistance: -1,
        calculatedSedanPrice: 0.00,
        calculatedSUVPrice: 0.00,
        calculatedLuxurySedanPrice: 0.00,
        calculatedPassengerVanPrice: 0.0,
        calculatedCorporateSprinterPrice: 0.00,
        calculatedLimoSprinterPrice: 0.0,
        calculated24PassengerBusPrice: 0.0,
        calculated34PassengerBusPrice: 0.0,
        calculated55PassengerCoachBusPrice: 0.0,
        calculatedStretchLimoPrice: 0.0,
        calculatedStretchSUVPrice: 0.0,
        validServiceTo: false,
        rateCalculated: false,
        error: false,
      })
    }
  }

  handleCalculateDistance(distance, duration) {
    this.setState({
      calculatedDistance: distance,
      calculatedRideDuration: duration
    })
  }

  handleUpdateRates(obj) {
    this.state.serviceType === 'Point-to-Point'
      ? this.setState({
        calculatedSedanPrice: obj.Sedan,
        calculatedLuxurySedanPrice: obj.LuxurySedan,
        calculatedSUVPrice: obj.SUV,
        calculatedPassengerVanPrice: obj.PassengerVan,
        calculatedCorporateSprinterPrice: obj.CorporateSprinter,
        calculatedLimoSprinterPrice: obj.LimoSprinter,
        calculated24PassengerBusPrice: obj.Bus24Passenger,
        calculated34PassengerBusPrice: obj.Bus34Passenger,
        calculated55PassengerCoachBusPrice: obj.CoachBus,
        calculatedStretchLimoPrice: obj.StretchLimo,
        calculatedStretchSUVPrice: obj.StretchSUV,
        loading: false,
        rateCalculated: obj.rateCalculated,
        error: obj.error
      })
      : this.setState({
        loading: false,
        rateCalculated: obj.rateCalculated,
        error: obj.error
      })
  }

  handleServiceTypeChanged(newServiceType) {
    this.setState({
      serviceType: newServiceType
    })
  }

  handlePlaceSelectFrom() {
    let addressObject = this.autocompleteServiceFrom.getPlace()
    let serviceFromState = '';
    if (addressObject && addressObject.address_components && addressObject.geometry) {
      addressObject.address_components.forEach(component => {
        component.types.forEach(type => {
          if (type === 'administrative_area_level_1') {
            serviceFromState = component.short_name;
          }
        })
      });
      // console.log('serviceFromState = ', serviceFromState);
      let currentLoading = this.state.loading;
      this.setState({
        serviceFromState: serviceFromState,
        serviceFrom: addressObject.name,
        validServiceFrom: true,
        fromlat: addressObject.geometry.location.lat(),
        fromlng: addressObject.geometry.location.lng(),
        loading: this.state.serviceTo !== '' && this.state.validServiceTo === true ? true : currentLoading
      })
    }
  }

  handlePlaceSelectTo() {
    let addressObject = this.autocompleteServiceTo.getPlace();
    if (addressObject && addressObject.geometry) {
      let currentLoading = this.state.loading;
      this.setState({
        serviceTo: addressObject.name,
        validServiceTo: true,
        tolat: addressObject.geometry.location.lat(),
        tolng: addressObject.geometry.location.lng(),
        loading: this.state.serviceFrom !== '' && this.state.validServiceFrom === true ? true : currentLoading
      })
    }
  }

  render() {
    let loader = null;
    if (this.state.loading === true) {
      loader = <Loader1 />
    }
    let pt2ptRates = [
      {
        images: 'sedan.png',
        title: 'Sedan',
        category: 'Cadillac XTS or similar',
        price: 'at $' + this.state.calculatedSedanPrice + '*'
      },
      {
        images: 'luxurysedan.png',
        title: 'Luxury Sedan',
        category: 'BMW 7 series, S-Class Mercedes or similar',
        price: 'at $' + this.state.calculatedLuxurySedanPrice + '*'
      },
      {
        images: 'SUV.png',
        title: 'SUV',
        category: 'GMC Yukon, Cadillac Escalade or similar',
        price: 'at $' + this.state.calculatedSUVPrice + '*'
      },
      {
        images: 'sprinter.png',
        title: 'Passenger Van',
        category: 'Mercedes-Benz Sprinter or similar',
        price: 'at $' + this.state.calculatedPassengerVanPrice + '*'
      },
      {
        images: 'sprinter.png',
        title: 'Corporate Sprinter',
        category: 'Mercedes-Benz Sprinter or similar',
        price: 'at $' + this.state.calculatedCorporateSprinterPrice + '*'
      },
      {
        images: 'sprinter.png',
        title: 'Limo Sprinter',
        category: 'Mercedes-Benz Sprinter or similar',
        price: 'at $' + this.state.calculatedLimoSprinterPrice + '*'
      },
      {
        images: '34passengerbus.png',
        title: '24 Passenger Bus',
        category: '',
        price: 'at $' + this.state.calculated24PassengerBusPrice + '*'
      },
      {
        images: '34passengerbus.png',
        title: '34 Passenger Bus',
        category: '',
        price: 'at $' + this.state.calculated34PassengerBusPrice + '*'
      },
      {
        images: 'bus.png',
        title: '55 Passenger CoachBus',
        category: '',
        price: 'at $' + this.state.calculated55PassengerCoachBusPrice + '*'
      },
      {
        images: 'stretchlimo.png',
        title: 'Stretch Limo',
        category: '',
        price: 'at $' + this.state.calculatedStretchLimoPrice + '*'
      },
      {
        images: 'stretchsuv.png',
        title: 'Stretch SUV',
        category: 'Hummer or similar',
        price: 'at $' + this.state.calculatedStretchSUVPrice + '*'
      },
    ];

    let hourlyRates = [
      {
        images: 'sedan.png',
        title: 'Sedan',
        category: 'Cadillac XTS or similar',
        price: '$70*/hour (Min. 2 hrs)'
      },
      {
        images: 'luxurysedan.png',
        title: 'Luxury Sedan',
        category: 'BMW 7 series, S-Class Mercedes or similar',
        price: '$95*/hour (Min. 2 hrs)'
      },
      {
        images: 'SUV.png',
        title: 'SUV',
        category: 'GMC Yukon, Cadillac Escalade or similar',
        price: 'at $85*/hour (Min. 2 hrs)'
      },
      {
        images: 'sprinter.png',
        title: 'Passenger Van',
        category: 'Mercedes-Benz Sprinter or similar',
        price: 'at $95*/hour (Min. 3 hrs)'
      },
      {
        images: 'sprinter.png',
        title: 'Corporate Sprinter',
        category: 'Mercedes-Benz Sprinter or similar',
        price: 'at $105*/hour (Min. 3 hrs)'
      },
      {
        images: 'sprinter.png',
        title: 'Limo Sprinter',
        category: 'Mercedes-Benz Sprinter or similar',
        price: 'at $125*/hour (Min. 3 hrs)'
      },
      {
        images: '34passengerbus.png',
        title: '24 Passenger Bus',
        category: '',
        price: 'at $110*/hour (Min. 3 hrs)'
      },
      {
        images: '34passengerbus.png',
        title: '34 Passenger Bus',
        category: '',
        price: 'at $125*/hour (Min. 4 hrs)'
      },
      {
        images: 'bus.png',
        title: '55 Passenger Coach Bus',
        category: '',
        price: 'at $165*/hour (Min. 5 hrs)'
      },
      {
        images: 'stretchlimo.png',
        title: 'Stretch Limo',
        category: '',
        price: 'at $95*/hour (Min. 3 hrs)'
      },
      {
        images: 'stretchsuv.png',
        title: 'Stretch SUV',
        category: 'Hummer or similar',
        price: 'at $145*/hour (Min. 3 hrs)'
      },
    ]


    const PostList = this.state.serviceType === 'Point-to-Point' ? pt2ptRates : hourlyRates;

    return (
      <React.Fragment>
        {loader}

        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6 col-6 mb--20">
                <button className={this.state.serviceType === 'Point-to-Point' ? activeBtn : inactiveBtn} onClick={(() => this.handleServiceTypeChanged('Point-to-Point'))}>Point-to-Point</button>
              </div>
              <div className="col-lg-6 col-6 mb--20">
                <button className={this.state.serviceType === 'HourlyCharter' ? activeBtn : inactiveBtn} onClick={(() => this.handleServiceTypeChanged('HourlyCharter'))}>Hourly Charter</button>
              </div>
            </div>
            <div className="row mt--20" hidden={this.state.serviceType === 'HourlyCharter'}>
              <div className="col-md-12 col-sm-12 ">
                <label htmlFor="serviceFrom">Enter Pick-Up Location<span className="star">*</span></label>
                <input id="serviceFrom"
                  ref="input"
                  onChange={this.handleChange}
                  placeholder=""
                  type="text" />
              </div>
            </div>
            <div className="row" hidden={this.state.serviceType === 'HourlyCharter'}>
              <div className="col-md-12 col-sm-12 ">
                <label htmlFor="serviceTo">Enter Drop-Off Location<span className="star">*</span></label>
                <input id="serviceTo"
                  placeholder=""
                  ref="input"
                  onChange={this.handleChange}
                  type="text" />
              </div>
            </div>
            <br />
            {this.state.loading &&
              <div className="grey-text">Please wait while we calculate the instant quote rate.</div>
            }
            {this.state.error &&
              <div>
                There was an error while processing the instant quote. Please try again.
              </div>
            }
          </div>
        </div>

        <div className="row row--35 align-items-start">
          <div className="col-lg-12">
            <RidePrices
              serviceFromState={this.state.serviceFromState}
              calculatedDistance={this.state.calculatedDistance}
              serviceType={this.state.serviceType}
              handleUpdateRates={this.handleUpdateRates}
            />
            <br />
            {this.state.rateCalculated && !this.state.error &&
              <React.Fragment>
                <h5 className="ml--30">Recommended rates by FINDANIO* </h5><br />
                <div className="row bg_color--5 ml--10">
                  {PostList.map((value, i) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                      <div className="blog blog-style--2">
                        <div className="thumbnail">
                          <div>
                            <img className="w-100" src={`/img/${value.images}`} alt={value.images} />
                          </div>
                        </div>
                        <div className="content mb--50">
                          <h5 className="title mt--20 ml--10 text-center"><span><b>{value.title}</b><br /><p className="microText">{value.category}<u> <br /><b className=" microText1 black-text">{value.price}</b></u></p></span></h5>

                          {/* <p className="blogtype">{value.category}</p>
                          <h4 className="title"><span>{value.title} {value.price}</span></h4> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <p className="microText ml--20">(*Rates are subject to change based on your city of travel and your selected operator)<br />
                  (*Additional fees may apply, including, but not limited to, airport fees, tolls, parking, wait times, additional stops, etc.)</p>
                <br />
              </React.Fragment>
            }
          </div>
        </div>

        <div className="row" hidden={this.state.serviceType === 'HourlyCharter'}>
          <div className="col-lg-12" hidden={!this.state.rateCalculated || this.state.error || !(this.state.validServiceTo && this.state.validServiceFrom)}>
            <MapWithADirectionsRenderer
              hidden={!this.state.rateCalculated || this.state.error || !(this.state.validServiceTo && this.state.validServiceFrom)} fromlat={this.state.fromlat}
              fromlng={this.state.fromlng}
              tolat={this.state.tolat}
              tolng={this.state.tolng}
              handleCalculateDistance={this.handleCalculateDistance}
              suppressMarkers={false}
              showCircle={false}
            />
            <br />
            <p><b>Ride Distance: </b>{this.state.calculatedDistance.toFixed(2) + ' miles'}</p>
            <p><b>Duration:</b> {this.state.calculatedRideDuration}</p>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default RideQuote
