import React from 'react'
import ReactMarkdown from 'react-markdown'
import ServiceOverviewMD from './ServiceOverviewMD';

class ServiceOverview extends React.Component {

    render() {
        return (
            <ReactMarkdown skipHtml={false}  children={ServiceOverviewMD}></ReactMarkdown>
        )
    }

}

export default ServiceOverview
