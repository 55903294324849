import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Loader1 from '../loader/Loader1'
import queryString from 'query-string'
import { handleStripeConnectAccount } from '../../store/actions/authActions'
import Breadcrumb from "../common/Breadcrumb";

class OAuthConnect extends Component {

    state = {
        redirect: false
    }

    componentDidMount() {
        const { auth, stripeConnectState, code } = this.props;
        let obj = {
            uid: auth.uid,
            code: code,
            stripeConnectState: stripeConnectState
        }
        this.props.handleStripeConnectAccount(obj);
    }

    setRedirect = () => {
        this.setState({
            redirect: true
        })
    }

    render() {
        const { auth, profile, stripeConnectState, error, errorMsg } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        if (!profile.isEmpty && profile.accountType !== 'Operator') {
            return <Redirect to='/404' />
        }

        if (profile.stripeConnectedAccount) {
            return <Redirect to='/cashout' />
        }

        if (this.state.redirect) {
            return <Redirect to='/cashout' />
        } else if (error || errorMsg || !stripeConnectState) {
            return (
                <>
                    <Breadcrumb title={'Cashout Connect'} parent='Cashout' parentLink='/cashout' />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                        <div className="container">
                            <div className="row align-items-start">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="section-title text-center mb--50">
                                        <p className="description error-text">There was an error processing the request.</p>
                                        <button onClick={this.setRedirect} className="rn-button-style--2 btn-solid">Try again</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else {
            return (
                <>
                    <Breadcrumb title={'Cashout Connect'} parent='Cashout' parentLink='/cashout' />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                        <div className="container">
                            <div className="row align-items-start">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="section-title text-center mb--50">
                                        <p className="description">Processing. Please wait.</p>
                                        <Loader1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const values = queryString.parse(ownProps.location.search)
    let stripeConnectState = values.state;
    let code = values.code;
    let error = values.error;
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        stripeConnectState: stripeConnectState,
        code: code,
        error: error,
        errorMsg: state.auth.errorMsg
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleStripeConnectAccount: (obj) => dispatch(handleStripeConnectAccount(obj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OAuthConnect)
