import React, { Component } from 'react'
import { connect } from 'react-redux'
import USAMap from "react-usa-map";
import './locations.css';
import { getLocationStates } from '../../store/actions/projectActions'
import Loader1 from '../loader/Loader1'
class LocationsLanding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount() {
        this.props.getLocationStates();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ width: window.innerWidth });
    }

    render() {
        let { locations } = this.props;
        if (!locations) {
            return (
                <Loader1 />
            )
        } else {
            let mapWidth = this.state.width < 450
                ? 300 : this.state.width < 550
                    ? 400
                    : this.state.width < 650
                        ? 500
                        : this.state.width < 800
                            ? 700
                            : this.state.width < 1000
                                ? 750
                                : 1000;
            return (
                <>
                    <div className="portfolio-area ptb--40 bg_color--1">
                        <div className="portfolio-sacousel-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center service-style--3 mb--30">
                                            <h2 className="title">Locations</h2>
                                            <p className="description">FINDANIO service is currently available in the highlighted states.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <USAMap
                                            title="FINDANIO locations"
                                            customize={locations}
                                            width={mapWidth}
                                            height={null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        locations: state.project.locations
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLocationStates: (creds) => dispatch(getLocationStates(creds))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsLanding)
