import React from 'react'
import Breadcrumb from "../common/Breadcrumb";
import { FiCheck } from "react-icons/fi";

const Pricing = () => {
    return (
        <>
            <Breadcrumb title={'Pricing'} />
            <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h2 className="title">Subscription Pricing Plan</h2>
                                    <p className="description justify">Independent Operators can subscribe to the pricing plan and start fulfilling rides.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 mt--20">
                            <div className="rn-pricing mt--20">
                                <div className="pricing-table-inner">
                                    <div className="pricing-header">
                                        {/* <h4 className="title">Premium</h4> */}
                                        <div className="pricing">
                                            <span className="price">$49</span>
                                            <span className="subtitle">Per Month</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="pricing-body">
                                                <ul className="list-style--1">
                                                    <li><FiCheck />Accept and fulfill unlimited rides</li>
                                                    <li><FiCheck />Be visible to travelers</li>
                                                    <li><FiCheck />Provide your own rate for rides and extra/additional stops</li>
                                                    <li><FiCheck />Become a preferred operator</li>
                                                    <li><FiCheck />View your rides history</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="pricing-body">
                                                <ul className="list-style--1">
                                                    <li><FiCheck />Instant quotes</li>
                                                    <li><FiCheck />On-demand and future bookings</li>
                                                    {/* <li><FiCheck />Submit unlimited ride requests without paying ride booking fee</li> */}
                                                    <li><FiCheck />Provide your preferred ride rate</li>
                                                    {/* <li><FiCheck />Select an operator of choice for your ride</li> */}
                                                    <li><FiCheck />Easily manage your passengers for frequent booking</li>
                                                    <li><FiCheck />Modify or cancel subscription anytime</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Pricing