import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {
  getTravelerFilteredRides, clearErrorAction, getTravelerRidesToday, getConfirmedOperatorList
} from '../../../store/actions/projectActions'
import Loader1 from '../../loader/Loader1'
import moment from 'moment';
import RideTableData from '../RideTableData4'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import { createDataForTraveler } from '../../util/rideUtils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as ga from '../../util/googleAnalytics'
import DatePicker from "react-datepicker";
import { IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

class TravelerRideSearch extends Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.onFromDateChange = this.onFromDateChange.bind(this);
    this.onToDateChange = this.onToDateChange.bind(this);
    this.filterResults = this.filterResults.bind(this);
    this.clearFilterResults = this.clearFilterResults.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.confirmedOperatorListRef = React.createRef();
  }

  initialState() {
    return {
      todaysRidesFetched: false,
      fromDate: new Date(new Date().setHours(0, 0, 0, 0)),
      toDate: new Date(new Date().setHours(0, 0, 0, 0)),
      filterActive: false,
      loading: false,
      selectedOperatorId: null
    }
  }

  onFromDateChange(value) {
    if (value) {
      let fromDate = new Date(value.setHours(0, 0, 0, 0));
      let toDate = this.state.toDate;
      if (moment(this.state.toDate) < moment(fromDate)) {
        toDate = fromDate;
      }
      this.setState({
        fromDate,
        toDate
      })
    }
  }

  onToDateChange(value) {
    if (value) {
      let toDate = new Date(value.setHours(0, 0, 0, 0));
      this.setState({
        toDate,
      })
    }
  }

  filterResults = () => {
    if (this.state.rideFilterText !== '') {
      this.setState({
        loading: true
      }, (() => {
        this.props.getTravelerFilteredRides({
          uid: this.props.auth.uid,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
        })
          .then(() => {
            this.setState({
              loading: false,
              filterActive: true,
            });
          })
      }));
    }
  }

  handleChange = (event) => {
    console.log('changed')
    const { id, name, value } = event.target
    if (name === 'operatorList') {
      this.setState({
        selectedOperatorId: value,
      })
    }
  }

  clearFilterResults = () => {
    this.setState({
      loading: true
    }, (() => {
      this.props.getTravelerFilteredRides({
        uid: this.props.auth.uid,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      })
        .then(() => {
          this.setState({
            loading: false,
            fromDate: new Date(new Date().setHours(0, 0, 0, 0)),
            toDate: new Date(new Date().setHours(0, 0, 0, 0)),
            filterActive: false,
          });
        })
    })
    );
  }

  componentDidMount() {
    const { auth } = this.props;
    ga.pageViewsTracking(window.location.pathname + "-calendar");
    this.props.clearError();
    if (!this.state.todaysRidesFetched && auth.uid) {
      this.props.getTravelerRidesToday({
        uid: this.props.auth.uid,
      });
      // this.props.getConfirmedOperatorList({
      //   uid: this.props.auth.uid,
      // })
      this.setState({
        todaysRidesFetched: true
      })
    }
  }

  componentDidUpdate() {
    const { travelerRidesToday, auth } = this.props;
    if (!this.state.todaysRidesFetched && auth.uid && !travelerRidesToday) {
      this.props.getTravelerRidesToday({
        uid: this.props.auth.uid,
      });
      this.setState({
        todaysRidesFetched: true
      })
    }

    // let confirmedOperatorLisDropdown = this.confirmedOperatorListRef.current;
    // this.props.confirmedOperatorList.forEach((value) => {
    //   confirmedOperatorLisDropdown.add(value);
    // });
  }

  render() {
    const { travelerFilteredProjects, travelerRidesToday, auth, errorMsg } = this.props;
    if (!auth.uid) return <Redirect to='/login' />
    var filterDiv = (
      <div className="mb--20">
        <h3>Please select a time duration to filter rides</h3>
        <div className="row mb--20">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <label htmlFor="fromDate">From Date<span className="star">*</span></label>
            <br />
            <DatePicker
              id='fromDate'
              placeholderText="Click to select from date"
              todayButton="Today"
              dateFormat="MMMM d, yyyy"
              showPopperArrow={false}
              selected={this.state.fromDate}
              maxDate={new Date(new Date().setFullYear((new Date().getFullYear() + 1)))}
              minDate={new Date(new Date().setFullYear((new Date().getFullYear() - 1)))}
              strictParsing
              peekNextMonth={false}
              onChange={this.onFromDateChange}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <label htmlFor="toDate">To Date<span className="star">*</span></label>
            <br />
            <DatePicker
              id='toDate'
              placeholderText="Click to select to date"
              todayButton="Today"
              dateFormat="MMMM d, yyyy"
              showPopperArrow={true}
              selected={this.state.toDate}
              maxDate={new Date(new Date().setFullYear((new Date().getFullYear() + 1)))}
              minDate={new Date(this.state.fromDate)}
              strictParsing
              peekNextMonth={false}
              onChange={this.onToDateChange}
            />
          </div>
        </div>


        {/* <h3>Select an operator</h3>

        <div className="row mb--20">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <select name="operatorList" id="operatorList"
              ref={this.confirmedOperatorListRef}
              value={this.state.selectedOperatorId}
              onChange={this.handleChange}
            >
              <option value="all">All Operators</option>
            </select>
          </div>
        </div> */}

        <div className="row">
          <div className="col-12">
            <Tooltip title="Filter" >
              <IconButton
                className="rn-button-style--2 btn-primary-color-blue"
                aria-label="Filter rides"
                onClick={this.filterResults}>
                <SearchIcon />
              </IconButton>
            </Tooltip> &nbsp;&nbsp;&nbsp;
            <Tooltip title="Clear" >
              <IconButton
                className="rn-button-style--2 btn-black-color"
                aria-label="Clear ride filter"
                onClick={this.clearFilterResults}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div >
    )

    const noRidesFoundDiv = (
      <Card variant="outlined">
        <CardContent>
          <p className="center error-text">No rides scheduled for selected duration</p>
        </CardContent>
      </Card>
    );

    if (errorMsg) {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">{errorMsg}</p>
          </CardContent>
        </Card>
      )
    } else if (this.state.filterActive === true && travelerFilteredProjects) {
      if (travelerFilteredProjects.length > 0) {
        let filteredRides = [];
        travelerFilteredProjects.forEach(project => {
          let viewDetailsBtn = (
            <Link to={'/ride/requested/' + project.id}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
          );
          let actionLink = '/ride/requested/' + project.id;
          let passengerName = project.passengerFirstName + ' ' + project.passengerLastName;
          let t = createDataForTraveler(
            project.id,
            project.serviceDateStr || moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY hh:mm A"),
            `${project.serviceFrom} ${project.serviceFromName ? `(${project.serviceFromName})` : ''}`,
            `${project.serviceTo} ${project.serviceToName ? `(${project.serviceToName})` : ''}`,
            project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : null,
            project.status ? project.status.toUpperCase() : null,
            viewDetailsBtn,
            project.serviceVehicle,
            project.serviceType,
            project.calculatedDistance.toFixed(2) + ' miles',
            project.serviceType === 'HourlyCharter' ? `${project.serviceHours} hours` : project.calculatedRideDuration,
            project.serviceDate.toDate(),
            actionLink,
            project.rideConfirmationCode,
            project.confirmedOperatorName || null,
            project.confirmedOperatorPhoneNumber || null,
            passengerName,
            project.confirmedOperatorProfileImageUrl || null,
            project.specialNotes,
            project.confirmedOperatorRate
          );
          filteredRides.push(t);
        })
        return (
          <React.Fragment>
            <div className="container pb--200">
              {filterDiv}
              <h3>Filtered rides</h3>
              {
                filteredRides.length > 0
                  ? <RideTableData sortAsc={true} rows={filteredRides} />
                  : noRidesFoundDiv
              }
            </div>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <div className="container pb--200">
              {filterDiv}
              <h3>Filtered rides</h3>
              {noRidesFoundDiv}
            </div>
          </React.Fragment>
        )
      }
    } else if (this.state.todaysRidesFetched) {
      if (travelerRidesToday.length > 0) {
        let filteredRides = [];

        travelerRidesToday.forEach(project => {
          let viewDetailsBtn = (
            <Link to={'/ride/requested/' + project.id}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
          );
          let actionLink = '/ride/requested/' + project.id;
          let passengerName = project.passengerFirstName + ' ' + project.passengerLastName;
          filteredRides.push(
            createDataForTraveler(
              project.id,
              project.serviceDateStr || moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY hh:mm A"),
              `${project.serviceFrom} ${project.serviceFromName ? `(${project.serviceFromName})` : ''}`,
              `${project.serviceTo} ${project.serviceToName ? `(${project.serviceToName})` : ''}`,
              project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : null,
              project.status ? project.status.toUpperCase() : null,
              viewDetailsBtn,
              project.serviceVehicle,
              project.serviceType,
              project.calculatedDistance.toFixed(2) + ' miles',
              project.serviceType === 'HourlyCharter' ? `${project.serviceHours} hours` : project.calculatedRideDuration,
              project.serviceDate.toDate(),
              actionLink,
              project.rideConfirmationCode,
              project.confirmedOperatorName,
              project.confirmedOperatorPhoneNumber,
              passengerName,
              project.confirmedOperatorProfileImageUrl,
              project.specialNotes,
              project.confirmedOperatorRate
            )
          )
        })
        return (
          <React.Fragment>
            <div className="container pb--200">
              {filterDiv}
              <h3>Today's rides</h3>
              {
                filteredRides.length > 0
                  ? <RideTableData sortAsc={true} rows={filteredRides} />
                  : noRidesFoundDiv
              }
            </div>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <div className="container pb--200">
              {filterDiv}
              {noRidesFoundDiv}
            </div>
          </React.Fragment>
        )
      }
    } else {
      return (
        <div className="container pb--200 mt--20 mb--20">
          <Card variant="outlined">
            <CardContent>
              <p className="center error-text">Loading</p>
              <Loader1 />
            </CardContent>
          </Card>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    travelerFilteredProjects: state.project.travelerFilteredProjects || [],
    travelerRidesToday: state.project.travelerRidesToday || [],
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    errorMsg: state.project.errorMsg,
    // confirmedOperatorList: state.project.confirmedOperatorList || []
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTravelerFilteredRides: (data) => dispatch(getTravelerFilteredRides(data)),
    getTravelerRidesToday: (data) => dispatch(getTravelerRidesToday(data)),
    // getConfirmedOperatorList: (data) => dispatch(getConfirmedOperatorList(data)),
    clearError: () => dispatch(clearErrorAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelerRideSearch)
