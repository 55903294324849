const initState = {
  textMsgSentSuccess: '',
  textMsgSentError: '',
  emailSentSuccess: '',
  emailSentError: ''
}

const adminReducer = (state = initState, action) => {
  switch (action.type) {

    case 'CLEAR_TEXT_MSG':
      return {
        ...state,
        textMsgSentSuccess: '',
        textMsgSentError: ''
      }


    case 'TEXT_MSG_SENT': {
      return {
        ...state,
        textMsgSentSuccess: action.textMsgSentSuccess,
        textMsgSentError: action.textMsgSentError
      }
    }

    case 'CLEAR_EMAIL_MSG':
      return {
        ...state,
        emailSentSuccess: '',
        emailSentError: ''
      }

    case 'EMAIL_MSG_SENT': {
      return {
        ...state,
        emailSentSuccess: action.emailSentSuccess,
        emailSentError: action.emailSentError
      }
    }

    default:
      return state
  }
};

export default adminReducer;