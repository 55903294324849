import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Loader1 from '../loader/Loader1'
import OperatorRides from './operator/OperatorRides'
import TravelerRides from './traveler/TravelerRides'
import Breadcrumb from "../common/Breadcrumb";

class Rides extends Component {

    render() {
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/login' />
        if (!profile.isEmpty && profile.accountType !== 'Traveler') {
            return <OperatorRides />
        } else if (!profile.isEmpty && profile.accountType !== 'Operator') {
            return <TravelerRides />
        } else {
            return (
                <>
                    <Breadcrumb title='Rides' />
                    <main className="contact-form--1 main_container mt--80 mb--80 bg_color--1">
                        <div className="section-title text-left mb--50">
                            <p className="error-text center">Loading</p>
                            <Loader1 />
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(Rides)
