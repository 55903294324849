import moment from 'moment';
import axios from 'axios';
import { baseURL, StorageBaseURL, altMedia } from '../../components/constants/CommonConstants';
import { generateRideId7 } from '../../components/util/rideUtils';

export const clearErrorAction = () => {
	// console.log('clearErrorAction')
	return (dispatch, getState, { getFirebase }) => {
		dispatch({ type: 'CLEAR_ERROR' });
	};
};
function isValid(element) {
	// console.log('validating - ' + element)
	return element === null || element.toString().trim() !== '';
}

async function updateHistory(firestore, projectId, userId, action) {
	return firestore.collection('projects').doc(projectId).collection('history').add({
		timestamp: new Date(),
		user: userId,
		action: action
	});
}

function addChatMessage(firestore, projectId, userId, userName, msg) {
	firestore.collection('projects').doc(projectId).collection('chats').add({
		createdAt: new Date(),
		userId: userId,
		userName: userName,
		msg: msg
	});
}

async function createTask(firestore, data) {
	return firestore
		.collection('tasks')
		.add({
			status: data.status,
			performAt: data.performAt,
			worker: data.worker,
			options: data.options,
			createdAt: new Date()
		})
		.then(async (docRef) => {
			// console.log("TaskID: ", docRef.id);
			await firestore.collection('projects').doc(data.options.projectId).collection('tasks').add({
				taskId: docRef.id,
				type: 'normal',
				status: data.status,
				performAt: data.performAt,
				worker: data.worker,
				options: data.options,
				createdAt: new Date()
			});
			return docRef.id;
		})
		.catch(function(error) {
			// console.error("Error creating task: ", error);
			return null;
		});
}

function createUrgentTask(firestore, options) {
	// console.log('inside createUrgentTask - ', options);
	return firestore
		.collection('urgentTasks')
		.add({
			status: options.status,
			worker: options.worker,
			options: {
				projectId: options.projectId,
				amount: options.amount || 0,
				operatorId: options.operatorId || null
			},
			createdAt: new Date()
		})
		.then(async (docRef) => {
			// console.log("urgentTaskId: ", docRef.id);
			await firestore.collection('projects').doc(options.projectId).collection('tasks').add({
				taskId: docRef.id,
				type: 'urgent',
				performAt: new Date(),
				status: options.status,
				worker: options.worker,
				options: {
					projectId: options.projectId,
					amount: options.amount || 0
				},
				createdAt: new Date()
			});
			return docRef.id;
		})
		.catch(function(error) {
			// console.error("Error creating URGENT task: ", error);
			return null;
		});
}

async function getLatestRideDetailsForOperator(firestore, projectId, operatorId) {
	var ride = null;
	var rideDetails = await firestore.collection('projects').doc(projectId).get();
	let isBlackListedOperator = false;
	if (rideDetails !== undefined && rideDetails.exists) {
		ride = rideDetails.data();
		if (ride.authorId == null) {
			ride = 'BadData';
		} else {
			await firestore.collection('users').doc(ride.authorId).get().then((doc) => {
				var travelerDetails = doc.data();
				ride.travellerRequestedRideCount =
					(travelerDetails.requestedRides && travelerDetails.requestedRides.length) || 0;
				ride.travellerCancelledRideCount =
					(travelerDetails.cancelledRides && travelerDetails.cancelledRides.length) || 0;
				if (travelerDetails.blackListedOperators && travelerDetails.blackListedOperators.includes(operatorId)) {
					isBlackListedOperator = true;
				}
			});

			if (!isBlackListedOperator) {
				// load operatorData collection items
				await firestore
					.collection('projects')
					.doc(projectId)
					.collection('operatorData')
					.get()
					.then((snapshot) => {
						var operatorDataObj = {};
						snapshot.forEach((doc) => {
							operatorDataObj[doc.id] = doc.data();
						});

						if (Object.keys(operatorDataObj).length > 0) {
							ride.operatorData = operatorDataObj;
						}
					});

				//load project chats
				await firestore
					.collection('projects')
					.doc(projectId)
					.collection('chats')
					.orderBy('createdAt', 'desc')
					.get()
					.then((snapshot) => {
						var chats = {};
						snapshot.forEach((doc) => {
							chats[doc.id] = doc.data();
						});
						ride.chats = chats;
					});
			}
		}
	} else {
		ride = 'BadData';
	}

	if (isBlackListedOperator) {
		ride = 'BadData';
	}
	return ride;
}

export const clearRideDetails = () => {
	return async (dispatch, getState, { getFirestore }) => {
		dispatch({ type: 'CLEAR_RIDE_DETAILS' });
	};
};

async function getLatestRideDetailsForTraveler(firestore, projectId) {
	var ride = {};
	var rideDetails = await firestore.collection('projects').doc(projectId).get();
	if (rideDetails !== undefined && rideDetails.exists) {
		ride = rideDetails.data();
		if (ride.authorId === null) {
			ride = 'BadData';
			return ride;
		} else {
			var operatorProfile = {};
			const operatorsLoaded =
				(ride.acceptedBy &&
					ride.acceptedBy.map(async (operatorId) => {
						const userProfile = await firestore.collection('users').doc(operatorId).get();
						operatorProfile[operatorId] = userProfile.data();
					})) ||
				[];
			return Promise.all(operatorsLoaded).then(async (res) => {
				if (Object.keys(operatorProfile).length > 0) {
					ride.operatorProfile = operatorProfile;
				}
				//load operator accepted data
				await firestore
					.collection('projects')
					.doc(projectId)
					.collection('operatorData')
					.get()
					.then(async (snapshot) => {
						var operatorDataObj = {};
						snapshot.forEach((doc) => {
							operatorDataObj[doc.id] = doc.data();
						});
						if (Object.keys(operatorDataObj).length > 0) {
							ride.operatorData = operatorDataObj;
						}
					});

				//load project history
				await firestore
					.collection('projects')
					.doc(projectId)
					.collection('history')
					.orderBy('timestamp', 'desc')
					.get()
					.then((snapshot) => {
						var history = {};
						snapshot.forEach((doc) => {
							history[doc.id] = doc.data();
						});
						if (Object.keys(history).length > 0) {
							ride.history = history;
						}
					});

				//get review
				if (ride.reviewId) {
					await firestore.collection('reviews').doc(ride.reviewId).get().then((doc) => {
						if (doc.exists) {
							ride.reviewDetails = doc.data();
						}
					});
				}

				//load project chats
				await firestore
					.collection('projects')
					.doc(projectId)
					.collection('chats')
					.orderBy('createdAt', 'desc')
					.get()
					.then((snapshot) => {
						var chats = {};
						snapshot.forEach((doc) => {
							chats[doc.id] = doc.data();
						});
						ride.chats = chats;
					});

				// console.log('getLatestRideDetailsForTraveler -- ride -- ', ride)
				return ride;
			});
		}
	}
}

export const createProject = (project) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - create project', project)
		const firestore = getFirestore();
		const profile = getState().firebase.profile;
		const authorId = getState().firebase.auth.uid;
		const coeff = 1000 * 60 * 5;
		const minDateTime = new Date(Math.round((new Date().getTime() + 15 * 60000) / coeff) * coeff);
		if (
			!isValid(project.serviceFrom) ||
			!isValid(project.serviceTo) ||
			!isValid(project.serviceType) ||
			!isValid(project.serviceDate) ||
			!isValid(project.passengerFirstName) ||
			!isValid(project.passengerLastName) ||
			!isValid(project.passengerPhone) ||
			!isValid(project.serviceVehicle) ||
			!isValid(project.fromlat) ||
			!isValid(project.fromlng) ||
			!isValid(project.tolat) ||
			!isValid(project.tolng) ||
			!isValid(project.calculatedDistance) ||
			!isValid(project.serviceFromState) ||
			!isValid(project.serviceToState)
		) {
			// invalid ride details
			dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please enter all the required details and try again' });
			return;
		} else if (new Date(project.serviceDate) < minDateTime) {
			//validate service date time should be at-least 15 mins from now
			dispatch({
				type: 'CREATE_PROJECT_ERROR',
				message: `Service date-time should be at least by ${moment(minDateTime).format(
					'dddd, MMMM Do, YYYY'
				)} ${moment(minDateTime).format('LT')}`
			});
			return;
		} else if (project.validServiceFrom === false) {
			// validate pick up location
			dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please enter valid Pick-Up location' });
			return;
		} else if (project.validServiceTo === false) {
			//validate drop off location
			dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please enter valid Drop-Off location' });
			return;
		} else if (
			project.serviceType === 'Point-to-Point' &&
			project.tolat === project.fromlat &&
			project.tolng === project.fromlng
		) {
			// validate pick and drop off location should not be same for point-to-point service type
			dispatch({
				type: 'CREATE_PROJECT_ERROR',
				message: 'Pick-Up and Drop-Off location cannot be the same for Point-to-Point ride.'
			});
			return;
		} else if (project.passengerPhone.length !== 10) {
			// validate passengerPhone is 10 digits
			dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please enter a valid passenger phone number' });
			return;
		} else if (!isValid(project.selectedPaymentMethod)) {
			// validate payment method is selected
			dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'Please select a payment method' });
			return;
		} else {
			// preauthorize $5.00 ride booking fee
			let url = baseURL + '/newRideBookingPreauthorization';
			let data = {
				travelerId: authorId,
				paymentMethod: project.selectedPaymentMethod
			};
			// console.log('payload of extraStopPaymentIntent API - ', data);
			let paymentIntentId = null;
			let paymentIntentPayload = null;
			let rideBookingPreauthorizationSkipped = false;
			await axios
				.post(url, data)
				.then(async (response) => {
					paymentIntentId = response.data.id;
					paymentIntentPayload = response.data.paymentIntentPayload;
					rideBookingPreauthorizationSkipped = response.data.rideBookingPreauthorizationSkipped;
					// console.log('done with preauthorization - paymentIntentId: ', paymentIntentId);

					let rideConfirmationCode = null;
					let uniqueRideConfirmationCode = false;
					while (uniqueRideConfirmationCode === false) {
						rideConfirmationCode = generateRideId7();
						// console.log('rideConfirmationCode = ', rideConfirmationCode);
						// check if rideId is unique
						await firestore
							.collection('projects')
							.where('rideConfirmationCode', '==', rideConfirmationCode)
							.get()
							// eslint-disable-next-line no-loop-func
							.then((snapshot) => {
								if (snapshot.empty) {
									uniqueRideConfirmationCode = true;
								}
								return;
							})
							.catch((error) => {
								// console.log('Error validating rideConfirmationCode :', rideConfirmationCode, error);
								return;
							});
					}
					//step 1 - create a new ride
					return await firestore
						.collection('projects')
						.add({
							rideConfirmationCode: rideConfirmationCode,
							serviceFrom: project.serviceFrom,
							serviceFromShort: project.serviceFromShort,
							serviceFromName: project.serviceFromName,
							serviceTo: project.serviceTo,
							serviceToShort: project.serviceToShort,
							serviceToName: project.serviceToName,
							// serviceDate: project.serviceDate,
							serviceDate: project.serviceDateActual,
							serviceDateStr: project.serviceDateStr,
							serviceType: project.serviceType,
							serviceHours: project.serviceHours,
							serviceVehicle: project.serviceVehicle,
							specialNotes: project.specialNotes,
							fromlat: project.fromlat,
							fromlng: project.fromlng,
							tolat: project.tolat,
							tolng: project.tolng,
							calculatedDistance: project.calculatedDistance,
							calculatedPrice: project.requestorPrice || project.calculatedPrice[project.serviceVehicle],
							serviceFromState: project.serviceFromState,
							serviceToState: project.serviceToState,
							authorFirstName: profile.firstName,
							authorLastName: profile.lastName,
							authorId: authorId,
							status: 'PENDING',
							createdAt: new Date(),
							availableOperators: [],
							acceptedBy: [],
							paymentMethod: project.selectedPaymentMethod,
							passengerFirstName: project.passengerFirstName,
							passengerLastName: project.passengerLastName,
							passengerPhone: project.passengerPhone,
							requestorPriceUpdated: project.requestorPrice !== project.calculatedPrice[project.serviceVehicle] ? true : false,
							recommendedPrice: project.calculatedPrice[project.serviceVehicle],
							calculatedRideDuration: project.calculatedRideDuration,
							source: 'WEB'
						})
						.then(async (res) => {
							let id = res.id;
							// console.log('projectId - ', id);

							//step 2 - update project history
							let action = profile.firstName + ' requested a ride';
							await updateHistory(firestore, id, authorId, action);

							// create a task for auto cancellation charges
							let performAt = new Date(project.serviceDate);
							var taskOptions = {
								status: 'PENDING',
								performAt: performAt,
								worker: 'autoCancellation',
								options: {
									projectId: id
								}
							};
							// console.log('taskOptions - ', taskOptions);
							await createTask(firestore, taskOptions);

							// create task for reminders
							let reminderAt = new Date(project.serviceDate);
							reminderAt.setHours(reminderAt.getHours() - 2);
							if (reminderAt.getTime() < new Date().getTime()) {
								var urgentTaskOptions = {
									status: 'PENDING',
									worker: 'upcomingRideReminderNotificationToTraveler',
									projectId: id
								};
								await createUrgentTask(firestore, urgentTaskOptions);
							} else {
								var taskOptions2 = {
									status: 'PENDING',
									performAt: reminderAt,
									worker: 'upcomingRideReminderNotificationToTraveler',
									options: {
										projectId: id
									}
								};
								await createTask(firestore, taskOptions2);
							}

							// create a task for auto complete ride after 24 hours
							let performEndRideTaskAt = new Date(project.serviceDate);
							performEndRideTaskAt.setHours(performEndRideTaskAt.getHours() + 24);
							var performEndRideTaskOptions = {
								status: 'PENDING',
								performAt: performEndRideTaskAt,
								worker: 'autoCompleteRide',
								options: {
									projectId: id
								}
							};
							// console.log('performEndRideTaskOptions - ', performEndRideTaskOptions);
							await createTask(firestore, performEndRideTaskOptions);

							// create firestore payment intent document for type rideBookingPreauthorization
							let paymentIntentAmount = 5; // $5.00
							// console.log('paymentIntentId = ', paymentIntentId);
							let paymentIntentType =
								rideBookingPreauthorizationSkipped === true
									? 'rideBookingPreauthorizationSkipped'
									: 'rideBookingPreauthorization';
							let paymentIntentStatus =
								rideBookingPreauthorizationSkipped === true ? 'SKIPPED' : 'CONFIRMED';
							await firestore
								.collection('projects')
								.doc(id)
								.collection('paymentIntents')
								.doc(paymentIntentId)
								.set({
									status: paymentIntentStatus,
									type: paymentIntentType,
									amount: paymentIntentAmount,
									createdAt: new Date(),
									paymentIntentPayload: paymentIntentPayload
								});

							//step 3 - update user profile
							return firestore
								.collection('users')
								.doc(authorId)
								.update({
									requestedRides: firestore.FieldValue.arrayUnion(id)
								})
								.then((res2) => {
									// console.log('created successfully');
									dispatch({ type: 'CREATE_PROJECT_SUCCESS', id });
									return;
								})
								.catch((err2) => {
									// console.log('error while updating user requestedRides -', err2);
									dispatch({
										type: 'CREATE_PROJECT_ERROR',
										message: 'An error occurred. Please try again.'
									});
									return;
								});
						})
						.catch((err) => {
							// console.log('error occurred while creating project - ', err)
							dispatch({ type: 'CREATE_PROJECT_ERROR', message: 'An error occurred. Please try again.' });
							return;
						});
				})
				.catch((err) => {
					// console.log(' paymentIntent failed', err);
					dispatch({
						type: 'CREATE_PROJECT_ERROR',
						message: 'Selected payment method is invalid. Please use another payment method.'
					});
					return;
				});
		}
	};
};

export const denyRideAction = (project) => {
	// this is for rides which are not accepted by operator
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - denyRideAction', project)
		const firestore = getFirestore();
		const operatorId = project.deniedByOperatorId;
		const projectId = project.projectId;
		const projectDetails = await firestore.collection('projects').doc(projectId).get();

		if (projectDetails.data().acceptedBy && projectDetails.data().acceptedBy.includes(operatorId)) {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg: 'You cannot deny this ride because you had already accepted this ride previously.',
					updatedProject: ride
				});
			}
			return;
		} else if (projectDetails.data().deniedBy && projectDetails.data().deniedBy.includes(operatorId)) {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg: 'You had already denied this ride previously.',
					updatedProject: ride
				});
			}
			return;
		} else {
			//step 1 - update rides - denied by
			return firestore
				.collection('projects')
				.doc(projectId)
				.update({
					deniedBy: firestore.FieldValue.arrayUnion(operatorId)
				})
				.then((res) => {
					//step 2 - update operator profile - denied rides
					return firestore
						.collection('users')
						.doc(operatorId)
						.update({
							deniedRides: firestore.FieldValue.arrayUnion(projectId)
						})
						.then(async (res) => {
							//get latest ride details
							let ride = await getLatestRideDetailsForOperator(firestore, projectId);
							if (ride === 'BadData') {
								dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
							} else {
								dispatch({ type: 'SUCCESSFULLY_DENIED_RIDE', updatedProject: ride });
							}
							return;
						})
						.catch((err) => {
							dispatch({ type: 'ERROR_DENYING_RIDE' }, err);
							return;
						});
				})
				.catch((err) => {
					dispatch({ type: 'ERROR_DENYING_RIDE' }, err);
					return;
				});
		}
	};
};

export const acceptRideAction = (project) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('Inside acceptRideAction - ', project);
		const firestore = getFirestore();
		const operatorId = project.acceptedByOperatorId;
		const projectId = project.projectId;

		var projectDetails = await firestore.collection('projects').doc(projectId).get();
		var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
		var operatorDetails = await firestore.collection('users').doc(operatorId).get();

		if (projectDetails.data().acceptedBy && projectDetails.data().acceptedBy.includes(operatorId)) {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg: 'You have already accepted this ride.',
					updatedProject: ride
				});
			}
			return;
		} else if (projectDetails.data().deniedBy && projectDetails.data().deniedBy.includes(operatorId)) {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg: 'You cannot accept this ride now because you have already denied this ride previously.',
					updatedProject: ride
				});
			}
			return;
		} else {
			let promiseList = [];

			// const createdAtPlus5Mins = new Date(Math.round(project.createdAt.getTime() + 5 * 60 * 1000));
			let createdAtPlus5Mins = new Date(projectDetails.data().createdAt.toDate());
			createdAtPlus5Mins.setHours(createdAtPlus5Mins.getMinutes() + 5);

			// promise 1 - update ride - accepted rides
			if (
				(projectDetails.data().acceptedBy && projectDetails.data().acceptedBy.length === 0) ||
				(projectDetails.data().availableOperators && projectDetails.data().availableOperators.length === 0)
			) {
				// first operator responding -
				let firstOperatorRespondedWithin5Mins = false;
				if (new Date().getTime() <= createdAtPlus5Mins) {
					firstOperatorRespondedWithin5Mins = true;
				}
				promiseList.push(
					firestore.collection('projects').doc(projectId).update({
						acceptedBy: firestore.FieldValue.arrayUnion(operatorId),
						availableOperators: firestore.FieldValue.arrayUnion(operatorId),
						firstOperatorRespondedWithin5Mins: firstOperatorRespondedWithin5Mins
					})
				);
			} else {
				promiseList.push(
					firestore.collection('projects').doc(projectId).update({
						acceptedBy: firestore.FieldValue.arrayUnion(operatorId),
						availableOperators: firestore.FieldValue.arrayUnion(operatorId)
					})
				);
			}

			// promise 2 - update current operatorData for this ride
			promiseList.push(
				firestore.collection('projects').doc(projectId).collection('operatorData').doc(operatorId).set({
					additionalChargesTolls: project.additionalChargesTolls,
					additionalChargesParking: project.additionalChargesParking,
					additionalChargesAirportFee: project.additionalChargesAirportFee,
					additionalChargesMisc: project.additionalChargesMisc,
					totalRate: project.totalRate,
					specialNotes:
						project.specialNotes.length > 500
							? project.specialNotes.substring(0, 500)
							: project.specialNotes,
					createdAt: new Date(),
					cancellationPolicy: operatorDetails.data().cancellationPolicy
				})
			);

			// promise 3- update project history
			let action = operatorDetails.data().firstName + ' accepted this ride';
			promiseList.push(updateHistory(firestore, projectId, operatorId, action));

			// promise 4 - update operator profile - accepted rides
			promiseList.push(
				firestore.collection('users').doc(operatorId).update({
					acceptedRides: firestore.FieldValue.arrayUnion(projectId)
				})
			);

			// promise 5 - get latest ride details for operator
			promiseList.push(getLatestRideDetailsForOperator(firestore, projectId));

			return Promise.all(promiseList).then((results) => {
				let ride = results[4];
				if (ride === 'BadData') {
					dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
				} else {
					let sendTravelerRideAcceptedMsg = false;
					if (ride.acceptedBy.length === 1) {
						sendTravelerRideAcceptedMsg = true;
					}
					dispatch({
						type: 'SUCCESSFULLY_ACCEPTED_RIDE',
						projectId: projectId,
						updatedProject: ride,
						operatorName: operatorDetails.data().firstName,
						operatorPhone: operatorDetails.data().phoneNumber,
						requestorPhone: requestorDetails.data().phoneNumber,
						sendTravelerRideAcceptedMsg: sendTravelerRideAcceptedMsg
					});
				}
				return;
			});
		}
	};
};

export const denyAcceptedRideAction = (project) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - denyAcceptedRideAction', project)
		const firestore = getFirestore();
		const operatorId = project.deniedAcceptedRideByOperatorId;
		const projectId = project.projectId;

		var operatorDetails = await firestore.collection('users').doc(operatorId).get();

		//step 1 - update ride - denied rides after accepting
		firestore
			.collection('projects')
			.doc(projectId)
			.update({
				deniedAfterAcceptingBy: firestore.FieldValue.arrayUnion(operatorId),
				availableOperators: firestore.FieldValue.arrayRemove(operatorId)
			})
			.then(async (res) => {
				//step 2 - update project history
				let action = operatorDetails.data().firstName + ' is no longer available for this ride';
				await updateHistory(firestore, projectId, operatorId, action);

				//step 3 - update operator profile - denied ride after accepting
				firestore
					.collection('users')
					.doc(operatorId)
					.update({
						deniedRidesAfterAccepting: firestore.FieldValue.arrayUnion(projectId)
					})
					.then(async (res) => {
						//get latest ride details
						let ride = await getLatestRideDetailsForOperator(firestore, projectId);
						if (ride === 'BadData') {
							dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
						} else {
							dispatch({ type: 'SUCCESSFULLY_DENIED_RIDE', updatedProject: ride });
						}
					})
					.catch((err) => {
						dispatch({ type: 'ERROR_DENYING_RIDE' }, err);
					});
			})
			.catch((err) => {
				dispatch({ type: 'ERROR_DENYING_RIDE' }, err);
			});
	};
};

export const operatorSelectedAction = (operatorSelected) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - operatorSelectedAction', operatorSelected)
		const firestore = getFirestore();
		const operatorId = operatorSelected.selectedOperatorId;
		const projectId = operatorSelected.projectId;
		const operatorPhone = operatorSelected.selectedOperatorPhone;
		var operatorDetails = await firestore.collection('users').doc(operatorId).get();

		firestore
			.collection('projects')
			.doc(projectId)
			.update({
				selectedOperatorId: operatorId,
				unselectedOperators: firestore.FieldValue.arrayRemove(operatorId),
				status: 'PENDING'
			})
			.then(async (data) => {
				//update project history
				let action = operatorDetails.data().firstName + ' is selected for this ride';
				await updateHistory(firestore, projectId, operatorId, action);

				//get latest ride details
				let ride = await getLatestRideDetailsForTraveler(firestore, projectId);

				// console.log('ride --- ', ride);

				if (ride === 'BadData') {
					dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
				} else {
					dispatch({
						type: 'SEND_SELECTED_OPERATOR_MSG',
						id: projectId,
						phone: operatorPhone,
						updatedProject: ride
					});
				}
			})
			.catch((err) => {
				dispatch({ type: 'ERROR_ACCEPTING_RIDE' }, err);
			});
	};
};

export const cancelSelectedOperator = (operatorCancelled) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - cancelSelectedOperator', operatorCancelled)
		const firestore = getFirestore();
		const projectId = operatorCancelled.projectId;
		const operatorPhone = operatorCancelled.selectedOperatorPhone;
		const operatorId = operatorCancelled.selectedOperatorId;
		var operatorDetails = await firestore.collection('users').doc(operatorId).get();

		firestore
			.collection('projects')
			.doc(projectId)
			.update({
				selectedOperatorId: null,
				status: 'PENDING',
				unselectedOperators: firestore.FieldValue.arrayUnion(operatorId)
			})
			.then(async (data) => {
				//update project history
				let action = operatorDetails.data().firstName + ' is un-selected for this ride';
				await updateHistory(firestore, projectId, operatorId, action);

				//get latest ride details
				let ride = await getLatestRideDetailsForTraveler(firestore, projectId);
				if (ride === 'BadData') {
					dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
				} else {
					dispatch({
						type: 'CANCEL_SELECTED_OPERATOR_MSG',
						id: projectId,
						phone: operatorPhone,
						updatedProject: ride
					});
				}
			})
			.catch((err) => {
				dispatch({ type: 'ERROR_ACCEPTING_RIDE' }, err);
			});
	};
};

export const sendReminderToSelectedOperator = (operatorSelected) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - sendReminderToSelectedOperator', operatorSelected)
		const firestore = getFirestore();
		const projectId = operatorSelected.projectId;
		const operatorPhone = operatorSelected.selectedOperatorPhone;
		const operatorId = operatorSelected.selectedOperatorId;
		var operatorDetails = await firestore.collection('users').doc(operatorId).get();

		let action = operatorDetails.data().firstName + ' is reminded for confirming this ride';
		await updateHistory(firestore, projectId, operatorId, action);

		//get latest ride details
		let ride = await getLatestRideDetailsForTraveler(firestore, projectId);
		if (ride === 'BadData') {
			dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
		} else {
			dispatch({
				type: 'SEND_SELECTED_OPERATOR_REMINDER_MSG',
				id: projectId,
				phone: operatorPhone,
				updatedProject: ride,
				operatorId: operatorId
			});
		}
	};
};

export const confirmRideAction = (inputObj) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - confirmRideAction', inputObj)
		const firestore = getFirestore();
		const operatorId = inputObj.confirmedByOperatorId;
		const projectId = inputObj.projectId;

		var projectDetails = await firestore.collection('projects').doc(projectId).get();
		var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
		var operatorDetails = await firestore.collection('users').doc(operatorId).get();

		if (projectDetails.data().confirmedByOperatorId && projectDetails.data().confirmedByOperatorId === operatorId) {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg: 'You have already confirmed this ride.',
					updatedProject: ride
				});
			}
			return;
		} else if (projectDetails.data().declinedBy && projectDetails.data().declinedBy.includes(operatorId)) {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg:
						'You are not allowed to confirm this ride because you had declined to confirm this ride previously.',
					updatedProject: ride
				});
			}
			return;
		} else if (
			projectDetails.data().confirmedByOperatorId &&
			projectDetails.data().confirmedByOperatorId !== operatorId
		) {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg:
						'You are not allowed to confirm this ride because you are no longer selected for this ride by the traveler.',
					updatedProject: ride
				});
			}
			return;
		} else {
			//step 1 - update ride - confirmed by
			return firestore
				.collection('projects')
				.doc(projectId)
				.update({
					confirmedByOperatorId: operatorId,
					status: 'CONFIRMED'
				})
				.then(async (data) => {
					//step 2 - update project history
					let action = operatorDetails.data().firstName + ' has confirmed this ride';
					await updateHistory(firestore, projectId, operatorId, action);

					//get amount to preauthorize
					let operatorDataObj = await firestore
						.collection('projects')
						.doc(projectId)
						.collection('operatorData')
						.doc(operatorId)
						.get();
					let operatorData = operatorDataObj.data();

					//step 3 - create task
					let performAt = new Date(projectDetails.data().serviceDate.toDate());
					// console.log('performAt as serviceDate- ', performAt);

					performAt.setHours(performAt.getHours() - 48);
					// console.log('performAt - 48hours from service date - ', performAt);

					if (performAt.getTime() < new Date().getTime()) {
						// console.log('performAt is in the past, so preauthorize now');
						var urgentTaskOptions = {
							status: 'PENDING',
							worker: 'preauthorize',
							projectId: projectId,
							amount: operatorData.totalRate
						};
						// console.log('urgentTaskOptions - ', urgentTaskOptions)
						await createUrgentTask(firestore, urgentTaskOptions);
					} else {
						// console.log('performAt is in the future, so preauthorize later');
						var taskOptions = {
							status: 'PENDING',
							performAt: performAt,
							worker: 'preauthorize',
							options: {
								projectId: projectId,
								amount: operatorData.totalRate
							}
						};
						// console.log('taskOptions - ', taskOptions);
						await createTask(firestore, taskOptions);
					}

					//step 4 - update operator profile - confirmedRides
					return firestore
						.collection('users')
						.doc(operatorId)
						.update({
							confirmedRides: firestore.FieldValue.arrayUnion(projectId)
						})
						.then(async (res) => {
							//get latest ride details
							let ride = await getLatestRideDetailsForOperator(firestore, projectId);
							if (ride === 'BadData') {
								dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
							} else {
								dispatch({
									type: 'SUCCESSFULLY_CONFIRMED_RIDE',
									projectId: projectId,
									updatedProject: ride,
									operatorName: operatorDetails.data().firstName,
									operatorPhone: operatorDetails.data().phoneNumber,
									requestorPhone: requestorDetails.data().phoneNumber
								});
							}

							//step 5 - create task for reminders
							let reminderAt = new Date(projectDetails.data().serviceDate.toDate());
							// console.log('performAt as serviceDate- ', performAt);

							reminderAt.setHours(reminderAt.getHours() - 2);
							// console.log('performAt - 48hours from service date - ', performAt);

							if (reminderAt.getTime() < new Date().getTime()) {
								// console.log('reminderAt is in the past');
								var urgentTaskOptions = {
									status: 'PENDING',
									worker: 'confirmedRideReminderNotifications',
									projectId: projectId,
									operatorId: operatorId
								};
								// console.log('urgentTaskOptions - ', urgentTaskOptions)
								await createUrgentTask(firestore, urgentTaskOptions);
							} else {
								// console.log('reminderAt is in the future');
								var taskOptions = {
									status: 'PENDING',
									performAt: reminderAt,
									worker: 'confirmedRideReminderNotifications',
									options: {
										projectId: projectId,
										operatorId: operatorId
									}
								};
								// console.log('taskOptions - ', taskOptions);
								await createTask(firestore, taskOptions);
							}

							return;
						})
						.catch((err) => {
							dispatch({ type: 'ERROR_CONFIRMING_RIDE' }, err);
							return;
						});
				})
				.catch((err) => {
					dispatch({ type: 'ERROR_CONFIRMING_RIDE' }, err);
					return;
				});
		}
	};
};

export const declineRideConfirmationAction = (project) => {
	// this is when selected operator declines to confirm the ride
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - declineRideConfirmationAction', project)
		const firestore = getFirestore();
		const operatorId = project.declinedByOperatorId;
		const projectId = project.projectId;

		var projectDetails = await firestore.collection('projects').doc(projectId).get();
		var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
		var operatorDetails = await firestore.collection('users').doc(operatorId).get();

		if (projectDetails.data().confirmedByOperatorId && projectDetails.data().confirmedByOperatorId === operatorId) {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg: 'You cannot decline this ride because you had already confirmed this ride.',
					updatedProject: ride
				});
			}
			return;
		} else if (projectDetails.data().declinedBy && projectDetails.data().declinedBy.includes(operatorId)) {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg:
						'You are not allowed to decline this ride because you had already declined this ride previously.',
					updatedProject: ride
				});
			}
			return;
		} else {
			//step 1 - update ride - denied rides by
			return firestore
				.collection('projects')
				.doc(projectId)
				.update({
					declinedBy: firestore.FieldValue.arrayUnion(operatorId),
					availableOperators: firestore.FieldValue.arrayRemove(operatorId),
					confirmedByOperatorId: null,
					selectedOperatorId: null,
					status: 'PENDING'
				})
				.then(async (res) => {
					//step 2 - update project history
					let action = operatorDetails.data().firstName + ' is no longer available for this ride';
					await updateHistory(firestore, projectId, operatorId, action);

					//step 3 - update operator profile denied by operator
					return firestore
						.collection('users')
						.doc(operatorId)
						.update({
							declinedRides: firestore.FieldValue.arrayUnion(projectId)
						})
						.then(async (res) => {
							//get latest ride details
							let ride = await getLatestRideDetailsForOperator(firestore, projectId);
							if (ride === 'BadData') {
								dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
							} else {
								dispatch({
									type: 'OPERATOR_DECLINED_CONFIRMATION',
									projectId: projectId,
									updatedProject: ride,
									operatorName: operatorDetails.data().firstName,
									operatorPhone: operatorDetails.data().phoneNumber,
									requestorPhone: requestorDetails.data().phoneNumber
								});
							}
							return;
						})
						.catch((err) => {
							dispatch({ type: 'OPERATOR_DECLINED_CONFIRMATION_ERR' }, err);
							return;
						});
				})
				.catch((err) => {
					dispatch({ type: 'OPERATOR_DECLINED_CONFIRMATION_ERR' }, err);
					return;
				});
		}
	};
};

export const cancelConfirmedRideAction = (project) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - cancelConfirmedRideAction', project)
		const firestore = getFirestore();
		const operatorId = project.cancelConfirmedRideByOperatorId;
		const projectId = project.projectId;
		var projectDetails = await firestore.collection('projects').doc(projectId).get();
		var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
		var operatorDetails = await firestore.collection('users').doc(operatorId).get();
		// step 1 - cancel preauthorization for confirmed ride amount
		let url = baseURL + '/cancelRideAmountPreauthorized';
		let data = {
			rideId: projectId,
			operatorId: operatorId
		};
		return axios
			.post(url, data)
			.then((response) => {
				if (response.data.success === false) {
					// console.log('cancel ride payment intent NOT successful ');
					dispatch({
						type: 'OPERATOR_CANCELLED_RIDE_ERR',
						errorMsg: 'There was an error, please try again in some time.'
					});
					return;
				} else {
					// console.log('cancel ride payment intent successful ');
					// step 2 - update ride
					return firestore
						.collection('projects')
						.doc(projectId)
						.update({
							cancelledConfirmedRideBy: firestore.FieldValue.arrayUnion(operatorId),
							availableOperators: firestore.FieldValue.arrayRemove(operatorId),
							confirmedByOperatorId: null,
							selectedOperatorId: null,
							status: 'PENDING'
						})
						.then(async (res) => {
							//step 3 - update project history
							let action = operatorDetails.data().firstName + ' is no longer available for this ride';
							await updateHistory(firestore, projectId, operatorId, action);

							//step 4 - update users details - ride cancelled after confirming
							return firestore
								.collection('users')
								.doc(operatorId)
								.update({
									cancelledConfirmedRides: firestore.FieldValue.arrayUnion(projectId)
								})
								.then(async (res) => {
									//get latest ride details
									let ride = await getLatestRideDetailsForOperator(firestore, projectId);
									if (ride === 'BadData') {
										dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
										return;
									} else {
										dispatch({
											type: 'OPERATOR_CANCELLED_RIDE',
											projectId: projectId,
											updatedProject: ride,
											operatorName: operatorDetails.data().firstName,
											operatorPhone: operatorDetails.data().phoneNumber,
											requestorEmail: requestorDetails.data().email,
											requestorPhone: requestorDetails.data().phoneNumber
										});
										return;
									}
								})
								.catch((err) => {
									dispatch({
										type: 'OPERATOR_CANCELLED_RIDE_ERR',
										errorMsg: 'There was an error, please try again in some time.'
									});
									return;
								});
						})
						.catch((err) => {
							dispatch({
								type: 'OPERATOR_CANCELLED_RIDE_ERR',
								errorMsg: 'There was an error, please try again in some time.'
							});
							return;
						});
				}
			})
			.catch((err) => {
				// console.log('failed to cancel ride - error occurred', err);
				dispatch({
					type: 'OPERATOR_CANCELLED_RIDE_ERR',
					errorMsg: 'There was an error, please try again in some time.'
				});
				return;
			});
	};
};

export const cancelRideByTraveler = (project) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - cancelRideByTraveler', project)
		const firestore = getFirestore();
		const projectId = project.projectId;
		const requestorId = project.requestorId;
		var projectDetails = await firestore.collection('projects').doc(projectId).get();
		var requestorDetails = await firestore.collection('users').doc(requestorId).get();
		var operatorDetails = null;
		// console.log('projectDetails.data().confirmedByOperatorId  - ', projectDetails.data().confirmedByOperatorId)
		if (
			projectDetails &&
			projectDetails.data().confirmedByOperatorId &&
			projectDetails.data().confirmedByOperatorId !== null
		) {
			operatorDetails = await firestore
				.collection('users')
				.doc(projectDetails.data().confirmedByOperatorId)
				.get();
		}
		let promiseList = [];
		// update project status
		promiseList.push(
			firestore.collection('projects').doc(projectId).update({
				status: 'CANCELLED'
			})
		);
		//update project history
		promiseList.push(
			updateHistory(firestore, projectId, requestorId, requestorDetails.data().firstName + ' cancelled this ride')
		);
		// create task to check if user needs to be charged
		promiseList.push(
			createUrgentTask(firestore, {
				status: 'PENDING',
				worker: 'travelerCancelledRide',
				projectId: projectId
			})
		);
		// update requestor cancelledRides
		promiseList.push(
			firestore.collection('users').doc(requestorId).update({
				cancelledRides: firestore.FieldValue.arrayUnion(projectId)
			})
		);
		return Promise.all(promiseList).then(() => {
			getLatestRideDetailsForTraveler(firestore, projectId)
				.then((ride) => {
					if (ride === 'BadData') {
						dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
						return;
					} else {
						dispatch({
							type: 'SUCCESSFULLY_CANCELLED_RIDE',
							projectId: projectId,
							operatorPhone: operatorDetails !== null ? operatorDetails.data().phoneNumber : null,
							requestorPhone: requestorDetails.data().phoneNumber,
							updatedProject: ride
						});
						return;
					}
				})
				.catch((err) => {
					dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
					return;
				});
		});
	};
};

export const clearCreatedProjectStatus = () => {
	return (dispatch, getState, { getFirestore }) => {
		dispatch({ type: 'CLEAR_PROJECT_STATUS' });
	};
};

export const getNewUpcomingRides = (obj) => {
	return (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getNewUpcomingRides for state: ', obj)
		const firestore = getFirestore();
		let upcomingProjects = [];
		let promiseList = [];
		if (obj.operatorUsState) {
			promiseList.push(
				firestore
					.collection('projects')
					.where('serviceDate', '>=', new Date())
					.where('serviceFromState', '==', obj.operatorUsState)
					.orderBy('serviceDate')
					.limit(100)
					.get()
			);
			promiseList.push(
				firestore
					.collection('projects')
					.where('serviceDate', '>=', new Date())
					.where('serviceToState', '==', obj.operatorUsState)
					.orderBy('serviceDate')
					.limit(100)
					.get()
			);

			// console.log('promises added');
			Promise.all(promiseList)
				.then((results) => {
					let promiseList1 = [];
					const snapshot1 = results[0];
					const snapshot2 = results[1];
					if (snapshot1.empty && snapshot2.empty) {
						dispatch({ type: 'ERROR_FETCHING_UPCOMING_RIDES', errorMsg: 'No rides found' });
					} else {
						let rideList = [];
						const allSnapshotDocs = snapshot1.docs.concat(snapshot2.docs);
						allSnapshotDocs.forEach((doc) => {
							let tmp = doc.data();
							// console.log('tmp1 - ', doc.id, tmp.serviceVehicle, tmp.status);
							// get requestor details
							promiseList1.push(
								firestore.collection('users').doc(tmp.authorId).get().then((requestorDetails) => {
									// console.log('requestorDetails - ', requestorDetails.data())
									if (requestorDetails != null && requestorDetails.data()) {
										if (
											requestorDetails.data().blackListedOperators &&
											requestorDetails.data().blackListedOperators.includes(obj.operatorId)
										) {
											// console.log('1, blacklisted operator')
											return;
										} else {
											// console.log('processing');
											if (tmp.status === 'PENDING' && !rideList.includes(doc.id)) {
												// filter new rides by the operator carType
												if (
													obj.carType === 'Sedan' &&
													[ 'Sedan' ].includes(tmp.serviceVehicle)
												) {
													// Sedan operator can view only Sedan rides
													tmp.id = doc.id;
													upcomingProjects.push(tmp);
													rideList.push(doc.id);
												}
												if (
													obj.carType === 'SUV' &&
													[ 'SUV', 'Sedan' ].includes(tmp.serviceVehicle)
												) {
													// SUV operator can view Sedan & SUV rides
													tmp.id = doc.id;
													upcomingProjects.push(tmp);
													rideList.push(doc.id);
												} else if (
													obj.carType === 'LuxurySedan' &&
													[ 'LuxurySedan', 'Sedan' ].includes(tmp.serviceVehicle)
												) {
													// LuxurySedan operator can view Sedan & LuxurySedan rides
													tmp.id = doc.id;
													upcomingProjects.push(tmp);
													rideList.push(doc.id);
												} else if (
													[ 'PassengerVan', 'CorporateSprinter', 'LimoSprinter' ].includes(
														obj.carType
													) &&
													[ 'PassengerVan', 'CorporateSprinter', 'LimoSprinter' ].includes(
														tmp.serviceVehicle
													)
												) {
													// PassengerVan/CorporateSprinter/LimoSprinter operators can view PassengerVan/CorporateSprinter/LimoSprinter rides
													tmp.id = doc.id;
													upcomingProjects.push(tmp);
													rideList.push(doc.id);
												} else if (
													obj.carType === 'Passenger24Bus' &&
													[
														'Passenger24Bus',
														'Passenger34Bus',
														'Passenger55CoachBus'
													].includes(tmp.serviceVehicle)
												) {
													// Passenger24Bus operator can view Passenger24Bus, Passenger34Bus & Passenger55CoachBus rides
													tmp.id = doc.id;
													upcomingProjects.push(tmp);
													rideList.push(doc.id);
												} else if (
													obj.carType === 'Passenger34Bus' &&
													[ 'Passenger34Bus', 'Passenger55CoachBus' ].includes(
														tmp.serviceVehicle
													)
												) {
													// Passenger34Bus operator can view Passenger34Bus & Passenger55CoachBus rides
													tmp.id = doc.id;
													upcomingProjects.push(tmp);
													rideList.push(doc.id);
												} else if (
													obj.carType === 'Passenger55CoachBus' &&
													[ 'Passenger55CoachBus' ].includes(tmp.serviceVehicle)
												) {
													// Passenger55CoachBus operator can view Passenger55CoachBus rides
													tmp.id = doc.id;
													upcomingProjects.push(tmp);
													rideList.push(doc.id);
												} else if (
													obj.carType === 'StretchLimo' &&
													[ 'StretchLimo', 'StretchSUV' ].includes(tmp.serviceVehicle)
												) {
													// StretchLimo operator can view StretchLimo &  StretchSUV rides
													tmp.id = doc.id;
													upcomingProjects.push(tmp);
													rideList.push(doc.id);
												} else if (
													obj.carType === 'StretchSUV' &&
													[ 'StretchSUV' ].includes(tmp.serviceVehicle)
												) {
													// StretchSUV operator can view StretchSUV rides
													tmp.id = doc.id;
													upcomingProjects.push(tmp);
													rideList.push(doc.id);
												}
											}
										}
									}
								})
							);
						});
						Promise.all(promiseList1).then((results1) => {
							// console.log('upcomingProjects = ', upcomingProjects);
							dispatch({
								type: 'UPCOMING_RIDES_FETCHED_SUCCESSFULLY',
								upcomingProjects: upcomingProjects
							});
						});
					}
				})
				.catch((err) => {
					// console.log('error', err)
					dispatch({
						type: 'ERROR_FETCHING_UPCOMING_RIDES',
						errorMsg: 'Error occurred while getting rides for ' + obj.operatorUsState
					});
				});
		} else {
			// console.log('error2')
			dispatch({ type: 'ERROR_FETCHING_UPCOMING_RIDES', errorMsg: 'State is empty' });
		}
	};
};

export const getTravelerRequestedRides = (uid) => {
	return (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getTravelerRequestedRides for uid: ', uid)
		const firestore = getFirestore();
		let requestedProjects = [];
		if (uid) {
			return firestore
				.collection('projects')
				.where('authorId', '==', uid)
				.where('serviceDate', '<', new Date(new Date().setHours(0, 0, 0, 0)))
				.orderBy('serviceDate', 'desc')
				.limit(200)
				.get()
				.then((snapshot) => {
					if (snapshot.empty) {
						// console.log('getRequestedRides - No matching documents.');
						dispatch({
							type: 'TRAVELER_REQUESTED_RIDES_FETCHED_SUCCESSFULLY',
							travelerRequestedProjects: requestedProjects
						});
					} else {
						// console.log('Getting requested rides');
						const promises = [];
						snapshot.forEach(async (doc) => {
							// console.log(doc.id, '=>', doc.data());
							// requestedProjects[doc.id]=doc.data();
							let tmp = doc.data();
							if (tmp.status === 'PENDING' && tmp.acceptedBy && tmp.acceptedBy.length > 0) {
								tmp.status = 'ACCEPTED';
							}
							if (tmp.confirmedByOperatorId) {
								// console.log(doc.id, ' confirmed ride')
								promises.push(
									firestore
										.collection('users')
										.doc(tmp.confirmedByOperatorId)
										.get()
										.then(async (operatorDetails) => {
											if (operatorDetails != null && operatorDetails.data()) {
												tmp.confirmedOperatorName =
													operatorDetails.data().firstName +
													' ' +
													operatorDetails.data().lastName;
												tmp.confirmedOperatorPhoneNumber = operatorDetails.data().phoneNumber;
												tmp.confirmedOperatorProfileImageUrl = operatorDetails.data()
													.profileImageUrl
													? StorageBaseURL +
														encodeURIComponent(
															tmp.confirmedByOperatorId +
																'/profile/thumb_' +
																operatorDetails.data().profileImageUrl
														) +
														altMedia
													: null;
												// console.log(doc.id, ' confirmed operator = ', tmp.confirmedOperatorName, tmp.confirmedOperatorPhoneNumber)
												let confirmedOperatorDate = await firestore
													.collection('projects')
													.doc(doc.id)
													.collection('operatorData')
													.doc(tmp.confirmedByOperatorId)
													.get();
												if (
													confirmedOperatorDate &&
													confirmedOperatorDate.data() &&
													confirmedOperatorDate.data().totalRate
												) {
													tmp.confirmedOperatorRate = `$${confirmedOperatorDate.data()
														.totalRate}`;
												}
												tmp.id = doc.id;
												requestedProjects.push(tmp);
											} else {
												tmp.id = doc.id;
												requestedProjects.push(tmp);
											}
										})
								);
							} else {
								// console.log(doc.id, ' non confirmed ride')
								tmp.id = doc.id;
								promises.push(requestedProjects.push(tmp));
							}
						});
						Promise.all(promises)
							.then((results) => {
								// console.log('promises length = ', promises.length, requestedProjects.length)
								// console.log('getRequestedRides - requestedProjects - ', requestedProjects)
								dispatch({
									type: 'TRAVELER_REQUESTED_RIDES_FETCHED_SUCCESSFULLY',
									travelerRequestedProjects: requestedProjects
								});
							})
							.catch((e) => {
								// console.log('error in processing promises - ', e);
								dispatch({
									type: 'ERROR_FETCHING_RIDES',
									errorMsg: 'Error occurred while getting requested rides'
								});
							});
					}
				})
				.catch((err) => {
					// console.log('getRequestedRides - Error occurred - ' + err)
					dispatch({
						type: 'ERROR_FETCHING_RIDES',
						errorMsg: 'Error occurred while getting requested rides'
					});
				});
		} else {
			// console.log('getRequestedRides - No UID');
			dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
			return;
		}
	};
};

export const getTravelerPendingApprovalRides = (uid) => {
	return (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getTravelerPendingApprovalRides for uid: ', uid)
		const firestore = getFirestore();
		let requestedProjects = [];
		if (uid) {
			return firestore
				.collection('projects')
				.where('authorId', '==', uid)
				.where('serviceDate', '>', new Date())
				.get()
				.then((snapshot) => {
					if (snapshot.empty) {
						// console.log('getRequestedRides - No matching documents.');
						dispatch({
							type: 'TRAVELER_PENDING_APPROVAL_RIDES_FETCHED_SUCCESSFULLY',
							travelerPendingApprovalRides: requestedProjects
						});
					} else {
						// console.log('Getting requested rides');
						const promises = [];
						snapshot.forEach((doc) => {
							let tmp = doc.data();
							tmp.id = doc.id;

							if (tmp.status === 'PENDING') {
								if (tmp.acceptedBy && tmp.acceptedBy.length > 0) {
									tmp.status = 'ACCEPTED';
								}
								tmp.id = doc.id;
								if (tmp.confirmedByOperatorId) {
									// console.log(doc.id, ' confirmed ride')
									promises.push(
										firestore
											.collection('users')
											.doc(tmp.confirmedByOperatorId)
											.get()
											.then(async (operatorDetails) => {
												if (operatorDetails != null && operatorDetails.data()) {
													tmp.confirmedOperatorName =
														operatorDetails.data().firstName +
														' ' +
														operatorDetails.data().lastName;
													tmp.confirmedOperatorPhoneNumber = operatorDetails.data().phoneNumber;
													tmp.confirmedOperatorProfileImageUrl = operatorDetails.data()
														.profileImageUrl
														? StorageBaseURL +
															encodeURIComponent(
																tmp.confirmedByOperatorId +
																	'/profile/thumb_' +
																	operatorDetails.data().profileImageUrl
															) +
															altMedia
														: null;
													// console.log(doc.id, ' confirmed operator = ', tmp.confirmedOperatorName, tmp.confirmedOperatorPhoneNumber)
													let confirmedOperatorDate = await firestore
														.collection('projects')
														.doc(doc.id)
														.collection('operatorData')
														.doc(tmp.confirmedByOperatorId)
														.get();
													if (
														confirmedOperatorDate &&
														confirmedOperatorDate.data() &&
														confirmedOperatorDate.data().totalRate
													) {
														tmp.confirmedOperatorRate = `$${confirmedOperatorDate.data()
															.totalRate}`;
													}
													requestedProjects.push(tmp);
												} else {
													requestedProjects.push(tmp);
												}
											})
									);
								} else {
									// console.log(doc.id, ' non confirmed ride')
									promises.push(requestedProjects.push(tmp));
								}
							}
						});
						Promise.all(promises)
							.then((results) => {
								dispatch({
									type: 'TRAVELER_PENDING_APPROVAL_RIDES_FETCHED_SUCCESSFULLY',
									travelerPendingApprovalRides: requestedProjects
								});
							})
							.catch((e) => {
								// console.log('error in processing promises - ', e);
								dispatch({
									type: 'ERROR_FETCHING_RIDES',
									errorMsg: 'Error occurred while getting requested rides'
								});
							});
					}
				})
				.catch((err) => {
					// console.log('getPendingApprovalRides - Error occurred - ' + err)
					dispatch({
						type: 'ERROR_FETCHING_RIDES',
						errorMsg: 'Error occurred while getting requested rides'
					});
					return;
				});
		} else {
			// console.log('getPendingApprovalRides - No UID');
			dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
			return;
		}
	};
};

export const getAcceptedPendingTravelerApprovalRidesData = (uid) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getAcceptedPendingTravelerApprovalRidesData for uid: ', uid)
		const firestore = getFirestore();
		let pendingAcceptedRides = [];
		var operatorDetails = await firestore.collection('users').doc(uid).get();
		if (operatorDetails != null && operatorDetails.data()) {
			let acceptedRidesIdList = operatorDetails.data().acceptedRides || [];
			for (const rideId of acceptedRidesIdList) {
				let projectDetails = await firestore.collection('projects').doc(rideId).get();
				let tmp = projectDetails.data();

				if (tmp.status === 'PENDING' && moment(new Date()) < moment(tmp.serviceDate.toDate())) {
					tmp.id = projectDetails.id;
					pendingAcceptedRides.push(tmp);
				}
			}
			dispatch({
				type: 'PENDING_ACCEPTED_RIDES_FETCHED_SUCCESSFULLY',
				pendingAcceptedRides: pendingAcceptedRides
			});
		} else {
			dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
		}
	};
};

export const getRideDetails = (projectId) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside getRideDetails')
		const firestore = getFirestore();

		let ride = await getLatestRideDetailsForTraveler(firestore, projectId);
		dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: ride });
	};
};

export const getRideDetailsForOperator = (data) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside getRideDetailsForOperator')
		const firestore = getFirestore();

		let ride = await getLatestRideDetailsForOperator(firestore, data.projectId, data.operatorId);
		dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: ride });
	};
};

export const getMyAcceptedRidesAction = (uid) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getMyAcceptedRidesAction for uid: ', uid)
		const firestore = getFirestore();
		let acceptedRides = [];
		var operatorDetails = await firestore.collection('users').doc(uid).get();
		if (operatorDetails != null && operatorDetails.data()) {
			let acceptedRidesIdList = operatorDetails.data().acceptedRides || [];
			for (const rideId of acceptedRidesIdList) {
				let projectDetails = await firestore.collection('projects').doc(rideId).get();
				let tmp = projectDetails.data();
				tmp.id = projectDetails.id;
				acceptedRides.push(tmp);
			}
			dispatch({ type: 'ACCEPTED_RIDES_FETCHED_SUCCESSFULLY', acceptedRides: acceptedRides });
		} else {
			dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
		}
	};
};

export const getOperatorPastRideHistoryAction = (uid) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getOperatorPastRideHistoryAction for uid: ', uid)
		const firestore = getFirestore();
		let operatorPastRides = [];
		var operatorDetails = await firestore.collection('users').doc(uid).get();
		if (operatorDetails != null && operatorDetails.data()) {
			let operatorPastRidesIdList = operatorDetails.data().confirmedRides || [];
			for (const rideId of operatorPastRidesIdList) {
				let projectDetails = await firestore.collection('projects').doc(rideId).get();
				let tmp = projectDetails.data();

				if (moment(new Date()) > moment(tmp.serviceDate.toDate())) {
					tmp.id = projectDetails.id;
					operatorPastRides.push(tmp);
				}
			}
			dispatch({ type: 'OPERATOR_PAST_RIDES_FETCHED_SUCCESSFULLY', operatorPastRides: operatorPastRides });
		} else {
			dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
		}
	};
};

export const getOperatorUpcomingConfirmedRides = (uid) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getOperatorUpcomingConfirmedRides for uid: ', uid)
		const firestore = getFirestore();
		let operatorUpcomingRides = [];
		var operatorDetails = await firestore.collection('users').doc(uid).get();
		if (operatorDetails != null && operatorDetails.data()) {
			let operatorUpcomingRidesIdList = operatorDetails.data().confirmedRides || [];
			for (const rideId of operatorUpcomingRidesIdList) {
				let projectDetails = await firestore.collection('projects').doc(rideId).get();
				let tmp = projectDetails.data();
				if (moment(tmp.serviceDate.toDate()) >= moment(new Date(new Date().setHours(0, 0, 0, 0)))) {
					// console.log('greater', moment(tmp.serviceDate.toDate()) >= moment(new Date(new Date().setHours(0, 0, 0, 0))));
					tmp.id = projectDetails.id;
					operatorUpcomingRides.push(tmp);
				}
			}
			// console.log('operatorUpcomingRides = ', operatorUpcomingRides);
			dispatch({
				type: 'OPERATOR_UPCOMING_RIDES_FETCHED_SUCCESSFULLY',
				operatorUpcomingRides: operatorUpcomingRides
			});
		} else {
			dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
		}
	};
};

export const updateOperatorCurrentRideStatus = (project) => {
	// console.log('inside updateOperatorCurrentRideStatus action ', project);
	return async (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		const operatorId = project.operatorId;
		const projectId = project.projectId;
		var projectDetails = await firestore.collection('projects').doc(projectId).get();
		var requestorDetails = await firestore.collection('users').doc(projectDetails.data().authorId).get();
		var operatorDetails = await firestore.collection('users').doc(operatorId).get();
		let action = '';
		let msg = '';

		let allowUpdateCurrentRideStatus = false;

		if (
			projectDetails.data().currentRideStatus &&
			projectDetails.data().currentRideStatus === project.currentRideStatus
		) {
			if (project.currentRideStatus === 'ADD_EXTRA_STOPS') {
				// allow to make changes
				allowUpdateCurrentRideStatus = true;
			} else {
				// do not allow to change current ride status again, already updated
				allowUpdateCurrentRideStatus = false;
			}
		} else {
			// allow to make changes
			allowUpdateCurrentRideStatus = true;
		}
		if (allowUpdateCurrentRideStatus === true) {
			// update ride - currentRideStatus, set history action and text message content for traveler
			switch (project.currentRideStatus) {
				case 'OPERATOR_ON_WAY_TO_PICKUP_LOCATION':
					action = operatorDetails.data().firstName + ' is on the way to ride pick up location. ';
					msg =
						'Your FINDANIO operator ' +
						operatorDetails.data().firstName +
						' is on the way to ride pick up location. ';
					await firestore.collection('projects').doc(projectId).update({
						currentRideStatus: project.currentRideStatus
					});
					break;
				case 'OPERATOR_ON_LOCATION':
					action = operatorDetails.data().firstName + ' is at location. ';
					msg =
						'Your FINDANIO operator ' + operatorDetails.data().firstName + ' is at ride pick up location. ';
					await firestore.collection('projects').doc(projectId).update({
						currentRideStatus: project.currentRideStatus
					});
					break;
				case 'PASSENGER_ON_BOARD':
					action = 'Ride started (passenger on board)';
					msg = 'Your FINDANIO ride has started. ';
					await firestore.collection('projects').doc(projectId).update({
						currentRideStatus: project.currentRideStatus
					});
					break;
				case 'ADD_EXTRA_STOPS':
					action = operatorDetails.data().firstName + ' has added an extra stop for your current ride. ';
					msg =
						'Your FINDANIO operator ' +
						operatorDetails.data().firstName +
						' has added an extra stop for your current ride which needs to be approved. ';
					await firestore.collection('projects').doc(projectId).update({
						currentRideStatus: project.currentRideStatus
					});
					var operatorData = await firestore
						.collection('projects')
						.doc(projectId + '/operatorData/' + operatorId)
						.get();
					let index =
						(operatorData.data() &&
							operatorData.data().extraStops &&
							Object.getOwnPropertyNames(operatorData.data().extraStops).length) ||
						0;
					// console.log('index = ', index)
					await firestore.collection('projects').doc(projectId + '/operatorData/' + operatorId).update({
						[`extraStops.${index}`]: {
							extraStopsNotes: project.extraStopsNotes,
							extraStopsCharges: project.extraStopsCharges,
							status: 'PENDING TRAVELER APPROVAL'
						}
					});
					break;
				case 'RIDE_ENDED':
					action = 'Ride ended';
					msg = 'Your FINDANIO ride has completed. Please verify final charges. ';
					await firestore.collection('projects').doc(projectId).update({
						currentRideStatus: project.currentRideStatus,
						status: 'COMPLETED',
						finalRate: project.totalRateWithStops
					});
					//create task to capture all paymentIntents for the ride
					await createUrgentTask(firestore, {
						status: 'PENDING',
						worker: 'capturePreAuthorizations',
						projectId: projectId
					});
					break;
				default:
					break;
			}

			// update project history
			await updateHistory(firestore, projectId, operatorId, action);
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);

			// return updated ride details
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'SUCCESSFULLY_UPDATED_RIDE_STATUS',
					projectId: projectId,
					updatedProject: ride,
					operatorName: operatorDetails.data().firstName,
					requestorPhone: requestorDetails.data().phoneNumber,
					msg: msg
				});
			}
		} else {
			//get latest ride details
			let ride = await getLatestRideDetailsForOperator(firestore, projectId);
			if (ride === 'BadData') {
				dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
			} else {
				dispatch({
					type: 'ERROR_UPDATING_RIDE_CURRENT_STATUS',
					errorMsg: 'Cannot update same status again.',
					updatedProject: ride
				});
			}
			return;
		}
	};
};

export const getTravelerUpcomingRides = (uid) => {
	return (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getTravelerUpcomingRides for uid: ', uid)
		const firestore = getFirestore();
		let requestedProjects = [];
		if (uid) {
			return firestore
				.collection('projects')
				.where('authorId', '==', uid)
				.where('serviceDate', '>=', new Date(new Date().setHours(0, 0, 0, 0)))
				.orderBy('serviceDate', 'asc')
				.get()
				.then((snapshot) => {
					if (snapshot.empty) {
						// console.log('getRequestedRides - No matching documents.');
						dispatch({
							type: 'UPCOMING_REQUESTED_RIDES_FETCHED_SUCCESSFULLY',
							travelerUpcomingProjects: requestedProjects
						});
					} else {
						// console.log('Getting requested rides');
						const promises = [];
						snapshot.forEach((doc) => {
							let tmp = doc.data();
							tmp.id = doc.id;
							if (tmp.status === 'CANCELLED') {
								return;
							}
							if (tmp.confirmedByOperatorId) {
								// console.log(doc.id, ' confirmed ride')
								promises.push(
									firestore
										.collection('users')
										.doc(tmp.confirmedByOperatorId)
										.get()
										.then(async (operatorDetails) => {
											if (operatorDetails != null && operatorDetails.data()) {
												tmp.confirmedOperatorName =
													operatorDetails.data().firstName +
													' ' +
													operatorDetails.data().lastName;
												tmp.confirmedOperatorPhoneNumber = operatorDetails.data().phoneNumber;
												tmp.confirmedOperatorProfileImageUrl = operatorDetails.data()
													.profileImageUrl
													? StorageBaseURL +
														encodeURIComponent(
															tmp.confirmedByOperatorId +
																'/profile/thumb_' +
																operatorDetails.data().profileImageUrl
														) +
														altMedia
													: null;
												// console.log(doc.id, ' confirmed operator = ', tmp.confirmedOperatorName, tmp.confirmedOperatorPhoneNumber)

												let confirmedOperatorDate = await firestore
													.collection('projects')
													.doc(doc.id)
													.collection('operatorData')
													.doc(tmp.confirmedByOperatorId)
													.get();
												if (
													confirmedOperatorDate &&
													confirmedOperatorDate.data() &&
													confirmedOperatorDate.data().totalRate
												) {
													tmp.confirmedOperatorRate = `$${confirmedOperatorDate.data()
														.totalRate}`;
												}
											} else {
												tmp.id = doc.id;
											}
											// console.log('tmp - ', tmp);
											requestedProjects.push(tmp);
										})
								);
							} else {
								// console.log(doc.id, ' non confirmed ride')
								promises.push(requestedProjects.push(tmp));
							}
						});
						Promise.all(promises)
							.then((results) => {
								dispatch({
									type: 'UPCOMING_REQUESTED_RIDES_FETCHED_SUCCESSFULLY',
									travelerUpcomingProjects: requestedProjects
								});
							})
							.catch((e) => {
								// console.log('error in processing promises - ', e);
								dispatch({
									type: 'ERROR_FETCHING_RIDES',
									errorMsg: 'Error occurred while getting requested rides'
								});
							});
					}
				})
				.catch((err) => {
					// console.log('getRequestedRides - Error occurred - ' + err)
					dispatch({
						type: 'ERROR_FETCHING_RIDES',
						errorMsg: 'Error occurred while getting requested rides'
					});
				});
		} else {
			// console.log('getRequestedRides - No UID');
			dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
		}
	};
};

export const handleExtraStopByTraveler = (obj) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - handleExtraStopByTraveler : ', obj)
		const firestore = getFirestore();
		const index = obj.extraStop.index;
		const projectId = obj.projectId;
		var projectDetails = await firestore.collection('projects').doc(projectId).get();
		const operatorId = projectDetails.data().confirmedByOperatorId;
		var operatorDetails = await firestore.collection('users').doc(operatorId).get();
		let action = '';
		let msg = '';

		if (obj.action === 'accept') {
			action = 'Approved extra stop: $' + obj.extraStop.extraStopsCharges;
			msg = 'Your extra charges: $' + obj.extraStop.extraStopsCharges + ' are accepted by the traveler.';
			//update extra charge as accepted
			await firestore.collection('projects').doc(projectId + '/operatorData/' + operatorId).update({
				[`extraStops.${index}.status`]: 'APPROVED'
			});

			//create a payment intent of extra stop
			await firestore
				.collection('newRidePayment')
				.add({
					paymentMethod: projectDetails.data().paymentMethod,
					amount: obj.extraStop.extraStopsCharges,
					requestorId: projectDetails.data().authorId,
					projectId: projectId,
					operatorId: operatorId,
					createdAt: new Date(),
					type: 'extraStop'
				})
				.then(async (extraStopPayment) => {
					// console.log('extraStopPayment created - ', extraStopPayment.id)
					let url = baseURL + '/extraStopPaymentIntent';
					let data = {
						extraStopPaymentId: extraStopPayment.id
					};
					// console.log('payload of extraStopPaymentIntent API - ', data);
					await axios
						.post(url, data)
						.then((response) => {
							// let paymentIntentId = response.data.paymentIntentId;
							// console.log('done with extra stop payment- paymentIntentId: ', paymentIntentId);
							return;
						})
						.catch((err) => {
							// console.log('extra stop paymentIntent failed', err);
							dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
							return;
						});
				})
				.catch((err) => {
					// console.log('error occurred ', err);
					dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
					return;
				});
		} else {
			action = 'Denied extra stop: $' + obj.extraStop.extraStopsCharges;
			msg = 'Your extra charges: $' + obj.extraStop.extraStopsCharges + ' are denied by the traveler.';
			//update extra charge as denied
			await firestore.collection('projects').doc(projectId + '/operatorData/' + operatorId).update({
				[`extraStops.${index}.status`]: 'DENIED'
			});
		}

		// update project history
		// console.log('update project history after handling extra stop')
		await updateHistory(firestore, projectId, operatorId, action);

		//return updated ride details
		let ride = await getLatestRideDetailsForTraveler(firestore, projectId);
		// console.log('updated ride - ', ride);
		if (ride === 'BadData') {
			dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
		} else {
			dispatch({
				type: 'UPDATED_EXTRA_STOP_BY_REQUESTOR_SUCCESSFUL',
				projectId: projectId,
				updatedProject: ride,
				// msg: action,
				operatorPhone: operatorDetails.data().phoneNumber,
				msg: msg
			});
		}
	};
};

export const choosePreferredOperator = (obj) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - choosePreferredOperator : ', obj)
		const firestore = getFirestore();
		firestore
			.collection('users')
			.doc(obj.requestorId)
			.update({
				preferredOperators: firestore.FieldValue.arrayUnion(obj.operatorId)
			})
			.then(async (res) => {
				// console.log('updated preferred operator')
			})
			.catch((err) => {
				dispatch({ type: 'ERROR' }, err);
			});
	};
};

export const saveRatingReview = (obj) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - saveRatingReview : ', obj);
		const firestore = getFirestore();

		if (obj.reviewId) {
			//update existing review
			await firestore
				.collection('reviews')
				.doc(obj.reviewId)
				.update({
					rating: obj.rating,
					review: obj.review,
					updatedTimestamp: new Date()
				})
				.then(async () => {
					// console.log('updated review')
					let ride = await getLatestRideDetailsForTraveler(firestore, obj.rideId);
					if (ride === 'BadData') {
						dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
					} else {
						// console.log('retrieved ride details - ', ride)
						dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: ride });
					}
				});
		} else {
			//insert in reviews collection
			await firestore
				.collection('reviews')
				.add({
					rating: obj.rating,
					review: obj.review,
					operatorId: obj.operatorId,
					requestorId: obj.requestorId,
					rideId: obj.rideId,
					timestamp: new Date()
				})
				.then(async (reviewDoc) => {
					// console.log('created review', reviewDoc.id);

					//update project reviewId
					await firestore.collection('projects').doc(obj.rideId).update({
						reviewId: reviewDoc.id
					});

					//update traveler profile - review given
					await firestore.collection('users').doc(obj.requestorId).update({
						reviewsGiven: firestore.FieldValue.arrayUnion(reviewDoc.id)
					});

					//update operator profile - review received
					await firestore.collection('users').doc(obj.operatorId).update({
						reviewsReceived: firestore.FieldValue.arrayUnion(reviewDoc.id)
					});

					let ride = await getLatestRideDetailsForTraveler(firestore, obj.rideId);
					if (ride === 'BadData') {
						dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
					} else {
						dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: ride });
					}
				})
				.catch((err) => {
					// console.log('error - ', err)
					dispatch({ type: 'ERROR' }, err);
				});
		}
	};
};

export const sendChatMessageToTraveler = (obj) => {
	return async (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		await addChatMessage(firestore, obj.projectId, obj.userId, obj.userName, obj.msg);
		let ride = await getLatestRideDetailsForOperator(firestore, obj.projectId);
		if (ride === 'BadData') {
			dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
		} else {
			dispatch({
				type: 'SENT_CHAT_MSG_SUCCESSFULLY',
				rideDetails: ride,
				phone: obj.phone,
				userName: obj.userName,
				rideId: obj.projectId,
				fromAccountType: 'Operator'
			});
		}
	};
};

export const sendChatMessageToOperator = (obj) => {
	return async (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		await addChatMessage(firestore, obj.projectId, obj.userId, obj.userName, obj.msg);
		let ride = await getLatestRideDetailsForTraveler(firestore, obj.projectId);
		if (ride === 'BadData') {
			dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
		} else {
			dispatch({
				type: 'SENT_CHAT_MSG_SUCCESSFULLY',
				rideDetails: ride,
				phone: obj.phone,
				userName: obj.userName,
				rideId: obj.projectId,
				fromAccountType: 'Traveler'
			});
		}
	};
};

export const getLocationStates = (obj) => {
	return (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		firestore
			.collection('operatorLocations')
			.get()
			.then((snapshot) => {
				var locations = {};
				snapshot.forEach((stateData) => {
					let stateId = stateData.id;
					let operators = stateData.data().operators;
					if (operators) {
						for (const vehicleType in operators) {
							if (
								operators.hasOwnProperty(vehicleType) &&
								operators[vehicleType].length > 0 &&
								!locations.hasOwnProperty(stateId)
							) {
								// console.log('state = ', stateId, ' | operators = ', operators, ' | vehicleType = ', vehicleType, ' | operators[vehicleType]= ', operators[vehicleType]);
								locations[stateId] = {
									fill: 'navy'
								};
							}
						}
					}
				});
				// console.log('getLocationStates -> locations = ', locations)
				dispatch({ type: 'RETRIEVED_OPERATOR_LOCATIONS', locations });
			})
			.catch((err) => {
				dispatch({ type: 'RETRIEVED_OPERATOR_LOCATIONS', locations: {} });
			});
	};
};

export const editRideAction = (changedRideObj) => {
	return async (dispatch, getState, { getFirestore }) => {
		// console.log('Inside editRideAction - ', changedRideObj);
		// console.log(' did ride change ? ', changedRideObj.rideEdited);
		const firestore = getFirestore();
		const rideId = changedRideObj.rideId;

		// TODO

		// update ride history

		// update ride details

		// change pre-authorizations if required - cancel old, create new

		// if ride is confirmed - only confirmed operator gets notified
		// else if ride is not confirmed - all operators get renotified

		// get latest updated ride details to display to the requestor with confirmation message

		let ride = await getLatestRideDetailsForTraveler(firestore, rideId);
		dispatch({ type: 'UPDATED_RIDE_DETAILS', rideDetails: ride });
		return;
	};
};

export const payTipAmountToOperatorFn = (data) => {
	return async (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		const projectId = data.projectId;
		var projectDetails = await firestore.collection('projects').doc(projectId).get();

		return firestore
			.collection('projects')
			.doc(projectId)
			.update({
				tipAmount: data.tipAmount,
				tipAmountPaid: false
			})
			.then(async (rsData) => {
				//update project history
				let action = `Requestor has paid a tip amount $${data.tipAmount.toFixed(
					2
				)} to the selected operator for this ride. `;
				await updateHistory(firestore, projectId, projectDetails.data().authorId, action);

				let url = baseURL + '/payTipAmountToOperator';
				let payload = {
					id: projectId,
					rid: projectDetails.data().authorId,
					tipAmount: data.tipAmount
				};
				// console.log('payload of pay tip amount API - ', payload);
				await axios
					.post(url, payload)
					.then(async (response) => {
						// let paymentIntentId = response.data.paymentIntentId;
						// console.log('done with paying tip amount, paymentIntentId: ', paymentIntentId);

						//get latest ride details
						let ride = await getLatestRideDetailsForTraveler(firestore, projectId);

						if (ride === 'BadData') {
							dispatch({ type: 'RETRIEVED_RIDE_DETAILS', rideDetails: 'BadData' });
						} else {
							dispatch({ type: 'SUCCESSFULLY_PAID_OPERATOR_TIP_AMOUNT', updatedProject: ride });
							return;
						}
					})
					.catch((err) => {
						// console.log('tip amount payment failed', err);
						dispatch({
							type: 'ERROR_PAYING_TIP_AMOUNT',
							errorMsg: 'There was an error during processing the tip amount '
						});
						return;
					});
			})
			.catch((err) => {
				dispatch({
					type: 'ERROR_PAYING_TIP_AMOUNT',
					errorMsg: 'There was an error during processing the tip amount '
				});
				return;
			});
	};
};

export const getTravelerFilteredRides = (data) => {
	return (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getTravelerFilteredRides for: ', data)
		const firestore = getFirestore();
		let requestedProjects = [];

		if (!moment(data.fromDate).isSame(moment(data.toDate))) {
			// console.log('1', data.fromDate, data.toDate);
			return firestore
				.collection('projects')
				.where('authorId', '==', data.uid)
				.where('serviceDate', '>=', new Date(data.fromDate.toString()))
				.where('serviceDate', '<=', new Date(data.toDate.toString()))
				.orderBy('serviceDate', 'asc')
				.get()
				.then((snapshot) => {
					if (snapshot.empty) {
						// console.log('getRequestedRides - No matching documents.');
						dispatch({
							type: 'TRAVELER_FILTERED_RIDES_FETCHED_SUCCESSFULLY',
							travelerFilteredProjects: requestedProjects
						});
					} else {
						// console.log('Getting requested rides', snapshot.docs);
						const promises = [];
						snapshot.forEach((doc) => {
							let tmp = doc.data();
							tmp.id = doc.id;
							// if (tmp.status === 'CANCELLED') {
							//   return;
							// }
							if (tmp.confirmedByOperatorId) {
								// console.log(doc.id, ' confirmed ride')
								promises.push(
									firestore
										.collection('users')
										.doc(tmp.confirmedByOperatorId)
										.get()
										.then(async (operatorDetails) => {
											if (operatorDetails != null && operatorDetails.data()) {
												tmp.confirmedOperatorName =
													operatorDetails.data().firstName +
													' ' +
													operatorDetails.data().lastName;
												tmp.confirmedOperatorPhoneNumber = operatorDetails.data().phoneNumber;
												tmp.confirmedOperatorProfileImageUrl = operatorDetails.data()
													.profileImageUrl
													? StorageBaseURL +
														encodeURIComponent(
															tmp.confirmedByOperatorId +
																'/profile/thumb_' +
																operatorDetails.data().profileImageUrl
														) +
														altMedia
													: null;
												// console.log(doc.id, ' confirmed operator = ', tmp.confirmedOperatorName, tmp.confirmedOperatorPhoneNumber)
												let confirmedOperatorDate = await firestore
													.collection('projects')
													.doc(doc.id)
													.collection('operatorData')
													.doc(tmp.confirmedByOperatorId)
													.get();
												if (
													confirmedOperatorDate &&
													confirmedOperatorDate.data() &&
													confirmedOperatorDate.data().totalRate
												) {
													tmp.confirmedOperatorRate = `$${confirmedOperatorDate.data()
														.totalRate}`;
												}
												requestedProjects.push(tmp);
											} else {
												tmp.id = doc.id;
												requestedProjects.push(tmp);
											}
										})
								);
							} else {
								// console.log(doc.id, ' non confirmed ride')
								promises.push(requestedProjects.push(tmp));
							}
						});
						Promise.all(promises)
							.then((results) => {
								dispatch({
									type: 'TRAVELER_FILTERED_RIDES_FETCHED_SUCCESSFULLY',
									travelerFilteredProjects: requestedProjects
								});
							})
							.catch((e) => {
								// console.log('error in processing promises - ', e);
								dispatch({
									type: 'ERROR_FETCHING_RIDES',
									errorMsg: 'Error occurred while getting requested rides'
								});
							});
					}
				})
				.catch((err) => {
					// console.log('getRequestedRides - Error occurred - ' + err)
					dispatch({
						type: 'ERROR_FETCHING_RIDES',
						errorMsg: 'Error occurred while getting requested rides'
					});
				});
		} else {
			const toDate = new Date(new Date(data.fromDate).setHours(23, 59, 59, 59));
			return firestore
				.collection('projects')
				.where('authorId', '==', data.uid)
				.where('serviceDate', '>', new Date(data.fromDate.toString()))
				.where('serviceDate', '<', toDate)
				.orderBy('serviceDate', 'asc')
				.get()
				.then((snapshot) => {
					if (snapshot.empty) {
						// console.log('getTravelerFilteredRides - No matching documents.');
						dispatch({
							type: 'TRAVELER_FILTERED_RIDES_FETCHED_SUCCESSFULLY',
							travelerFilteredProjects: requestedProjects
						});
					} else {
						// console.log('Getting requested rides', snapshot.docs);
						const promises = [];
						snapshot.forEach((doc) => {
							let tmp = doc.data();
							tmp.id = doc.id;
							// if (tmp.status === 'CANCELLED') {
							//   return;
							// }
							if (tmp.confirmedByOperatorId) {
								// console.log(doc.id, ' confirmed ride')
								promises.push(
									firestore
										.collection('users')
										.doc(tmp.confirmedByOperatorId)
										.get()
										.then((operatorDetails) => {
											if (operatorDetails != null && operatorDetails.data()) {
												tmp.confirmedOperatorName =
													operatorDetails.data().firstName +
													' ' +
													operatorDetails.data().lastName;
												tmp.confirmedOperatorPhoneNumber = operatorDetails.data().phoneNumber;
												tmp.confirmedOperatorProfileImageUrl = operatorDetails.data()
													.profileImageUrl
													? StorageBaseURL +
														encodeURIComponent(
															tmp.confirmedByOperatorId +
																'/profile/thumb_' +
																operatorDetails.data().profileImageUrl
														) +
														altMedia
													: null;
												// console.log(doc.id, ' confirmed operator = ', tmp.confirmedOperatorName, tmp.confirmedOperatorPhoneNumber)
												requestedProjects.push(tmp);
											} else {
												tmp.id = doc.id;
												requestedProjects.push(tmp);
											}
										})
								);
							} else {
								// console.log(doc.id, ' non confirmed ride')
								promises.push(requestedProjects.push(tmp));
							}
						});
						Promise.all(promises)
							.then((results) => {
								dispatch({
									type: 'TRAVELER_FILTERED_RIDES_FETCHED_SUCCESSFULLY',
									travelerFilteredProjects: requestedProjects
								});
							})
							.catch((e) => {
								// console.log('error in getTravelerFilteredRides - ', e);
								dispatch({
									type: 'ERROR_FETCHING_RIDES',
									errorMsg: 'Error occurred while getting requested rides'
								});
							});
					}
				})
				.catch((err) => {
					// console.log('getRequestedRides - Error occurred - ' + err)
					dispatch({
						type: 'ERROR_FETCHING_RIDES',
						errorMsg: 'Error occurred while getting requested rides'
					});
				});
		}
	};
};

export const getTravelerRidesToday = (data) => {
	return (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getTravelerRidesToday for: ', data)
		const firestore = getFirestore();
		let requestedProjects = [];
		if (data.uid) {
			return firestore
				.collection('projects')
				.where('authorId', '==', data.uid)
				.where('serviceDate', '>=', new Date(new Date().setHours(0, 0, 0, 0)))
				.where('serviceDate', '<', new Date(new Date().setHours(23, 59, 59, 0)))
				.orderBy('serviceDate', 'asc')
				.get()
				.then((snapshot) => {
					if (snapshot.empty) {
						// console.log('getRequestedRides - No matching documents.');
						dispatch({
							type: 'TRAVELER_FILTERED_RIDES_TODAY_FETCHED_SUCCESSFULLY',
							travelerRidesToday: requestedProjects
						});
					} else {
						// console.log('Getting requested rides', snapshot.docs);
						const promises = [];
						snapshot.forEach((doc) => {
							let tmp = doc.data();
							tmp.id = doc.id;
							// if (tmp.status === 'CANCELLED') {
							//   return;
							// }
							if (tmp.confirmedByOperatorId) {
								// console.log(doc.id, ' confirmed ride')
								promises.push(
									firestore
										.collection('users')
										.doc(tmp.confirmedByOperatorId)
										.get()
										.then(async (operatorDetails) => {
											if (operatorDetails != null && operatorDetails.data()) {
												tmp.confirmedOperatorName =
													operatorDetails.data().firstName +
													' ' +
													operatorDetails.data().lastName;
												tmp.confirmedOperatorPhoneNumber = operatorDetails.data().phoneNumber;
												tmp.confirmedOperatorProfileImageUrl = operatorDetails.data()
													.profileImageUrl
													? StorageBaseURL +
														encodeURIComponent(
															tmp.confirmedByOperatorId +
																'/profile/thumb_' +
																operatorDetails.data().profileImageUrl
														) +
														altMedia
													: null;
												// console.log(doc.id, ' confirmed operator = ', tmp.confirmedOperatorName, tmp.confirmedOperatorPhoneNumber)
												let confirmedOperatorDate = await firestore
													.collection('projects')
													.doc(doc.id)
													.collection('operatorData')
													.doc(tmp.confirmedByOperatorId)
													.get();
												if (
													confirmedOperatorDate &&
													confirmedOperatorDate.data() &&
													confirmedOperatorDate.data().totalRate
												) {
													tmp.confirmedOperatorRate = `$${confirmedOperatorDate.data()
														.totalRate}`;
												}
												requestedProjects.push(tmp);
											} else {
												tmp.id = doc.id;
												requestedProjects.push(tmp);
											}
										})
								);
							} else {
								// console.log(doc.id, ' non confirmed ride')
								promises.push(requestedProjects.push(tmp));
							}
						});
						Promise.all(promises)
							.then((results) => {
								dispatch({
									type: 'TRAVELER_FILTERED_RIDES_TODAY_FETCHED_SUCCESSFULLY',
									travelerRidesToday: requestedProjects
								});
							})
							.catch((e) => {
								// console.log('error in processing promises - ', e);
								dispatch({
									type: 'ERROR_FETCHING_RIDES',
									errorMsg: 'Error occurred while getting requested rides'
								});
							});
					}
				})
				.catch((err) => {
					// console.log('getRequestedRides - Error occurred - ' + err)
					dispatch({
						type: 'ERROR_FETCHING_RIDES',
						errorMsg: 'Error occurred while getting requested rides'
					});
				});
		} else {
			// console.log('getRequestedRides - No UID');
			dispatch({ type: 'ERROR_FETCHING_RIDES', errorMsg: 'No rides found' });
		}
	};
};

export const getConfirmedOperatorList = (data) => {
	return (dispatch, getState, { getFirestore }) => {
		// console.log('inside project actions - getConfirmedOperatorList for: ', data);
		const firestore = getFirestore();
		let confirmedOperatorList = [];
		if (data.uid) {
			return firestore
				.collection('users')
				.doc(data.uid)
				.get()
				.then((travelerData) => {
					var travelerDetails = travelerData.data();
					console.log('travelerDetails', travelerDetails);
					if (travelerDetails.confirmedOperatorList) {
						console.log('travelerDetails.confirmedOperatorList', travelerDetails.confirmedOperatorList);
						let promises = [];
						travelerDetails.confirmedOperatorList.forEach((operatorId) => {
							promises.push(
								firestore.collection('users').doc(operatorId).get().then((operatorData) => {
									let operatorDetails = operatorData.data();
									console.log(operatorId, 'operatorDetails', operatorDetails);
									let operatorName = operatorDetails.firstName + ' ' + operatorDetails.lastName;
									console.log('----', new Option(operatorName, operatorId));
									confirmedOperatorList.push(new Option(operatorName, operatorId));
								})
							);
						});
						Promise.all(promises)
							.then((results) => {
								console.log('operatorList', confirmedOperatorList);
								dispatch({ type: 'FETCH_CONFIRMED_OPR_LIST', confirmedOperatorList });
							})
							.catch((e) => {
								console.log('error in processing promises - ', e);
								dispatch({ type: 'FETCH_CONFIRMED_OPR_LIST', confirmedOperatorList });
							});
					} else {
						console.log('no operators');
						dispatch({ type: 'FETCH_CONFIRMED_OPR_LIST', confirmedOperatorList });
					}
				})
				.catch((err) => {
					console.log('getConfirmedOperatorList - Error occurred - ' + err);
					dispatch({ type: 'FETCH_CONFIRMED_OPR_LIST', confirmedOperatorList });
				});
		} else {
			// console.log('getConfirmedOperatorList - No UID');
			dispatch({ type: 'FETCH_CONFIRMED_OPR_LIST', confirmedOperatorList });
		}
	};
};

export const resendRideAvailableNotifications = (inputObj) => {
	return async (dispatch, getState, { getFirestore }) => {
		const firestore = getFirestore();
		// console.log('resendRideAvailableNotifications ', inputObj);
		var projectDetails = await firestore.collection('projects').doc(inputObj.projectId).get();
		if (projectDetails.exists) {
			let url = baseURL + '/manuallySendNewRideMsgToOperators';
			let data = {
				rideId: inputObj.projectId
			};
      // console.log('data', data, url)
			return axios.post(url, data).then((response) => {
				if (response.data.success === false) {
					let action = 'Resent ride available notifications to all operators.';
					updateHistory(firestore, inputObj.projectId, projectDetails.data().authorId, action);
					dispatch({ type: 'RESENT_RIDE_CREATED_NOTIFICATION_SUCCESSFULLY' });
				} else {
					dispatch({ type: 'RESENT_RIDE_CREATED_NOTIFICATION_FAILED' });
				}
			});
		} else {
			dispatch({ type: 'RESENT_RIDE_CREATED_NOTIFICATION_FAILED' });
		}
	};
};
