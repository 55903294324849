const getRideStatusBackground = (status) => {
    switch (status) {
        case 'PENDING': return 'gray-background'
        case 'ACCEPTED': return 'yellow-background'
        case 'CONFIRMED': return 'blue-background'
        case 'COMPLETED': return 'green-background'
        case 'CANCELLED': return 'red-background'
        default: return 'blue-background'
    }
}

export default getRideStatusBackground