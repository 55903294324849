import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import StarIcon from '@material-ui/icons/Star';
import PersonIcon from '@material-ui/icons/Person';
import moment from 'moment'

const RideChatTimeline = (props) => {

  if (props.chats) {
    const values = Object.values(props.chats);
    if (values.length > 0) {
      let verticalTimelineElements = values.map(value => {
        let icon = <PersonIcon />;
        let iconStyle = null;
        let position = 'left'
        if (value.userId === props.uid) {
          iconStyle = { background: 'rgb(16, 204, 82)', color: '#fff' };
          position = 'left'
        } else {
          iconStyle = { background: 'rgb(33, 150, 243)', color: 'fff' };
          position = 'right';
        }

        return (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date={moment(value.createdAt.seconds * 1000).format("MMM Do YYYY, h:mm:ss a")}
            key={value.createdAt.seconds + value.msg}
            iconStyle={iconStyle}
            icon={icon}
            position={position}>
            <p>{value.msg}</p>
            <p>- {value.userName}</p>
          </VerticalTimelineElement>
        )
      })
      return (
        <div className="rideTimeline">
          <VerticalTimeline>
            {verticalTimelineElements}
            <VerticalTimelineElement
              iconStyle={{ background: 'black', color: '#fff' }}
              icon={<StarIcon />}
            />
          </VerticalTimeline>
        </div>
      )
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }

}

export default RideChatTimeline