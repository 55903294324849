import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getStripeCustomerPortalLink } from '../../store/actions/authActions';
import moment from 'moment';
import Breadcrumb from "../common/Breadcrumb";
import OperatorPricingTable from './OperatorPricingTable';
import { FiCheck } from "react-icons/fi";
import Loader1 from '../loader/Loader1'
import * as ga from '../util/googleAnalytics'

class Subscription extends Component {

    constructor(props) {
        super(props)
        this.state = this.initialState();
    }

    initialState() {
        return {
            redirect: false,
            loading: false
        }
    }

    componentDidMount = () => {
        ga.pageViewsTracking(window.location.pathname);
    }

    viewStripeSubscriptionPortal = (e) => {
        this.setState({
            loading: true
        }, (() => {
            this.props.getStripeCustomerPortalLink({
                uid: this.props.auth.uid
            }).then(() => {
                window.scrollTo(0, 0);
                this.setState({
                    redirect: true,
                });
            });
        })
        );
    }

    redirect = (url) => {
        window.location = url;
        this.setState({
            loading: false,
        });
    }

    render() {
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        if (!profile.isEmpty && profile.accountType === 'Traveler') {
            return <Redirect to='/404' />
        }

        let loader = null;
        if (this.state.loading === true) {
            loader = <Loader1 />
        }

        if (profile.isEmpty) {
            return (
                <>
                    <Breadcrumb title={'Subscription'} parent='Account Settings' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <p className="description">Loading</p>
                                    <Loader1 />
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else if (!profile.approved) {
            return (
                <>
                    <Breadcrumb title={'Subscription'} parent='Account Settings' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h3 className="description error-text center">Please wait for your account to be reviewed. </h3>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }

        if (this.state.redirect && this.props.stripeCustomerPortalUrl) {
            this.redirect(this.props.stripeCustomerPortalUrl);
        }

        // console.log('1', profile);

        if (profile && profile.accountType === 'Operator') {
            if (profile.stripeSubscribedUser === true) {
                if (profile.stripeSubscriptionActive === true) {
                    //user is currently subscribed and subscription is active
                    return (
                        <>
                            {loader}
                            <Breadcrumb title={'Subscription'} parent='Account Settings' parentLink='/account' />
                            <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                            <h2 className="title">Your subscription is active</h2>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="pricing-body">
                                                        <ul className="list-style--1 center">
                                                            {/* <li>Subscribed on {moment(profile.stripeSubscriptionCreatedAt * 1000).format("MMMM Do YYYY")}</li> */}
                                                            <li><h5><b>Subscription end date: {moment(profile.stripeSubscriptionExpiresAt * 1000).format("MMMM Do YYYY")}</b></h5></li>
                                                            <li>The subscription will auto renew. You can manage your subscription from the stripe subscription dashboard.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 col-md-6 offset-md-3 col-12 mt--30">
                                        <div className="rn-pricing">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-header">
                                                    {/* <h4 className="title">Premium</h4> */}
                                                    <div className="pricing">
                                                        <span className="price">$49</span>
                                                        <span className="subtitle">Per Month</span>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="pricing-body">
                                                            <ul className="list-style--1">
                                                                <li><FiCheck />Accept and fulfill unlimited rides</li>
                                                                <li><FiCheck />Be visible to travelers</li>
                                                                <li><FiCheck />Provide your own rate for rides and extra/additional stops</li>
                                                                <li><FiCheck />Become a preferred operator</li>
                                                                <li><FiCheck />View your rides history</li>
                                                                <li><FiCheck />Modify or cancel subscription anytime</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="pricing-body">
                                                            <ul className="list-style--1">
                                                                <li><FiCheck />Instant quotes</li>
                                                                <li><FiCheck />On-demand and future bookings</li>
                                                                {/* <li><FiCheck />Submit unlimited ride requests without paying ride booking fee</li> */}
                                                                <li><FiCheck />Provide your preferred ride rate</li>
                                                                {/* <li><FiCheck />Select an operator of choice for your ride</li> */}
                                                                <li><FiCheck />Easily manage your passengers for frequent booking</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <button className="rn-btn" onClick={this.viewStripeSubscriptionPortal}>Manage Subscription</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </>
                    )
                } else if (profile.stripeSubscriptionActive === false && profile.stripeSubscriptionExpiresAt > Math.round(Date.now() / 1000)) {
                    // stripe dashboard does not allow to create a subscription, so have to go through the stripe checkout process
                    return (
                        <>
                            {loader}
                            <Breadcrumb title={'Subscription'} parent='Account Settings' parentLink='/account' />
                            <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                            <h4 className="title">You have cancelled your subscription. The subscription is active till {moment(profile.stripeSubscriptionExpiresAt * 1000).format("MMMM Do YYYY")}</h4>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="pricing-body">
                                                        <ul className="list-style--1 center">
                                                            <li>The subscription will not renew automatically. You can manage your subscription from the stripe subscription dashboard.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 col-md-6 offset-md-3 col-12 mt--30">
                                        <div className="rn-pricing">
                                            <div className="pricing-table-inner">
                                                <div className="pricing-header">
                                                    {/* <h4 className="title">Premium</h4> */}
                                                    <div className="pricing">
                                                        <span className="price">$49</span>
                                                        <span className="subtitle">Per Month</span>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="pricing-body">
                                                            <ul className="list-style--1">
                                                                <li><FiCheck />Accept and fulfill unlimited rides</li>
                                                                <li><FiCheck />Be visible to travelers</li>
                                                                <li><FiCheck />Provide your own rate for rides and extra/additional stops</li>
                                                                <li><FiCheck />Become a preferred operator</li>
                                                                <li><FiCheck />View your rides history</li>
                                                                <li><FiCheck />Modify or cancel subscription anytime</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="pricing-body">
                                                            <ul className="list-style--1">
                                                                <li><FiCheck />Instant quotes</li>
                                                                <li><FiCheck />On-demand and future bookings</li>
                                                                {/* <li><FiCheck />Submit unlimited ride requests without paying ride booking fee</li> */}
                                                                <li><FiCheck />Provide your preferred ride rate</li>
                                                                {/* <li><FiCheck />Select an operator of choice for your ride</li> */}
                                                                <li><FiCheck />Easily manage your passengers for frequent booking</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pricing-footer">
                                                    <button className="rn-btn" onClick={this.viewStripeSubscriptionPortal}>Manage Subscription</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </>
                    )
                } else {
                    return (
                        <>
                            <Breadcrumb title={'Subscription'} parent='Account Settings' parentLink='/account' />
                            <OperatorPricingTable />
                        </>
                    );
                }
            } else {
                //not subscribed, show operator plans
                return (
                    <>
                        <Breadcrumb title={'Subscription'} parent='Account Settings' parentLink='/account' />
                        <OperatorPricingTable />
                    </>
                )
            }
        } else {
            //error
            return (
                <>
                    <Breadcrumb title={'Subscription'} parent='Account Settings' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-left mb--50">
                                    <p className="description">An unexpected error has occurred. Please try again.</p>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        stripeCustomerPortalUrl: state.auth.stripeCustomerPortalUrl,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getStripeCustomerPortalLink: (obj) => dispatch(getStripeCustomerPortalLink(obj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
