import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Loader1 from '../loader/Loader1';
import Breadcrumb from "../common/Breadcrumb";
import { HiOutlineBriefcase } from "react-icons/hi";
import { NavLink } from 'react-router-dom'
import { IoCarOutline } from 'react-icons/io5';
import * as ga from '../util/googleAnalytics'

class SignUp extends Component {

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    componentDidMount = () => {
        ga.pageViewsTracking(window.location.pathname);
    }

    render() {
        const { auth, profile } = this.props;
        if (auth.uid) {
            if (profile && !profile.isEmpty) {
                if (profile.accountType === 'Traveler') {
                    return <Redirect to='/home' />
                } else {
                    return <Redirect to='/home' />
                }
            } else {
                return (
                    <div className="container center">
                        <div>Loading</div>
                        <Loader1 />
                    </div>
                )
            }
        }

        return (
            <>
                <Breadcrumb title={'Sign Up'} />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="row service-one-wrapper">

                                    <div className="col-lg-6 order-1 order-lg-1">
                                        <div className="about-inner inner">
                                            <NavLink className="text-center" onClick={this.scrollToTop} to='/signup/operator'>
                                                <div className="service service__style--2">
                                                    <div className="icon">
                                                        <IoCarOutline />
                                                    </div>
                                                    <div className="content mb--50">
                                                        <h3 className="title">Operator / Chauffeur</h3>
                                                        <p>Sign Up as an operator & accept ride requests</p>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 order-2 order-lg-2">
                                        <div className="about-inner inner">
                                            <NavLink className="text-center" onClick={this.scrollToTop} to='/signup/traveler'>
                                                <div className="service service__style--2">
                                                    <div className="icon">
                                                        <HiOutlineBriefcase />
                                                    </div>
                                                    <div className="content mb--50">
                                                        <h3 className="title">Traveler / Requester</h3>
                                                        <p>Sign Up as a traveler & submit ride requests</p>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
