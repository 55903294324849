import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
// import { toggleLoggedInOperatorMode } from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom'

class Breadcrumb extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
    }


    render() {
        const { title, parent, title1, parentLink, loggedInOperatorMode, profile } = this.props;
        let banner = null;
        if (profile && profile.accountType === 'Operator' && loggedInOperatorMode === 'TravelerMode') {
            banner = 'Currently viewing as Traveler mode'
        }

        if (this.state.redirect) {
            return <Redirect to='/home' />
        } else return (
            <React.Fragment>
                <div className="breadcrumb-area rn-bg-color pt--100 pb--50 bg_image bg_image--1" data-black-overlay="6">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-inner">
                                <h2 className="title">{title1 || title}</h2>
                                <ul className="page-list">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    {parentLink ?
                                        <li className="breadcrumb-item"><Link to={parentLink}>{parent}</Link></li>
                                        : parent ?
                                            <li className="breadcrumb-item">{parent}</li>
                                            : ''
                                    }
                                    {title ? <li className="breadcrumb-item active">{title}</li> : ''}
                                </ul>
                                <br/>
                                {<p className="center white font14">{banner}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedInOperatorMode: state.auth.loggedInOperatorMode,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)
