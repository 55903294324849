import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader1 from '../../loader/Loader1'
import SelectPaymentMethodData from './SelectPaymentMethodData';
import { getPaymentMethods } from '../../../store/actions/authActions';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddPaymentMethodInModal from '../addPaymentMethod/AddPaymentMethodInModal';
import M from "materialize-css";

class SelectPaymentMethod extends Component {

    initialState() {
        return {
        }
    }

    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.addNewPaymentMethodBtnClicked = this.addNewPaymentMethodBtnClicked.bind(this);
    }

    componentDidMount() {
        const { uid } = this.props;
        if (uid) {
            this.props.getPaymentMethods({ uid: uid });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.paymentUpdated && prevProps.paymentUpdated !== this.props.paymentUpdated) {
            this.props.getPaymentMethods({ uid: this.props.uid });
            var addNewPaymentMethodModal = document.getElementById('addNewPaymentMethodModal');
            M.Modal.getInstance(addNewPaymentMethodModal).close();
        }
    }

    addNewPaymentMethodBtnClicked = () => {
        var addNewPaymentMethodModal = document.getElementById('addNewPaymentMethodModal');
        M.Modal.init(addNewPaymentMethodModal, {});
        M.Modal.getInstance(addNewPaymentMethodModal).open();
    }

    render() {
        const { rows } = this.props;
        let payment = null;
        const addNewPaymentMethod = (
            <div style={{ textAlign: 'right' }}>
                <label onClick={this.addNewPaymentMethodBtnClicked} className="underline">
                    + Add new payment method
                </label>
            </div>
        );

        if (rows) {
            if (rows.length > 0) {
                payment = (
                    <SelectPaymentMethodData
                        rows={rows}
                        selectedPaymentMethod={this.props.selectedPaymentMethod}
                        handleChange={this.props.handleChange}
                    />
                )
            } else {
                payment = (
                    <>
                        No payment methods found.
                    </>
                )
            }
            return (
                <React.Fragment>
                    {payment}
                    <br />
                    {addNewPaymentMethod}
                    {/* <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <Typography><span style={{color: 'black'}}>Select payment method</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography component={'div'} style={{'width': '100%'}}>
                                {payment}
                                <br />
                                {addNewPaymentMethod}
                            </Typography>
                        </AccordionDetails>
                    </Accordion> */}
                    <div id="addNewPaymentMethodModal" className="modal">
                        <div className="modal-content modalForm">
                            <div>
                                <i className="material-icons modal-close float-right">close</i>
                                <AddPaymentMethodInModal redirectToPath={false} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <div className='center'>
                    <div>Loading. Please wait..</div>
                    <Loader1 />
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        rows: state.auth.rows,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPaymentMethods: (obj) => dispatch(getPaymentMethods(obj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPaymentMethod)
