import React, { Component } from 'react'
import { connect } from 'react-redux'
import USAMap from "react-usa-map";
import './locations.css';
import Breadcrumb from "../common/Breadcrumb";
import { getLocationStates } from '../../store/actions/projectActions'
import Loader1 from '../loader/Loader1';
import * as ga from '../util/googleAnalytics'

class Locations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount() {
        this.props.getLocationStates();
        window.addEventListener("resize", this.updatePredicate);
        ga.pageViewsTracking(window.location.pathname);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ width: window.innerWidth });
    }

    render() {
        let { locations } = this.props;
        if (!locations) {
            return (
                <>
                    <Breadcrumb title={'Locations'} />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12 text-center">
                                <div className="section-title">
                                    <p className="description">Loading</p>
                                    <Loader1 />
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else {
            let mapWidth = this.state.width < 450
                ? 300 : this.state.width < 550
                    ? 400
                    : this.state.width < 650
                        ? 500
                        : this.state.width < 800
                            ? 700
                            : this.state.width < 1000
                                ? 750
                                : 1000;
            return (
                <>
                    <Breadcrumb title={'Locations'} />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12 text-center">
                                <div className="section-title">
                                    <p className="description">FINDANIO service is currently available in the highlighted states.</p>
                                    <USAMap
                                        title="FINDANIO locations"
                                        customize={locations}
                                        width={mapWidth}
                                        height={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        locations: state.project.locations
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLocationStates: (creds) => dispatch(getLocationStates(creds))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Locations)
