import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import getRideStatusBackground from './RideStatusBackground';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { IoCarOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { getServiceTypeName } from '../util/rideUtils'
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function RideTableData(props) {
    const rows = props.rows
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(200);
    let history = useHistory();
    const redirectToPage = (path) => {
        history.push(path)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    let sortedRows = null;
    if (props.sortAsc) {
        sortedRows = rows.sort((a, b) => {
            return a.serviceDate - b.serviceDate;
        })
    } else {
        sortedRows = rows.sort((a, b) => {
            return b.serviceDate - a.serviceDate;
        })
    }

    return (
        <Paper className={classes.root}>
            {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <Card className={`${classes.root}`} variant="outlined" key={row.id}>
                    <CardHeader
                        avatar={
                            <Avatar className={getRideStatusBackground(row.status)}>
                                <IoCarOutline />
                            </Avatar>
                        }
                        title={`${getServiceTypeName(row.serviceType)} - ${row.serviceVehicle} - ${row.dateTime}`}
                        subheader={`Recommended Rate: ${row.price} | Ride Code : ${row.rideConfirmationCode}`}
                    />
                    <CardContent>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <b>Status: {row.status}</b>
                                <br />
                                <b>From:</b> {row.from}
                                <br />
                                <b>To:</b> {row.to}
                                <br />
                                {
                                    row.serviceType === 'HourlyCharter' ? null
                                        : <><b>Distance:</b> {row.distance}<br /></>
                                }
                                {
                                    row.serviceType === 'HourlyCharter' ?
                                        <><b>Number of hours:</b> {row.duration}</>
                                        : <><b>Estimated Trip Duration:</b> {row.duration}</>
                                }
                                {
                                    row.passengerName ?
                                        <>
                                            <br />
                                            <b>Passenger Name:</b> {row.passengerName}
                                        </>
                                        : null
                                }
                                {
                                    row.specialNotes ?
                                        <>
                                            <br />
                                            <b>Special Notes:</b> {row.specialNotes}
                                        </>
                                        : null
                                }
                                <br />
                                <br />
                                <div>
                                    <label>
                                        <NavLink to={row.actionLink} className="rn-btn">View Details</NavLink>
                                    </label>
                                </div>
                                {/* <button className="rn-button-style--2 rn-btn-dark-red"
                                    onClick={() => { redirectToPage(row.actionLink) }}>View Details</button> */}
                            </div>
                            <div className="col-md-6 col-12">
                                {
                                    row.confirmedOperatorName ?
                                        <>
                                            <b>Operator Name:</b> {row.confirmedOperatorName}
                                            <br />
                                            <b>Operator Phone Number:</b> <a href={`tel:${row.confirmedOperatorPhoneNumber}`}>{row.confirmedOperatorPhoneNumber}</a>
                                            <br />
                                            <b>Operator Ride Rate:</b> {row.confirmedOperatorRate} 
                            </>
                            : null
                                }
                            {
                                row.confirmedOperatorProfileImageUrl ?
                                    <div className="operator-response-section-photo">
                                        <img src={row.confirmedOperatorProfileImageUrl || 'http://via.placeholder.com/200x200'} alt="profile" />
                                    </div> : null
                            }
                        </div>
                    </div>
                </CardContent>
                </Card>
    ))
}
<TablePagination
    rowsPerPageOptions={[50, 100, 200, 500]}
    component="div"
    count={rows.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
/>
        </Paper >
    );
}
