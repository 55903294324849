import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Loader1 from '../loader/Loader1'
import { updateUserConnectState, deauthorizeStripeConnectedAccount, clearErrorAction } from '../../store/actions/authActions'
import Breadcrumb from "../common/Breadcrumb";
import { standardActionURL } from '../constants/CommonConstants';
import M from "materialize-css";

class Cashout extends Component {


    constructor(props) {
        super(props)
        this.state = this.initialState()
        this.openDeauthorizationConfirmationModal = this.openDeauthorizationConfirmationModal.bind(this)
        this.confirmDeauthorizeConnectedStripeAccount = this.confirmDeauthorizeConnectedStripeAccount.bind(this)
    }

    initialState() {
        return {
            stripeConnectState: null,
            loading: false
        }
    }

    openDeauthorizationConfirmationModal = () => {
        var elems = document.getElementById('confirmDeauthorizationModal');
        M.Modal.init(elems, {});
        M.Modal.getInstance(elems).open();
    }

    confirmDeauthorizeConnectedStripeAccount = () => {
        this.setState({
            loading: true
        }, (() => {
            this.props.deauthorizeStripeConnectedAccount(this.props.auth.uid)
                .then(() => {
                    var elems = document.getElementById('confirmDeauthorizationModal');
                    M.Modal.init(elems, {});
                    M.Modal.getInstance(elems).close();
                    this.setState({
                        loading: false
                    });
                });
        })
        );
    }

    componentDidMount() {
        this.props.clearError();
        const { auth, profile, errorMsg } = this.props;
        if (!profile.isEmpty && !errorMsg && !profile.stripeConnectedAccount && !profile.stripeConnectState) {
            //user doesn't have stripeConnectState
            if (!this.state.stripeConnectState) {
                let stripeConnectState = Math.random().toString(36).substring(2, 36)
                    + auth.uid.substring(2, 8)
                    + Math.random().toString(36).substring(2, 36)
                    + Math.random().toString(36).substring(2, 36)
                    + auth.uid.substring(auth.uid.length - 3, auth.uid.length);
                let obj = {
                    uid: auth.uid,
                    stripeConnectState: stripeConnectState
                }
                this.props.updateUserConnectState(obj);
                this.setState({
                    stripeConnectState: stripeConnectState
                })
            }
        }
    }

    componentDidUpdate() {
        const { auth, profile, errorMsg } = this.props;
        if (!profile.isEmpty && !errorMsg && !profile.stripeConnectedAccount && !profile.stripeConnectState) {
            //user doesn't have stripeConnectState
            if (!this.state.stripeConnectState) {
                let stripeConnectState = Math.random().toString(36).substring(2, 36)
                    + auth.uid.substring(2, 8)
                    + Math.random().toString(36).substring(2, 36)
                    + Math.random().toString(36).substring(2, 36)
                    + auth.uid.substring(auth.uid.length - 3, auth.uid.length);
                let obj = {
                    uid: auth.uid,
                    stripeConnectState: stripeConnectState
                }
                this.props.updateUserConnectState(obj);
                this.setState({
                    stripeConnectState: stripeConnectState
                })
            }
        }
    }

    componentWillUnmount() {
        this.props.clearError();
    }

    render() {
        const { auth, profile, errorMsg } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        if (!profile.isEmpty && profile.accountType !== 'Operator') {
            return <Redirect to='/404' />
        }

        let loader = null;
        if (this.state.loading === true) {
            loader = <Loader1 />
        }

        if (errorMsg) {
            return (
                <>
                    {loader}
                    <Breadcrumb title={'Cashout'} parent='Account Settings' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--50">
                                    <p className="description error-text">Something went wrong, please try again later.</p>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        } else if (!profile.isEmpty) {
            if (profile.stripeConnectedAccount) {
                return (
                    <>
                        {loader}
                        <Breadcrumb title={'Cashout'} parent='Account Settings' parentLink='/account' />
                        <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                            <div className="row mb--20">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <p className="description">You have successfully linked your Standard Stripe account with FINDANIO. </p>
                                        <p className="description">Click on the icon below to access your Stripe Dashboard.</p>
                                        <a href='https://dashboard.stripe.com/' target="_blank" rel="noopener noreferrer">
                                            <img src="/img/Stripe wordmark - blurple (large).png" alt="Stripe Dashboard" style={{ maxWidth: '200px' }} />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb--20">
                                <div className="col-lg-12 center">
                                    <div className="thumbnail position-relative">
                                        <img className="w-100" src="/img/Stripe - Home.jpg" alt="Stripe Home" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mt--20">
                                        <p className="description">
                                            Click <span className="error-text" style={{ textDecoration: 'underline' }} onClick={this.openDeauthorizationConfirmationModal}>here</span> if you want to revoke FINDANIO's access to your Stripe account.
                                        </p>
                                    </div>
                                    <div id="confirmDeauthorizationModal" className="modal">
                                        <div className="modal-content center modalForm">
                                            <div>
                                                <i className="material-icons modal-close float-right">close</i>
                                                <h5>Please confirm that you want to revoke access by FINDANIO to your connected Standard Stripe account.</h5>
                                                <hr /><br />
                                                <button className="rn-button-style--2 btn-solid" onClick={this.confirmDeauthorizeConnectedStripeAccount}>Confirm</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </main>
                    </>
                )
            } else {
                let state = null;
                if (profile.stripeConnectState) {
                    state = '&state=' + profile.stripeConnectState;
                } else if (this.state.stripeConnectState) {
                    state = '&state=' + this.state.stripeConnectState;
                }
                const response_type = '&response_type=code';
                const scope = '&scope=read_write';
                const userURL = '&stripe_user[email]=' + profile.email;
                const phoneUrl = '&stripe_user[phone_number]=' + profile.phoneNumber;
                const firstName = '&stripe_user[first_name]=' + profile.firstName;
                const lastName = '&stripe_user[last_name]=' + profile.lastName;
                let connectURL = standardActionURL + state + response_type + scope + userURL + phoneUrl + firstName + lastName;
                return (
                    <>
                        {loader}
                        <Breadcrumb title={'Cashout'} parent='Account Settings' parentLink='/account' />
                        <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--50">
                                        <p className="description">FINDANIO partners with Stripe for secure payments.</p>
                                        <br />
                                        <p className="description">Please connect your Standard Stripe Account with FINDANIO to enable cashout.</p>
                                        <br />
                                        <a href={connectURL}>
                                            <img src="/img/Connect with Stripe - blurple 2x.png" alt="Connect with stripe" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                )
            }
        } else {
            return (
                <>
                    <Breadcrumb title={'Cashout'} parent='Account Settings' parentLink='/account' />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--40 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--50">
                                    <p className="description">Loading</p>
                                    <Loader1 />
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
        errorMsg: state.auth.errorMsg
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        updateUserConnectState: (obj) => dispatch(updateUserConnectState(obj)),
        deauthorizeStripeConnectedAccount: (obj) => dispatch(deauthorizeStripeConnectedAccount(obj)),
        clearError: () => dispatch(clearErrorAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cashout)
