import React from 'react'

export const CheckBox = props => {
    return (
        <li>
            <label>
                <input
                    onChange={props.handleCheckInputChanged}
                    type="checkbox"
                    checked={props.isChecked}
                    disabled={props.disabled}
                    className={props.className}
                    value={props.value} /> {props.label}
            </label>
        </li>
    )
}

export default CheckBox