import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import TravelerUpcomingRides from './TravelerUpcomingRides'
import RequestedRidesData from './RequestedRidesData'
import TravelerRideSearch from './TravelerRideSearch'
import PendingApprovalRidesData from './PendingApprovalRidesData'
import Breadcrumb from "../../common/Breadcrumb";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader1 from '../../loader/Loader1'
import * as ga from '../../util/googleAnalytics'

class TravelerRides extends Component {

    componentDidMount = () => {
        ga.pageViewsTracking(window.location.pathname);
    }

    render() {
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/login' />

        // if (!profile.isEmpty && profile.accountType !== 'Traveler') {
        //     return <Redirect to='/404' />
        // }

        if (!profile.isEmpty) {
            return (
                <>
                    <Breadcrumb title='My Reservations' />
                    <main className="pb--40 bg_color--1">
                        <div className="container">
                            <Tabs className="mb--40">
                                <div className="tablist-inner">
                                    <TabList className="pv-tab-button">
                                        <Tab><span>Confirmed rides</span></Tab>
                                        <Tab><span>Pending rides</span></Tab>
                                        <Tab><span>History</span></Tab>
                                        <Tab><span>Search</span></Tab>
                                        <Tab><span>Book new ride</span></Tab>
                                    </TabList>
                                </div>

                                <TabPanel>
                                    <div className="single-tab-content mt--20">
                                        <TravelerUpcomingRides />
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="single-tab-content mt--20">
                                        <PendingApprovalRidesData />
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="single-tab-content mt--20">
                                        <RequestedRidesData />
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="single-tab-content mt--20">
                                        <TravelerRideSearch />
                                    </div>
                                </TabPanel>
                                
                                <TabPanel>
                                    <div className="single-tab-content mt--20">
                                        <Redirect to='/ride/create' />
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </main>
                </>
            )
        } else {
            return (
                <>
                    <Breadcrumb title='My Reservations' />
                    <main className="pb--40 bg_color--1">
                        <div className="container mt--20 mb--20">
                            <p className="description center">Loading</p>
                            <Loader1 />
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(TravelerRides)
