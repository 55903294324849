import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Loader1 from '../../loader/Loader1'
// import ViewPaymentMethodsData from './ViewPaymentMethodsData';
import ViewPaymentMethodsDataCard from './ViewPaymentMethodsDataCard';
import {
    getPaymentMethods, deletePaymentMethod,
    updateDefaultPaymentMethod, clearPaymentMethodError
} from '../../../store/actions/authActions';
import Breadcrumb from "../../common/Breadcrumb";
import M from "materialize-css";

class ViewPaymentMethods extends Component {

    state = {
        refreshed: false,
        loading: false
    }

    componentDidMount() {
        const { auth } = this.props;
        if (auth && auth.uid) {
            this.props.getPaymentMethods({ uid: auth.uid });
        }
    }

    componentDidUpdate() {
        const { auth } = this.props;
        const refreshData = this.props.location
            ? this.props.location.state
                ? this.props.location.state.refresh : null
            : null;
        if (auth && auth.uid && refreshData && !this.state.refreshed) {
            this.props.getPaymentMethods({ uid: auth.uid });
            this.setState({
                refreshed: true
            })
        }
    }

    componentWillUnmount() {
        this.props.clearPaymentMethodError();
    }

    deletePaymentMethodShowModal = (id) => {
        this.setState({
            deletePaymentMethod: id
        }, () => {
            var elems = document.getElementById('deletePaymentMethodModal');
            M.Modal.init(elems, {});
            M.Modal.getInstance(elems).open();
        })
    }

    deletePaymentMethod = () => {
        this.setState({
            loading: true
        }, (() => {
            this.props.deletePaymentMethod({
                id: this.state.deletePaymentMethod,
                uid: this.props.auth.uid
            })
                .then(() => {
                    window.scrollTo(0, 0);
                    this.setState({
                        loading: false,
                        deletePaymentMethod: null
                    });
                });
        })
        );
    }

    updateDefaultPaymentMethodShowModal = (id) => {
        this.setState({
            newDefaultPaymentMethod: id
        }, () => {
            var elems = document.getElementById('updateDefaultPaymentMethodModal');
            M.Modal.init(elems, {});
            M.Modal.getInstance(elems).open();
        })
    }

    updateDefaultPaymentMethod = () => {
        this.setState({
            loading: true
        }, (() => {
            this.props.updateDefaultPaymentMethod({
                id: this.state.newDefaultPaymentMethod,
                uid: this.props.auth.uid
            })
                .then(() => {
                    window.scrollTo(0, 0);
                    this.setState({
                        loading: false,
                        newDefaultPaymentMethod: null
                    });
                });
        })
        );
    }

    render() {
        const { auth, rows, errorMsg } = this.props;
        let loader = null;
        if (this.state.loading === true) {
            loader = <Loader1 />
        }
        if (!auth.uid) return <Redirect to='/login' />
        if (errorMsg) {
            return (
                <>
                    <Breadcrumb title={'Payment Methods'} title1={'Payment Methods'} parent='Payment' parentLink='/payment' />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 ">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mb--50">
                                        <p className="description">Something went wrong. Please try again later.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
        if (rows) {
            if (rows.length > 0) {
                return (
                    <>
                        {loader}
                        <Breadcrumb title={'Payment Methods'} title1={'Payment Methods'} parent='Payment' parentLink='/payment' />
                        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 ">
                            <div className="container">
                                <div className="row row--35 align-items-start">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left mb--20">
                                            <p className="description">Your saved payment methods</p>
                                            <br />
                                        </div>
                                        <ViewPaymentMethodsDataCard
                                            rows={rows}
                                            deletePaymentMethod={this.deletePaymentMethodShowModal}
                                            updateDefaultPaymentMethod={this.updateDefaultPaymentMethodShowModal}
                                        />
                                        <div id="deletePaymentMethodModal" className="modal">
                                            <div className="modal-content center modalForm">
                                                <div className="center">
                                                    <i className="material-icons modal-close float-right">close</i>
                                                    <h5>Are you sure you want to delete this payment method?</h5>
                                                    <hr />
                                                    <button className="rn-button-style--2 btn-solid modal-close"
                                                        onClick={this.deletePaymentMethod}>Yes</button>
                                                    <button className="rn-button-style--2 btn-solid modal-close mt--5 ml--10">No</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="updateDefaultPaymentMethodModal" className="modal">
                                            <div className="modal-content center modalForm">
                                                <div className="center">
                                                    <i className="material-icons modal-close float-right">close</i>
                                                    <h5>Are you sure you want to make this payment method as your default payment method?</h5>
                                                    <hr />
                                                    <button className="rn-button-style--2 btn-solid modal-close"
                                                        onClick={this.updateDefaultPaymentMethod}>Yes</button>
                                                    <button className="rn-button-style--2 btn-solid modal-close mt--5 ml--10">No</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                )
            } else {
                return (
                    <>
                        <Breadcrumb title={'Payment Methods'} title1={'Payment Methods'} parent='Payment' parentLink='/payment' />
                        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 ">
                            <div className="container">
                                <div className="row row--35 align-items-start">
                                    <div className="col-lg-12">
                                        <div className="section-title text-left mb--50">
                                            <p className="description error-text">You have no saved payment methods</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </>
                )
            }
        } else {
            return (
                <>
                    <Breadcrumb title={'Payment Methods'} title1={'Payment Methods'} parent='Payment' parentLink='/payment' />
                    <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 ">
                        <div className="container">
                            <div className="row row--35 align-items-start">
                                <div className="col-lg-12">
                                    <div className="section-title text-left mb--50">
                                        <p className="description">Loading</p>
                                        <Loader1 />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        rows: state.auth.rows,
        errorMsg: state.auth.errorMsg
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPaymentMethods: (obj) => dispatch(getPaymentMethods(obj)),
        deletePaymentMethod: (obj) => dispatch(deletePaymentMethod(obj)),
        updateDefaultPaymentMethod: (obj) => dispatch(updateDefaultPaymentMethod(obj)),
        clearPaymentMethodError: (obj) => dispatch(clearPaymentMethodError(obj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPaymentMethods)
