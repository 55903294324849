import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Loader1 from '../loader/Loader1'
import OperatorHome from './OperatorHome'
import TravelerHome from './TravelerHome'
import Breadcrumb from "../common/Breadcrumb";

class Home extends Component {

    render() {
        const { auth, profile, loggedInOperatorMode } = this.props;
        if (!auth.uid) return <Redirect to='/login' />
        if (!profile.isEmpty && profile.accountType === 'Traveler') {
            return <TravelerHome />
        } else if (!profile.isEmpty && profile.accountType === 'Operator') {
            if (loggedInOperatorMode && loggedInOperatorMode === 'TravelerMode') {
                return <TravelerHome />
            } else {
                return <OperatorHome />
            }
        } else {
            return (
                <>
                    <Breadcrumb title={'Welcome'} />
                    <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--80 bg_color--1">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--50">
                                    <p className="description">Loading</p>
                                    <Loader1 />
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        loggedInOperatorMode: state.auth.loggedInOperatorMode,
        profile: state.firebase.profile
    }
}


export default connect(mapStateToProps)(Home)
