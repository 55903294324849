import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { updatePassword } from '../../store/actions/authActions'
import Breadcrumb from "../common/Breadcrumb";
import ErrorMessage from "../errorMessage/ErrorMessage";
import ConfirmationMessage from "../confirmationMessage/ConfirmationMessage";
import PasswordMask from 'react-password-mask';
import M from "materialize-css";
import Loader1 from '../loader/Loader1'

class UpdatePassword extends Component {

  constructor(props) {
    super(props);

    // Set the initial input values
    this.state = {
      newPassword: '',
      currentPassword: '',
      loading: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openConfirmChangeModal = this.openConfirmChangeModal.bind(this);
  }

  openConfirmChangeModal = () => {
    var elems = document.getElementById('confirmPasswordChangeModal');
    M.Modal.init(elems, {});
    M.Modal.getInstance(elems).open();
  }


  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.updatePassword({
      ...this.state,
      uid: this.props.auth.uid
    }).then(() => {
      var elems = document.getElementById('confirmPasswordChangeModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).close();

      this.setState({
        loading: false,
        newPassword: '',
        currentPassword: ''
      });
    })
  }

  render() {
    const { auth, authError, authSuccess } = this.props;
    if (!auth.uid) return <Redirect to='/login' />
    let loader = null;
    if (this.state.loading === true) {
      loader = <Loader1 />
    }
    return (
      <>
        <Breadcrumb title='Security' parent='Account Settings' parentLink='/account' />
        {loader}
        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 offset-lg-3 col-12">

                <div className="section-title text-center mb--30">
                  <h4>Update your account password</h4>
                </div>

                <div className="mt--20 mb--20">
                  {authError ? <ErrorMessage errorMessageText={authError}></ErrorMessage> : null}
                  {authSuccess ? <ConfirmationMessage message={authSuccess} /> : null}
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <p className="description">Enter your current password<span className="star">*</span></p>
                    <PasswordMask
                      id="currentPassword"
                      name="currentPassword"
                      placeholder="XXXXXXXXXX"
                      value={this.state.currentPassword}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <p className="description">Enter your new password<span className="star">*</span></p>
                    <PasswordMask
                      id="newPassword"
                      name="newPassword"
                      placeholder="XXXXXXXXXX"
                      value={this.state.newPassword}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="row mt--20">
                  <div className="col-lg-12 center">
                    <button
                      disabled={this.state.newPassword === '' || this.state.currentPassword === ''
                        || this.state.newPassword.trim() === '' || this.state.currentPassword.trim() === ''
                      }
                      onClick={this.openConfirmChangeModal} className="rn-button-style--2 btn-solid">Change Password</button>
                  </div>
                </div>
                <div id="confirmPasswordChangeModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h4 className="center">Are you sure you want to change your password?</h4>
                      <button className="rn-button-style--2 btn-solid mt--5" onClick={this.handleSubmit}>Yes</button>
                      <button className="rn-button-style--2 btn-solid modal-close mt--5 ml--10">No</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    authSuccess: state.auth.authSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePassword: (creds) => dispatch(updatePassword(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword)
