import ReactGA from 'react-ga4';

export const pageViewsTracking = (pathName) => {
    // for google analytics
    // console.log('Inside pageViewTracking, ', pathName)
    let pageView;
    if (pathName === "*") {
        pageView = '/not-found';
    } else {
        pageView = pathName;
    }
    ReactGA.send({ hitType: "pageview", page: pageView, title: pageView });
}

export const updateGoogleAnalyticsUser = (userId) => {
    ReactGA.set({ userId: userId });
}