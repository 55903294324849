import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import Breadcrumb from "../common/Breadcrumb";
import { FiCheck, FiUsers, FiLogIn } from "react-icons/fi";
import ModalVideo from 'react-modal-video';
import * as ga from '../util/googleAnalytics'

const namesItemTwo = [
    'Pay less as you no longer need to cover commission fees',
    'No surge pricing on high-demand days, unlike our competitors',
    'Create a list of your favorite/preferred independent operators (IOs)',
    'Submit multiple requests at the same time',
    'Put in a request at any time without having to call around',
    'Convenient access through your mobile device, computer, or tablet',

];

class Travelers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen1: false,
        }
        this.openModal1 = this.openModal1.bind(this)
    }

    openModal1() {
        this.setState({ isOpen1: true })
    }

    linkScrollToTop() {
        window.scrollTo(0, 0);
    }

    componentDidMount = () => {
        ga.pageViewsTracking(window.location.pathname);
    }

    render() {
        const { auth, profile } = this.props;
        if (auth.uid) {
            if (profile && !profile.isEmpty) {
                if (profile.accountType === 'Traveler') {
                    return <Redirect to='/home' />
                } else {
                    return <Redirect to='/home' />
                }
            }
        }

        return (
            <>
                <Breadcrumb title={'Travelers'} />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h2 className="title">Looking for a ride?</h2>
                                    <p>Let FINDANIO be your new chauffeur match-maker!</p>
                                    <p className="description justify">
                                        All you have to do is enter your pick-up and drop-off locations, along with your preferences.
                                        We will then present you with a list of available chauffeurs in your area.
                                        You make your chauffeur selection based on your criteria and needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60">
                            <div className="col-lg-6 order-1 order-lg-2">
                                <h4>Benefits</h4>
                                <ul className="list-style--1">
                                    {namesItemTwo.map((name, index) => {
                                        return <li key={index}><FiCheck /> {name}</li>;
                                    })}
                                </ul>
                            </div>
                            <div className="col-lg-6 order-2 order-lg-2">
                                <div className="thumbnail position-relative">
                                    <img className="w-100" src="/img/iStock-838094110.jpg" alt="Traveler" />
                                    <ModalVideo channel='custom' isOpen={this.state.isOpen1} url="/video/vid5.mp4" onClose={() => this.setState({ isOpen1: false })} />
                                    <button className="video-popup position-top-center theme-color" onClick={this.openModal1}><span className="play-icon"></span></button>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60">
                            <div className="col-lg-2">
                            </div>
                            <div className="col-lg-4 order-2 order-lg-2">
                                <NavLink className="text-center" onClick={this.linkScrollToTop} to='/signup/traveler'>
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            <FiUsers />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">Sign Up</h3>
                                            <p>Create an account and get started</p>
                                            {/* <p>Get an instant quote, choose your operator, and save on corporate travel</p> */}
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                            <div className="col-lg-4 order-2 order-lg-2">
                                <NavLink className="text-center" onClick={this.linkScrollToTop} to='/login'>
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            <FiLogIn />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">Login</h3>
                                            {/* <p>Login now</p> */}
                                        </div>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Travelers)
