import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import {
  acceptRideAction, confirmRideAction, denyRideAction, cancelConfirmedRideAction, declineRideConfirmationAction,
  denyAcceptedRideAction, getRideDetailsForOperator, updateOperatorCurrentRideStatus,
  clearRideDetails, sendChatMessageToTraveler, clearErrorAction
} from '../../../store/actions/projectActions';
import M from "materialize-css";
import { Link } from 'react-router-dom';
import MapWithADirectionsRenderer from "../../maps/MapWithADirectionsRenderer";
import RideChatTimeline from '../RideChatTimeline';
import Breadcrumb from "../../common/Breadcrumb";
import CurrencyInput from 'react-currency-input-field';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from 'react-accessible-accordion';
import Tooltip from '@material-ui/core/Tooltip';
import { IoInformationCircle } from 'react-icons/io5';
import Loader1 from '../../loader/Loader1'
import { getVehicleTypeName, getServiceTypeName } from '../../util/rideUtils';
import ErrorMessage from "../../errorMessage/ErrorMessage"
import AppleMaps from '../apple-maps.svg';
import GoogleMaps from '../google-maps.svg';
import ConfirmationMessage from '../../confirmationMessage/ConfirmationMessage';

class OperatorRideDetails extends Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.specialNotesInput = React.createRef();
    this.extraStopsNotesInput = React.createRef();
    this.acceptRideBtnClicked = this.acceptRideBtnClicked.bind(this);
    this.denyRideBtnClicked = this.denyRideBtnClicked.bind(this);
    this.confirmRideBtnClicked = this.confirmRideBtnClicked.bind(this);
    this.declineRideConfirmationBtnClicked = this.declineRideConfirmationBtnClicked.bind(this);
    this.cancelConfirmedRideBtnClicked = this.cancelConfirmedRideBtnClicked.bind(this);
    this.handleAdditionalChargeChanges = this.handleAdditionalChargeChanges.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleExtraStopsNotes = this.handleExtraStopsNotes.bind(this);
    this.handleExtraStopsCharges = this.handleExtraStopsCharges.bind(this);
    this.sendChatMessage = this.sendChatMessage.bind(this);
    this.handleRideStatusChange = this.handleRideStatusChange.bind(this);
    this.updateOperatorCurrentRideStatusBtnClicked = this.updateOperatorCurrentRideStatusBtnClicked.bind(this);
    this.updateOperatorCurrentRideStatus = this.updateOperatorCurrentRideStatus.bind(this);
    this.getRideStatusByCode = this.getRideStatusByCode.bind(this);
  }

  initialState() {
    return {
      categoryId: 'rideDetailsViewOption',
      projectId: '',
      additionalChargesTolls: 0.00,
      additionalChargesParking: 0.00,
      additionalChargesAirportFee: 0.00,
      additionalChargesMisc: 0.00,
      totalRate: '',
      specialNotes: '',
      extraStopsNotes: '',
      extraStopsCharges: "0.00",
      totalRateWithStops: '',
      newChatMessage: '',
      currentRideStatus: '',
      loading: false
    }
  }

  componentDidMount() {
    let charCounter = document.querySelectorAll('.has-character-counter');
    M.CharacterCounter.init(charCounter);

    if (this.props.projectId) {
      this.props.getRideDetails({ projectId: this.props.projectId, operatorId: this.props.auth.uid });
    }
    this.props.clearError();
  }

  componentDidUpdate() {
    if (this.props.project && this.props.auth && this.props.auth.uid) {
      const price =  this.props.project.calculatedPrice;
      if (this.state.totalRate === '' || this.state.totalRateWithStops === '') {
        let operatorData = (this.props.project && this.props.project.operatorData
          && this.props.project.operatorData[this.props.auth.uid]) ? this.props.project.operatorData[this.props.auth.uid] : null;
        if (operatorData) {
          this.setState({
            totalRate: operatorData.totalRate,
            totalRateWithStops: operatorData.totalRateWithStops || operatorData.totalRate
          })
        } else {
          this.setState({
            totalRate: price,
            totalRateWithStops: price
          })
        }
      }
    }
  }

  handleAdditionalChargeChanges = (category, value) => {
    let temp = value ? (+value).toFixed(2) : 0.00;
    let additionalChargesTolls = this.state.additionalChargesTolls;
    let additionalChargesParking = this.state.additionalChargesParking;
    let additionalChargesAirportFee = this.state.additionalChargesAirportFee;
    let additionalChargesMisc = this.state.additionalChargesMisc;
    let price = +this.props.project.calculatedPrice;
    switch (category) {
      case 'additionalChargesTolls':
        this.setState({
          additionalChargesTolls: +temp,
          totalRate: +price + +temp + +additionalChargesParking + +additionalChargesAirportFee + +additionalChargesMisc
        });
        break;
      case 'additionalChargesParking':
        this.setState({
          additionalChargesParking: +temp,
          totalRate: +price + additionalChargesTolls + +temp + additionalChargesAirportFee + +additionalChargesMisc
        });
        break;
      case 'additionalChargesAirportFee':
        this.setState({
          additionalChargesAirportFee: +temp,
          totalRate: +price + +additionalChargesTolls + +additionalChargesParking + +temp + additionalChargesMisc
        });
        break;
      case 'additionalChargesMisc':
        this.setState({
          additionalChargesMisc: +temp,
          totalRate: +price + +additionalChargesTolls + additionalChargesParking + additionalChargesAirportFee + +temp
        });
        break;
      default: break;
    }
  }

  handleNotesChange = (e) => {
    this.setState({
      specialNotes: e.target.value,
    }, () => {
      this.specialNotesInput.current.focus();
    })
  }

  getRideStatusByCode = (rideStatusCode) => {
    let rideStatusText = '';
    switch (rideStatusCode) {
      case 'OPERATOR_ON_WAY_TO_PICKUP_LOCATION': rideStatusText = 'ON THE WAY TO PICK UP LOCATION'; break;
      case 'OPERATOR_ON_LOCATION': rideStatusText = 'AT PICK UP LOCATION'; break;
      case 'PASSENGER_ON_BOARD': rideStatusText = 'RIDE STARTED - PASSENGER ON BOARD'; break;
      case 'ADD_EXTRA_STOPS': rideStatusText = 'RIDE STARTED - EXTRA CHARGES PENDING APPROVAL'; break;
      case 'RIDE_ENDED': rideStatusText = 'RIDE ENDED'; break;
      default: break;
    }
    return rideStatusText;
  }

  denyRideBtnClicked = () => {
    this.setState({
      projectId: this.props.projectId,
      deniedByOperatorId: this.props.auth.uid
    }, () => {
      //display modal to deny
      var elems = document.getElementById('denyRideModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  agreeToDenyRideBtnClicked = () => {
    this.props.denyRideAction(this.state);
  }

  acceptRideBtnClicked = () => {
    this.setState({
      projectId: this.props.projectId,
      acceptedByOperatorId: this.props.auth.uid
    }, () => {
      let profile = this.props.profile;
      if (profile && !profile.approved) {
        //show profile not approved modal
        var approvalRequiredModal = document.getElementById('approvalRequiredModal');
        M.Modal.init(approvalRequiredModal, {});
        M.Modal.getInstance(approvalRequiredModal).open();
      } else if (profile && (!profile.carPlate || !profile.carYear || !profile.vehicleImageUrl || !profile.profileImageUrl
        || !profile.vehicleVIN || !profile.insuranceImageUrl || !profile.vehicleRegistrationImageUrl || !profile.cancellationPolicy
        || !profile.liabilityInsuranceAmt || !profile.carColor)) {
        //show incomplete profile modal
        var profileIncompletModal = document.getElementById('profileIncompletModal');
        M.Modal.init(profileIncompletModal, {});
        M.Modal.getInstance(profileIncompletModal).open();
      } else if (profile && !profile.stripeConnectedAccount) {
        //show cashout modal
        var cashoutModal = document.getElementById('cashoutModal');
        M.Modal.init(cashoutModal, {});
        M.Modal.getInstance(cashoutModal).open();
      } else if (profile && (!profile.stripeSubscribedUser || profile.stripeSubscribedUser === false)) {
        // operator hasn't subscribed yet - display subscribe plan modal
        var subscribePlanModal = document.getElementById('subscribePlanModal');
        M.Modal.init(subscribePlanModal, {});
        M.Modal.getInstance(subscribePlanModal).open();
      } else if (profile && (profile.stripeSubscribedUser === true && profile.stripeSubscriptionActive === false && profile.stripeSubscriptionExpiresAt < Math.round(Date.now() / 1000))) {
        // operator subscription is expired - display subscribe plan modal
        var subscribePlanModal2 = document.getElementById('subscribePlanModal');
        M.Modal.init(subscribePlanModal2, {});
        M.Modal.getInstance(subscribePlanModal2).open();
      } else {
        //display modal to accept
        var acceptRideModal = document.getElementById('acceptRideModal');
        M.Modal.init(acceptRideModal, {});
        M.Modal.getInstance(acceptRideModal).open();
      }
    })
  }

  agreeAcceptRideBtnClicked = () => {
    this.props.acceptRideAction(this.state);
  }

  denyAcceptedRideBtnClicked = () => {
    this.setState({
      projectId: this.props.projectId,
      deniedAcceptedRideByOperatorId: this.props.auth.uid
    }, () => {
      //display modal to accept
      var elems = document.getElementById('denyAcceptedRideModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  agreeToDenyAcceptedRideBtnClicked = () => {
    this.props.denyAcceptedRideAction(this.state);
  }

  confirmRideBtnClicked = () => {
    this.setState({
      projectId: this.props.projectId,
      confirmedByOperatorId: this.props.auth.uid
    }, () => {
      //display modal to confirm ride
      var elems = document.getElementById('confirmRideModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  agreeToConfirmRideBtnClicked = () => {
    this.props.confirmRideAction(this.state);
  }

  declineRideConfirmationBtnClicked = () => {
    this.setState({
      projectId: this.props.projectId,
      declinedByOperatorId: this.props.auth.uid
    }, () => {
      //display modal to accept
      var elems = document.getElementById('declineRideConfirmationModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  agreeToDeclineRideConfirmationBtnClicked = () => {
    this.props.declineRideConfirmationAction(this.state);
  }

  cancelConfirmedRideBtnClicked = () => {
    this.setState({
      projectId: this.props.projectId,
      cancelConfirmedRideByOperatorId: this.props.auth.uid
    }, () => {
      var elems = document.getElementById('cancelConfirmedRideModal');
      M.Modal.init(elems, {});
      M.Modal.getInstance(elems).open();
    })
  }

  agreeToCancelConfirmedRideBtnClicked = () => {
    this.props.cancelConfirmedRideAction(this.state);
  }

  updateOperatorCurrentRideStatusBtnClicked = () => {
    var elems = document.getElementById('updateOperatorCurrentRideStatusConfirmModal');
    M.Modal.init(elems, {});
    M.Modal.getInstance(elems).open();
  }

  updateOperatorCurrentRideStatus = (value) => {
    this.setState({
      loading: true
    }, (() => {
      this.props.updateOperatorCurrentRideStatusAction({
        ...this.state,
        projectId: this.props.projectId,
        operatorId: this.props.auth.uid,
        currentRideStatus: this.state.currentRideStatus
      })
        .then(() => {
          window.scrollTo(0, 0);
          this.setState({
            loading: false,
            extraStopsNotes: '',
            extraStopsCharges: "0.00",
          });
        });
    })
    );
  }

  handleExtraStopsCharges = (event, value) => {
    let { project, auth } = this.props;
    let operatorData = (project && project.operatorData && project.operatorData[auth.uid]) ? project.operatorData[auth.uid] : null;
    let price = operatorData && operatorData.totalRate ? operatorData.totalRate : project.calculatedPrice;
    this.setState({
      extraStopsCharges: (+value).toFixed(2),
      totalRateWithStops: ((+price) + (+value)).toFixed(2)
    })
  }

  handleExtraStopsNotes = (e) => {
    this.setState({
      extraStopsNotes: e.target.value,
    }, () => {
      this.extraStopsNotesInput.current.focus();
    })
  }

  // addExtraStopBtnClicked = () => {
  //   this.props.addExtraStopAction({
  //     extraStopsNotes: this.state.extraStopsNotes,
  //     extraStopsCharges: this.state.extraStopsCharges,
  //     projectId: this.props.projectId,
  //     operatorId: this.props.auth.uid
  //   }).then(() => {
  //     this.setState({
  //       extraStopsNotes: '',
  //       extraStopsCharges: 0.00
  //     })
  //   })
  // }

  sendChatMessage = (msg) => {
    this.setState({
      newChatMessage: '',
    }, () => {
      this.props.sendChatMessage({
        projectId: this.props.projectId,
        msg: msg,
        userId: this.props.auth.uid,
        userName: this.props.profile.firstName,
        phone: this.props.profile.phoneNumber,
      })
    })
  }

  componentWillUnmount() {
    this.props.clearRideDetails();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleRideStatusChange = (currentRideStatus) => {
    // console.log('handleRideStatusChange = ', currentRideStatus);
    this.setState({
      currentRideStatus: currentRideStatus
    })
  }

  render() {
    const { project, auth, projectId, profile, errorMsg, successMsg } = this.props;
    let rideStatus = null;
    let loader = null;
    if (this.state.loading === true) {
      loader = <Loader1 />
    }
    if (!auth.uid) return <Redirect to='/login' />
    if (project && project === 'badData') {
      return (
        <Redirect to='/404' />
      )
    } else if (project && profile) {
      if (profile.accountType === 'Traveler') {
        //traveler is trying to access another ride, do not allow
        return <Redirect to='/404' />
      } else {
        // operator is accessing the ride, do some more checks
        if (project === 'BadData') {
          return (
            <Redirect to='/404' />
          )
        } else if (project.authorId === auth.uid) {
          // operator created the ride - redirect them to the traveler ride details page
          let redirectPath = '/ride/requested/' + projectId;
          return (
            <Redirect to={redirectPath} />
          )
        } else {
          const rideConfirmationCode = project.rideConfirmationCode;

          let rideCancellation = (project && project.status && project.status === 'CANCELLED')
            ? profile.cancelledConfirmedRides && profile.cancelledConfirmedRides.includes(projectId)
              ? (<>
                <h3><p className="red-background1 white text-center"><b>Cancelled</b></p></h3>
                <div className="mainFont error-text">You cancelled this ride after confirming it.</div>
              </>)
              : (<>
                <h3><p className="red-background1 white text-center"><b>Cancelled</b></p></h3>
                <div className="mainFont error-text">This ride has been cancelled by the traveler.</div>
              </>)
            : null;

          let operatorData = (project && project.operatorData && project.operatorData[auth.uid]) ? project.operatorData[auth.uid] : null;
          let updateRideStatusDisabled2PriorPickup = moment(project.serviceDate.toDate()).subtract(4, 'hours').toDate() > moment().toDate()
          // let updateRideStatusDisabled2PostPickup = moment(project.serviceDate.toDate()).add(2, 'hours').toDate() < moment().toDate()
          let chatWithTraveler = (
            <>
              <div className="row">
                <div className="col-lg-6">
                  <div>
                    <label htmlFor="newChatMessage">Enter your message</label>
                    <textarea id="newChatMessage" className="materialize-textarea has-character-counter"
                      value={this.state.newChatMessage}
                      onChange={this.handleChange}
                      data-length="500"
                      maxLength="500"></textarea>
                  </div>
                  <div className="mt--20">
                    <button className="rn-button-style--2 btn-solid"
                      disabled={this.state.newChatMessage === '' || this.state.newChatMessage.trim() === ''}
                      onClick={() => { this.sendChatMessage(this.state.newChatMessage) }}>Send</button>
                  </div>
                </div>
              </div>
              <div className="row mt--20">
                <div className="col-lg-8">
                  <RideChatTimeline
                    uid={this.props.auth.uid}
                    chats={project.chats} />
                </div>
              </div>
            </>
          );
          let extraStopSection = (
            <>
              {
                operatorData && operatorData.extraStops ?
                  Object.entries(operatorData.extraStops).map(([key, extraStop]) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>${extraStop.extraStopsCharges}</TableCell>
                        <TableCell>{extraStop.extraStopsNotes}</TableCell>
                        <TableCell>{extraStop.status}</TableCell>
                      </TableRow>
                    )
                  })
                  :
                  <TableRow>
                    <TableCell>No extra charges added yet</TableCell>
                  </TableRow>
              }
            </>);

          let rideRateSection = project && project.acceptedBy && (project.acceptedBy.includes(auth.uid))
            ? (
              <div className="row mb--20">
                <div className="col-lg-12">
                  <h5>Additional charges:</h5>
                  <Table aria-label="additional charges table">
                    <TableBody>
                      <TableRow>
                        <TableCell>Ride Rate</TableCell>
                        <TableCell>{project.calculatedPrice ? (project.calculatedPrice === '-1.00' ? 'N/A' : '$' + project.calculatedPrice) : 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tolls</TableCell>
                        <TableCell>${operatorData && operatorData.additionalChargesTolls ? operatorData.additionalChargesTolls : '0.00'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Parking</TableCell>
                        <TableCell>${operatorData && operatorData.additionalChargesParking ? operatorData.additionalChargesParking : '0.00'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Airport Fee</TableCell>
                        <TableCell>${operatorData && operatorData.additionalChargesAirportFee ? operatorData.additionalChargesAirportFee : '0.00'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Other Charges</TableCell>
                        <TableCell>${operatorData && operatorData.additionalChargesMisc ? operatorData.additionalChargesMisc : '0.00'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Special Notes:</TableCell>
                        <TableCell className="breakAll">{operatorData && operatorData.specialNotes ? operatorData.specialNotes : '-'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><b>Total Ride Rate</b></TableCell>
                        <TableCell><b className="red-text">${operatorData && operatorData.totalRate ? operatorData.totalRate : project.calculatedPrice}</b></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            )
            : (
              <div className="row mb--20">
                <div className="col-lg-12">
                  <h5>Additional charges:</h5>
                  <Table aria-label="additional charges table">
                    <TableBody>
                      <TableRow>
                        <TableCell>Ride Rate</TableCell>
                        <TableCell>{project.calculatedPrice ? (project.calculatedPrice === '-1.00' ? 'N/A' : '$' + project.calculatedPrice) : 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tolls</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Parking</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Airport Fee</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Other Charges</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Special Notes:</TableCell>
                        <TableCell>-</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><b>Total Ride Rate</b></TableCell>
                        <TableCell>{project.calculatedPrice ? (project.calculatedPrice === '-1.00' ? 'N/A' : '$' + project.calculatedPrice) : 'N/A'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            );

          const replyToRideRequest = (
            (project && project.acceptedBy && (project.acceptedBy.includes(auth.uid)))
              ? (project && project.cancelledConfirmedRideBy && project.cancelledConfirmedRideBy.includes(auth.uid))
                ? (
                  //current operator cancelled the ride after confirming
                  <div className="row">
                    <div className="col-lg-12 ">
                      <div className="red-background ptb--10 mb--10 center">You declined this ride after confirming it.</div>
                    </div>
                  </div>
                )
                : (project && project.declinedBy && project.declinedBy.includes(auth.uid))
                  ? (
                    //current operator denied before confirming the ride
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="red-background ptb--10 mb--10 center">You declined this ride after accepting.</div>
                      </div>
                    </div>
                  )

                  : (project && project.unselectedOperators && project.unselectedOperators.includes(auth.uid))
                    ? (
                      //current operator is unselected by the operator
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="red-background ptb--10 mb--10 center">You have been unselected for this ride by the traveler.</div>
                        </div>
                      </div>
                    )
                    : (project && project.confirmedByOperatorId && project.confirmedByOperatorId === auth.uid)
                      ? (
                        //current operator has confirmed the ride
                        <React.Fragment>

                          <div className="row mb--10 ptb--20">
                            <div className="col-12">
                              <div className="green-background text-center ptb--5"><b>
                                {project.currentRideStatus ? this.getRideStatusByCode(project.currentRideStatus) : 'RIDE CONFIRMED'}
                              </b></div>
                            </div>
                          </div>

                          {project.currentRideStatus !== 'RIDE_ENDED' ?
                            <>
                              <div className="row mb--20">
                                <div className="col-12">
                                  <Accordion className="accodion-style--1">
                                    <AccordionItem
                                      disabled={updateRideStatusDisabled2PriorPickup || project.currentRideStatus === 'OPERATOR_ON_WAY_TO_PICKUP_LOCATION' || project.currentRideStatus === 'OPERATOR_ON_LOCATION' || project.currentRideStatus === 'PASSENGER_ON_BOARD' || project.currentRideStatus === 'ADD_EXTRA_STOPS' || project.currentRideStatus === 'RIDE_ENDED'}>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          <input id="OPERATOR_ON_WAY_TO_PICKUP_LOCATION" name="updateRideStatusAction" type="radio" value="OPERATOR_ON_WAY_TO_PICKUP_LOCATION"
                                            checked={this.state.currentRideStatus === "OPERATOR_ON_WAY_TO_PICKUP_LOCATION"}
                                            disabled={updateRideStatusDisabled2PriorPickup || project.currentRideStatus === 'OPERATOR_ON_WAY_TO_PICKUP_LOCATION' || project.currentRideStatus === 'OPERATOR_ON_LOCATION' || project.currentRideStatus === 'PASSENGER_ON_BOARD' || project.currentRideStatus === 'ADD_EXTRA_STOPS' || project.currentRideStatus === 'RIDE_ENDED'}
                                            onChange={() => { this.handleRideStatusChange("OPERATOR_ON_WAY_TO_PICKUP_LOCATION") }} />
                                          <label htmlFor="OPERATOR_ON_WAY_TO_PICKUP_LOCATION">On the way to ride pick up location</label>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <p>
                                          This indicates that you are on the way to the ride pick up location.
                                        </p>
                                      </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem
                                      disabled={updateRideStatusDisabled2PriorPickup || !project.currentRideStatus || project.currentRideStatus === 'OPERATOR_ON_LOCATION' || project.currentRideStatus === 'PASSENGER_ON_BOARD' || project.currentRideStatus === 'ADD_EXTRA_STOPS' || project.currentRideStatus === 'RIDE_ENDED'}>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          <input id="OPERATOR_ON_LOCATION" name="updateRideStatusAction" type="radio" value="OPERATOR_ON_LOCATION"
                                            checked={this.state.currentRideStatus === "OPERATOR_ON_LOCATION"}
                                            disabled={updateRideStatusDisabled2PriorPickup || !project.currentRideStatus || project.currentRideStatus === 'OPERATOR_ON_LOCATION' || project.currentRideStatus === 'PASSENGER_ON_BOARD' || project.currentRideStatus === 'ADD_EXTRA_STOPS' || project.currentRideStatus === 'RIDE_ENDED'}
                                            onChange={() => { this.handleRideStatusChange("OPERATOR_ON_LOCATION") }} />
                                          <label htmlFor="OPERATOR_ON_LOCATION">At pick up location</label>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <p>
                                          This indicates that you are at the pick up location.
                                        </p>
                                      </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem
                                      disabled={updateRideStatusDisabled2PriorPickup || !project.currentRideStatus || project.currentRideStatus === 'PASSENGER_ON_BOARD' || project.currentRideStatus === 'ADD_EXTRA_STOPS' || project.currentRideStatus === 'RIDE_ENDED'}>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          <input id="PASSENGER_ON_BOARD" name="updateRideStatusAction" type="radio" value="PASSENGER_ON_BOARD"
                                            checked={this.state.currentRideStatus === "PASSENGER_ON_BOARD"}
                                            disabled={updateRideStatusDisabled2PriorPickup || !project.currentRideStatus || project.currentRideStatus === 'PASSENGER_ON_BOARD' || project.currentRideStatus === 'ADD_EXTRA_STOPS' || project.currentRideStatus === 'RIDE_ENDED'}
                                            onChange={() => { this.handleRideStatusChange("PASSENGER_ON_BOARD") }} />
                                          <label htmlFor="PASSENGER_ON_BOARD">Passenger on board</label>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <p>
                                          This indicates that the passenger is in the vehicle and the ride has started now.
                                        </p>
                                      </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem
                                      disabled={updateRideStatusDisabled2PriorPickup || !project.currentRideStatus || project.currentRideStatus === 'RIDE_ENDED'}>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          <input id="ADD_EXTRA_STOPS" name="updateRideStatusAction" type="radio" value="ADD_EXTRA_STOPS"
                                            checked={this.state.currentRideStatus === "ADD_EXTRA_STOPS"}
                                            disabled={updateRideStatusDisabled2PriorPickup || !project.currentRideStatus || project.currentRideStatus === 'RIDE_ENDED'}
                                            onChange={() => { this.handleRideStatusChange("ADD_EXTRA_STOPS") }} />
                                          <label htmlFor="ADD_EXTRA_STOPS">Add extra charges during ride</label>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <div className="row">
                                          <div className="col-12">
                                            <p>Enter extra charges:</p>
                                            <CurrencyInput
                                              id="extraStopsCharges"
                                              name="extraStopsCharges"
                                              disabled={updateRideStatusDisabled2PriorPickup || !project.currentRideStatus || project.currentRideStatus === 'RIDE_ENDED'}
                                              placeholder="$0.00"
                                              decimalsLimit={2}
                                              prefix={'$'}
                                              allowNegativeValue={false}
                                              disableAbbreviations={true}
                                              onValueChange={(value, name) => this.handleExtraStopsCharges('extraStopsCharges', value)}
                                            />
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div className="col-12">
                                            <label htmlFor="specialNotesForStops">Notes:</label>
                                            <textarea id="specialNotesForStops"
                                              disabled={updateRideStatusDisabled2PriorPickup || !project.currentRideStatus || project.currentRideStatus === 'RIDE_ENDED'}
                                              className="materialize-textarea has-character-counter"
                                              onChange={this.handleExtraStopsNotes}
                                              ref={this.extraStopsNotesInput}
                                              value={this.state.extraStopsNotes}
                                              data-length="5"
                                              maxLength="500"></textarea>
                                          </div>
                                        </div>
                                      </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem disabled={updateRideStatusDisabled2PriorPickup || (!project.currentRideStatus && (project.currentRideStatus !== 'PASSENGER_ON_BOARD' || project.currentRideStatus !== 'ADD_EXTRA_STOPS'))}>
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          <input id="RIDE_ENDED" name="updateRideStatusAction" type="radio" value="RIDE_ENDED"
                                            checked={this.state.currentRideStatus === "RIDE_ENDED"}
                                            disabled={updateRideStatusDisabled2PriorPickup || (!project.currentRideStatus && (project.currentRideStatus !== 'PASSENGER_ON_BOARD' || project.currentRideStatus !== 'ADD_EXTRA_STOPS'))}
                                            onChange={() => { this.handleRideStatusChange("RIDE_ENDED") }} />
                                          <label htmlFor="RIDE_ENDED">End Ride</label>
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <p>
                                          This indicates that the ride is ended.
                                        </p>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  </Accordion>

                                  <div>
                                    <button onClick={() => this.updateOperatorCurrentRideStatusBtnClicked()}
                                      disabled={this.state.currentRideStatus === ''
                                        || (this.state.currentRideStatus === 'ADD_EXTRA_STOPS' && this.state.extraStopsCharges === "0.00")}
                                      className='rn-button-style--2 btn-solid'>Update Ride Status</button>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="smallerFont75 red-text">If you are not available for this ride anymore, please click <span style={{ textDecoration: 'underline' }} onClick={this.cancelConfirmedRideBtnClicked}> here </span>.</p>
                                </div>
                              </div>
                            </>
                            : null
                          }

                        </React.Fragment>
                      )
                      : (project.selectedOperatorId && project.selectedOperatorId === auth.uid)
                        ? (
                          //current operator has been selected to confirm the ride
                          <React.Fragment>
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="ptb--20 mb--10 center">
                                  <div className="green-background">You have been selected by the traveler.<br /> Please confirm your availability.
                                  </div>
                                  <br />
                                  <div>
                                    <button onClick={this.confirmRideBtnClicked} className="rn-button-style--2 btn-solid">Reconfirm</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            <br />
                            <div className="row mtb--20">
                              <div className="col-lg-12">
                                <p className="smallerFont75 red-text">If you are not available for this ride anymore, please click <span style={{ textDecoration: 'underline' }} onClick={this.declineRideConfirmationBtnClicked}> here </span>.</p>
                              </div>
                            </div>
                          </React.Fragment>
                        )
                        : (project.confirmedByOperatorId && project.confirmedByOperatorId !== auth.uid)
                          ? (
                            //current operator has accepted the ride but the ride is confirmed by another operator
                            <div className="row">
                              <div className="col-lg-12 error-text">
                                <p className="red-background ptb--10 center">This ride has been fulfilled by another operator.</p>
                              </div>
                            </div>
                          )
                          :
                          (project && project.deniedAfterAcceptingBy && (project.deniedAfterAcceptingBy.includes(auth.uid)))
                            ? (
                              //current operator has denied after accepting the ride
                              <React.Fragment>
                                <div className="row">
                                  <div className="col-lg-12 ">
                                    <p className="red-background ptb--10 center">You declined this ride after accepting.</p>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
                            : (
                              // current operator has accepted the ride
                              <React.Fragment>
                                <div className="row mb--20">
                                  <div className="col-lg-12 col-8">
                                    <p className="yellow-background ptb--10 center"><b>Pending traveler approval</b></p>
                                  </div>
                                </div>
                                <div className="row mtb--20">
                                  <div className="col-lg-12">
                                    <p className="smallerFont75 red-text">If you are not available for this ride anymore, please click <span style={{ textDecoration: 'underline' }} onClick={this.denyAcceptedRideBtnClicked}>here</span>.</p>
                                  </div>
                                </div>
                              </React.Fragment>
                            )
              :
              (project && (project.selectedOperatorId))
                ? (
                  //current operator has not accepted the ride and the ride is already confirmed by another operator
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="error-text">This ride has been fulfilled by another operator.</p>
                    </div>
                  </div>
                )
                : (project && (project.deniedBy) && (project.deniedBy.includes(auth.uid)))
                  ? (
                    //current operator has denied the ride without accepting it
                    <div className="row">
                      <div className="col-lg-12">
                        <h3><p className="red-background1 white text-center">Declined</p></h3>
                        <p className="error-text">You declined this ride.</p>
                      </div>
                    </div>
                  )
                  : (project && project.serviceDate && (moment().toDate() > moment(project.serviceDate.toDate()).toDate()))
                    ?
                    // current operator cannot accept/deny because of timeout from ride pickup time
                    <div className="row">
                      <div className="col-lg-12">
                        <p className="red-background1 white text-center"><b>Timed Out</b></p>
                        <div className="mainFont error-text">The time limit to respond to this ride is over.</div>
                      </div>
                    </div>
                    : (
                      //current operator can accept/deny the ride
                      <React.Fragment>
                        <div className="row">
                          <div className="col-12">
                            <p>Original Ride Rate: {project.calculatedPrice ? (project.calculatedPrice === '-1.00' ? 'N/A' : '$' + project.calculatedPrice) : 'N/A'}</p>
                            <h5>Additional charges:</h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-6 col-5">
                            <b>Tolls</b>
                            <CurrencyInput
                              id="additionalChargesTolls"
                              name="additionalChargesTolls"
                              placeholder="$0.00"
                              decimalsLimit={2}
                              prefix={'$'}
                              allowNegativeValue={false}
                              disableAbbreviations={true}
                              onValueChange={(value, name) => this.handleAdditionalChargeChanges('additionalChargesTolls', value)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6 col-5">
                            <b>Parking</b>
                            <CurrencyInput
                              id="additionalChargesParking"
                              name="additionalChargesParking"
                              placeholder="$0.00"
                              decimalsLimit={2}
                              prefix={'$'}
                              allowNegativeValue={false}
                              disableAbbreviations={true}
                              onValueChange={(value, name) => this.handleAdditionalChargeChanges('additionalChargesParking', value)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6 col-5">
                            <b>Airport Fee</b>
                            <CurrencyInput
                              id="additionalChargesAirportFee"
                              name="additionalChargesAirportFee"
                              placeholder="$0.00"
                              decimalsLimit={2}
                              prefix={'$'}
                              allowNegativeValue={false}
                              disableAbbreviations={true}
                              onValueChange={(value, name) => this.handleAdditionalChargeChanges('additionalChargesAirportFee', value)}
                            />
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-6 col-5">
                            <b>Other charges</b>
                            <CurrencyInput
                              id="additionalChargesMisc"
                              name="additionalChargesMisc"
                              placeholder="$0.00"
                              decimalsLimit={2}
                              prefix={'$'}
                              allowNegativeValue={false}
                              disableAbbreviations={true}
                              onValueChange={(value, name) => this.handleAdditionalChargeChanges('additionalChargesMisc', value)}
                            />
                          </div>
                        </div>
                        <div className="row mt--10">
                          <div className="col-12">
                            <h4><u>Total Ride Rate:</u></h4>
                            <CurrencyInput
                              id="totalRate"
                              name="totalRate"
                              placeholder="$0.00"
                              decimalsLimit={2}
                              prefix={'$'}
                              allowNegativeValue={false}
                              disableAbbreviations={true}
                              disabled={true}
                              value={this.state.totalRate}
                            />
                          </div>
                        </div>
                        <div className="row mt--10">
                          <div className="col-12">
                            <h5>Special Notes:</h5>
                            <textarea id="specialNotes"
                              onChange={this.handleNotesChange}
                              ref={this.specialNotesInput}
                              value={this.state.specialNotes}
                              data-length="500"
                              maxLength="500"></textarea>
                          </div>
                        </div>
                        
                        <p className="red-text">The rate you are accepting is all-inclusive. You must add your tolls, parking, and airport fee prior to accepting the ride. If you add after the job completion, your request will be declined.</p>

                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-4 col-12 mt--10">
                            <button onClick={this.acceptRideBtnClicked}
                              className="rn-button-style--2 btn-solid">Accept</button>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-8 col-12 mt--10">
                            <button onClick={this.denyRideBtnClicked}
                              className="rn-button-style--2 btn-solid ">Not available</button>
                          </div>
                        </div>
                      </React.Fragment>
                    )
          )
          rideStatus = rideCancellation || replyToRideRequest;
          const linkToSourceAddressGoogle = `https://maps.google.com/?q=${project.fromlat},${project.fromlng}`
          const linkToSourceAddressApple = `https://maps.apple.com/maps?q=${project.fromlat},${project.fromlng}`
          const linkToDestinationAddressGoogle = `https://maps.google.com/?q=${project.tolat},${project.tolng}`
          const linkToDestinationAddressApple = `https://maps.apple.com/maps?q=${project.tolat},${project.tolng}`
          return (
            <>
              {loader}
              <Breadcrumb title1={'Ride Details'} title={rideConfirmationCode} parent='Rides' parentLink='/rides' />
              <main className="contact-form--1 main_container mt--80 bg_color--1">
                <div ref="viewOptions" className="row row--35 align-items-start mt--60">
                  <div className="col-lg-12">
                    {
                      this.state.categoryId === 'rideDetailsViewOption' &&
                      <>
                        {errorMsg ? <ErrorMessage errorMessageText={errorMsg}></ErrorMessage> : null}
                        {successMsg ? <ConfirmationMessage errorMessageText={successMsg}></ConfirmationMessage> : null}
                        <div className="section-title text-left">
                          <p><b>Ride Code:</b> {project.rideConfirmationCode ? project.rideConfirmationCode : 'N/A'}</p>
                        </div>

                        <Tabs className="mb--40">
                          <div className="tablist-inner">
                            <TabList className="pv-tab-button text-center">
                              <Tab><span>RIDE DETAILS</span></Tab>
                              <Tab><span>CHAT</span></Tab>
                              <Tab><span>MAP</span></Tab>
                            </TabList>
                          </div>
                          <TabPanel>
                            <div className="single-tab-content">
                              <div className="row mb--30">
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt--20">
                                  <div className="rn-address1 bg_color--5">
                                    <h3><u>RIDE DETAILS</u></h3>
                                    <div className="inner">
                                      <Table aria-label="ride details table">
                                        <TableBody>
                                          <TableRow>
                                            <TableCell><b>Date-Time</b></TableCell>
                                            <TableCell>{project.serviceDateStr || moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY hh:mm A")} </TableCell>
                                          </TableRow>
                                          {
                                            (project && project.confirmedByOperatorId && project.confirmedByOperatorId === auth.uid)
                                              ? <>
                                                <TableRow>
                                                  <TableCell><b>From</b></TableCell>
                                                  <TableCell>
                                                    {project.serviceFrom} {project.serviceFromName ? `(${project.serviceFromName})` : null}
                                                    <a target="_blank" rel="noopener noreferrer" href={linkToSourceAddressGoogle}>
                                                      <Tooltip title="Open with Google Maps"><img src={GoogleMaps} alt="GoogleMaps" /></Tooltip>
                                                    </a>
                                                    <a target="_blank" rel="noopener noreferrer" href={linkToSourceAddressApple}>
                                                      <Tooltip title="Open with Apple Maps"><img src={AppleMaps} alt="AppleMaps" /></Tooltip>
                                                    </a>
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell><b>To</b></TableCell>
                                                  <TableCell>
                                                    {project.serviceTo} {project.serviceToName ? `(${project.serviceToName})` : null}
                                                    <a target="_blank" rel="noopener noreferrer" href={linkToDestinationAddressGoogle}>
                                                      <Tooltip title="Open with Google Maps"><img src={GoogleMaps} alt="GoogleMaps" /></Tooltip>
                                                    </a>
                                                    <a target="_blank" rel="noopener noreferrer" href={linkToDestinationAddressApple}>
                                                      <Tooltip title="Open with Apple Maps"><img src={AppleMaps} alt="AppleMaps" /></Tooltip>
                                                    </a>
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                              : <>
                                                <TableRow>
                                                  <TableCell><b>From</b></TableCell>
                                                  <TableCell>
                                                    {project.serviceFrom} {project.serviceFromName ? `(${project.serviceFromName})` : null}
                                                    <a target="_blank" rel="noopener noreferrer" href={linkToSourceAddressGoogle}>
                                                      <Tooltip title="Open with Google Maps"><img src={GoogleMaps} alt="GoogleMaps" /></Tooltip>
                                                    </a>
                                                    <a target="_blank" rel="noopener noreferrer" href={linkToSourceAddressApple}>
                                                      <Tooltip title="Open with Apple Maps"><img src={AppleMaps} alt="AppleMaps" /></Tooltip>
                                                    </a>
                                                  </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell><b>To</b></TableCell>
                                                  <TableCell>
                                                    {project.serviceTo} {project.serviceToName ? `(${project.serviceToName})` : null}
                                                    <a target="_blank" rel="noopener noreferrer" href={linkToDestinationAddressGoogle}>
                                                      <Tooltip title="Open with Google Maps"><img src={GoogleMaps} alt="GoogleMaps" /></Tooltip>
                                                    </a>
                                                    <a target="_blank" rel="noopener noreferrer" href={linkToDestinationAddressApple}>
                                                      <Tooltip title="Open with Apple Maps"><img src={AppleMaps} alt="AppleMaps" /></Tooltip>
                                                    </a>
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                          }
                                          {
                                            project.serviceType !== 'HourlyCharter' ?
                                              <TableRow>
                                                <TableCell><b>Estimated Duration</b></TableCell>
                                                <TableCell>{project.calculatedRideDuration}</TableCell>
                                              </TableRow>
                                              : null
                                          }
                                          <TableRow>
                                            <TableCell><b>Service Type</b></TableCell>
                                            <TableCell>{getServiceTypeName(project.serviceType)}</TableCell>
                                          </TableRow>
                                          {
                                            project.serviceType === 'HourlyCharter' ?
                                              <TableRow>
                                                <TableCell><b>Number of Hours</b></TableCell>
                                                <TableCell>{project.serviceHours} hours</TableCell>
                                              </TableRow>
                                              : null
                                          }
                                          <TableRow>
                                            <TableCell><b>Vehicle Type</b></TableCell>
                                            <TableCell>{getVehicleTypeName(project.serviceVehicle)}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell><b>Distance</b></TableCell>
                                            <TableCell>{project.calculatedDistance.toFixed(2) + ' miles'}</TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell><b>Special Notes</b></TableCell>
                                            <TableCell className="breakAll">{project.specialNotes || '-'}</TableCell>
                                          </TableRow>
                                          {
                                            (project && project.confirmedByOperatorId && project.confirmedByOperatorId === auth.uid) ?
                                              <>
                                                <TableRow>
                                                  <TableCell><b>Passenger Name</b></TableCell>
                                                  <TableCell>{project.passengerFirstName ? project.passengerFirstName.toUpperCase() : ''} {project.passengerLastName ? project.passengerLastName.toUpperCase() : ''}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                  <TableCell><b>Passenger Phone</b></TableCell>
                                                  <TableCell>{project.passengerPhone ? <a className="underline" href={`tel:${project.passengerPhone}`}>{project.passengerPhone}</a> : 'Not specified'}</TableCell>
                                                </TableRow>
                                              </>
                                              :
                                              <>
                                                <TableRow>
                                                  <TableCell><b>Passenger Details</b></TableCell>
                                                  <TableCell>Passenger details will be available upon ride confirmation</TableCell>
                                                </TableRow>
                                              </>
                                          }
                                        </TableBody>
                                      </Table>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt--20">
                                  <div className="rn-address1">
                                    <h3><u>RIDE STATUS&nbsp;<Tooltip title="Indicates current ride status"><span>
                                      <IoInformationCircle size={25} /></span>
                                    </Tooltip></u></h3>
                                    {rideStatus}
                                  </div>
                                </div>

                                {
                                  !rideCancellation &&
                                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 mt--20">
                                    <div className="rn-address1 bg_color--5">
                                      <h3><u>RIDE RATE CARD</u></h3>
                                      {rideRateSection}
                                      <h5>EXTRA CHARGES</h5>
                                      {
                                        (project && project.confirmedByOperatorId && project.confirmedByOperatorId === auth.uid)
                                          ?
                                          <Table aria-label="extra charges table">
                                            <TableBody>
                                              {extraStopSection}
                                            </TableBody>
                                          </Table>
                                          : <p>N/A</p>
                                      }
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="single-tab-content mt--20">
                              {
                                (project && project.confirmedByOperatorId && project.confirmedByOperatorId === auth.uid)
                                  ? chatWithTraveler
                                  : (<div className="row">
                                    <div className="col-12">
                                      <p>Applicable only when the ride is confirmed.</p>
                                    </div>
                                  </div>)
                              }
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="single-tab-content mt--20">
                              <MapWithADirectionsRenderer
                                fromlat={project.fromlat}
                                fromlng={project.fromlng}
                                tolat={project.tolat}
                                tolng={project.tolng}
                                suppressMarkers={project && ((project.confirmedByOperatorId && project.confirmedByOperatorId !== auth.uid) || !project.confirmedByOperatorId)}
                                showCircle={project && project.confirmedByOperatorId !== auth.uid}
                              />
                            </div>
                          </TabPanel>
                        </Tabs>
                      </>
                    }
                  </div>
                </div>

                <div id="cancelConfirmedRideModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Please confirm that you are NOT available for this ride.</h5>
                      <br /><hr /><br />
                      <button className="rn-button-style--2 btn-solid modal-close"
                        onClick={this.agreeToCancelConfirmedRideBtnClicked}>Confirm</button>
                      <button className="rn-button-style--2 rn-btn-dark modal-close ml--30">Cancel</button>
                    </div>
                  </div>
                </div>

                <div id="updateOperatorCurrentRideStatusConfirmModal" className="modal">
                  <div className="modal-content modalForm center">
                    <div>
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Confirm ride status update</h5>
                      <br /><hr /><br />
                      {
                        this.state.currentRideStatus === 'ADD_EXTRA_STOPS'
                          ? <>
                            <p className="center"><b>Status: </b>{this.getRideStatusByCode(this.state.currentRideStatus)}</p>
                            <p className="center">Extra charges: ${this.state.extraStopsCharges}</p>
                            <p className="center">Extra charges notes: {this.state.extraStopsNotes}</p>
                          </>
                          : this.state.currentRideStatus === 'RIDE_ENDED'
                            ? <>
                              <p className="center"><b>Status:</b> END RIDE</p>
                              <p className="center"><b>Final charges: ${this.state.totalRate}</b></p>
                              <p className="smallerFont75 center red-text">All charges submitted are final.</p>
                            </>
                            : <p>Status: {this.getRideStatusByCode(this.state.currentRideStatus)}</p>
                      }
                      <br /><hr /><br />
                      <button className="rn-button-style--2 btn-solid modal-close mt--10"
                        onClick={this.updateOperatorCurrentRideStatus}>Confirm</button>
                      <button className="rn-button-style--2 rn-btn-dark modal-close mt--10  ml--10">Cancel</button>
                    </div>
                  </div>
                </div>

                <div id="denyRideModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Please confirm that you are NOT available for this ride</h5>
                      <br /><hr /><br />
                      <button className="rn-button-style--2 btn-solid modal-close"
                        onClick={this.agreeToDenyRideBtnClicked}>Confirm</button>
                      <button className="rn-button-style--2 rn-btn-dark modal-close ml--30">Cancel</button>
                    </div>
                  </div>
                </div>

                <div id="approvalRequiredModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">You cannot accept this ride currently because your account is not approved yet.</h5>
                      <br /><hr /><br />
                    </div>
                  </div>
                </div>

                <div id="profileIncompletModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Please update your complete profile before accepting this ride.</h5>
                      <br /><hr /><br />
                      <Link to={'/profile'}>
                        <button className="rn-button-style--2 btn-solid">Update Profile</button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div id="cashoutModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Please connect or create a Stripe account to accept rides and receive funds</h5>
                      <br /><hr /><br />
                      <Link to={'/cashout'}>
                        <button className="rn-button-style--2 btn-solid">Setup Cashout</button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div id="subscribePlanModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Please subscribe to accept rides.</h5>
                      <br /><hr /><br />
                      <Link to={'/subscription'}>
                        <button className="rn-button-style--2 btn-solid">Subscribe now</button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div id="acceptRideModal" className="modal">
                  <div className="modal-content modalForm">
                    <div>
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Verify your additional charges</h5>
                      <p className="red-text center">Please make sure you entered the correct details, all charges submitted are final.</p>
                      <Table aria-label="additional charges table">
                        <TableBody>
                          <TableRow>
                            <TableCell>Ride Rate</TableCell>
                            <TableCell>{project.calculatedPrice ? (project.calculatedPrice === '-1.00' ? 'N/A' : '$' + project.calculatedPrice) : 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Tolls</TableCell>
                            <TableCell>${this.state.additionalChargesTolls}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Parking</TableCell>
                            <TableCell>${this.state.additionalChargesParking}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Airport Fee</TableCell>
                            <TableCell>${this.state.additionalChargesAirportFee}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Other Charges</TableCell>
                            <TableCell>${this.state.additionalChargesMisc}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Special Notes:</TableCell>
                            <TableCell className="breakAll">{this.state.specialNotes || '-'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell><b>Total Ride Rate</b></TableCell>
                            <TableCell><b className="red-text">${this.state.totalRate}</b></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <p className='center'>
                        <button className="rn-button-style--2 btn-solid modal-close"
                          onClick={this.agreeAcceptRideBtnClicked}>Accept</button>
                        <button className="rn-button-style--2 rn-btn-dark modal-close ml--10">Cancel</button>
                      </p>
                    </div>
                  </div>
                </div>

                <div id="denyAcceptedRideModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Please confirm that you are NOT available for this ride</h5>
                      <br /><hr /><br />
                      <button className="rn-button-style--2 btn-solid modal-close"
                        onClick={this.agreeToDenyAcceptedRideBtnClicked}>Not available</button>
                      <button className="rn-button-style--2 rn-btn-dark modal-close ml--30">Cancel</button>
                    </div>
                  </div>
                </div>

                <div id="confirmRideModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Please confirm that you are available for this ride</h5>
                      <br /><hr /><br />
                      <button className="rn-button-style--2 btn-solid modal-close mt--10"
                        onClick={this.agreeToConfirmRideBtnClicked}>Confirm</button>
                      <button className="rn-button-style--2 rn-btn-dark modal-close mt--10 ml--10">Cancel</button>
                    </div>
                  </div>
                </div>

                <div id="declineRideConfirmationModal" className="modal">
                  <div className="modal-content modalForm">
                    <div className="center">
                      <i className="material-icons modal-close float-right">close</i>
                      <h5 className="center">Please confirm that you are not available for this ride</h5>
                      <br /><hr /><br />
                      <button className="rn-button-style--2 btn-solid modal-close"
                        onClick={this.agreeToDeclineRideConfirmationBtnClicked}>Confirm</button>
                      <button className="rn-button-style--2 rn-btn-dark modal-close ml--30">Cancel</button>
                    </div>
                  </div>
                </div>

              </main>
            </>
          )
        }
      }
    } else {
      return (
        <>
          <Breadcrumb title1={'Ride Details'} title={'XXXXXXX'} parent='Rides' parentLink='/rides' />
          <main className="contact-form--1 main_container mt--80 bg_color--1">
            <div className="row row--35 align-items-start">
              <div className="col-lg-12">
                <div className="section-title text-left mb--50">
                  <p className="description">Loading</p>
                  <Loader1 />
                </div>
              </div>
            </div>
          </main>
        </>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const project = state.project.rideDetails;
  return {
    project: project,
    auth: state.firebase.auth,
    projectId: id,
    profile: state.firebase.profile,
    errorMsg: state.project.errorMsg,
    callError: state.auth.callError,
    successMsg: state.project.successMsg
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRideDetails: (data) => dispatch(getRideDetailsForOperator(data)),
    denyRideAction: (project) => dispatch(denyRideAction(project)),
    acceptRideAction: (project) => dispatch(acceptRideAction(project)),
    denyAcceptedRideAction: (project) => dispatch(denyAcceptedRideAction(project)),
    confirmRideAction: (operatorConfirmed) => dispatch(confirmRideAction(operatorConfirmed)),
    declineRideConfirmationAction: (operatorConfirmed) => dispatch(declineRideConfirmationAction(operatorConfirmed)),
    cancelConfirmedRideAction: (project) => dispatch(cancelConfirmedRideAction(project)),
    updateOperatorCurrentRideStatusAction: (project) => dispatch(updateOperatorCurrentRideStatus(project)),
    clearRideDetails: (project) => dispatch(clearRideDetails(project)),
    sendChatMessage: (obj) => dispatch(sendChatMessageToTraveler(obj)),
    clearError: () => dispatch(clearErrorAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorRideDetails)
