import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getTravelerRequestedRides } from '../../../store/actions/projectActions'
import Loader1 from '../../loader/Loader1'
import moment from 'moment';
import RideTableData from '../RideTableData4'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { createDataForTraveler } from '../../util/rideUtils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as ga from '../../util/googleAnalytics'
import { IconButton, Tooltip } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

class RequestedRidesData extends Component {

  constructor(props) {
    super(props)
    this.state = this.initialState()
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this)
    this.filterResults = this.filterResults.bind(this)
    this.clearFilterResults = this.clearFilterResults.bind(this)
  }

  initialState() {
    return {
      fetchedRequestedProjectDetails: false,
      rideFilterText: '',
      loading: false,
      filterActive: false,
      filteredProjects: []
    }
  }

  handleSearchTextChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  filterResults = () => {
    if (this.state.rideFilterText !== '') {
      this.setState({
        loading: true
      }, (() => {
        var filteredProjects = this.props.projects.filter((project) => {
          return project.rideConfirmationCode === this.state.rideFilterText ||
            project.passengerFirstName === this.state.rideFilterText ||
            project.passengerLastName === this.state.rideFilterText
        });
        this.setState({
          loading: false,
          filteredProjects,
          filterActive: true,
        });
      })
      );
    }
  }

  clearFilterResults = () => {
    this.setState({
      loading: true
    }, (() => {
      this.setState({
        loading: false,
        rideFilterText: '',
        filterActive: false,
        filteredProjects: []
      });
    })
    );
  }

  componentDidMount() {
    const { auth } = this.props;
    if (!this.state.fetchedRequestedProjectDetails && auth && auth.uid) {
      this.props.getRequestedRides(auth.uid);
      this.setState({
        fetchedRequestedProjectDetails: true
      })
    }
  }

  componentDidUpdate() {
    const { auth } = this.props;
    ga.pageViewsTracking(window.location.pathname);
    if (!this.state.fetchedRequestedProjectDetails && auth && auth.uid) {
      this.props.getRequestedRides(auth.uid);
      this.setState({
        fetchedRequestedProjectDetails: true
      })
    }
  }

  render() {
    const { projects, auth, errorMsg } = this.props;
    const filteredProjects = this.state.filteredProjects;
    if (!auth.uid) return <Redirect to='/login' />

    if (this.state.loading === true) {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">Loading</p>
            <Loader1 />
          </CardContent>
        </Card>
      )
    } else if (this.state.fetchedRequestedProjectDetails && projects && !errorMsg) {
      let finalData = [];
      if (this.state.filterActive === true) {
        // console.log('projects length = ', projects && projects.length);
        filteredProjects && filteredProjects.forEach(project => {
          let viewDetailsBtn = (
            <Link to={'/ride/requested/' + project.id}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
          )
          let actionLink = '/ride/requested/' + project.id;
          let passengerName = project.passengerFirstName + ' ' + project.passengerLastName;
          finalData.push(
            createDataForTraveler(
              project.id,
              project.serviceDateStr || moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY hh:mm A"),
              `${project.serviceFrom} ${project.serviceFromName ? `(${project.serviceFromName})` : ''}`,
              `${project.serviceTo} ${project.serviceToName ? `(${project.serviceToName})` : ''}`,
              project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : 'N/A',
              project.status ? project.status.toUpperCase() : null,
              viewDetailsBtn,
              project.serviceVehicle,
              project.serviceType,
              project.calculatedDistance.toFixed(2) + ' miles',
              project.serviceType === 'HourlyCharter' ? `${project.serviceHours} hours` : project.calculatedRideDuration,
              project.serviceDate.toDate(),
              actionLink,
              project.rideConfirmationCode,
              project.confirmedOperatorName,
              project.confirmedOperatorPhoneNumber,
              passengerName,
              project.confirmedOperatorProfileImageUrl,
              project.specialNotes,
              project.confirmedOperatorRate
            )
          )
        })

      } else if (projects.length > 0) {
        // console.log('projects length = ', projects && projects.length);
        projects && projects.forEach(project => {
          let viewDetailsBtn = (
            <Link to={'/ride/requested/' + project.id}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
          )
          let actionLink = '/ride/requested/' + project.id;
          let passengerName = project.passengerFirstName + ' ' + project.passengerLastName;
          finalData.push(
            createDataForTraveler(
              project.id,
              project.serviceDateStr || moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY hh:mm A"),
              `${project.serviceFrom} ${project.serviceFromName ? `(${project.serviceFromName})` : ''}`,
              `${project.serviceTo} ${project.serviceToName ? `(${project.serviceToName})` : ''}`,
              project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : 'N/A',
              project.status ? project.status.toUpperCase() : null,
              viewDetailsBtn,
              project.serviceVehicle,
              project.serviceType,
              project.calculatedDistance.toFixed(2) + ' miles',
              project.serviceType === 'HourlyCharter' ? `${project.serviceHours} hours` : project.calculatedRideDuration,
              project.serviceDate.toDate(),
              actionLink,
              project.rideConfirmationCode,
              project.confirmedOperatorName,
              project.confirmedOperatorPhoneNumber,
              passengerName,
              project.confirmedOperatorProfileImageUrl,
              project.specialNotes,
              project.confirmedOperatorRate
            )
          )
        })
      }
      return (
        <React.Fragment>
          <div className="container">

            <p className="description">Search using ride code or passenger first or last name</p>
            <div className="row mb--30">
              <div className="col-6">
                <input type="text" id='rideFilterText'
                  value={this.state.rideFilterText}
                  placeholder="Search text" onChange={this.handleSearchTextChange} />
              </div>
              <div className="col-6">
                <Tooltip title="Filter results">
                  <span><IconButton
                    onClick={this.filterResults}
                    disabled={this.state.rideFilterText === ''}
                    className="tooltip-btn"
                    color="primary"
                    aria-label="star">
                    <SearchIcon />
                  </IconButton></span>
                </Tooltip>
                <Tooltip title="Reset">
                  <span><IconButton
                    onClick={this.clearFilterResults}
                    disabled={this.state.rideFilterText === ''}
                    className="tooltip-btn"
                    color="secondary"
                    aria-label="star">
                    <ClearIcon />
                  </IconButton></span>
                </Tooltip>
              </div>
            </div>
            {finalData && finalData.length > 0 ? <RideTableData rows={finalData} />
              : (
                <Card variant="outlined">
                  <CardContent>
                    <p className="center error-text">No results found. Please try again with a different value or reset search.</p>
                  </CardContent>
                </Card>
              )
            }
          </div>
        </React.Fragment>
      )
    } else if (errorMsg) {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">{errorMsg}</p>
          </CardContent>
        </Card>
      )
    } else {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">Loading</p>
            <Loader1 />
          </CardContent>
        </Card>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    projects: state.project.travelerRequestedProjects,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getRequestedRides: (uid) => dispatch(getTravelerRequestedRides(uid))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(RequestedRidesData)
