import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: 'white',
  },
  body: {
    fontSize: 12,
    color: '#D8B46C',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


export default function SelectPaymentMethodData(props) {
  const rows = props.rows;
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Credit and Debit Cards</StyledTableCell>
            <StyledTableCell>Expires</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <TableCell component="th" scope="row">
                <label>
                  <input className="with-gap updatable"
                    name="selectedPaymentMethod"
                    type="radio"
                    value={row.id + '::' + row.card}
                    checked={props.selectedPaymentMethod === row.id}
                    onChange={props.handleChange}
                  />
                  <span>{row.name}</span>
                </label>
              </TableCell>
              <TableCell>{row.card}</TableCell>
              <TableCell>{row.expires}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
