import React from 'react'
import ErrorIcon from '@material-ui/icons/Error';

class ConfirmationMessage extends React.Component {
    render() {
        const { message } = this.props;
        if (message) {
            return (
                <>
                    <div className="confirmation-box">
                        <span className="icon"><ErrorIcon className="green-text" /></span>
                        {message}
                    </div>
                </>
            )
        } else {
            return (<></>)
        }
    }
}

export default ConfirmationMessage
