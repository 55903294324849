import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logout, clearErrorAction, getLoggedInOperatorMode, toggleLoggedInOperatorMode } from '../../store/actions/authActions';
import { FiX, FiMenu } from "react-icons/fi";
import { NavLink } from 'react-router-dom';
import { Redirect } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import { largeWidth, travelerPageArr } from "../constants/CommonConstants"
import { IoCarOutline } from 'react-icons/io5';
import { HiOutlineBriefcase } from "react-icons/hi";
import ReactGA from 'react-ga4';

class NavigationMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      windowWidth: window.innerWidth
    }
    this.logoutFn = this.logoutFn.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.toggleOperatorMode = this.toggleOperatorMode.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.props.clearError();
    const { auth, profile, loggedInOperatorMode } = this.props;
    // console.log('navigationMenu mount -loggedInOperatorMode= ', loggedInOperatorMode);
    if (profile && profile.accountType === 'Operator' && loggedInOperatorMode === null) {
      this.props.getLoggedInOperatorMode(auth.uid)
    }
    window.addEventListener("resize", this.updatePredicate);
    if (travelerPageArr.includes(window.location.pathname) && loggedInOperatorMode === 'OperatorMode') {
      this.toggleOperatorMode();
    }
  }

  componentDidUpdate(prevProps) {
    const { auth, profile, loggedInOperatorMode } = this.props;
    // console.log('navigationMenu update -loggedInOperatorMode= ', loggedInOperatorMode);
    if (auth && auth.uid && this.props.auth.uid !== prevProps.auth.uid) {
      this.props.clearError();
    }
    if (profile && profile.accountType === 'Operator' && loggedInOperatorMode === null) {
      this.props.getLoggedInOperatorMode(auth.uid)
    }
    if (travelerPageArr.includes(window.location.pathname) && loggedInOperatorMode === 'OperatorMode') {
      this.toggleOperatorMode();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  toggleOperatorMode() {
    this.props.toggleLoggedInOperatorMode();
    this.setState({
      redirect: true
    })
  }

  updatePredicate() {
    this.setState({ windowWidth: window.innerWidth });
  }

  menuTrigger() {
    window.scrollTo(0, 0);
    document.querySelector('.header-wrapper').classList.toggle('menu-open');
  }

  CLoseMenuTrigger() {
    window.scrollTo(0, 0);
    document.querySelector('.header-wrapper').classList.remove('menu-open');
  }

  logoutFn() {
    this.props.logout();
    this.setRedirect();
    this.CLoseMenuTrigger();
    ReactGA.set({ userId: 'Anonymous' });
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    })
  }

  renderRedirect = () => {
    if (this.props.auth && !this.props.auth.uid && this.state.redirect) {
      return <Redirect to='/login' />
    }
  }

  render() {
    // console.log('windowWidth = ', this.state.windowWidth, ' > 992 ', this.state.windowWidth > largeWidth);
    window.addEventListener('scroll', function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector('.header--fixed').classList.add('sticky')
      } else {
        document.querySelector('.header--fixed').classList.remove('sticky')
      }
    });

    var elements = document.querySelectorAll('.has-droupdown > a');
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector('.submenu').classList.toggle("active");
          this.classList.toggle("open");
        }
      }
    }

    const { auth, profile, loggedInOperatorMode } = this.props;
    // console.log('navigationMenu render - loggedInOperatorMode =', loggedInOperatorMode);

    let userRoleSpecificTabs = null;
    let accountLink = '/account';
    if (profile.accountType === 'Traveler') {
      userRoleSpecificTabs = <>
        <li><NavLink to="/instant-quote" onClick={this.CLoseMenuTrigger}>Instant Quote</NavLink></li>
        <li><NavLink to="/ride/create" onClick={this.CLoseMenuTrigger}>Book a Ride</NavLink></li>
        <li><NavLink to="/my-reservations" onClick={this.CLoseMenuTrigger}>My Reservations</NavLink></li>
      </>;
    } else if (profile.accountType === 'Operator') {
      if (loggedInOperatorMode === 'OperatorMode') {
        userRoleSpecificTabs = <>
          <li><NavLink to="/rides" onClick={this.CLoseMenuTrigger}>Rides</NavLink></li>
          <li><NavLink to="" onClick={() => { this.CLoseMenuTrigger(); this.toggleOperatorMode() }}>Switch to Traveler Mode</NavLink></li>
        </>
      } else if (loggedInOperatorMode === 'TravelerMode') {
        userRoleSpecificTabs = <>
          <li><NavLink to="/instant-quote" onClick={this.CLoseMenuTrigger}>Instant Quote</NavLink></li>
          <li><NavLink to="/ride/create" onClick={this.CLoseMenuTrigger}>Book a Ride</NavLink></li>
          <li><NavLink to="/my-reservations" onClick={this.CLoseMenuTrigger}>My Reservations</NavLink></li>
          <li><NavLink to="" onClick={() => { this.CLoseMenuTrigger(); this.toggleOperatorMode() }}>Switch to Operator Mode</NavLink></li>
        </>;
      } else {
        // do nothing
      }
    }

    let avatarContent = null;
    if (profile.accountType === 'Operator') {
      avatarContent = <IoCarOutline />
    } else {
      avatarContent = <HiOutlineBriefcase />
    }

    let header = auth && auth.uid ?
      (
        // logged in menu
        <header className="header-area formobile-menu header--fixed default-color" >
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo title">
                <NavLink to="/">
                  <img className="logo-1" src="/assets/images/logo/logo-findanio.png" alt="FINDANIO Logo" />
                  <img className="logo-2" src="/assets/images/logo/logo-findanio.png" alt="FINDANIO Logo" />
                </NavLink>
              </div>
            </div>
            <div className="header-right justify-content-end">
              <nav className="mainmenunav d-lg-block">
                <ul className="mainmenu">
                  {
                    this.state.windowWidth >= largeWidth ?
                      <>
                        <li><NavLink to="/home" onClick={this.CLoseMenuTrigger}>Home</NavLink></li>
                        {userRoleSpecificTabs}
                        <li className="open">
                          {/* <Avatar variant="circle" className="avatar-red-color">{profile.initials}</Avatar> */}
                          <Avatar variant="circle" className="avatar-red-color">{avatarContent}</Avatar>
                          <ul className="submenu active">
                            <li className="submenu-signed-in-li">Logged in as {profile.accountType}
                              <br /> {profile.firstName ? profile.firstName.toUpperCase() : null}
                              <br /> {profile.email}
                            </li>
                            <hr />
                            <li><NavLink to={accountLink} onClick={this.CLoseMenuTrigger}>Account Settings</NavLink></li>
                            <li><NavLink to="" onClick={this.logoutFn}>Log Out</NavLink></li>
                          </ul>
                        </li>
                      </>
                      :
                      <>
                        <li className="submenu-signed-in-li text-left">Logged in as {profile.accountType}
                          <br /> {profile.firstName ? profile.firstName.toUpperCase() : null}
                          <br /> {profile.email}
                        </li>
                        <hr />
                        <li><NavLink to="/home" onClick={this.CLoseMenuTrigger}>Home</NavLink></li>
                        <li><NavLink to={accountLink} onClick={this.CLoseMenuTrigger}>Account Settings</NavLink></li>
                        {userRoleSpecificTabs}
                        <hr />
                        <li><NavLink to="" onClick={this.logoutFn}>Log Out</NavLink></li>
                      </>
                  }
                </ul>
              </nav>
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
              </div>
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
              </div>
            </div>
          </div>
        </header>
      ) :
      // logged out menu
      (<header className="header-area formobile-menu header--fixed default-color">
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo title">
              <NavLink to="/">
                <img className="logo-1" src="/assets/images/logo/logo-findanio.png" alt="FINDANIO Logo" />
                <img className="logo-2" src="/assets/images/logo/logo-findanio.png" alt="FINDANIO Logo" />
              </NavLink>
            </div>
          </div>
          <div className="header-right justify-content-end">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li><NavLink to="/" onClick={this.CLoseMenuTrigger}>Home</NavLink></li>
                <li><NavLink to="/about" onClick={this.CLoseMenuTrigger} >About Us</NavLink></li>
                <li><NavLink to="/travelers" onClick={this.CLoseMenuTrigger}>Travelers</NavLink></li>
                <li><NavLink to="/operators" onClick={this.CLoseMenuTrigger}>Operators</NavLink></li>
                <li><NavLink to="/instant-quote" onClick={this.CLoseMenuTrigger}>Instant Quote</NavLink></li>
                <li><NavLink to="/signup" onClick={this.CLoseMenuTrigger}>Sign Up</NavLink></li>
              </ul>
            </nav>
            <div className="header-btn">
              <NavLink className="rn-btn" to="/login">
                <span>Login</span>
              </NavLink>
            </div>
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
            </div>
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
            </div>
          </div>
        </div>
      </header>
      );


    return (
      <React.Fragment>
        {this.renderRedirect()}
        {header}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    loggedInOperatorMode: state.auth.loggedInOperatorMode,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLoggedInOperatorMode: (operatorMode) => dispatch(getLoggedInOperatorMode(operatorMode)),
    logout: () => dispatch(logout()),
    clearError: () => dispatch(clearErrorAction()),
    toggleLoggedInOperatorMode: () => dispatch(toggleLoggedInOperatorMode()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu)
