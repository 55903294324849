import React, { Component } from 'react'
import { FiCheck } from "react-icons/fi";
import { connect } from 'react-redux'
import axios from 'axios';
import { baseURL, stripePublishableKey } from "../constants/CommonConstants";
import Loader1 from '../loader/Loader1'

var stripe = window.Stripe(stripePublishableKey);

class OperatorPricingTable extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.handleClick = this.handleClick.bind(this);
    }

    initialState() {
        return {
            loading: false
        }
    }

    handleClick = (e) => {
        const { auth } = this.props;
        const type = e.target.id;
        this.setState({
            loading: true
        }, (async () => {
            let url = baseURL + '/getStripeSession';
            let data = {
                user: auth.uid,
                type: type
            };
            try {
                const response = await axios.post(url, data);
                //redirect to checkout page
                stripe.redirectToCheckout({
                    sessionId: response.data
                }).then(function (result) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                    // using `result.error.message`.
                    window.scrollTo(0, 0);
                    this.setState({
                        loading: false,
                    });
                    return;
                });
            }
            catch (e) {
                return;
            }
        })
        );
    }

    render() {
        let loader = null;
        if (this.state.loading === true) {
            loader = <Loader1 />
        }
        return (
            <>
                {loader}
                <main className="page-wrapper" >
                    <div className="rn-pricing-table-area ptb--40 bg_color--5" id="pricing" >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                        <h4 className="title">You do NOT have an active subscription.</h4>
                                        <p>Checkout our pricing plan and subscribe now to accept rides.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2 col-md-6 offset-md-3 col-12 mt--30">
                                    <div className="rn-pricing">
                                        <div className="pricing-table-inner">
                                            <div className="pricing-header">
                                                {/* <h4 className="title">Premium</h4> */}
                                                <div className="pricing">
                                                    <span className="price">$49</span>
                                                    <span className="subtitle">Per Month</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="pricing-body">
                                                        <ul className="list-style--1">
                                                            <li><FiCheck />Accept and fulfill unlimited rides</li>
                                                            <li><FiCheck />Be visible to travelers</li>
                                                            <li><FiCheck />Provide your own rate for rides and extra/additional stops</li>
                                                            <li><FiCheck />Become a preferred operator</li>
                                                            <li><FiCheck />View your rides history</li>
                                                            <li><FiCheck />Modify or cancel subscription anytime</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="pricing-body">
                                                        <ul className="list-style--1">
                                                            <li><FiCheck />Instant quotes</li>
                                                            <li><FiCheck />On-demand and future bookings</li>
                                                            {/* <li><FiCheck />Submit unlimited ride requests without paying ride booking fee</li> */}
                                                            <li><FiCheck />Provide your preferred ride rate</li>
                                                            {/* <li><FiCheck />Select an operator of choice for your ride</li> */}
                                                            <li><FiCheck />Easily manage your passengers for frequent booking</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pricing-footer">
                                                <button id="opPrem" className="rn-btn" onClick={this.handleClick}>Subscribe Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

export default connect(mapStateToProps, null)(OperatorPricingTable)
