import axios from 'axios'
import jwt from "jsonwebtoken";
import { baseURL, jwtSecret } from "../../components/constants/CommonConstants";

const initState = {
  authError: null,
  authSuccess: null,
  data: null,
  subError: null,
  errorMsg: null,
  callError: null,
  sessionEmail: null,
  adminRole: null,
  loggedInOperatorMode: null,
  mailListSignUpError: null,
  mailListSignUpSuccess: null,
  successMsg: null,
  globalSignUpError: null,
  globalSignUpSuccess: null,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLEAR_ERROR':
      return {
        ...state,
        authError: null,
        authSuccess: null,
        data: null,
        subError: null,
        errorMsg: null,
        callError: null,
        sessionEmail: null,
        loggedInOperatorMode: null,
        mailListSignUpError: null,
        mailListSignUpSuccess: null,
        successMsg: null,
        globalSignUpError: null,
        globalSignUpSuccess: null,      
      }

    case 'CLEAR_SEARCH_RESULTS':
      return {
        ...state,
        searchResultList: null,
      }

    case 'ERROR_UPDATING_PROFILE':
      return {
        ...state,
        successMsg: null,
        errorMsg: action.errorMsg
      }

    case 'GET_LOGGED_IN_OPERATOR_MODE':
      return {
        ...state,
        loggedInOperatorMode: action.loggedInOperatorMode
      }

    case 'TOGGLE_LOGGED_IN_OPERATOR_MODE': {
      if (state.loggedInOperatorMode === 'TravelerMode') {
        return {
          ...state,
          loggedInOperatorMode: 'OperatorMode'
        }
      } else {
        return {
          ...state,
          loggedInOperatorMode: 'TravelerMode'
        }
      }
    }

    case 'STRIPE_CUSTOMER_PORTAL_RETRIEVED':
      return {
        ...state,
        stripeCustomerPortalUrl: action.stripeCustomerPortalUrl
      }

    case 'UPDATED_PROFILE_SUCCESSFULLY':
      {
        if (action.profileUpdateNeedsApproval && action.profileUpdateNeedsApproval === true) {
          sendPendingOperatorProfileUpdateEmailToAdmin(action.uid);
        }
        return {
          ...state,
          errorMsg: null,
          refresh: Math.random(),
          successMsg: action.successMsg
        }
      }

    case 'LOGIN_ERROR':
      // console.log('login error');
      return {
        ...state,
        sessionEmail: action.email,
        authError: 'Incorrect credentials.',
        loggedInOperatorMode: null
      }

    case 'LOGIN_SUCCESS':
      // console.log('login success');
      return {
        ...state,
        sessionEmail: action.email,
        authError: null,
        loggedInOperatorMode: action.loggedInOperatorMode
      }

    case 'LOGOUT_SUCCESS':
      // console.log('logout success');
      return {
        ...state,
        authError: null,
        authSuccess: null,
        data: null,
        subError: null,
        errorMsg: null,
        callError: null,
        sessionEmail: null,
        adminRole: null,
        loggedInOperatorMode: null
      }

    case 'SIGNUP_SUCCESS':
      // console.log('signup success')
      return {
        ...state,
        authError: null
      }

    case 'PHONE_EXISTS':
      return {
        ...state,
        authError: 'Phone number already used. Please try again with a different phone number.'
      }

    case 'EMAIL_EXISTS':
      return {
        ...state,
        authError: 'The email is already used. Please try again with a different email.'
      }

    case 'SIGNUP_ERROR':
      // console.log('signup error')
      return {
        ...state,
        authError: 'An error occurred, please try again.'
      }

    case 'OPERATOR_SIGNUP_STEP_ERROR':
      // console.log('OPERATOR_SIGNUP_STEP_ERROR')
      return {
        ...state,
        authError: 'Please provide details for the mandatory fields marked by *'
      }

    case 'OPERATOR_SIGNUP_STEP_ERROR_VIN':
      // console.log('OPERATOR_SIGNUP_STEP_ERROR')
      return {
        ...state,
        authError: 'Invalid Vehicle VIN'
      }

    case 'SIGNUP_INVALID':
      // console.log('signup error')
      return {
        ...state,
        authError: 'Please provide the mandatory details and try again.'
      }

    case 'INVALID_PHONE':
      // console.log('signup error')
      return {
        ...state,
        authError: action.errMsg || 'Please provide a valid phone number and try again.'
      }

    case 'INVALID_PASSWORD':
      return {
        ...state,
        authError: 'Password should be at least 8 characters'
      }

    case 'PASSWORD_UPDATE_SUCCESSFUL':
      {
        sendPasswordUpdatedConfirmationEmail(action.uid, action.token);
        return {
          ...state,
          authError: null,
          authSuccess: 'Password has been updated successfully.'
        }
      }

    case 'PASSWORD_UPDATE_FAILED':
      // console.log('authReducer -> password update failed');
      return {
        ...state,
        authError: 'Password update failed. ' + action.err,
        authSuccess: null
      }

    case 'FORGOT_PASSWORD_SUCCESSFUL':
      // console.log('authReducer -> forgot password successful');
      return {
        ...state,
        authError: null,
        authSuccess: 'Password reset instructions have been sent to your email address.'
      }

    case 'FORGOT_PASSWORD_FAILED':
      // console.log('authReducer -> forgot password failed');
      return {
        ...state,
        authError: 'Please enter a valid email address and try again.',
        authSuccess: null
      }

    case 'NO_USER_LOGGED_IN':
      // console.log('authReducer -> no user logged in);
      return {
        ...state,
        authError: 'Request failed. Please try again later.',
        authSuccess: null
      }

    case 'PROCESSED_SUB':
      // console.log('PROCESSED_SUB', action.data);
      return {
        ...state,
        status: action.data
      }

    case 'ERROR_PROCESSING_SUB':
      return {
        ...state,
        subError: 'Failed to process subscription.'
      }
    case 'PHONE_VERIFICATION_ERROR':
      return {
        ...state,
        authError: 'Phone number verification failed. Please try again.'
      }
    case 'ERROR_PROCESSING_RIDE_PAYMENT':
      return {
        ...state,
        subError: 'Failed to process ride payment.',
        updated: false
      }

    case 'SETUP_INTENT_CREATED':
      return {
        ...state,
        profile: action.userProfile
      }

    case 'UPDATED_PAYMENT_METHOD':
      return {
        ...state,
        profile: action.userProfile,
        paymentUpdated: action.paymentUpdated
      }

    case 'PAYMENT_METHODS_RETRIEVED':
      return {
        ...state,
        rows: action.rows,
        errorMsg: null
      }

    case 'CLEAR_PAYMENT_ERROR':
      return {
        ...state,
        errorMsg: null
      }

    case 'ERROR':
      return {
        ...state,
        errorMsg: action.errorMsg,
      }

    case 'PROCESSED_RIDE_PAYMENT':
      return {
        ...state,
        updated: true,
        project: action.project
      }

    case 'ERROR_CALLING':
      return {
        ...state,
        callError: action.msg
      }


    case 'STRIPE_DASHBOARD_LINK':
      return {
        ...state,
        url: action.url,
        errorMsg: action.errorMsg
      }

    case 'RETRIEVED_USER_DETAILS':
      return {
        ...state,
        profile: action.userProfile
      }

    case 'RETRIEVED_ADMIN_DETAILS':
      return {
        ...state,
        adminRole: action.adminRole
      }

    case 'CLEAR_CONTACT_US_ERROR':
      return {
        ...state,
        contactError: null,
        contactSuccess: null
      }

    case 'CONTACT_US_ERROR':
      return {
        ...state,
        contactError: action.contactError,
        contactSuccess: null
      }

    case 'CONTACT_US_SUCCESSFUL':
      return {
        ...state,
        contactError: null,
        contactSuccess: true
      }

    case 'PROFILE_RETRIEVE_SUCCESSFUL':
      return {
        ...state,
        userProfile: action.userProfile,
      }
    case 'FAILED_TO_RETRIEVE_PROFILE':
      return {
        ...state,
        err: action.err,
      }
    case 'PROFILES_RETRIEVE_SUCCESSFUL':
      return {
        ...state,
        pendingOperators: action.pendingOperators,
      }
    case 'FAILED_TO_RETRIEVE_PROFILES':
      return {
        ...state,
        err: action.err,
      }
    case 'OPERATOR_APPROVED_SUCCESSFUL':
      return {
        ...state,
        userProfile: action.userProfile,
      }
    case 'FAILED_TO_APPROVE_OPERATOR':
      return {
        ...state,
        err1: action.err1,
      }
    case 'SEARCH_RETRIEVE_SUCCESSFUL':
      return {
        ...state,
        searchResultList: action.searchResultList,
        searchError: null
      }
    case 'FAILED_TO_RETRIEVE_SEARCH_RESULT':
      return {
        ...state,
        searchResultList: null,
        searchError: action.err1,
      }

    case 'DEAUTHORIZED_STRIPE_ACCOUNT_SUCCESS':
      return {
        ...state,
      }
    case 'DEAUTHORIZED_STRIPE_ACCOUNT_SUCCESS_FAILURE':
      return {
        ...state,
        errorMsg: action.errorMsg,
      }

    case 'MAILLIST_SIGNUP_SUCCESS':
      return {
        ...state,
        mailListSignUpError: null,
        mailListSignUpSuccess: true
      }
    case 'MAILLIST_SIGNUP_ERROR':
      return {
        ...state,
        mailListSignUpError: action.err
      }
    case 'MAILLIST_EMAIL_EXISTS':
      return {
        ...state,
        mailListSignUpError: 'The email address is already been added to the FINDANIO marketing email list.'
      }

    case 'RETRIEVED_CAR_MAKE_LIST':
      return {
        ...state,
        carMakeList: action.carMakeList
      }

    case 'OPERATOR_APPROVAL_ON_HOLD':
      return {
        ...state,
        userProfile: action.userProfile,
      }


    case 'FAILED_TO_ HOLD_APPROVAL_OF_OPERATOR':
      return {
        ...state,
        err3: action.err3,
      }


    case 'GLOBAL_SIGNUP_SUCCESS':
      return {
        ...state,
        globalSignUpError: null,
        globalSignUpSuccess: true
      }

    case 'GLOBAL_SIGNUP_ERROR':
      return {
        ...state,
        globalSignUpError: action.err,
        globalSignUpSuccess: false
      }

    case 'GLOBAL_EMAIL_EXISTS':
      return {
        ...state,
        globalSignUpError: 'The email address is already in use.',
        globalSignUpSuccess: false
      }


    default:
      return state
  }
};

function sendPendingOperatorProfileUpdateEmailToAdmin(uid) {
  let url = baseURL + '/sendOperatorProfileUpdatedNotification';
  let data = {
    uid,
  }
  axios.post(url, data)
    .then(response => {
      // console.log('sent email successfully - ', response);
      return;
    })
    .catch(err => {
      // console.error('an error occurred while sending email - ', err);
      return;
    })
  return;
}

async function sendPasswordUpdatedConfirmationEmail(uid, token) {
  let url = baseURL + '/sendPasswordUpdatedConfirmationEmailFn';
  let jwtToken = null;
  if (uid) {
    jwtToken = jwt.sign({
      exp: Math.floor(Date.now() / 1000) + (5 * 60),
      uid: uid
    }, jwtSecret);
  } else if (token) {
    jwtToken = token;
  }
  let data = {
    token: jwtToken
  }
  try {
    const response = await axios.post(url, data);
    // console.log('sent email successfully - ', response);
    return;
  }
  catch (err) {
    // console.error('an error occurred while sending email - ', err);
    return;
  }
}

export default authReducer;