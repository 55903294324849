import React, { Component } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InjectedCardSetupForm from './InjectedCardSetupForm';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Loader1 from '../../loader/Loader1'
import { createSetupIntent, getUserProfile } from '../../../store/actions/authActions';
import { stripePublishableKey } from "../../constants/CommonConstants";
import * as ga from '../../util/googleAnalytics'

const stripePromise = loadStripe(stripePublishableKey);

class AddPaymentMethod extends Component {

    componentDidMount() {
        const { auth, profile } = this.props;
        ga.pageViewsTracking(window.location.pathname);
        if (auth && auth.uid && !profile) {
            this.props.getUserProfile(auth.uid);
        }
        if (profile && !profile.stripeSetupIntentClientSecret) {
            this.props.createSetupIntent({
                email: profile.email,
                uid: auth.uid
            })
        }
    }

    componentDidUpdate() {
        const { auth, profile } = this.props;
        if (auth && auth.uid && !profile) {
            this.props.getUserProfile(auth.uid);
        }
        if (profile && !profile.stripeSetupIntentClientSecret) {
            this.props.createSetupIntent({
                email: profile.email,
                uid: auth.uid
            })
        }
    }

    render() {
        const { auth, profile } = this.props;
        if (!auth.uid) return <Redirect to='/login' />
        if (profile && profile.stripeSetupIntentClientSecret) {
            return (
                <>
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-8 col-12">
                            <Elements stripe={stripePromise}  >
                                <InjectedCardSetupForm
                                    redirectToPath={this.props.redirectToPath}
                                    secret={profile.stripeSetupIntentClientSecret} />
                            </Elements>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <Loader1 />
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.auth.profile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createSetupIntent: (obj) => dispatch(createSetupIntent(obj)),
        getUserProfile: (uid) => dispatch(getUserProfile(uid))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethod)
