import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { getRideDetails, clearRideDetails, clearErrorAction, editRideAction, clearCreatedProjectStatus } from '../../../store/actions/projectActions';
import Loader1 from '../../loader/Loader1';
import Breadcrumb from "../../common/Breadcrumb";
import ConfirmationMessage from '../../confirmationMessage/ConfirmationMessage';
import { coeff, format } from '../../constants/CommonConstants';
import ErrorMessage from "../../errorMessage/ErrorMessage"
import ServiceHours from '../ServiceHours'
import DatePicker from "react-datepicker";
import PhoneInput from 'react-phone-input-2';
import EditRideDetailsRidePrice from '../EditRideDetailsRidePrice';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import MapWithADirectionsRenderer from "../../maps/MapWithADirectionsRenderer";
import RideDistance from '../RideDistance';
import M from "materialize-css";
import DisclaimerMessages from "../../disclaimerMessages/DisclaimerMessages"

class EditTravelerRideDetails extends Component {

  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handleChange = this.handleChange.bind(this);
    this.handleCalculateDistance = this.handleCalculateDistance.bind(this);
    this.updateCalculatedPrice = this.updateCalculatedPrice.bind(this);
    this.reviewRideChanges = this.reviewRideChanges.bind(this);
    this.confirmRideChanges = this.confirmRideChanges.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.getServiceVehicleBags = this.getServiceVehicleBags.bind(this);
    this.getServiceVehiclePersons = this.getServiceVehiclePersons.bind(this);
    this.getServiceHours = this.getServiceHours.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.cancelRideChanges = this.cancelRideChanges.bind(this);
    this.resetRideChanges = this.resetRideChanges.bind(this);
    this.handlePlaceSelectFrom = this.handlePlaceSelectFrom.bind(this);
    this.handlePlaceSelectTo = this.handlePlaceSelectTo.bind(this);
  }

  initialState() {
    return {
      rideEdited: false,
      serviceFrom: '',
      serviceFromName: '',
      serviceTo: '',
      serviceToName: '',
      serviceDate: new Date(Math.round((new Date().getTime() + 30 * 60000) / coeff) * coeff),
      serviceType: 'Point-to-Point',
      serviceHours: 2,
      serviceVehicle: 'Sedan',
      specialNotes: '',
      serviceVehiclePersons: 3,
      serviceVehicleBags: 3,
      fromlat: "",
      fromlng: "",
      tolat: "",
      tolng: "",
      calculatedDistance: -1,
      serviceFromState: '',
      serviceToState: '',
      loading: false,
      validServiceTo: false,
      validServiceFrom: false,
      selectedPaymentMethod: '',
      selectedPaymentMethodStr: '',
      requestorPrice: 0.00,
      requestorPriceError: false,
      passengerFirstName: '',
      passengerLastName: '',
      passengerPhone: '',
      calculatedPrice: {
        Sedan: 0.00,
        LuxurySedan: 0.00,
        SUV: 0.00,
        PassengerVan: 0.00,
        CorporateSprinter: 0.00,
        LimoSprinter: 0.00,
        Bus24Passenger: 0.00,
        Bus34Passenger: 0.00,
        CoachBus: 0.00,
        StretchLimo: 0.00,
        StretchSUV: 0.00
      },
      rateCalculated: false,
      error: false,
      calculatedRideDuration: '',
      utc_offset_minutes: 99999,
      serviceDateStr: moment(new Date(Math.round((new Date().getTime() + 15 * 60000) / coeff) * coeff)).format("dddd, MMMM Do, YYYY hh:mm A"),
      serviceDateActual: new Date(Math.round((new Date().getTime() + 15 * 60000) / coeff) * coeff),
    }
  }

  reviewRideChanges = () => {
    var elems = document.getElementById('confirmRideChangesReviewModal');
    M.Modal.init(elems, {});
    M.Modal.getInstance(elems).open();
  }

  resetRideChanges = () => {
    this.props.clearError();
    const { originalRideDetails } = this.props;
    if (originalRideDetails) {
      this.setState({
        loading: true,
        rideEdited: false,
        ...originalRideDetails,
        rateCalculated: false,
        serviceDate: moment(originalRideDetails.serviceDate.toDate()).toDate(),
      }, () => {
        this.setState({
          loading: false
        })
      })
    }
  }

  cancelRideChanges = () => {
    this.setState({
      rideEdited: false
    }, () => {
      this.props.updateEditRideStatus(false);
    })
  }

  confirmRideChanges = () => {
    // console.log('confirming ride changes now');
    this.setState({
      loading: true
    }, () => {
      this.props.editRideAction({ ...this.state, rideId: this.props.rideId })
        .then(() => {
          var elems = document.getElementById('confirmRideChangesReviewModal');
          M.Modal.getInstance(elems).close();
          this.setState({
            loading: false
          });
          this.props.updateEditRideStatus(false);
        })
    })
  }

  getServiceVehicleBags(vehicle) {
    switch (vehicle) {
      case 'Sedan': return 3;
      case 'LuxurySedan': return 3;
      case 'SUV': return 6;
      case 'PassengerVan': return 12;
      case 'CorporateSprinter': return 12;
      case 'LimoSprinter': return 12;
      case 'Bus24Passenger': return 24;
      case 'Bus34Passenger': return 34;
      case 'CoachBus': return 55;
      case 'StretchLimo': return 3;
      case 'StretchSUV': return 6;
      default: return 0;
    }
  }

  getServiceVehiclePersons(vehicle) {
    switch (vehicle) {
      case 'Sedan': return 3;
      case 'LuxurySedan': return 3;
      case 'SUV': return 6;
      case 'PassengerVan': return 12;
      case 'CorporateSprinter': return 12;
      case 'LimoSprinter': return 12;
      case 'Bus24Passenger': return 24;
      case 'Bus34Passenger': return 34;
      case 'CoachBus': return 55;
      case 'StretchLimo': return 8;
      case 'StretchSUV': return 16;
      default: return 0;
    }
  }

  getServiceHours(vehicle) {
    switch (vehicle) {
      case 'Sedan': return 2;
      case 'LuxurySedan': return 2;
      case 'SUV': return 2;
      case 'PassengerVan': return 3;
      case 'CorporateSprinter': return 3;
      case 'LimoSprinter': return 3;
      case 'Bus24Passenger': return 3;
      case 'Bus34Passenger': return 4;
      case 'CoachBus': return 5;
      case 'StretchLimo': return 3;
      case 'StretchSUV': return 3;
      default: return 2;
    }
  }

  onTimeChange(value) {
    if (value && value._d) {
      let serviceDate = new Date(new Date(new Date(new Date(this.state.serviceDate.getTime()).setHours(value._d.getHours())).setMinutes(value._d.getMinutes())));
      // console.log('--onTimeChange ', this.state.utc_offset_minutes, this.state.serviceDate.getTimezoneOffset())
      let diff = this.state.utc_offset_minutes === 99999 ? 0 : this.state.serviceDate.getTimezoneOffset() + this.state.utc_offset_minutes;
      if (this.state.utc_offset_minutes < 0) {
        diff = diff * -1;
      }
      const serviceDateActual = new Date(serviceDate.valueOf() + (diff * 60 * 1000));
      const serviceDateStr = moment(serviceDate).format("dddd, MMMM Do, YYYY hh:mm A");
      // console.log('onTimeChange - 1-', serviceDateActual, serviceDateStr);
      this.setState({
        rideEdited: true,
        serviceDate: serviceDate,
        serviceDateActual: serviceDateActual,
        serviceDateStr: serviceDateStr
      })
    }
  }

  onDateChange(value) {
    if (value) {
      let serviceDate = value;
      // console.log('--onDateChange ', this.state.utc_offset_minutes, this.state.serviceDate.getTimezoneOffset())
      let diff = this.state.utc_offset_minutes === 99999 ? 0 : this.state.serviceDate.getTimezoneOffset() + this.state.utc_offset_minutes;
      if (this.state.utc_offset_minutes < 0) {
        diff = diff * -1;
      }
      const serviceDateActual = new Date(serviceDate.valueOf() + (diff * 60 * 1000));
      const serviceDateStr = moment(serviceDate).format("dddd, MMMM Do, YYYY hh:mm A");
      // console.log('onDateChange - 1-', serviceDateActual, serviceDateStr);
      this.setState({
        rideEdited: true,
        serviceDate: serviceDate,
        serviceDateActual: serviceDateActual,
        serviceDateStr: serviceDateStr
      })
      // this.setState({
      //   serviceDate: value,
      // })
    }
  }

  handleChange = (e) => {
    this.props.clearCreatedProjectStatus();
    if (e.target.id === 'serviceFrom') {
      this.setState({
        rideEdited: true,
        serviceFrom: e.target.value,
        serviceFromState: '',
        serviceFromShort: '',
        fromlat: '',
        fromlng: '',
        calculatedPrice: {
          Sedan: 0.00,
          LuxurySedan: 0.00,
          SUV: 0.00,
          PassengerVan: 0.00,
          CorporateSprinter: 0.00,
          LimoSprinter: 0.00,
          Bus24Passenger: 0.00,
          Bus34Passenger: 0.00,
          CoachBus: 0.00,
          StretchLimo: 0.00,
          StretchSUV: 0.00
        },
        calculatedDistance: -1,
        validServiceFrom: false,
        requestorPrice: 0.0,
        requestorPriceError: false,
        calculatedRideDuration: '',
        utc_offset_minutes: 99999,
      })
    } else if (e.target.id === 'serviceTo') {
      this.setState({
        rideEdited: true,
        serviceTo: e.target.value,
        serviceToShort: '',
        serviceToState: '',
        tolat: '',
        tolng: '',
        calculatedPrice: {
          Sedan: 0.00,
          LuxurySedan: 0.00,
          SUV: 0.00,
          PassengerVan: 0.00,
          CorporateSprinter: 0.00,
          LimoSprinter: 0.00,
          Bus24Passenger: 0.00,
          Bus34Passenger: 0.00,
          CoachBus: 0.00,
          StretchLimo: 0.00,
          StretchSUV: 0.00
        },
        calculatedDistance: -1,
        validServiceTo: false,
        requestorPrice: 0.0,
        requestorPriceError: false,
        calculatedRideDuration: ''
      })
    } else if (e.target.id === 'serviceVehicle') {
      let currentLoading = this.state.loading;
      if (this.state.serviceType === 'HourlyCharter') {
        this.setState({
          rideEdited: true,
          serviceVehicle: e.target.value,
          serviceVehiclePersons: this.getServiceVehiclePersons(e.target.value),
          serviceVehicleBags: this.getServiceVehicleBags(e.target.value),
          serviceHours: this.getServiceHours(e.target.value),
          loading: this.state.validServiceFrom === true && this.state.validServiceTo === true ? true : currentLoading
        })
      } else {
        this.setState({
          rideEdited: true,
          serviceVehicle: e.target.value,
          serviceVehiclePersons: this.getServiceVehiclePersons(e.target.value),
          serviceVehicleBags: this.getServiceVehicleBags(e.target.value),
          // serviceHours: this.getServiceHours(e.target.value),
          loading: this.state.validServiceFrom === true && this.state.validServiceTo === true ? true : currentLoading
        })
      }
    } else if (e.target.id === 'serviceType') {
      let currentLoading = this.state.loading;
      this.setState({
        rideEdited: true,
        serviceType: e.target.value,
        serviceHours: this.getServiceHours(this.state.serviceVehicle),
        loading: this.state.validServiceFrom === true && this.state.validServiceTo === true ? true : currentLoading
      })
    } else {
      this.setState({
        rideEdited: true,
        [e.target.id]: e.target.value
      })
    }
  }

  handlePhoneChange = (value, data, event) => {
    this.setState({
      rideEdited: true,
      passengerPhone: value.replace(/[^0-9]+/g, '')
    })
  }

  componentDidMount() {
    let charCounter = document.querySelectorAll('.has-character-counter');
    M.CharacterCounter.init(charCounter);

    const google = window.google;
    var options = {
      componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] }
    };
    this.autocompleteServiceFrom = new google.maps.places.Autocomplete(document.getElementById('serviceFrom'), options)
    this.autocompleteServiceFrom.addListener("place_changed", this.handlePlaceSelectFrom);

    this.autocompleteServiceTo = new google.maps.places.Autocomplete(document.getElementById('serviceTo'), options)
    this.autocompleteServiceTo.addListener("place_changed", this.handlePlaceSelectTo);

    this.props.clearError();
    const { originalRideDetails } = this.props;
    if (originalRideDetails) {
      this.setState({
        ...originalRideDetails,
        serviceDate: moment(originalRideDetails.serviceDate.toDate()).toDate(),
      })
    }
  }

  handlePlaceSelectFrom() {
    let addressObject = this.autocompleteServiceFrom.getPlace();
    // console.log('from changed - ', addressObject.utc_offset_minutes, this.state.serviceDate.getTimezoneOffset())
    let diff = this.state.serviceDate.getTimezoneOffset() + addressObject.utc_offset_minutes;
    if (addressObject.utc_offset_minutes < 0) {
      diff = diff * -1.;
    }
    // console.log('diff', diff)
    const serviceDateActual = new Date(this.state.serviceDate.valueOf() + (diff * 60 * 1000));
    const serviceDateStr = moment(this.state.serviceDate).format("dddd, MMMM Do, YYYY hh:mm A");
    // console.log('handlePlaceSelectFrom - 1-', serviceDateActual, serviceDateStr);
    let state = null;
    let locality = null;
    let postalCode = null;
    if (addressObject && addressObject.address_components && addressObject.geometry) {
      addressObject.address_components.forEach(component => {
        component.types.forEach(type => {
          if (type === 'administrative_area_level_1') {
            state = component.short_name;
          }
          if (type === 'locality') {
            locality = component.short_name;
          }
          if (type === 'postal_code') {
            postalCode = component.short_name;
          }
        })
      })
      // console.log('handlePlaceSelectFrom ', state, locality, postalCode, addressObject, `${locality ? locality : ''}${state ? ', ' + state : ''}${postalCode ? ', ' + postalCode : ''}`)
      let currentLoading = this.state.loading;
      this.setState({
        rideEdited: true,
        serviceFrom: addressObject.formatted_address,
        serviceFromName: addressObject.name,
        serviceFromShort: `${locality ? locality : ''}${state ? ', ' + state : ''}${postalCode ? ', ' + postalCode : ''}`,
        serviceFromState: state,
        validServiceFrom: true,
        fromlat: addressObject.geometry.location.lat(),
        fromlng: addressObject.geometry.location.lng(),
        loading: this.state.serviceTo !== '' && this.state.validServiceTo === true ? true : currentLoading,
        utc_offset_minutes: addressObject.utc_offset_minutes,
        serviceDateActual: serviceDateActual,
        serviceDateStr: serviceDateStr
      })
    }
  }

  handlePlaceSelectTo() {
    let addressObject = this.autocompleteServiceTo.getPlace();
    let currentLoading = this.state.loading;
    let state = null;
    let locality = null;
    let postalCode = null;
    if (addressObject && addressObject.address_components && addressObject.geometry) {
      addressObject.address_components.forEach(component => {
        component.types.forEach(type => {
          if (type === 'administrative_area_level_1') {
            state = component.short_name;
          }
          if (type === 'locality') {
            locality = component.short_name;
          }
          if (type === 'postal_code') {
            postalCode = component.short_name;
          }
        })
      })
      // console.log('handlePlaceSelectTo ', state, locality, postalCode, addressObject, `${locality ? locality : ''}${state ? ', ' + state : ''}${postalCode ? ', ' + postalCode : ''}`)
      this.setState({
        rideEdited: true,
        serviceTo: addressObject.formatted_address,
        serviceToName: addressObject.name,
        serviceToShort: `${locality ? locality : ''}${state ? ', ' + state : ''}${postalCode ? ', ' + postalCode : ''}`,
        serviceToState: state,
        validServiceTo: true,
        tolat: addressObject.geometry.location.lat(),
        tolng: addressObject.geometry.location.lng(),
        loading: this.state.serviceFrom !== '' && this.state.validServiceFrom === true ? true : currentLoading
      })
    }
  }

  handleCalculateDistance(distance, duration) {
    this.setState({
      calculatedDistance: distance,
      calculatedRideDuration: duration
    })
  }

  updateCalculatedPrice(obj) {
    if (obj.refresh) {
      this.setState({
        loading: false,
        rateCalculated: true,
      })
    } else {
      this.setState({
        calculatedPrice: obj,
        rateCalculated: true,
        loading: false,
      })
    }
  }

  render() {
    const { originalRideDetails, auth, profile, successMsg, errorMsg } = this.props;
    if (!auth.uid) return <Redirect to='/login' />
    if (originalRideDetails && auth && (originalRideDetails === 'badData' || originalRideDetails.authorId !== auth.uid)) {
      return (
        <Redirect to='/404' />
      )
    }

    let loader = null;
    if (this.state.loading === true) {
      loader = <Loader1 />
    }

    let conditionsAppliedTxt = ' (*Airport fees, tolls, parking, wait times and additional stops might apply)';
    let serviceTypeRelatedDiv = null;
    if (this.state.serviceType === 'HourlyCharter') {
      conditionsAppliedTxt = ' (*Hourly jobs booked garage-to-garage or check with your operator.)';
      serviceTypeRelatedDiv = (
        <div className="col-12">
          <ServiceHours
            serviceHours={this.state.serviceHours}
            serviceVehicle={this.state.serviceVehicle}
            handleChange={this.handleChange}
          />
        </div>
      )
    } else {
      serviceTypeRelatedDiv = null;
    }


    if (auth && originalRideDetails && profile) {
      const rideConfirmationCode = originalRideDetails.rideConfirmationCode;
      return (
        <>
          {loader}
          <Breadcrumb title1={'Modify Ride Details'} title={rideConfirmationCode} parent='Rides' parentLink='/rides' />
          <main className="contact-form--1 main_container mt--40 mb--30 bg_color--1">
            <div className="section-title text-left">
              <p><b>Ride Code:</b> {originalRideDetails.rideConfirmationCode ? originalRideDetails.rideConfirmationCode : 'N/A'}</p>
            </div>

            <div className="single-tab-content">
              <div className="row plr-20">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <u><h3> MODIFY RIDE DETAILS</h3></u>
                  <br />
                  {successMsg
                    ? <ConfirmationMessage message={successMsg}></ConfirmationMessage>
                    : errorMsg
                      ? <ErrorMessage errorMessageText={errorMsg}></ErrorMessage>
                      : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <h3>Step 1: Modify ride</h3>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="serviceType">Service Type<span className="star">*</span></label>
                    <select id='serviceType' value={this.state.serviceType} onChange={this.handleChange} >
                      <option value="Point-to-Point">
                        Point-to-Point
                      </option>
                      <option value="HourlyCharter">
                        Hourly Charter
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="serviceDate">Service Date<span className="star">*</span></label>
                    <br />
                    <DatePicker
                      id='serviceDate'
                      placeholderText="Click to select a date"
                      todayButton="Today"
                      dateFormat="MMMM d, yyyy"
                      showPopperArrow={false}
                      selected={this.state.serviceDate}
                      maxDate={new Date(new Date().setFullYear((new Date().getFullYear() + 1)))}
                      minDate={new Date()}
                      strictParsing
                      peekNextMonth={false}
                      onChange={this.onDateChange}
                    />
                  </div>

                  <div className="col-lg-6">
                    <label htmlFor="serviceTime">Service Time<span className="star">*</span></label>
                    <br />
                    <TimePicker
                      showSecond={false}
                      defaultValue={moment(this.state.serviceDate)}
                      value={moment(this.state.serviceDate)}
                      onChange={this.onTimeChange}
                      format={format}
                      use12Hours={true}
                      minuteStep={5}
                      id="serviceTime"
                      placement={'bottomRight'}
                      inputReadOnly />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="serviceFrom">Pick-Up Location<span className="star">*</span></label>
                    <input id="serviceFrom"
                      value={this.state.serviceFrom}
                      ref="input"
                      onChange={this.handleChange}
                      placeholder=""
                      type="text" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="serviceTo">Drop-Off Location<span className="star">*</span></label>
                    <input id="serviceTo"
                      value={this.state.serviceTo}
                      placeholder=""
                      ref="input"
                      onChange={this.handleChange}
                      type="text" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <label htmlFor="serviceVehicle">Vehicle Type<span className="star">*</span></label>
                    <select id='serviceVehicle' onChange={this.handleChange}
                      value={this.state.serviceVehicle}>
                      <option value="Sedan">Sedan</option>
                      <option value="LuxurySedan">Luxury Sedan</option>
                      <option value="SUV">SUV</option>
                      <option value="PassengerVan">Passenger Van</option>
                      <option value="CorporateSprinter">Corporate Sprinter</option>
                      <option value="LimoSprinter">Limo Sprinter</option>
                      <option value="Bus24Passenger">24 Passenger Bus</option>
                      <option value="Bus34Passenger">34 Passenger Bus</option>
                      <option value="CoachBus">CoachBus</option>
                      <option value="StretchLimo">Stretch Limo</option>
                      <option value="StretchSUV">Stretch SUV</option>
                    </select>
                  </div>
                  <div className="col-6 mt--30">
                    <div className="row">
                      <div className="col-md-2 col-sm-3 offset-md-1 col-3">
                        <i className="material-icons">person</i>
                      </div>
                      <div className="col-md-2 col-sm-3 col-3 ">{this.state.serviceVehiclePersons}</div>

                      <div className="col-md-2 col-sm-3 offset-md-2 col-3 ">
                        <i className="material-icons">card_travel</i>
                      </div>
                      <div className="col-md-2 col-sm-3 col-3 ">{this.state.serviceVehicleBags}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {serviceTypeRelatedDiv}
                </div>
              </div>
              <div className="col-lg-6" style={{ marginBottom: '10px' }}>
                <MapWithADirectionsRenderer
                  fromlat={this.state.fromlat}
                  fromlng={this.state.fromlng}
                  tolat={this.state.tolat}
                  tolng={this.state.tolng}
                  handleCalculateDistance={this.handleCalculateDistance}
                  suppressMarkers={false}
                  showCircle={false}
                />
              </div>
            </div>

            <div className="row mb--40">
              <div className="col-lg-12">
                <div>
                  {
                    this.state.calculatedPrice[this.state.serviceVehicle] < 0
                      ? <div>
                        Please submit a request to receive quotes.
                      </div>
                      :
                      <div>
                        <h3><b>FINDANIO recommended ride rate: </b> {this.state.rateCalculated ? `$${this.state.calculatedPrice[this.state.serviceVehicle]}` : 'N/A'}</h3>
                        <RideDistance
                          fromlat={this.state.fromlat}
                          fromlng={this.state.fromlng}
                          tolat={this.state.tolat}
                          tolng={this.state.tolng}
                          calculatedDistance={this.state.calculatedDistance}
                        />
                        <p>Duration: {this.state.calculatedRideDuration || 'N/A'}</p>
                      </div>
                  }
                  <EditRideDetailsRidePrice
                    rateCalculated={this.state.rateCalculated}
                    serviceFromState={this.state.serviceFromState}
                    updateCalculatedPrice={this.updateCalculatedPrice}
                    calculatedDistance={this.state.calculatedDistance}
                    serviceVehicle={this.state.serviceVehicle}
                    serviceType={this.state.serviceType}
                    serviceHours={this.state.serviceHours}
                  />
                </div>
                {this.state.calculatedPrice[this.state.serviceVehicle] !== 0.00
                  && this.state.calculatedPrice[this.state.serviceVehicle] > 0
                  ? (<p className="conditionsApplied">{conditionsAppliedTxt}</p>) : null}
              </div>
            </div>

            <hr />
            <div className="row">
              <div className="col-12">
                <h3>Step 2: Modify Passenger details</h3>
              </div>
            </div>

            <div className="row mt--30 mb--40">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <label htmlFor="passengerFirstName">Passenger First Name<span className="star">*</span></label>
                    <input type="text" id='passengerFirstName' value={this.state.passengerFirstName} onChange={this.handleChange} />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="passengerLastName">Passenger Last Name<span className="star">*</span></label>
                    <input type="text" id='passengerLastName' value={this.state.passengerLastName} onChange={this.handleChange} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="passengerPhone">Passenger Phone<span className="star">*</span></label>
                    <PhoneInput
                      specialLabel=""
                      id='passengerPhone'
                      lab
                      onlyCountries={['us']}
                      country={'us'}
                      disableCountryCode={true}
                      disableSearchIcon={true}
                      disableDropdown={true}
                      placeholder={'(xxx) xxx-xxxx'}
                      value={this.state.passengerPhone}
                      onChange={this.handlePhoneChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12">
                    <label htmlFor="specialNotes">Special Notes</label>
                    <textarea id="specialNotes" className="materialize-textarea has-character-counter"
                      onChange={this.handleChange}
                      value={this.state.specialNotes}
                      cols={5}
                      data-length="500"
                      maxLength="500"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt--30">
              <div className="col-12">
                <DisclaimerMessages disclaimerMessagesArr={
                  [
                    'In case of change of price, the original pre-authorized amount will be cancelled and your payment method will be pre-authorized for the new amount.'
                  ]
                }></DisclaimerMessages>
              </div>
            </div>

            <div className="row mt--30">
              <div className="col-6">
                <button
                  onClick={this.cancelRideChanges}
                  className="rn-button-style--2 btn-solid">Back</button>
              </div>
              {/* <div className="col-4">
                <button
                  onClick={this.resetRideChanges}
                  className="rn-button-style--2 btn-solid">Reset</button>
              </div> */}
              <div className="col-6">
                <button
                  disabled={this.state.rideEdited === false? true : false}
                  onClick={this.reviewRideChanges}
                  className="rn-button-style--2 btn-solid">Review</button>
              </div>
            </div>

            <div id="confirmRideChangesReviewModal" className="modal">
              <div className="modal-content modalForm">
                <div>
                  <i className="material-icons modal-close float-right">close</i>
                  <h4 className="center"><b>Review ride details</b></h4>
                  <div className="ml--20">
                    <b className="error-text">From:</b> {this.state.serviceFrom} {this.state.serviceFromName ? `(${this.state.serviceFromName})` : ''}<br /><hr />
                    <b className="error-text">To:</b> {this.state.serviceTo} {this.state.serviceToName ? `(${this.state.serviceToName})` : ''}<br />  <hr />
                    <b className="error-text">Date:</b> {this.state.serviceDateStr}<br />  <hr />
                    <b className="error-text">Service Type:</b> {this.state.serviceType}<br />  <hr />
                    <b className="error-text">Vehicle Type:</b> {this.state.serviceVehicle}<br />  <hr />
                    <b className="error-text">Passenger Name:</b> {this.state.passengerFirstName + ' ' + this.state.passengerLastName}<br />  <hr />
                    <b className="error-text">Passenger Phone:</b> {this.state.passengerPhone}<br />  <hr />
                    <b className="error-text breakAll">Special Notes:</b> {this.state.specialNotes}<br />  <hr />
                    <b className="error-text">Ride Rate:</b> {
                      this.state.calculatedPrice[this.state.serviceVehicle] < 0
                        ? 'N/A'
                        : "$" + this.state.calculatedPrice[this.state.serviceVehicle]
                    }<br />
                    <hr />
                  </div>
                </div>
                <div className="center">
                  <button className="rn-button-style--2 btn-solid mt--5" onClick={this.confirmRideChanges}>Confirm ride changes</button>
                  <button className="rn-button-style--2 btn-solid modal-close mt--5 ml--10">Cancel</button>
                </div>
              </div>
            </div>

          </main>
        </>
      )
    } else {
      return (
        <>
          <Breadcrumb title1={'Modify Ride Details'} title={'XXXXXXX'} parent='Rides' parentLink='/rides' />
          <main className="contact-form--1 main_container mt--40 mb--30 bg_color--1">
            <div className="section-title text-left">
              <p className="description">Loading</p>
              <Loader1 />
            </div>
          </main>
        </>
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const rideId = ownProps.rideId;
  const originalRideDetails = ownProps.originalRideDetails;
  return {
    originalRideDetails: originalRideDetails,
    auth: state.firebase.auth,
    rideId: rideId,
    profile: state.firebase.profile,
    successMsg: state.project.successMsg,
    errorMsg: state.project.errorMsg
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearError: () => dispatch(clearErrorAction()),
    getRideDetails: (rideId) => dispatch(getRideDetails(rideId)),
    clearRideDetails: (ride) => dispatch(clearRideDetails(ride)),
    editRideAction: (ride) => dispatch(editRideAction(ride)),
    clearCreatedProjectStatus: () => dispatch(clearCreatedProjectStatus()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTravelerRideDetails)
