import axios from 'axios';

import { baseURL } from '../../components/constants/CommonConstants';

const initState = {
	rideCreationErr: null,
	rideCreationStatus: false,
	errorMsg: null,
	successMsg: null,
	travelerRidesToday: null,
	travelerFilteredProjects: null
};

const projectReducer = (state = initState, action) => {
	switch (action.type) {
		case 'CLEAR_ERROR':
			return {
				...state,
				errorMsg: null,
				successMsg: null
			};

		case 'RETRIEVED_OPERATOR_LOCATIONS':
			return {
				...state,
				locations: action.locations
			};

		case 'TRAVELER_REQUESTED_RIDES_FETCHED_SUCCESSFULLY':
			return {
				...state,
				travelerRequestedProjects: action.travelerRequestedProjects,
				errorMsg: null
			};

		case 'TRAVELER_PENDING_APPROVAL_RIDES_FETCHED_SUCCESSFULLY':
			return {
				...state,
				travelerPendingApprovalRides: action.travelerPendingApprovalRides,
				errorMsg: null
			};

		case 'UPCOMING_REQUESTED_RIDES_FETCHED_SUCCESSFULLY':
			return {
				...state,
				travelerUpcomingProjects: action.travelerUpcomingProjects,
				errorMsg: null
			};

		case 'ACCEPTED_RIDES_FETCHED_SUCCESSFULLY':
			return {
				...state,
				acceptedRides: action.acceptedRides,
				errorMsg: null
			};

		case 'PENDING_ACCEPTED_RIDES_FETCHED_SUCCESSFULLY':
			return {
				...state,
				pendingAcceptedRides: action.pendingAcceptedRides,
				errorMsg: null
			};

		case 'OPERATOR_UPCOMING_RIDES_FETCHED_SUCCESSFULLY':
			return {
				...state,
				operatorUpcomingRides: action.operatorUpcomingRides,
				errorMsg: null
			};

		case 'OPERATOR_PAST_RIDES_FETCHED_SUCCESSFULLY':
			return {
				...state,
				operatorPastRides: action.operatorPastRides,
				errorMsg: null
			};

		case 'ERROR_FETCHING_RIDES':
			return {
				...state,
				errorMsg: action.errorMsg
			};

		case 'UPCOMING_RIDES_FETCHED_SUCCESSFULLY':
			return {
				...state,
				upcomingProjects: action.upcomingProjects,
				errorMsg: null
			};

		case 'ERROR_FETCHING_UPCOMING_RIDES':
			return {
				...state,
				errorMsg: action.errorMsg
			};

		case 'CREATE_PROJECT_SUCCESS':
			return {
				...state,
				rideCreationErr: null,
				rideCreationStatus: true,
				createdRideId: action.id
			};
		case 'CREATE_PROJECT_ERROR':
			return {
				...state,
				rideCreationErr: action.message,
				rideCreationStatus: false
			};

		case 'SUCCESSFULLY_ACCEPTED_RIDE':
			if (action.sendTravelerRideAcceptedMsg === true) {
				sendTravelerRideAcceptedMessage(action.projectId, action.requestorPhone);
			}
			return {
				...state,
				rideDetails: action.updatedProject
			};

		case 'SUCCESSFULLY_UPDATED_RIDE_STATUS':
			sendTravelerRideUpdatedMessage(action.projectId, action.requestorPhone, action.msg);
			return {
				...state,
				rideDetails: action.updatedProject
			};

		case 'UPDATED_EXTRA_STOP_BY_REQUESTOR_SUCCESSFUL':
			sendOperatorRideUpdatedMessage(action.projectId, action.operatorPhone, action.msg);
			return {
				...state,
				rideDetails: action.updatedProject
			};

		case 'SUCCESSFULLY_DENIED_RIDE':
			return {
				...state,
				rideDetails: action.updatedProject
			};

		case 'OPERATOR_DECLINED_CONFIRMATION':
			sendTravelerRideCannotBeConfirmedMsg(action.projectId, action.requestorPhone, action.operatorName);
			return {
				...state,
				rideDetails: action.updatedProject
			};

		case 'ERROR_UPDATING_RIDE_CURRENT_STATUS':
			return {
				...state,
				errorMsg: action.errorMsg,
				rideDetails: action.updatedProject
			};

		case 'OPERATOR_CANCELLED_RIDE':
			sendTravelerRideCancelledByOperatorMsg(
				action.projectId,
				action.requestorPhone,
				action.operatorName,
				action.requestorEmail
			);
			sendOperatorRideCancelledByOperatorMsg(action.projectId, action.operatorPhone);
			return {
				...state,
				rideDetails: action.updatedProject
			};

		case 'SUCCESSFULLY_CONFIRMED_RIDE':
			sendTravelerRideConfirmedMessage(action.projectId, action.requestorPhone, action.operatorName);
			sendOperatorRideConfirmedMessage(action.projectId, action.operatorPhone);
			return {
				...state,
				rideDetails: action.updatedProject
			};

		case 'ERROR_DENYING_RIDE':
			return state;

		case 'ERROR_ACCEPTING_RIDE':
			return state;

		case 'ERROR_CANCELLING_RIDE':
			return state;

		case 'ERROR_UPDATING_RIDE':
			return state;

		case 'SUCCESSFULLY_CANCELLED_RIDE':
			sendTravelerCancellationMsg(action.projectId, action.requestorPhone);
			if (action.operatorPhone !== null) {
				sendOperatorCancellationMsg(action.projectId, action.operatorPhone);
			}
			return {
				...state,
				rideDetails: action.updatedProject
			};

		case 'OPERATOR_CANCELLED_RIDE_ERR':
			return {
				...state,
				errorMsg: action.errorMsg
			};

		case 'CLEAR_RIDE_DETAILS':
			return {
				...state,
				rideDetails: null
			};

		case 'OPERATOR_DECLINED_CONFIRMATION_ERR':
			return state;

		case 'ERROR_CONFIRMING_RIDE':
			return state;

		case 'SEND_SELECTED_OPERATOR_MSG':
			sendSelectedOperatorMessage(action.id, action.phone, 'selected');
			return {
				...state,
				rideDetails: action.updatedProject,
				successMsg:
					'The selected operator is notified of the selection. Please wait for the confirmation from the operator.'
			};

		case 'CANCEL_SELECTED_OPERATOR_MSG':
			sendSelectedOperatorMessage(action.id, action.phone, 'unselected');
			return {
				...state,
				rideDetails: action.updatedProject,
				successMsg: 'The operator is un-selected successfully.'
			};

		case 'SEND_SELECTED_OPERATOR_REMINDER_MSG':
			sendReminderToSelectedOperator(action.id, action.phone, 'selected', action.operatorId);
			return {
				...state,
				rideDetails: action.updatedProject,
				successMsg: 'Reminder message sent to selected operator to confirm availability.'
			};

		case 'CLEAR_PROJECT_STATUS':
			return {
				...state,
				rideCreationErr: null,
				rideCreationStatus: false,
				createdRideId: null,
				successMsg: null,
				errorMsg: null,
				travelerRidesToday: null,
				travelerFilteredProjects: null
			};

		case 'RETRIEVED_RIDE_DETAILS':
			return {
				...state,
				rideDetails: action.rideDetails
			};

		case 'UPDATED_RIDE_DETAILS':
			return {
				...state,
				rideDetails: action.rideDetails,
				successMsg: 'Successfully updated ride details',
				errorMsg: null
			};

		case 'SENT_CHAT_MSG_SUCCESSFULLY':
			sendNewChatNotificationMsg(action.rideId, action.phone, action.userName, action.fromAccountType);
			return {
				...state,
				rideDetails: action.rideDetails
			};

		case 'SUCCESSFULLY_PAID_OPERATOR_TIP_AMOUNT':
			return {
				...state,
				rideDetails: action.updatedProject,
				successMsg: 'Successfully paid tip amount to operator',
				errorMsg: null
			};

		case 'ERROR_PAYING_TIP_AMOUNT':
			return {
				...state,
				successMsg: null,
				errorMsg: action.errorMsg
			};

		case 'TRAVELER_FILTERED_RIDES_FETCHED_SUCCESSFULLY':
			return {
				...state,
				successMsg: null,
				errorMsg: null,
				travelerFilteredProjects: action.travelerFilteredProjects
			};

		case 'TRAVELER_FILTERED_RIDES_TODAY_FETCHED_SUCCESSFULLY':
			return {
				...state,
				successMsg: null,
				errorMsg: null,
				travelerRidesToday: action.travelerRidesToday
			};

		case 'FETCH_CONFIRMED_OPR_LIST':
			return {
				...state,
				confirmedOperatorList: action.confirmedOperatorList
			};

		case 'RESENT_RIDE_CREATED_NOTIFICATION_SUCCESSFULLY':
			return {
				...state,
				successMsg: 'Reminder message sent to selected operator to confirm availability.',
				errorMsg: null
			};

		case 'RESENT_RIDE_CREATED_NOTIFICATION_FAILED':
			return {
				...state,
				successMsg: null,
				errorMsg: 'Failed to resend ride available notification to operators. Please try again later.'
			};

		default:
			return state;
	}
};

function sendNewChatNotificationMsg(rideId, phone, userName, fromAccountType) {
	// console.log('sendNewChatNotificationMsg = ', phone, userName, fromAccountType, rideId);
	let url = baseURL + '/sendNewChatNotificationMsg';
	let msg =
		fromAccountType === 'Operator'
			? 'You have a new chat message from your selected operator ' + userName
			: 'You have a new chat message from the traveler ' + userName;
	let data = {
		rideId: rideId,
		phone: phone,
		msg: msg,
		fromAccountType: fromAccountType
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendSelectedOperatorMessage(id, phone, status) {
	let url = baseURL + '/sendMessageToSelectedOperator';
	let data = {
		id: id,
		phone: phone,
		status: status
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendReminderToSelectedOperator(id, phone, status, operatorId) {
	let url = baseURL + '/sendReminderMessageToSelectedOperator';
	let data = {
		id: id,
		phone: phone,
		status: status,
		operatorId: operatorId
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendTravelerRideUpdatedMessage(projectId, requestorPhone, msg) {
	let url = baseURL + '/sendTravelerRideUpdatedMessage';
	let data = {
		id: projectId,
		phone: requestorPhone,
		msg: msg
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendOperatorRideUpdatedMessage(projectId, operatorPhone, msg) {
	let url = baseURL + '/sendOperatorRideUpdatedMessage';
	let data = {
		id: projectId,
		phone: operatorPhone,
		msg: msg
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendTravelerRideAcceptedMessage(projectId, requestorPhone) {
	let url = baseURL + '/sendRideAcceptedMsgToTraveler';
	let data = {
		id: projectId,
		phone: requestorPhone
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendTravelerRideConfirmedMessage(projectId, requestorPhone, operatorName) {
	let url = baseURL + '/sendRideConfirmedMsgToTraveler';
	let data = {
		id: projectId,
		phone: requestorPhone,
		operatorName: operatorName
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendOperatorRideConfirmedMessage(projectId, operatorPhone) {
	let url = baseURL + '/sendRideConfirmedMsgToOperator';
	let data = {
		id: projectId,
		phone: operatorPhone
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendTravelerRideCannotBeConfirmedMsg(projectId, requestorPhone, operatorName) {
	let url = baseURL + '/sendRideNotConfirmedMsgToTraveler';
	let data = {
		id: projectId,
		phone: requestorPhone,
		operatorName: operatorName
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendTravelerRideCancelledByOperatorMsg(projectId, requestorPhone, operatorName, requestorEmail) {
	let url = baseURL + '/sendRideCancelledByOperatorMsgToTraveler';
	let data = {
		id: projectId,
		phone: requestorPhone,
		operatorName: operatorName,
		requestorEmail: requestorEmail
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendOperatorRideCancelledByOperatorMsg(projectId, operatorPhone) {
	let url = baseURL + '/sendRideCancelledByOperatorMsgToOperator';
	let data = {
		id: projectId,
		phone: operatorPhone
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendOperatorCancellationMsg(projectId, operatorPhone) {
	let url = baseURL + '/sendRideCancelledByTravelerMsgToOperator';
	let data = {
		id: projectId,
		phone: operatorPhone
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

function sendTravelerCancellationMsg(projectId, requestorPhone) {
	let url = baseURL + '/sendRideCancelledByTravelerMsgToTraveler';
	let data = {
		id: projectId,
		phone: requestorPhone
	};
	axios
		.post(url, data)
		.then((response) => {
			// console.log('sent message successfully - ', response);
			return;
		})
		.catch((err) => {
			// console.error('an error occurred while sending message - ', err);
			return;
		});
	return;
}

export default projectReducer;
