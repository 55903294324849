import React, { Component } from 'react'
import RideQuote from './RideQuote'
import Breadcrumb from "../common/Breadcrumb";
import { NavLink } from 'react-router-dom'
import * as ga from '../util/googleAnalytics'

class InstantQuote extends Component {

  componentDidMount = () => {
    ga.pageViewsTracking(window.location.pathname);
  }

  render() {
    return (
      <>
        <Breadcrumb title={'Instant Quote'} />
        <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Get a free instant quote</h2>
                    <p className="description justify">
                      Select a service type
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt--20">
              <div className="col-12">
                <RideQuote />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="link-box mt--20 center bg_color--5">
                  <p className="description">Ready to travel? <NavLink to='/ride/create' className="link-red-color" onClick={this.linkScrollToTop}>Book now!</NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

export default InstantQuote
