import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import { connect } from 'react-redux'
import { updateUserStripePaymentMethod, validateCard } from '../../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import ErrorMessage from '../../errorMessage/ErrorMessage'

class CardSetupForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = this.initialState()
    }

    initialState() {
        return {
            errorMsg: '',
            redirect: false,
            email: '',
            name: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            country: 'US',
        }
    }

    handleSubmit = async (event) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        event.preventDefault();
        const { stripe, elements, secret, auth } = this.props
        if (!stripe || !elements || !secret || !auth) {
            // Stripe.js has not yet loaded. Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        this.props.validateCard(this.state)
            .then(async (isValidCard) => {
                if (isValidCard === true) {
                    const result = await stripe.confirmCardSetup(secret, {
                        payment_method: {
                            card: elements.getElement(CardElement),
                            type: "card",
                            billing_details: {
                                "name": this.state.name,
                                "email": this.state.email,
                                "address": {
                                    "line1": this.state.addressLine1,
                                    "line2": this.state.addressLine2,
                                    "city": this.state.city,
                                    "state": this.state.state,
                                    "country": this.state.country,
                                },
                            }
                        }
                    });
                    if (result.error) {
                        // Display result.error.message
                        this.setState({
                            errorMsg: result.error.message,
                        })
                    } else {
                        // The setup has succeeded
                        this.props.updateUserStripePaymentMethod({
                            uid: auth.uid,
                            paymentMethod: result.setupIntent.payment_method
                        });
                        elements.getElement(CardElement).clear();
                        window.scrollTo(0, 0);
                        this.setState({
                            errorMsg: null,
                            redirect: true,
                            email: '',
                            name: '',
                            addressLine1: '',
                            addressLine2: '',
                            city: '',
                            state: '',
                        })
                    }
                } else {
                    this.setState({
                        errorMsg: "Please provide all mandatory fields and try again.",
                    })
                }
            })
            .catch(err => {
                this.setState({
                    errorMsg: "An error occurred while saving your payment method",
                })
            })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    render() {
        const { stripe, elements, secret, auth, redirectToPath } = this.props;
        if (this.state.redirect && redirectToPath !== false) {
            return <Redirect to={{
                pathname: '/payment/methods',
                state: { refresh: true }
            }} />
        }
        return (

            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-left mb--20">
                        <p className="description">Enter the billing and payment details to save your payment method.</p>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12">
                            <label>Name on the card<span className="star">*</span></label>
                            <input type="name" id='name' placeholder="John Doe" onChange={this.handleChange} />
                        </div>
                        <div className="col-md-6 col-12">
                            <label htmlFor="email">Email<span className="star">*</span></label>
                            <input id="email" ref="input" onChange={this.handleChange} placeholder="john@example.com" type="text" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12">
                            <label>Address Line 1<span className="star">*</span></label>
                            <input type="addressLine1" id='addressLine1' placeholder="123 Rose St." onChange={this.handleChange} />
                        </div>
                        <div className="col-md-6 col-12">
                            <label>Address Line 2</label>
                            <input type="addressLine2" id='addressLine2' placeholder="Apt 22" onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-12">
                            <label>City <span className="star">*</span></label>
                            <input type="city" id='city' placeholder="San Francisco" onChange={this.handleChange} />
                        </div>
                        <div className="col-md-6 col-12">
                            <label htmlFor="state">State<span className="star">*</span></label>
                            <select name="state" id="state" value={this.state.state} onChange={this.handleChange}>
                                <option value="">Select a State</option>
                                <option value="AL">Alabama (AL)</option>
                                <option value="AK">Alaska (AK)</option>
                                <option value="AZ">Arizona (AZ)</option>
                                <option value="AR">Arkansas (AR)</option>
                                <option value="CA">California (CA)</option>
                                <option value="CO">Colorado (CO)</option>
                                <option value="CT">Connecticut (CT)</option>
                                <option value="DE">Delaware (DE)</option>
                                <option value="DC">District Of Columbia (DC)</option>
                                <option value="FL">Florida (FL)</option>
                                <option value="GA">Georgia (GA)</option>
                                <option value="HI">Hawaii (HI)</option>
                                <option value="ID">Idaho (ID)</option>
                                <option value="IL">Illinois (IL)</option>
                                <option value="IN">Indiana (IN)</option>
                                <option value="IA">Iowa (IA)</option>
                                <option value="KS">Kansas (KS)</option>
                                <option value="KY">Kentucky (KY)</option>
                                <option value="LA">Louisiana (LA)</option>
                                <option value="ME">Maine (ME)</option>
                                <option value="MD">Maryland (MD)</option>
                                <option value="MA">Massachusetts (MA)</option>
                                <option value="MI">Michigan (MI)</option>
                                <option value="MN">Minnesota (MN)</option>
                                <option value="MS">Mississippi (MS)</option>
                                <option value="MO">Missouri (MO)</option>
                                <option value="MT">Montana (MT)</option>
                                <option value="NE">Nebraska (NE)</option>
                                <option value="NV">Nevada (NV)</option>
                                <option value="NH">New Hampshire (NH)</option>
                                <option value="NJ">New Jersey (NJ)</option>
                                <option value="NM">New Mexico (NM)</option>
                                <option value="NY">New York (NY)</option>
                                <option value="NC">North Carolina (NC)</option>
                                <option value="ND">North Dakota (ND)</option>
                                <option value="OH">Ohio (OH)</option>
                                <option value="OK">Oklahoma (OK)</option>
                                <option value="OR">Oregon (OR)</option>
                                <option value="PA">Pennsylvania (PA)</option>
                                <option value="RI">Rhode Island (RI)</option>
                                <option value="SC">South Carolina (SC)</option>
                                <option value="SD">South Dakota (SD)</option>
                                <option value="TN">Tennessee (TN)</option>
                                <option value="TX">Texas (TX)</option>
                                <option value="UT">Utah (UT)</option>
                                <option value="VT">Vermont (VT)</option>
                                <option value="VA">Virginia (VA)</option>
                                <option value="WA">Washington (WA)</option>
                                <option value="WV">West Virginia (WV)</option>
                                <option value="WI">Wisconsin (WI)</option>
                                <option value="WY">Wyoming (WY)</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="cardDetailsSection">Payment method details<span className="star">*</span></label>
                        <CardSection />
                    </div>

                    <div className="mt--20">
                        <button
                            onClick={this.handleSubmit}
                            className="rn-button-style--2 btn-solid"
                            disabled={!stripe || !elements || !secret || !auth}>
                            Save Card
                        </button>
                    </div>

                    <div className="mt--30">
                        <ErrorMessage errorMessageText={this.state.errorMsg} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUserStripePaymentMethod: (obj) => dispatch(updateUserStripePaymentMethod(obj)),
        validateCard: (obj) => dispatch(validateCard(obj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardSetupForm)