import axios from 'axios';
import { baseURL } from "../../components/constants/CommonConstants";

function isValid(element) {
    return (element === null || element !== '' || element.trim() !== '')
};

export const getPreferredOperators = (data) => {
    return async (dispatch, getState, { getFirebase }) => {
        if (isValid(data.uid)) {
            const inputData = {
                uid: data.uid,
            }
            const url = baseURL + '/getPreferredOperators';
            await axios.post(url, inputData)
                .then(async (res) => {
                    // console.log('res= ', res.data);
                    dispatch({ type: 'RETRIEVED_PREFERRED_OPERATORS_SUCCESSFULLY', preferredOperatorsData: res.data });
                })
                .catch((err) => {
                    // console.log('error = ', err)
                    dispatch({ type: 'PREFERRED_OPERATORS_RETRIEVAL_ERROR' });
                });
        } else {
            dispatch({ type: 'PREFERRED_OPERATORS_RETRIEVAL_ERROR' });
        }
    }
}