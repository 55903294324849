import React from 'react'

const RideDistance = (props) => {
  return (
    <p>
      Distance: { props.calculatedDistance !== -1 ? props.calculatedDistance.toFixed(2) + ' miles' : 'N/A'}
    </p>
  )
}

export default RideDistance