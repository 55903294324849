import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    operatorExpressSignUpAction, clearErrorAction,
    verifyOperatorExpressSignUpAction, sendVerificationCode, verifyCode, getCarMakeListAction
} from '../../store/actions/authActions'
import PasswordMask from 'react-password-mask';
import M from "materialize-css";
import Loader1 from '../loader/Loader1';
import PhoneInput from 'react-phone-input-2';
import Breadcrumb from "../common/Breadcrumb";
import ErrorMessage from "../errorMessage/ErrorMessage"
import * as ga from '../util/googleAnalytics'
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import '../common/Checkbox.css';
import CheckBox from '../common/Checkbox';
import firebase from "../../config/fbConfig";

class OperatorExpressSignUp extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.carMakeRef = React.createRef();
        this.handleCarModelChange = this.handleCarModelChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyPhoneForm = this.verifyPhoneForm.bind(this);
        this.handleChangeAirport = this.handleChangeAirport.bind(this);
        this.handleCheckInputChanged = this.handleCheckInputChanged.bind(this);
        this.handleAcceptPhoneTNC = this.handleAcceptPhoneTNC.bind(this);
        this.handleAcceptTNC = this.handleAcceptTNC.bind(this);
    }

    initialState() {
        return {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            accountType: 'Operator',
            phoneNumber: '',
            carMake: '',
            carModel: '',
            carType: '',
            state: '',
            airports: [],
            verifiedPhone: false,
            verificationCode: '',
            verificationCodeSent: false,
            loading: false,
            airportList: [],
            carModelList: [],
            carModelTypeMapping: {},
            carMakeUpdated: false,
            carModelBeingUpdated: false,
            receiveEmails: 'Yes',
            receiveTexts: 'Yes',
            acceptPhoneTNC: false,
            acceptTNC: false
        }
    }

    handleCarModelChange = (event) => {
        let carModel = event.target.value;
        this.setState({
            carModel: carModel,
            carType: this.state.carModelTypeMapping[carModel]
        })
    }

    handleChange(event) {
        const { id, name, value } = event.target
        if (name === 'state') {
            this.setState({
                [id]: value,
                airports: [],
            })
        } else if (name === 'carMake') {
            this.setState({
                [id]: value,
                carModel: '',
                carType: ''
            })
        } else if (id === 'carPlate') {
            this.setState({
                carPlate: value.toUpperCase()
            })
        } else if (id === 'phoneNumber') {
            this.setState({
                [id]: value,
                verificationCodeSent: false
            })
        } else {
            this.setState({
                [id]: value
            })
        }
    }

    handlePhoneChange = (value) => {
        this.setState({
            phoneNumber: value.replace(/[^0-9]+/g, ''),
            verificationCodeSent: false
        })
    }

    openVerifyDetailsModal = () => {
        var elems = document.getElementById('verifySignUpDetailsModal');
        M.Modal.init(elems, {});
        M.Modal.getInstance(elems).open();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        var elems = document.getElementById('verifySignUpDetailsModal');
        M.Modal.getInstance(elems).close();
        this.props.clearError();
        this.setState({
            verificationCode: '',
            loading: true
        }, () => {
            this.props.verifyOperatorExpressSignUpAction(this.state)
                .then((valid) => {
                    if (valid && valid === true) {
                        if (this.state.verificationCodeSent === false) {
                            //send verification code to user
                            this.props.sendVerificationCodeAction(this.state)
                                .then((status) => {
                                    // console.log('status -', status);
                                    if (status === true) {
                                        this.setState({
                                            verificationCodeSent: true,
                                            loading: false
                                        });
                                        //open the modal, so user can enter verification code
                                        var elems = document.getElementById('verifyPhoneModal');
                                        M.Modal.init(elems, {});
                                        M.Modal.getInstance(elems).open();
                                    } else {
                                        // console.log('code sending failed');
                                        window.scrollTo(0, 0);
                                        this.setState({
                                            loading: false
                                        });
                                    }
                                })
                                .catch(() => {
                                    // console.log('error sending code');
                                    window.scrollTo(0, 0);
                                    this.setState({
                                        loading: false
                                    });
                                })
                        }
                    } else {
                        window.scrollTo(0, 0);
                        this.setState({
                            loading: false
                        });
                    }
                })
                .catch(() => {
                    window.scrollTo(0, 0);
                    this.setState({
                        loading: false
                    });
                })
        })
    }

    verifyPhoneForm = (e) => {
        e.preventDefault();
        let inputData = {
            phoneNumber: this.state.phoneNumber,
            code: this.state.verificationCode
        }
        var elems = document.getElementById('verifyPhoneModal');
        M.Modal.getInstance(elems).close();

        //verify the user entered code
        this.props.verifyCode(inputData)
            .then((data) => {
                if (!data.err) {
                    //save profile now
                    this.props.operatorExpressSignUpAction(this.state);
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            })
            .catch(() => {
                this.setState({
                    loading: false
                });
            })
    }

    handleChangeAirport = (airports) => {
        this.setState({
            airports
        })
    }

    handleCheckInputChanged = (event) => {
        let airportList = this.state.airportList;
        let selectedAirports = [];
        airportList.forEach(airport => {
            if (airport.value === event.target.value) {
                airport.isChecked = event.target.checked;
            }
            if (airport.isChecked) {
                selectedAirports.push(airport.value);
            }
        })
        this.setState({ airportList });
        this.handleChangeAirport(selectedAirports);
    }

    handleAcceptPhoneTNC = (event) => {
        this.setState({ 
            acceptPhoneTNC: event.target.checked
        });
    }

    handleAcceptTNC = (event) => {
        this.setState({ 
            acceptTNC: event.target.checked
        });
    }

    componentDidMount() {
        this.props.clearError();
        ga.pageViewsTracking(window.location.pathname);
        this.setState({
            loading: true
        }, (() => {
            this.props.getCarMakeList()
                .then(() => {
                    window.scrollTo(0, 0);
                    this.setState({
                        loading: false
                    });
                });
        })
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const currentCarMake = this.state.carMake;
        let carModelDropdown = document.getElementById("carModel");
        const currentState = this.state.state;
        let airports = [];
        if (currentState !== '' && prevState.state !== currentState) {
            const ref = firebase.firestore().collection('airports').doc(currentState);
            ref.get().then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    for (var p in data.airports) {
                        if (data.airports.hasOwnProperty(p)) {
                            airports.push({ value: p, label: data.airports[p] + " (" + p + ")", isChecked: false });
                        }
                    }
                }
                this.setState({
                    airportList: airports
                });
            })
        }

        // load the car makes
        if (this.props.carMakeList && prevState.carMakeList !== this.props.carMakeList && this.props.carMakeList.length > 0 && this.state.carMakeUpdated === false) {
            // populate the car make list
            // let carMakeDropdown = document.getElementById("carMake");
            let carMakeDropdown = this.carMakeRef.current;
            this.props.carMakeList.forEach((value) => {
                if (value === this.state.carMake) {
                    carMakeDropdown.add(new Option(value, value, false, true));
                } else {
                    carMakeDropdown.add(new Option(value, value));
                }
            })
            this.setState({
                carMakeUpdated: true
            });
        }

        if (currentCarMake === "") {
            if (carModelDropdown) {
                carModelDropdown.length = 1;
                carModelDropdown.setAttribute("disabled", "disabled");
            }
        } else if (prevState.carMake !== currentCarMake) {
            const ref = firebase.firestore().collection('cars').doc(currentCarMake);
            ref.get().then((doc) => {
                if (doc.exists) {
                    carModelDropdown.length = 1;
                    let data = doc.data();
                    let carModelList = Object.keys(data.modelType);
                    for (var m in carModelList) {
                        carModelDropdown.add(new Option(carModelList[m], carModelList[m]));
                    }
                    carModelDropdown.removeAttribute("disabled");
                    this.setState({
                        carModelList: carModelList,
                        carModelTypeMapping: data.modelType
                    });
                } else {
                    carModelDropdown.length = 1;
                    carModelDropdown.setAttribute("disabled", "disabled");
                    this.setState({
                        carModelList: [],
                        carModelTypeMapping: {}
                    });
                }
            });
        }
    }

    render() {
        const { auth, authError, profile } = this.props;
        if (auth.uid) {
            if (profile && !profile.isEmpty) {
                return <Redirect to='/home' />
            } else {
                return (
                    <div className="container center">
                        <div>Loading</div>
                        <Loader1 />
                    </div>
                )
            }
        }

        let loader = null;
        if (this.state.loading === true) {
            loader = <Loader1 />
        }

        return (
            <>
        {loader}
                <Breadcrumb title={'Operator'} title1={'Operator Sign Up'} parent='Sign Up' parentLink='/signup' />
                <main className="page-wrapper contact-form--1 rn-contact-form-area ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-12">
                                <div className="mb--40">
                                    {authError ? <ErrorMessage errorMessageText={authError}></ErrorMessage> : null}
                                </div>
                                <div className="section-title text-left mb--50">
                                    <h3 className="title">Create your operator account</h3>
                                    <p className="description ml--10">Join now and start accepting new rides!</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div>
                                            <label htmlFor="firstName">First Name<span className="star">*</span></label>
                                            <input type="text" id='firstName' placeholder="Enter your first name" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div>
                                            <label htmlFor="lastName">Last Name<span className="star">*</span></label>
                                            <input type="text" id='lastName' placeholder="Enter your last name" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div>
                                            <label htmlFor="phoneNumber">Cell Phone <span className="star">*</span> <span>
                                                <Tooltip title='Landline number is not allowed. Only cell phone number is allowed.'>
                                                    <InfoIcon fontSize='small' />
                                                </Tooltip>
                                            </span>
                                            </label>
                                            <PhoneInput
                                                specialLabel=""
                                                id='phoneNumber'
                                                onlyCountries={['us']}
                                                country={'us'}
                                                disableCountryCode={true}
                                                disableSearchIcon={true}
                                                disableDropdown={true}
                                                placeholder={'(xxx) xxx-xxxx'}
                                                value={this.state.phoneNumber}
                                                onChange={this.handlePhoneChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <div>
                                            <label htmlFor="email">Email<span className="star">*</span></label>
                                            <input type="email" id='email' placeholder="yours@example.com" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <div>
                                            <label htmlFor="email">Password <span className="star">* </span>
                                                <span><Tooltip title='Password should be minimum 8 characters'>
                                                    <InfoIcon fontSize='small' />
                                                </Tooltip></span>
                                            </label>
                                            <PasswordMask
                                                id="password"
                                                name="password"
                                                placeholder="Set your Password"
                                                value={this.state.password}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <label htmlFor="carMake">Make<span className="star">*</span></label>
                                        <select name="carMake" id="carMake"
                                            ref={this.carMakeRef}
                                            value={this.state.carMake}
                                            onChange={this.handleChange}>
                                            <option value="">Select a car make</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <label htmlFor="carModel">Model<span className="star">*</span></label>
                                        <select name="carModel" id="carModel" value={this.state.carModel} onChange={this.handleCarModelChange}>
                                            <option value="">Select a car model</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <label htmlFor="state">State<span className="star">*</span></label>
                                        <select name="state" id="state" value={this.state.state} onChange={this.handleChange}>
                                            <option value="">Select a State</option>
                                            <option value="AL">Alabama (AL)</option>
                                            <option value="AK">Alaska (AK)</option>
                                            <option value="AZ">Arizona (AZ)</option>
                                            <option value="AR">Arkansas (AR)</option>
                                            <option value="CA">California (CA)</option>
                                            <option value="CO">Colorado (CO)</option>
                                            <option value="CT">Connecticut (CT)</option>
                                            <option value="DE">Delaware (DE)</option>
                                            <option value="DC">District Of Columbia (DC)</option>
                                            <option value="FL">Florida (FL)</option>
                                            <option value="GA">Georgia (GA)</option>
                                            <option value="HI">Hawaii (HI)</option>
                                            <option value="ID">Idaho (ID)</option>
                                            <option value="IL">Illinois (IL)</option>
                                            <option value="IN">Indiana (IN)</option>
                                            <option value="IA">Iowa (IA)</option>
                                            <option value="KS">Kansas (KS)</option>
                                            <option value="KY">Kentucky (KY)</option>
                                            <option value="LA">Louisiana (LA)</option>
                                            <option value="ME">Maine (ME)</option>
                                            <option value="MD">Maryland (MD)</option>
                                            <option value="MA">Massachusetts (MA)</option>
                                            <option value="MI">Michigan (MI)</option>
                                            <option value="MN">Minnesota (MN)</option>
                                            <option value="MS">Mississippi (MS)</option>
                                            <option value="MO">Missouri (MO)</option>
                                            <option value="MT">Montana (MT)</option>
                                            <option value="NE">Nebraska (NE)</option>
                                            <option value="NV">Nevada (NV)</option>
                                            <option value="NH">New Hampshire (NH)</option>
                                            <option value="NJ">New Jersey (NJ)</option>
                                            <option value="NM">New Mexico (NM)</option>
                                            <option value="NY">New York (NY)</option>
                                            <option value="NC">North Carolina (NC)</option>
                                            <option value="ND">North Dakota (ND)</option>
                                            <option value="OH">Ohio (OH)</option>
                                            <option value="OK">Oklahoma (OK)</option>
                                            <option value="OR">Oregon (OR)</option>
                                            <option value="PA">Pennsylvania (PA)</option>
                                            <option value="RI">Rhode Island (RI)</option>
                                            <option value="SC">South Carolina (SC)</option>
                                            <option value="SD">South Dakota (SD)</option>
                                            <option value="TN">Tennessee (TN)</option>
                                            <option value="TX">Texas (TX)</option>
                                            <option value="UT">Utah (UT)</option>
                                            <option value="VT">Vermont (VT)</option>
                                            <option value="VA">Virginia (VA)</option>
                                            <option value="WA">Washington (WA)</option>
                                            <option value="WV">West Virginia (WV)</option>
                                            <option value="WI">Wisconsin (WI)</option>
                                            <option value="WY">Wyoming (WY)</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        {this.state.airportList && this.state.airportList.length > 0
                                            ?
                                            <>
                                                <label>Select airports you service<span className="star">*</span></label>
                                                <ul className="airportList">
                                                    {
                                                        this.state.airportList.map((airport) => {
                                                            return (<CheckBox key={airport.value} handleCheckInputChanged={this.handleCheckInputChanged}  {...airport} />)
                                                        })
                                                    }
                                                </ul>
                                            </>
                                            : ""
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 red-text">
                                        <ul className="acceptTNCCheckList">
                                            <CheckBox key="acceptPhoneTNC" 
                                                handleCheckInputChanged={this.handleAcceptPhoneTNC} 
                                                label="I agree to receive at the cell phone number I have provided automated texts, automated and/or prerecorded messages and/or calls from Findanio and its agents regarding this account. I am not consenting to receive marketing messages as a condition of signing up for this account. Message and data rates may apply."
                                            />
                                            <CheckBox key="acceptTNC" 
                                                handleCheckInputChanged={this.handleAcceptTNC} 
                                                label={<>I confirm that you've read and accepted our <a href="/tnc" target="_blank" className="anchor underline">Terms and Conditions of Service</a> and <a href="/privacy" target="_blank" className="anchor underline">Privacy Policy</a>.</>}
                                            />
                                        </ul>
                                    </div>
                                </div>
                                <div className="row ptb--10">
                                    <div className="col-sm-12">
                                        <button className="rn-button-style--2 btn-solid" 
                                            onClick={this.openVerifyDetailsModal}
                                            disabled={(this.state.acceptPhoneTNC === true && this.state.acceptTNC === true) ? false : true}
                         >Sign Up</button>
                                    </div>
                                </div>

                                <div id="verifySignUpDetailsModal" className="modal">
                                    <div className="modal-content modalForm">
                                        <div>
                                            <i className="material-icons modal-close float-right">close</i>
                                            <h4 className="center "> <b>Review your details</b></h4>
                                            <div className="ml--20">
                                                <b className="error-text">First Name:</b> {this.state.firstName}<br /><hr />
                                                <b className="error-text">Last Name:</b> {this.state.lastName}<br />  <hr />
                                                <b className="error-text">Phone Number:</b> {this.state.phoneNumber}<br />  <hr />
                                                <b className="error-text">Email:</b> {this.state.email}<br />  <hr />
                                                <b className="error-text">State:</b> {this.state.state}<br />  <hr />
                                                <b className="error-text">Selected Airport(s):</b>
                                                {
                                                    this.state.airportList.map((airport) => {
                                                        return airport.isChecked ? (<div key={airport.value}>{airport.label} </div>)
                                                            : null
                                                    })
                                                }
                                                <br />  <hr />
                                            </div>
                                        </div>
                                        <div className="center">
                                            <button className="rn-button-style--2 btn-solid mt--5" onClick={this.handleSubmit}>Continue</button>
                                            <button className="rn-button-style--2 btn-solid modal-close mt--5 ml--10">Cancel</button>
                                        </div>
                                    </div>
                                </div>


                                <div id="verifyPhoneModal" className="modal">
                                    <div className="modal-content modalForm">
                                        <div className="center">
                                            <i className="material-icons modal-close" style={{ float: 'right' }}>close</i>
                                            <form className="modalForm " onSubmit={this.verifyPhoneForm}>
                                                <h5>Please enter the verification code sent to {this.state.phoneNumber}</h5>
                                                <br />
                                                <div>
                                                    <label htmlFor="verificationCode">Enter Verification Code<span className="star">*</span></label>
                                                    <input type="text" id='verificationCode' onChange={this.handleChange} />
                                                </div>
                                                <br />
                                                <button className="rn-button-style--2 btn-solid">Verify</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError,
        profile: state.firebase.profile,
        carMakeList: state.auth.carMakeList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCarMakeList: (creds) => dispatch(getCarMakeListAction()),
        verifyOperatorExpressSignUpAction: (data) => dispatch(verifyOperatorExpressSignUpAction(data)),
        sendVerificationCodeAction: (data) => dispatch(sendVerificationCode(data)),
        verifyCode: (code) => dispatch(verifyCode(code)),
        operatorExpressSignUpAction: (data) => dispatch(operatorExpressSignUpAction(data)),
        clearError: () => dispatch(clearErrorAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorExpressSignUp)
