import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import NavigationMenu from './components/header/NavigationMenu'
import Login from './components/login/Login'
import OperatorSignUp from './components/signup/OperatorSignUp'
import OperatorExpressSignUp from './components/signup/OperatorExpressSignUp'
import TravelerSignUp from './components/signup/TravelerSignUp'
import MarketingSubscription from './components/signup/MarketingSubscription'
import GlobalSignUp from './components/signup/GlobalSignUp'
import PreferredOperators from './components/preferredOperators/PreferredOperators'
import SignUp from './components/signup/SignUp'
import AboutUs from './components/aboutUs/AboutUs'
import ContactUs from './components/contactUs/ContactUs'
import FAQs from './components/faqs/FAQs'
import Footer from './components/footer/Footer'
import TNC from './components/termsAndCondition/TNC'
import NoMatch from './components/noMatch/NoMatch'
import ForgotPassword from './components/profile/ForgotPassword';
import ResetPassword from './components/profile/ResetPassword';
import InstantQuote from './components/rides/InstantQuote';
import Privacy from './components/privacy/Privacy'
import Locations from './components/locations/Locations';
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom'
import BookNewRide from './components/rides/BookNewRide'
import Rides from './components/rides/Rides'
import Subscription from './components/subscription/Subscription'
import ViewProfile from './components/profile/ViewProfile'
import Account from './components/profile/Account'
import AccountOperator from './components/profile/AccountOperator'
import UpdatePassword from './components/profile/UpdatePassword'
import RequestedRides from './components/rides/traveler/RequestedRides'
import GetStarted from './components/subscription/GetStarted'
// import AcceptedRides from './components/rides/operator/AcceptedRides';
import Home from './components/home/Home';
import ProcessRidePayment from './components/rides/traveler/ProcessRidePayment';
import TravelerRideDetails from './components/rides/traveler/TravelerRideDetails'
import OperatorRideDetails from './components/rides/operator/OperatorRideDetails'
// import AdminHome from './components/admin/AdminHome'
// import NewRides from './components/rides/operator/NewRides'
import Cashout from './components/cashout/Cashout'
import OAuthConnect from './components/cashout/OAuthConnect'
import ViewPaymentMethods from './components/payment/viewPaymentMethods/ViewPaymentMethods'
// import ViewPaymentMethodsInPage from './components/payment/viewPaymentMethods/ViewPaymentMethodsInPage'
// import AddPaymentMethod from './components/payment/addPaymentMethod/AddPaymentMethod'
import Operators from './components/landing/Operators'
import Travelers from './components/landing/Travelers'
import Payment from './components/payment/Payment'
import Landing from './components/landing/Landing';
// import Helmet from "./components/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import QuickGuide from './components/quickGuides/QuickGuide';
import TravelerQuickGuide from './components/quickGuides/TravelerQuickGuide';
import OperatorQuickGuide from './components/quickGuides/OperatorQuickGuide';
import Pricing from './components/pricing/Pricing';
// import PendingOperators from './components/admin/PendingOperators';
// import AdminSearch from './components/admin/AdminSearch';
// import AdminText from './components/admin/AdminText';
// import AdminEmail from './components/admin/AdminEmail';
// import VehicleTypes from './components/admin/VehicleTypes';
// import OperatorProfile from './components/admin/OperatorProfile';
// import SearchOperatorProfile from './components/admin/SearchOperatorProfile';
// import MarketingSubscriptionList from './components/admin/MarketingSubscriptionList';
import TravelerRides from './components/rides/traveler/TravelerRides';
import ProtectedRoute from './ProtectedRoute';

class App extends Component {

  linkScrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <BrowserRouter>

        <NavigationMenu />

        <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/login' render={(props) => <Login {...props} />} />
          <Route exact path='/about' component={AboutUs} />
          <Route exact path='/contact-us' component={ContactUs} />
          <Route exact path='/faqs' component={FAQs} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/signup/traveler' component={TravelerSignUp} />
          <Route exact path='/signup/operator' component={OperatorExpressSignUp} />
          <Route exact path='/signup/operator/old' component={OperatorSignUp} />
          <Route exact path='/marketingSubscription' component={MarketingSubscription} />
          <Route exact path='/global' component={GlobalSignUp} />
          <Route exact path='/tnc' component={TNC} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/forgotPassword' component={ForgotPassword} />
          <Route exact path='/instant-quote' component={InstantQuote} />
          <Route exact path='/locations' component={Locations} />
          <Route exact path='/operators' component={Operators} />
          <Route exact path='/travelers' component={Travelers} />
          <Route exact path='/quickGuide' component={QuickGuide} />
          <Route exact path='/quickGuide/traveler' component={TravelerQuickGuide} />
          <Route exact path='/quickGuide/operator' component={OperatorQuickGuide} />
          <Route exact path='/resetPassword' component={ResetPassword} />
          <Route exact path='/pricing' component={Pricing} />
          <Route exact path='/404' component={NoMatch} />

          <ProtectedRoute exact path='/home' component={Home} />
          <ProtectedRoute exact path='/account' component={Account} />
          <ProtectedRoute exact path='/rides' component={Rides} />
          <ProtectedRoute exact path='/ride/create' component={BookNewRide} />
          <ProtectedRoute exact path='/ride/requested' component={RequestedRides} />
          <ProtectedRoute exact path='/ride/requested/:id' component={TravelerRideDetails} />
          <ProtectedRoute exact path='/ride/:id' component={OperatorRideDetails} />
          <ProtectedRoute exact path='/subscription' component={Subscription} />
          <ProtectedRoute exact path='/profile' component={ViewProfile} />
          <ProtectedRoute exact path='/accountOperator' component={AccountOperator} />
          <ProtectedRoute exact path='/security' component={UpdatePassword} />
          <ProtectedRoute exact path='/subscription/getStarted/:id' component={GetStarted} />
          <ProtectedRoute exact path='/processRidePayment/:id/:token' component={ProcessRidePayment} />
          {/* <ProtectedRoute exact path='/admin' component={AdminHome} /> */}
          <ProtectedRoute exact path='/cashout' component={Cashout} />
          <ProtectedRoute exact path='/payment' component={Payment} />
          <ProtectedRoute exact path='/payment/methods' component={ViewPaymentMethods} />
          {/* <ProtectedRoute exact path='/payment/viewMethods' component={ViewPaymentMethodsInPage} /> */}
          {/* <ProtectedRoute exact path='/payment/method/new' component={AddPaymentMethod} /> */}
          <ProtectedRoute exact path='/cashout/connect/oauth' component={OAuthConnect} />
          <ProtectedRoute exact path='/preferredOperators' component={PreferredOperators} />
          {/* <ProtectedRoute exact path='/admin/pendingOperators' component={PendingOperators} />
          <ProtectedRoute exact path='/admin/adminSearch' component={AdminSearch} />
          <ProtectedRoute exact path='/admin/vehicleTypes' component={VehicleTypes} />
          <ProtectedRoute exact path='/admin/text' component={AdminText} />
          <ProtectedRoute exact path='/admin/email' component={AdminEmail} />
          <ProtectedRoute exact path='/admin/operatorProfile/:id' component={OperatorProfile} />
          <ProtectedRoute exact path='/admin/searchOperatorProfile/:id' component={SearchOperatorProfile} /> */}
          <ProtectedRoute exact path='/my-reservations' component={TravelerRides} />
          {/* <ProtectedRoute exact path='/admin/marketingSubscriptionList' component={MarketingSubscriptionList} /> */}

          <Route>
            <Redirect to='/404' />
          </Route>
        </Switch>

        <Footer />

        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>

        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="acceptCookies"
          cookieValue={true}
          style={{ background: "rgb(248, 249, 252)", paddingTop: "15px", color: "#1d1d24" }}
          disableButtonStyles={true}
          buttonClasses="rn-button-style--2 btn-solid"
          buttonWrapperClasses="padding10">
          We use cookies to enhance your experience on our site. To find out more,
          please read our <Link
            style={{ textDecoration: 'underline' }}
            onClick={this.linkScrollToTop}
            to={'/tnc'}>Terms and Conditions of Service</Link> and <Link
              style={{ textDecoration: 'underline' }}
              onClick={this.linkScrollToTop}
              to={'/privacy'}>Privacy Policy</Link>.
        </CookieConsent>

      </BrowserRouter>
    );
  }
}

export default App;
