import React from 'react'
import ErrorIcon from '@material-ui/icons/Error';

class ErrorMessage extends React.Component {
    render() {
        const { errorMessageText } = this.props;
        if (errorMessageText) {
            return (
                <>
                    <div className="error-box">
                        <span className="icon"><ErrorIcon className="error-text" /></span> <b>Error:</b> {errorMessageText}
                    </div>
                </>
            )
        } else {
            return (<></>)
        }
    }
}

export default ErrorMessage
