import React, { Component } from "react";
import { NavLink } from 'react-router-dom';

class CallAction extends Component {

    constructor(props) {
        super(props);
        this.linkScrollToTop = this.linkScrollToTop.bind(this);
    }

    linkScrollToTop() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <span>Use FINDANIO to unite the chauffeured industry and disrupt the TNCs!</span>
                                <h5>Ready to do this?</h5>
                                <NavLink className="rn-button-style--2" onClick={this.linkScrollToTop} to="/contact-us"><span>Contact Us</span></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CallAction;