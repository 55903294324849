import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CardSetupForm from './CardSetupForm';

export default function InjectedCardSetupForm(props) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => {
                return (
                    <CardSetupForm
                        stripe={stripe}
                        elements={elements}
                        secret={props.secret}
                        redirectToPath={props.redirectToPath} />
                )
            }
            }
        </ElementsConsumer>
    );
}