export const getVehicleTypeName = (vehicleType) => {
    switch (vehicleType) {
        case 'Sedan': return 'Sedan'
        case 'LuxurySedan': return 'Luxury Sedan'
        case 'SUV': return 'SUV'
        case 'PassengerVan': return 'Passenger Van'
        case 'CorporateSprinter': return 'Corporate Sprinter'
        case 'LimoSprinter': return 'Limo Sprinter'
        case 'Bus24Passenger': return '24 Passenger Bus'
        case 'Bus34Passenger': return '34 Passenger Bus'
        case 'CoachBus': return 'Coach Bus'
        case 'StretchLimo': return 'Stretch Limo'
        case 'StretchSUV': return 'Stretch SUV'
        default: return 'N/A'
    }
}

export const generateRideId7 = () => {
    return Math.floor((1 + Math.random()) * 0x10000000)
        .toString(16)
        .substring(1)
        .toUpperCase();
}

export const createDataForAdmin = (id, dateTime, from, to, price, status, action, serviceVehicle, serviceType, distance, duration, serviceDate, actionLink,
    rideConfirmationCode, confirmedOperatorName = null, confirmedOperatorPhoneNumber = null) => {
    return {
        id, dateTime, from, to, price: '$' + price, status, action, serviceVehicle, serviceType, distance, duration, serviceDate, actionLink,
        rideConfirmationCode, confirmedOperatorName, confirmedOperatorPhoneNumber
    };
}

export const createDataForTraveler = (id, dateTime, from, to, price, status, action, serviceVehicle, serviceType, distance, duration, serviceDate, actionLink,
    rideConfirmationCode, confirmedOperatorName = null, confirmedOperatorPhoneNumber = null, passengerName, confirmedOperatorProfileImageUrl = null, specialNotes, confirmedOperatorRate = null) => {
    return {
        id, dateTime, from, to, price: '$' + price, status, action, serviceVehicle, serviceType, distance, duration, serviceDate, actionLink,
        rideConfirmationCode, confirmedOperatorName, confirmedOperatorPhoneNumber, passengerName, confirmedOperatorProfileImageUrl, specialNotes, confirmedOperatorRate
    };
}

export const createDataForOperator = (id, dateTime, from, to, price, status, action, serviceVehicle, serviceType, distance, duration, serviceDate, actionLink,
    rideConfirmationCode, confirmedOperatorName = null, confirmedOperatorPhoneNumber = null) => {
    return {
        id, dateTime, from, to, price: '$' + price, status, action, serviceVehicle, serviceType, distance, duration, serviceDate, actionLink,
        rideConfirmationCode, confirmedOperatorName, confirmedOperatorPhoneNumber
    };
}

export const getServiceTypeName = (serviceType) => {
    switch (serviceType) {
        case 'Point-to-Point': return 'Point-to-Point'
        case 'HourlyCharter': return 'Hourly Charter'
        default: return 'N/A'
    }
}