import React from 'react'

class OperatorSignUpStep4 extends React.Component {
  render() {
    if (this.props.currentStep !== 4) { // Prop: The current step
      return null
    }
    return (
      <div className="form-group mt--20">
        <div className="row mb--20">
          <div className="col-12" style={{ fontSize: '80%' }}>
            <span className="star">*</span> Required
          </div>
        </div>

        <div className="row mb--20">
          <div className="col-lg-6 col-sm-6 col-md-6">
            Do you want to receive text messages? <span className="star">*</span>
          </div>
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span className="mr--20">
              <input name="receiveTexts" type="radio" value="Yes"
                checked={this.props.receiveTexts === "Yes"}
                onChange={this.props.handleChange} />
              <span>Yes</span>
            </span>
            <span>
              <input name="receiveTexts" type="radio" value="No"
                checked={this.props.receiveTexts === "No"}
                onChange={this.props.handleChange} />
              <span>No</span>
            </span>
          </div>
        </div>

        <div className="row mb--20">
          <div className="col-lg-6 col-sm-6 col-md-6">
            Do you want to receive email communications? <span className="star">*</span>
          </div>
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span className="mr--20">
              <input name="receiveEmails" type="radio" value="Yes"
                checked={this.props.receiveEmails === "Yes"}
                onChange={this.props.handleChange} />
              <span>Yes</span>
            </span>
            <span>
              <input name="receiveEmails" type="radio" value="No"
                checked={this.props.receiveEmails === "No"}
                onChange={this.props.handleChange} />
              <span>No</span>
            </span>
          </div>
        </div>

        <div className="row mb--20">
          <div className="col-lg-6 col-sm-6 col-md-6">
            Is your vehicle pet friendly? <span className="star">*</span>
          </div>
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span className="mr--20">
              <input name="petFriendly" type="radio" value="Yes"
                checked={this.props.petFriendly === "Yes"}
                onChange={this.props.handleChange} />
              <span>Yes</span>
            </span>
            <span>
              <input name="petFriendly" type="radio" value="No"
                checked={this.props.petFriendly === "No"}
                onChange={this.props.handleChange} />
              <span>No</span>
            </span>
          </div>
        </div>

        <div className="row mb--20">
          <div className="col-lg-6 col-sm-6 col-md-6">
            Do you sanitize the vehicle before each ride pick up?
          </div>
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span className="mr--20">
              <input name="q1" type="radio" value="Yes"
                checked={this.props.q1 === "Yes"}
                onChange={this.props.handleChange} />
              <span>Yes</span>
            </span>
            <span>
              <input name="q1" type="radio" value="No"
                checked={this.props.q1 === "No"}
                onChange={this.props.handleChange} />
              <span>No</span>
            </span>
          </div>
        </div>

        <div className="row mb--20">
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span>Do you provide complimentary drinking water?</span>
          </div>
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span className="mr--20">
              <input name="q2" type="radio" value="Yes"
                checked={this.props.q2 === "Yes"}
                onChange={this.props.handleChange} />
              <span>Yes</span>
            </span>
            <span>
              <input name="q2" type="radio" value="No"
                checked={this.props.q2 === "No"}
                onChange={this.props.handleChange} />
              <span>No</span>
            </span>
          </div>
        </div>

        <div className="row mb--20">
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span>Do you provide hand sanitizer?</span>
          </div>
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span className="mr--20">
              <input name="q3" type="radio" value="Yes"
                checked={this.props.q3 === "Yes"}
                onChange={this.props.handleChange} />
              <span>Yes</span>
            </span>
            <span>
              <input name="q3" type="radio" value="No"
                checked={this.props.q3 === "No"}
                onChange={this.props.handleChange} />
              <span>No</span>
            </span>
          </div>
        </div>

        <div className="row mb--20">
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span>Do you have a sneeze guards divider shield in your car?</span>
          </div>
          <div className="col-lg-6 col-sm-6 col-md-6">
            <span className="mr--20">
              <input name="q4" type="radio" value="Yes"
                checked={this.props.q4 === "Yes"}
                onChange={this.props.handleChange} />
              <span>Yes</span>
            </span>
            <span>
              <input name="q4" type="radio" value="No"
                checked={this.props.q4 === "No"}
                onChange={this.props.handleChange} />
              <span>No</span>
            </span>
          </div>
        </div>

      </div>
    )
  }
}

export default OperatorSignUpStep4