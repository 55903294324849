import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getOperatorPastRideHistoryAction, clearErrorAction } from '../../../store/actions/projectActions'
import Loader1 from '../../loader/Loader1'
import moment from 'moment';
import RideTableData from '../RideTableData4'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createDataForOperator } from '../../util/rideUtils';
import * as ga from '../../util/googleAnalytics'

class OperatorHistoryRidesData extends Component {

  state = {
    projectsFound: false
  }

  componentDidMount() {
    const { auth, profile } = this.props;
    ga.pageViewsTracking(window.location.pathname);
    this.props.clearError();
    if (!this.state.projectsFound && !profile.isEmpty) {
      this.props.getOperatorConfirmedRideHistory(auth.uid);
      this.setState({
        projectsFound: true
      })
    }
  }

  componentDidUpdate() {
    const { projects, auth } = this.props;
    if (!this.state.projectsFound && !projects) {
      this.props.getOperatorConfirmedRideHistory(auth.uid);
      this.setState({
        projectsFound: true
      })
    }
  }


  render() {
    const { projects, auth, profile, errorMsg } = this.props;
    if (!auth.uid) return <Redirect to='/login' />

    if (!profile.isEmpty && profile.accountType !== 'Operator') {
      return <Redirect to='/404' />
    }

    if (this.state.projectsFound && projects && !errorMsg) {
      if (projects.length > 0) {
        let finalData = [];
        projects && projects.forEach(project => {
          let viewDetailsBtn = (
            <Link to={'/ride/' + project.id}><Tooltip title="More Details"><MoreVertIcon /></Tooltip></Link>
          );
          let actionLink = '/ride/' + project.id;
          finalData.push(
            createDataForOperator(
              project.id,
              // moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY"),
              // moment(project.serviceDate.toDate()).format("LT"),
              project.serviceDateStr || moment(project.serviceDate.toDate()).format("dddd, MMMM Do, YYYY hh:mm A"),
              `${project.serviceFrom} ${project.serviceFromName ? `(${project.serviceFromName})` : ''}`,
              `${project.serviceTo} ${project.serviceToName ? `(${project.serviceToName})` : ''}`,
              project.calculatedPrice && project.calculatedPrice !== '-1.00' ? project.calculatedPrice : null,
              project.status ? project.status.toUpperCase() : null,
              viewDetailsBtn,
              project.serviceVehicle,
              project.serviceType,
              project.calculatedDistance.toFixed(2) + ' miles',
              project.serviceType === 'HourlyCharter' ? `${project.serviceHours} hours` : project.calculatedRideDuration,
              project.serviceDate.toDate(),
              actionLink,
              project.rideConfirmationCode
            )
          )
        })
        return (
          <RideTableData rows={finalData} />
        )
      } else {
        return (
          <Card variant="outlined">
            <CardContent>
              <p className="center error-text">No rides found</p>
            </CardContent>
          </Card>
        )
      }
    } else if (errorMsg) {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">{errorMsg}</p>
          </CardContent>
        </Card>
      )
    } else {
      return (
        <Card variant="outlined">
          <CardContent>
            <p className="center error-text">Loading</p>
            <Loader1 />
          </CardContent>
        </Card>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    projects: state.project.operatorPastRides,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    errorMsg: state.project.errorMsg
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOperatorConfirmedRideHistory: (uid) => dispatch(getOperatorPastRideHistoryAction(uid)),
    clearError: () => dispatch(clearErrorAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorHistoryRidesData)
