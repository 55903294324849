import React from 'react'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loader1 = () => {
  return (
    <div className="main-loader">
      <Loader
        type="Circles"
        color="#f81f01"
        height={100}
        width={100}
      />
    </div>
  )
}

export default Loader1