import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Loader1 from '../../loader/Loader1';
import { connect } from 'react-redux';
import { processRideConfirmationAction } from '../../../store/actions/authActions';


class ProcessRidePayment extends Component {

  componentDidMount() {
    const { auth, token, projectId, updated } = this.props;
    if (projectId && token && !updated && processRideConfirmationAction !== undefined) {
      this.props.processRideConfirmationAction(token, auth.uid, projectId)
    }
  }

  render() {
    const { auth, token, projectId, updated, project } = this.props;
    if (!auth.uid) return <Redirect to='/login' />

    if (projectId && token && project && updated) {
      if (auth.uid === project.authorId) {
        let projectUrl = '/ride/requested/' + projectId;
        return <Redirect to={projectUrl} />
      } else {
        let projectUrl = '/ride/' + projectId;
        return <Redirect to={projectUrl} />
      }

    } else {
      return (
        <div className='center'>
          <div>Loading. Please wait</div>
          <Loader1 />
        </div>)
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.id;
  const token = ownProps.match.params.token;
  const project = state.auth.project;
  return {
    auth: state.firebase.auth,
    updated: state.auth.updated,
    token: token,
    projectId: projectId,
    profile: state.firebase.profile,
    project: project
  }
}

const mapDispatchToProps = dispatch => {
  return {
    processRideConfirmationAction: (id, uid, projectId) => dispatch(processRideConfirmationAction(id, uid, projectId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessRidePayment)
