import React, { Component } from 'react'
import OperatorSignUpStep1 from './OperatorSignUpStep1'
import OperatorSignUpStep2 from './OperatorSignUpStep2'
import OperatorSignUpStep3 from './OperatorSignUpStep3'
import OperatorSignUpStep4 from './OperatorSignUpStep4'
import OperatorSignUpStep5 from './OperatorSignUpStep5'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    operatorsignUpAction, clearErrorAction,
    validateOperatorSignupStepAction, sendVerificationCode, verifyCode,
    getCarMakeListAction
} from '../../store/actions/authActions'
import M from "materialize-css";
import Loader1 from '../loader/Loader1'
import ErrorMessage from "../errorMessage/ErrorMessage"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Breadcrumb from "../common/Breadcrumb";
import * as ga from '../util/googleAnalytics'

const google = window.google;
var options = {
    componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] }
};

class OperatorSignUp extends Component {
    constructor(props) {
        super(props)
        // Set the initial input values
        this.state = {
            currentStep: 1, // Default is Step 1
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            officePhoneNumber: '',
            vehicleVIN: '',
            carYear: '2023',
            carMake: '',
            carModel: '',
            carColor: 'Black',
            otherColor: '',
            carPlate: '',
            carType: '',
            state: '',
            airports: [],
            accountType: 'Operator',
            petFriendly: '',
            receiveEmails: '',
            receiveTexts: '',
            companyName: '',
            profileImageFile: null,
            profileImageFileURL: '',
            licenseFile: null,
            licenseFileURL: '',
            insuranceFile: null,
            insuranceFileURL: '',
            vehicleImageFile: null,
            vehicleImageFileURL: '',
            vehicleRegistrationImageFile: '',
            vehicleRegistrationImageURL: null,
            verifiedPhone: null,
            verificationCode: '',
            verificationCodeSent: false,
            loading: false,
            q1: '',
            q2: '',
            q3: '',
            q4: '',
            liabilityInsuranceAmt: '',
            cancellationPolicy: '',
            address: '',
            profileDescription: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.updateCarModelChange = this.updateCarModelChange.bind(this);
        this.updateLoader = this.updateLoader.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.removeFileHandler = this.removeFileHandler.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleOfficePhoneChange = this.handleOfficePhoneChange.bind(this);
        this._next = this._next.bind(this);
        this._prev = this._prev.bind(this);
        this.handlePlaceSelectAddress = this.handlePlaceSelectAddress.bind(this);
        this.autocompleteAddress = null;
    }

    handlePhoneChange = (value, data, event) => {
        this.setState({
            phoneNumber: value.replace(/[^0-9]+/g, ''),
            verificationCodeSent: false
        })
    }

    handleOfficePhoneChange = (value, data, event) => {
        this.setState({
            officePhoneNumber: value.replace(/[^0-9]+/g, '')
        })
    }

    handlePlaceSelectAddress() {
        let addressObject = this.autocompleteAddress.getPlace();
        if (addressObject) {
            this.setState({
                address: addressObject.formatted_address
            })
        }
    }

    verifyPhoneForm = (e) => {
        e.preventDefault();
        let inputData = {
            phoneNumber: this.state.phoneNumber,
            code: this.state.verificationCode
        }

        var elems = document.getElementById('verifyPhoneModal');
        this.setState({
            loading: true
        }, () => {
            M.Modal.getInstance(elems).close();
            //verify the user entered code
            this.props.verifyCode(inputData)
                .then((data) => {
                    if (!data.err) {
                        let currentStep = this.state.currentStep;
                        currentStep = currentStep >= 2 ? 3 : currentStep + 1
                        this.setState({
                            verifiedPhone: inputData.phoneNumber,
                            currentStep: currentStep,
                            loading: false
                        })
                    } else {
                        this.setState({
                            verificationCode: '',
                            loading: false
                        })
                    }
                })
                .catch(() => {
                    this.setState({
                        loading: false
                    })
                }, () => {
                    this.setState({
                        loading: false
                    })
                })
        })
    }

    // _next and _previous functions will be called on button click
    _next() {
        // console.log('next clicked')
        this.props.clearError();
        let currentStep = this.state.currentStep;
        this.setState({
            loading: true
        }, () => {
            this.props.validateOperatorSignupStep(currentStep, this.state)
                .then((validStep) => {
                    // console.log('valid step ', validStep);
                    if (validStep) {
                        // check if verification code needs to be sent to phoneNumber
                        if (currentStep === 1 && (this.state.verifiedPhone === null || this.state.phoneNumber !== this.state.verifiedPhone)) {
                            this.setState({
                                verificationCode: ''
                            }, () => {
                                if (this.state.verificationCodeSent === false) {
                                    //send verification code to user
                                    this.props.sendVerificationCodeAction(this.state)
                                        .then((data) => {
                                            // console.log('data =', data);
                                            if (data.err) {
                                                this.setState({
                                                    verificationCodeSent: false,
                                                    loading: false
                                                });
                                            } else {
                                                this.setState({
                                                    verificationCodeSent: true,
                                                    loading: false
                                                });
                                                //open the modal, so user can enter verification code
                                                var elems = document.getElementById('verifyPhoneModal');
                                                M.Modal.init(elems, {});
                                                M.Modal.getInstance(elems).open();
                                            }
                                        })
                                        .catch((err) => {
                                            // console.log('error', err)
                                            this.setState({
                                                loading: false
                                            })
                                        })
                                } else {
                                    this.setState({
                                        verificationCodeSent: true,
                                        loading: false
                                    });
                                    //open the modal, so user can enter verification code
                                    var elems = document.getElementById('verifyPhoneModal');
                                    M.Modal.init(elems, {});
                                    M.Modal.getInstance(elems).open();
                                }
                            }
                            )
                        } else {
                            currentStep = currentStep >= 4 ? 5 : currentStep + 1
                            this.setState({
                                currentStep: currentStep,
                                loading: false
                            });
                        }
                    } else {
                        // console.log('invalid step');
                        this.setState({
                            loading: false
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        loading: false
                    });
                });
            window.scrollTo(0, 0);
        })
    }

    _prev() {
        let currentStep = this.state.currentStep
        // If the current step is 2 or 3, then subtract one on "previous" button click
        currentStep = currentStep <= 1 ? 1 : currentStep - 1
        this.setState({
            currentStep: currentStep,
        });
        window.scrollTo(0, 0);
    }

    updateLoader(flag) {
        this.setState({
            //isLoading: flag
        })
    }

    handleChangeAirport = (airports) => {
        this.setState({
            airports
        })
    }

    updateCarModelChange = (carModel, carType) => {
        this.setState({
            carModel: carModel,
            carType: carType
        })
    }

    // Use the submitted data to set the state
    handleChange(event) {
        const { id, name, value } = event.target
        if (name === 'petFriendly'
            || name === 'receiveEmails'
            || name === 'receiveTexts'
            || name === 'q1'
            || name === 'q2'
            || name === 'q3'
            || name === 'q4'
        ) {
            this.setState({
                [name]: value
            })
        } else if (name === 'state') {
            this.setState({
                [id]: value,
                airports: [],
            })
        } else if (name === 'carColor') {
            if (value === 'OtherColor') {
                document.getElementById('otherColor').disabled = false;
                this.setState({
                    [id]: value,
                })
            } else {
                document.getElementById('otherColor').disabled = true;
                this.setState({
                    [id]: value,
                    otherColor: ''
                })
            }
        } else if (name === 'carMake') {
            this.setState({
                [id]: value,
                carModel: '',
                carType: ''
            })
        } else if (id === 'carPlate') {
            this.setState({
                carPlate: value.toUpperCase()
            })
        } else if (id === 'phoneNumber') {
            this.setState({
                [id]: value,
                verificationCodeSent: false
            })
        } else {
            this.setState({
                [id]: value
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let currentStep = this.state.currentStep;
        this.setState({
            loading: true
        }, (() => {
            this.props.validateOperatorSignupStep(currentStep, this.state)
                .then((data) => {
                    if (data) {
                        this.props.operatorsignUp(this.state)
                            .then(() => {
                                window.scrollTo(0, 0);
                                this.setState({
                                    loading: false
                                });
                            })
                            .catch((err) => {
                                this.setState({
                                    loading: false
                                });
                            })
                    }
                })
                .catch((err) => {
                    this.setState({
                        loading: false
                    });
                })
        })
        );
    }

    fileChangedHandler = (event) => {
        const file = event.target.files[0];
        if (file.type && file.type.toLowerCase().includes('image')) {
            switch (event.target.id) {
                case 'profileImageFile':
                    this.setState({
                        profileImageFile: file,
                        profileImageFileURL: URL.createObjectURL(event.target.files[0])
                    });
                    break;
                case 'licenseFile':
                    this.setState({
                        licenseFile: file,
                        licenseFileURL: URL.createObjectURL(event.target.files[0]),
                    });
                    break;
                case 'insuranceFile':
                    this.setState({
                        insuranceFile: file,
                        insuranceFileURL: URL.createObjectURL(event.target.files[0]),
                    });
                    break;
                case 'vehicleImageFile':
                    this.setState({
                        vehicleImageFile: file,
                        vehicleImageFileURL: URL.createObjectURL(event.target.files[0]),
                    });
                    break;
                case 'vehicleRegistrationImageFile':
                    this.setState({
                        vehicleRegistrationImageFile: file,
                        vehicleRegistrationImageURL: URL.createObjectURL(event.target.files[0]),
                    });
                    break;
                default:
                    break;
            }
            document.getElementById(`${event.target.id}Err`).innerHTML = '';
        } else {
            // invalid image file uploaded, so clear it
            document.getElementById(event.target.id).value = '';
            document.getElementById(`${event.target.id}Err`).innerHTML = 'Please upload an image file only.';
        }
    }

    removeFileHandler = (id) => {
        document.getElementById(id).value = '';
        switch (id) {
            case 'profileImageFile':
                this.setState({
                    profileImageFile: null,
                    profileImageFileURL: ''
                });
                break;
            case 'licenseFile':
                this.setState({
                    licenseFile: null,
                    licenseFileURL: '',
                });
                break;
            case 'insuranceFile':
                this.setState({
                    insuranceFile: null,
                    insuranceFileURL: '',
                });
                break;
            case 'vehicleImageFile':
                this.setState({
                    vehicleImageFile: null,
                    vehicleImageFileURL: '',
                });
                break;
            case 'vehicleRegistrationImageFile':
                this.setState({
                    vehicleRegistrationImageFile: null,
                    vehicleRegistrationImageURL: '',
                });
                break;



            default:
                break;
        }
    }

    getSteps() {
        return ['Personal Details',
            'Vehicle Details',
            'Upload Documents',
            'Preferences',
            'Review'
        ];
    }

    getStepContent(step) {
        switch (step) {
            case 1:
                return <OperatorSignUpStep1
                    currentStep={this.state.currentStep}
                    handleChange={this.handleChange}
                    email={this.state.email}
                    password={this.state.password}
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    phoneNumber={this.state.phoneNumber}
                    companyName={this.state.companyName}
                    address={this.state.address}
                    profileDescription={this.state.profileDescription}
                    handlePhoneChange={this.handlePhoneChange}
                    handleOfficePhoneChange={this.handleOfficePhoneChange}
                />
            case 2:
                return <OperatorSignUpStep2
                    currentStep={this.state.currentStep}
                    updateCarModelChange={this.updateCarModelChange}
                    carMakeList={this.props.carMakeList}
                    handleChange={this.handleChange}
                    vehicleVIN={this.state.vehicleVIN}
                    carMake={this.state.carMake}
                    carModel={this.state.carModel}
                    carYear={this.state.carYear}
                    carPlate={this.state.carPlate}
                    carColor={this.state.carColor}
                    otherColor={this.state.otherColor}
                    carType={this.state.carType}
                    liabilityInsuranceAmt={this.state.liabilityInsuranceAmt}
                    cancellationPolicy={this.state.cancellationPolicy}
                    airports={this.state.airports}
                    state={this.state.state}
                    updateLoader={this.updateLoader}
                    handleChangeAirport={this.handleChangeAirport}
                />;
            case 3:
                return <OperatorSignUpStep3
                    currentStep={this.state.currentStep}
                    fileChangedHandler={this.fileChangedHandler}
                    removeFileHandler={this.removeFileHandler}
                    profileImageFileURL={this.state.profileImageFileURL}
                    licenseFileURL={this.state.licenseFileURL}
                    insuranceFileURL={this.state.insuranceFileURL}
                    vehicleImageFileURL={this.state.vehicleImageFileURL}
                    vehicleRegistrationImageURL={this.state.vehicleRegistrationImageURL}
                />;
            case 4:
                return <OperatorSignUpStep4
                    currentStep={this.state.currentStep}
                    receiveEmails={this.state.receiveEmails}
                    receiveTexts={this.state.receiveTexts}
                    petFriendly={this.state.petFriendly}
                    q1={this.state.q1}
                    q2={this.state.q2}
                    q3={this.state.q3}
                    q4={this.state.q4}
                    handleChange={this.handleChange}
                />;
            case 5:
                return <OperatorSignUpStep5
                    details={this.state}
                />;
            default:
                return 'An error has occurred.';
        }
    }

    componentDidMount() {
        this.props.clearError();
        let addressElement = document.getElementById('address');
        if (addressElement) {
            this.autocompleteAddress = new google.maps.places.Autocomplete(document.getElementById('address'), options)
            this.autocompleteAddress.addListener("place_changed", this.handlePlaceSelectAddress);
        }
        this.props.getCarMakeList();
        ga.pageViewsTracking(window.location.pathname);
    }

    componentDidUpdate() {
        M.updateTextFields();
        let addressElement = document.getElementById('address');
        if (addressElement) {
            this.autocompleteAddress = new google.maps.places.Autocomplete(document.getElementById('address'), options)
            this.autocompleteAddress.addListener("place_changed", this.handlePlaceSelectAddress);
        }
    }

    render() {
        const { auth, authError, profile } = this.props;
        if (auth.uid) {
            if (profile && !profile.isEmpty) {
                if (profile.accountType === 'Traveler') {
                    return <Redirect to='/home' />
                } else {
                    return <Redirect to='/home' />
                }
            } else {
                return (
                    <div className="container center">
                        <div>Loading</div>
                        <Loader1 />
                    </div>
                )
            }
        }

        let loader = (this.state.loading === true) ?
            (
                <div className="container center">
                    <Loader1 />
                </div>
            ) : null;

        const steps = this.getSteps();

        let currentStep = this.state.currentStep;
        // If the current step is not 1, then render the "previous" button
        let previousStepDiv = (currentStep !== 1) ?
            (
                <button
                    className="rn-button-style--2 btn-solid"
                    onClick={this._prev}>
                    Previous
                </button>
            ) : (
                <button
                    className="rn-button-style--2 disabled" disabled>
                    Previous
                </button>
            );

        let nextStepDiv = (currentStep < 5) ?
            (
                <button
                    className="rn-button-style--2 btn-solid"
                    onClick={this._next}>
                    Next
                </button>
            ) :
            // if step 4 then render the "sign up " button
            (
                <button
                    className="rn-button-style--2 btn-solid"
                    onClick={this.handleSubmit}>
                    Sign Up
                </button>
            );

        return (
            <>
                <Breadcrumb title={'Operator'} title1={'Operator Sign Up'} parent='Signup' parentLink='/signup' />

                {loader}
                <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--50 bg_color--1">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-12">
                            <div className="section-title text-left mb--50">
                                <h3 className="title ml--10" >Create your account</h3>
                                <p className="description ml--10">Join now and start accepting new rides!</p>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {authError ? <ErrorMessage errorMessageText={authError}></ErrorMessage> : null}
                                </div>
                            </div>
                            <Stepper activeStep={this.state.currentStep - 1} orientation="vertical">
                                {steps.map((label, index) => {
                                    return (
                                        <Step key={"step" + index}>
                                            {/* <StepLabel>{label}</StepLabel> */}
                                            <StepLabel><span style={{fontSize: 16 + 'px' }}>{label}</span></StepLabel>
                                            <StepContent>
                                                <>{this.getStepContent(this.state.currentStep)}</>
                                                <div className="row mb--20" style={{ paddingLeft: '20px' }}>
                                                    <div className="col-lg-6 ptb--20 order-sm-2 order-lg-1">
                                                        {previousStepDiv}
                                                    </div>
                                                    <div className="col-lg-6 ptb--20 order-sm-1 order-lg-2">
                                                        {nextStepDiv}
                                                    </div>
                                                </div>

                                                <div id="verifyPhoneModal" className="modal">
                                                    <div className="modal-content center">  
                                                        <div>
                                                            <i className="material-icons modal-close" style={{ float: 'right' }}>close</i>
                                                            <form className="modalForm " onSubmit={this.verifyPhoneForm}>
                                                                <h5>Please enter the verification code sent to {this.state.phoneNumber}</h5>
                                                                <br />
                                                                <div>
                                                                    <label htmlFor="verificationCode">Enter Verification Code<span className="star">*</span></label>
                                                                    <input type="text" id='verificationCode' value={this.state.verificationCode} onChange={this.handleChange} />
                                                                </div>
                                                                <br />
                                                                <button className="rn-button-style--2 btn-solid">Verify</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                            </StepContent>
                                        </Step>
                                    );
                                })}
                            </Stepper>

                        </div>
                    </div>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        authError: state.auth.authError,
        profile: state.firebase.profile,
        carMakeList: state.auth.carMakeList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCarMakeList: (creds) => dispatch(getCarMakeListAction()),
        operatorsignUp: (creds) => dispatch(operatorsignUpAction(creds)),
        sendVerificationCodeAction: (creds) => dispatch(sendVerificationCode(creds)),
        verifyCode: (code) => dispatch(verifyCode(code)),
        clearError: () => dispatch(clearErrorAction()),
        validateOperatorSignupStep: (step, creds) => dispatch(validateOperatorSignupStepAction(step, creds))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorSignUp)