import React, { Component } from 'react'
import { connect } from 'react-redux'
import { login, clearErrorAction } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import PasswordMask from 'react-password-mask';
import Loader1 from '../loader/Loader1'
import Breadcrumb from "../common/Breadcrumb";
import ErrorMessage from "../errorMessage/ErrorMessage"
import * as ga from '../util/googleAnalytics'

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loading: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.clearError();
    ga.pageViewsTracking(window.location.pathname);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    }, (() => {
      this.props.login(this.state)
        .then(() => {
          window.scrollTo(0, 0);
          this.setState({
            password: '',
            loading: false
          });
        });
    })
    );
  }

  linkScrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const { authError, auth, profile } = this.props;
    let loader = null;
    if (this.state.loading === true) {
      loader = <Loader1 />
    }
    if (auth.uid) {
      if (profile && !profile.isEmpty) {
        let redirectAfterLoginTo = this.props.location.state ? this.props.location.state.redirectAfterLoginTo : null;
        // console.log('redirectAfterLoginTo = ', redirectAfterLoginTo);
        if (redirectAfterLoginTo) {
          return <Redirect to={redirectAfterLoginTo} />;
        } else {
          return <Redirect to='/home' />
        }
      } else {
        return (
          <>
            <Breadcrumb title={'Login'} />
            <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--80 bg_color--1">
              <div className="row row--35 align-items-start">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--50">
                    <p className="description">Loading</p>
                    <Loader1 />
                  </div>
                </div>
              </div>
            </main>
          </>
        )
      }
    }
    return (
      <>
        {loader}
        <Breadcrumb title={'Login'} />
        <main className="page-wrapper contact-form--1 container rn-contact-form-area ptb--20 bg_color--1">
          <div className="row align-items-start">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="mb--40">
                {authError ? <ErrorMessage errorMessageText={authError}></ErrorMessage> : null}
              </div>
              <div className="section-title text-center mb--30">
                <h4>Login to access your account</h4>
              </div>
              <div className="container">
                <form onSubmit={this.handleSubmit}>
                  <label><b>Email address</b><span className="star">*</span></label>
                  <div>
                    <input type="email" id='email' placeholder="yours@example.com" onChange={this.handleChange} />
                  </div>
                  <label><b>Password</b><span className="star">*</span></label>
                  <div>
                    <PasswordMask
                      id="password"
                      name="password"
                      placeholder="Enter password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="mb--20" style={{ float: 'right' }}>
                    <button className="rn-button-style--2 btn-solid">Login</button>
                  </div>
                  <div>
                    <label>
                      <NavLink to='/forgotPassword' onClick={this.linkScrollToTop} className="link-red-color">Forgot Password?</NavLink>
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="link-box mt--20 center bg_color--5">
                <p className="description">New to FINDANIO? <NavLink to='/signup' className="link-red-color" onClick={this.linkScrollToTop}>Create an account.</NavLink>
                </p>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (creds) => dispatch(login(creds)),
    clearError: () => dispatch(clearErrorAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
