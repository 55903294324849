import React from 'react'
import ReactMarkdown from 'react-markdown'
import SAASAgreementMD from './SAASAgreementMD';

class SAASAgreement extends React.Component {

    render() {
        return (
            <ReactMarkdown skipHtml={false}  children={SAASAgreementMD}></ReactMarkdown>
        )
    }

}

export default SAASAgreement
