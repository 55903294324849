import React from 'react'
import ReactMarkdown from 'react-markdown'
import ServiceAgreementMD from './ServiceAgreementMD';

class ServiceAgreement extends React.Component {

    render() {
        return (
            <>
                <ReactMarkdown skipHtml={false} children={ServiceAgreementMD}></ReactMarkdown>
            </>
        )
    }
}

export default ServiceAgreement
