// React Required
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import fbConfig from './config/fbConfig'
import './index.scss';
import './customStyle.scss';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';

const store = createStore(rootReducer,
    compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        reactReduxFirebase(fbConfig, { userProfile: 'users', useFirestoreForProfile: true, attachAuthIsReady: true }),
        reduxFirestore(fbConfig) // redux bindings for firestore
    )
);

// G-CC76TPGCK4 - prod ga4 measurement id
// G-V5TJJH0NRC - dev ga4 measurement id
store.firebaseAuthIsReady.then(() => {
    ReactGA.initialize('G-CC76TPGCK4', {
        debug: false,
        titleCase: false,
        gaOptions: {
            userId: 'Anonymous',
            siteSpeedSampleRate : 100
        }
    });
    ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
    // registerServiceWorker();
    serviceWorker.register();
});