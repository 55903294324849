import React from 'react'

const ServiceHours = (props) => {
  switch (props.serviceVehicle) {
    case 'Sedan':
    case 'LuxurySedan':
    case 'SUV':
      return (
        <div >
          <label htmlFor="serviceHours">Service Hours<span className="star">*</span></label>
          <select id='serviceHours' className="serviceHours" value={props.serviceHours} onChange={props.handleChange}>
            <option value={2}>2 Hours Minimum</option>
            <option value={3}>3 Hours</option>
            <option value={4}>4 Hours</option>
            <option value={5}>5 Hours</option>
            <option value={6}>6 Hours</option>
            <option value={7}>7 Hours</option>
            <option value={8}>8 Hours</option>
            <option value={9}>9 Hours</option>
            <option value={10}>10 Hours</option>
            <option value={11}>11 Hours</option>
            <option value={12}>12 Hours</option>
            <option value={13}>13 Hours</option>
            <option value={14}>14 Hours</option>
            <option value={15}>15 Hours</option>
            <option value={16}>16 Hours</option>
            <option value={17}>17 Hours</option>
            <option value={18}>18 Hours</option>
            <option value={19}>19 Hours</option>
            <option value={20}>20 Hours</option>
            <option value={21}>21 Hours</option>
            <option value={22}>22 Hours</option>
            <option value={23}>23 Hours</option>
            <option value={24}>24 Hours</option>
          </select>
        </div>
      )

    case 'PassengerVan':
    case 'CorporateSprinter':
    case 'LimoSprinter':
    case 'Bus24Passenger':
    case 'StretchLimo':
    case 'StretchSUV':
      return (
        <div >
          <label htmlFor="serviceHours">Service Hours<span className="star">*</span></label>
          <select id='serviceHours' className="serviceHours" value={props.serviceHours} onChange={props.handleChange}>
            <option value={3}>3 Hours Minimum</option>
            <option value={4}>4 Hours</option>
            <option value={5}>5 Hours</option>
            <option value={6}>6 Hours</option>
            <option value={7}>7 Hours</option>
            <option value={8}>8 Hours</option>
            <option value={9}>9 Hours</option>
            <option value={10}>10 Hours</option>
            <option value={11}>11 Hours</option>
            <option value={12}>12 Hours</option>
            <option value={13}>13 Hours</option>
            <option value={14}>14 Hours</option>
            <option value={15}>15 Hours</option>
            <option value={16}>16 Hours</option>
            <option value={17}>17 Hours</option>
            <option value={18}>18 Hours</option>
            <option value={19}>19 Hours</option>
            <option value={20}>20 Hours</option>
            <option value={21}>21 Hours</option>
            <option value={22}>22 Hours</option>
            <option value={23}>23 Hours</option>
            <option value={24}>24 Hours</option>
          </select>
        </div>
      )
    case 'Bus34Passenger':
      return (
        <div >
          <label htmlFor="serviceHours">Service Hours<span className="star">*</span></label>
          <select id='serviceHours' className="serviceHours" value={props.serviceHours} onChange={props.handleChange}>
            <option value={4}>4 Hours Minimum</option>
            <option value={5}>5 Hours</option>
            <option value={6}>6 Hours</option>
            <option value={7}>7 Hours</option>
            <option value={8}>8 Hours</option>
            <option value={9}>9 Hours</option>
            <option value={10}>10 Hours</option>
            <option value={11}>11 Hours</option>
            <option value={12}>12 Hours</option>
            <option value={13}>13 Hours</option>
            <option value={14}>14 Hours</option>
            <option value={15}>15 Hours</option>
            <option value={16}>16 Hours</option>
            <option value={17}>17 Hours</option>
            <option value={18}>18 Hours</option>
            <option value={19}>19 Hours</option>
            <option value={20}>20 Hours</option>
            <option value={21}>21 Hours</option>
            <option value={22}>22 Hours</option>
            <option value={23}>23 Hours</option>
            <option value={24}>24 Hours</option>
          </select>
        </div>
      )

    case 'CoachBus':
      return (
        <div >
          <label htmlFor="serviceHours">Service Hours<span className="star">*</span></label>
          <select id='serviceHours' className="serviceHours" value={props.serviceHours} onChange={props.handleChange}>
            <option value={5}>5 Hours Minimum</option>
            <option value={6}>6 Hours</option>
            <option value={7}>7 Hours</option>
            <option value={8}>8 Hours</option>
            <option value={9}>9 Hours</option>
            <option value={10}>10 Hours</option>
            <option value={11}>11 Hours</option>
            <option value={12}>12 Hours</option>
            <option value={13}>13 Hours</option>
            <option value={14}>14 Hours</option>
            <option value={15}>15 Hours</option>
            <option value={16}>16 Hours</option>
            <option value={17}>17 Hours</option>
            <option value={18}>18 Hours</option>
            <option value={19}>19 Hours</option>
            <option value={20}>20 Hours</option>
            <option value={21}>21 Hours</option>
            <option value={22}>22 Hours</option>
            <option value={23}>23 Hours</option>
            <option value={24}>24 Hours</option>
          </select>
        </div>
      )
    default:
      return (
        <div >
          <label htmlFor="serviceHours">Service Hours<span className="star">*</span></label>
          <select id='serviceHours' className="serviceHours" value={props.serviceHours} onChange={props.handleChange}>
            <option value={2}>2 Hours Minimum</option>
            <option value={3}>3 Hours</option>
            <option value={4}>4 Hours</option>
            <option value={5}>5 Hours</option>
            <option value={5}>5 Hours</option>
            <option value={5}>5 Hours</option>
            <option value={6}>6 Hours</option>
            <option value={7}>7 Hours</option>
            <option value={8}>8 Hours</option>
            <option value={9}>9 Hours</option>
            <option value={10}>10 Hours</option>
            <option value={11}>11 Hours</option>
            <option value={12}>12 Hours</option>
            <option value={13}>13 Hours</option>
            <option value={14}>14 Hours</option>
            <option value={15}>15 Hours</option>
            <option value={16}>16 Hours</option>
            <option value={17}>17 Hours</option>
            <option value={18}>18 Hours</option>
            <option value={19}>19 Hours</option>
            <option value={20}>20 Hours</option>
            <option value={21}>21 Hours</option>
            <option value={22}>22 Hours</option>
            <option value={23}>23 Hours</option>
            <option value={24}>24 Hours</option>
          </select>
        </div>
      )
  }
}

export default ServiceHours