// FIREBASE FUNCTION BASE URL 
// export const baseURL = 'https://us-central1-findanio-8f6ff.cloudfunctions.net'; // PROD/BETA
// export const baseURL = 'http://localhost:5000/findanio-leading-edge/us-central1'; // local
export const baseURL = ''; // using redirects from firebase.json file

// STRIPE
export const stripeClientId = 'ca_Gk9yYTeyQMBuFTX1PSeUdxPazKMzTrau' // PROD
export const stripePublishableKey = 'pk_live_7g0qh1QU2uxz0MHXuxuc9kzk'; // PROD/BETA
export const standardActionURL = 'https://connect.stripe.com/oauth/authorize?client_id=ca_Gk9yYTeyQMBuFTX1PSeUdxPazKMzTrau'; // PROD 

// GCP STORAGE
export const altMedia = '?alt=media';
export const StorageBaseURL = 'https://firebasestorage.googleapis.com/v0/b/findanio-8f6ff.appspot.com/o/'; // PROD

// FIREBASE configuration
export const config = {
  apiKey: "AIzaSyAhKDh0mb-G5dIj1Iyk32wN_9j-dx686MM",
  authDomain: "findanio-8f6ff.firebaseapp.com",
  projectId: "findanio-8f6ff",
  storageBucket: "findanio-8f6ff.appspot.com",
  messagingSenderId: "923561860019",
  appId: "1:923561860019:web:01b1e12d98c399679a89c6",
  measurementId: "G-MX74QMGVZP"
};

export const largeWidth = 992;

export const travelerPageArr = ['/instant-quote', '/ride/create', '/my-reservations', '/payment', '/payment/methods', '/preferredOperators'];

export const jwtSecret = 'Mu@OLbrf7hHpQZ$IdKHkdZ3m5K*jRAM0X958xD9fZW2';

export const format = 'hh:mm a';

export const coeff = 1000 * 60 * 5;
